import React from 'react'
import {Link} from 'react-router-dom'

export default function AdmissionHeader() {
  return (
    <div>
      <div className={`card  mb-6`}>
        {/* begin::Header */}
        <div className='card-header align-tels-center'>
          <div className='card-title align-items-start flex-column'>
            <span className='fw-bold fs-3 mb-1'>Admission</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Check Admission status</span>
          </div>
          <div className='card-toolbar'>
            <Link to='/Admission/Add'>
              <button className='btn btn-light-primary'>
                <i className='ki-duotone ki-message-add fs-1'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                  <span className='path3'></span>
                                </i> Add Admission
              </button>
            </Link>
          </div>
        </div>
        {/* end::Header */}
        {/* <div className='card-body pb-0 pt-0'>
                    <div className='d-flex overflow-auto h-55px'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/fess' && 'active')
                                    }
                                    to='/Admission/fess'
                                >
                                    Fess Structure
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/stream' && 'active')
                                    }
                                    to='/Admission/stream'
                                >
                                    Change Stream
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/order' && 'active')
                                    }
                                    to='/Admission/order'
                                >
                                    Orders
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/documents' && 'active')
                                    }
                                    to='/Admission/documents'
                                >
                                    Documents
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div> */}
      </div>
    </div>
  )
}
