import React, {useEffect, useState} from 'react'
import './css/notification.css'
import {useLazyFeedsQuery} from '../../Api/allApi'
import {Link} from 'react-router-dom'

export function Feeds(props) {
  const {active, ToggleSidebar, type} = props
  const [feedsTrigger, result] = useLazyFeedsQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    if (type === 'feeds') {
      feedsTrigger({})
    }
  }, [feedsTrigger, type])
  const [feedsData, setFeedsData] = useState([])
  useEffect(() => {
    if (isSuccess && !isFetching) {
      if (type === 'feeds') {
        setFeedsData(result?.data?.feeds)
      }
    }
  }, [isSuccess, isFetching, result?.data?.feeds, type])
  return (
    <>
      <div className={`position-fixed card ${active} notification`} style={{zIndex: '999'}}>
        <div className='row p-5 pt-0'>
          <div
            className='col-12 card rounded-0 border-secondary header-width border-8 px-8 py-5'
            style={{borderBottom: '2px solid', zIndex: '999', position: 'fixed'}}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className=''>Subject</h3>
              <div
                className='close-icon bg-light-dark'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  ToggleSidebar()
                }}
              >
                <i className='bi bi-x-lg  fw-bold'></i>
              </div>
            </div>
          </div>
          {type === 'feeds' ? (
            <>
              {feedsData?.map((item, index) => {
                return (
                  <div
                    className={`col-12 my-2 ${index === 0 ? 'mt-20' : 'mt-0'}`}
                    key={index.toString()}
                  >
                    <div
                      className={`p-5  notification-width fw-semibold mw-lg-400px text-start`}
                      data-kt-element='message-text'
                    >
                      <div className='d-flex align-items-center'>
                        {/* begin::Avatar */}
                        <div className='symbol symbol-50px me-5'>
                          {/* <img src={require('../../../assets/IconButton.png')} className='' alt='' /> */}
                          {item?.iconType === 'pin' ? (
                            <i className={`ki-solid ki-${item?.iconType} fs-2x text-muted`}></i>
                          ) : (
                            <i className={`fa-solid fa-${item?.iconType} fs-2x text-muted`}></i>
                          )}
                        </div>
                        {/* end::Avatar */}
                        {/* begin::Text */}
                        <div className='flex-grow-1'>
                          {item?.link ? (
                            <a
                              href={item?.link}
                              className=' fw-bold text-black'
                              style={{cursor: 'pointer'}}
                            >
                              {item?.title}
                            </a>
                          ) : (
                            <Link
                              to={`/${item?.internalLink}`}
                              className=' fw-bold text-black'
                              style={{cursor: 'pointer'}}
                            >
                              {item?.title}
                            </Link>
                          )}
                          <span className='d-block fw-semibold'>{item?.time}</span>
                        </div>
                        {/* end::Text */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {feedsData.map((subject, index) => {
                return (
                  <div
                    className={`col-12 my-2 ${index === 0 ? 'mt-20' : 'mt-0'}`}
                    key={index.toString()}
                  >
                    <div className='d-flex align-items-center mb-8'>
                      {/* begin::Bullet */}
                      <div className='symbol symbol-50px me-5'>
                        {/* <span className='symbol-label bg-light-success'>
                  <KTIcon iconName='abstract-26' className='fs-2x text-success' />
                </span> */}
                        <div
                          className='symbol-label'
                          style={{
                            backgroundImage: `url(${subject?.iconLink})`,
                          }}
                        ></div>
                      </div>
                      {/* end::Bullet */}
                      {/* begin::Checkbox */}
                      <div className='d-flex w-100 flex-grow-1'>
                        <div className='d-flex flex-column'>
                          <a href='#Subject' className='text-dark text-hover-primary fs-6 fw-bold'>
                            {subject?.label}
                          </a>
                          <h4>{subject?.subjectName} Lectures</h4>
                          <span>{subject?.shortDescr}</span>
                        </div>
                      </div>

                      {/* end::Description */}
                      <div className='d-flex flex-column w-50 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>
                            {subject?.attendance?.present}%
                          </span>
                        </div>
                        <div className='progress h-6px w-100'>
                          <div
                            className={`progress-bar bg-${
                              (subject?.attendance?.present / subject?.attendance?.total) * 100 < 65
                                ? 'danger'
                                : (subject?.attendance?.present / subject?.attendance?.total) *
                                    100 <
                                  80
                                ? 'warning'
                                : 'success'
                            }`}
                            role='progressbar'
                            style={{
                              width: `${
                                (subject?.attendance?.present / subject?.attendance?.total) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </>
  )
}
