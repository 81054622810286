import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import CreatableSelect from 'react-select'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {
  useLazyGetCertificationQuery,
  useLazyGetFacultyDetailsQuery,
  useUpdateCertificationMutation,
} from '../../Api/allApi'
import {setCertification} from '../../redux/achivementSlice'

/* eslint-disable jsx-a11y/anchor-is-valid */
const CertificateModel = ({name, data}) => {
  const dispatch = useDispatch()
  const {certification} = useSelector((state: any) => state.achievementSlice)
  const Certificate = Yup.object().shape({
    issueDate: Yup.string().required('issueDate is required'),
    expirationDate: Yup.string()
      .required('expirationDate is required')
      .test('is-greater', 'Issue date must be less than expiration date', function (value) {
        const issueDate: any = formik.values?.issueDate // Reference to issueDate field
        if (value && issueDate) {
          return new Date(value) > new Date(issueDate)
        }
        return true // If either date is missing, consider it valid
      }),
    credentialID: Yup.string().required('credentialID is required'),
    shortDescr: Yup.string().required('shortDescr is required'),
    credentialURL: Yup.string().required('credentialURL is required'),
    certificationName: Yup.string().required('Title is required'),
    issuerName: Yup.string(),
  })
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    certificationName: '',
    issueDate: '',
    expirationDate: '',
    credentialID: '',
    shortDescr: '',
    issuerName: '',
    credentialURL: '',
  })
  const [array, setArray]: any = useState({
    certification: [],
    selectedCertification: {label: '', value: ''},
    certificateDoesNotExpire: false,
  })
  // certificate api
  const [CertificationTrigger, CertificateResult] = useLazyGetCertificationQuery()
  const {isSuccess: isCertification, isFetching: isNotCertification} = CertificateResult
  // Faculty details API
  useEffect(() => {
    CertificationTrigger({})
  }, [CertificationTrigger])
  useEffect(() => {
    if (isCertification && !isNotCertification) {
      dispatch(setCertification(CertificateResult?.data?.certificateIssuerList))
    }
  }, [isCertification, isNotCertification, CertificateResult, dispatch])
  useEffect(() => {
    let array: any = []

    if (certification?.length !== 0) {
      certification?.map((item: any, index) => {
        array.push({value: item?.dbId, label: item?.issuerName})
        if (certification?.length === index + 1) {
          array.push({value: 'other', label: 'other'})
        }
        return item
      })
    }
    setArray({...array, certification: array})
  }, [certification])
  const [images, setImages]: any = useState({})
  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setImages({
        ...images,
        [e.target.name]: base64String,
      })
    }
    if (file) {
      reader.readAsDataURL(file)
      setImages({
        ...images,
        [e.target.name]: file,
      })
    }
  }
  const [updateTrigger, result] = useUpdateCertificationMutation()
  const {isSuccess, isLoading} = result
  // Faculty details API
  const [facultyDetailsTrigger] = useLazyGetFacultyDetailsQuery()
  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Certificate,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {}
        if (data?.dbId) {
          payload = {
            dbId: data?.dbId ? data?.dbId : 0,
            facultyId: Number(data?.facultyId),
            issueDate: values?.issueDate,
            expirationDate: array.certificateDoesNotExpire ? null : values?.expirationDate,
            credentialID: values?.credentialID,
            credentialURL: values?.credentialURL,
            credentialImg: images?.credentialImg,
            issueByImg: images?.issueByImg,
            certificationName: values?.certificationName,
            shortDescr: values?.shortDescr,
            issuerId:
              array?.selectedCertification?.label === 'other'
                ? null
                : array?.selectedCertification?.value,
            issuerDetails: {
              issuerName: array?.selectedCertification?.label === 'other' ? values?.issuerName : '',
            },
          }
        } else {
          payload = {
            facultyId: Number(data?.facultyId),
            issuerDetails: {
              issuerName: array?.selectedCertification?.label === 'other' ? values?.issuerName : '',
            },
            issuerId:
              array?.selectedCertification?.label === 'other'
                ? null
                : array?.selectedCertification?.value,
            certificationName: values?.certificationName,
            issueDate: values?.issueDate,
            expirationDate: values?.expirationDate,
            credentialID: values?.credentialID,
            credentialURL: values?.credentialURL,
            credentialImg: images?.credentialImg,
            issueByImg: images?.issueByImg,
            shortDescr: values?.shortDescr,
          }
        }
        updateTrigger(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    if (isSuccess && !isLoading) {
      facultyDetailsTrigger(data?.facultyId)
    }
  }, [isSuccess, isLoading, data?.facultyId, facultyDetailsTrigger])
  // set data while updating
  useEffect(() => {
    setInitialValues(data)
    setArray((oldValue) => ({
      ...oldValue,
      selectedCertification: {label: data?.certificationName, value: data?.issuerId},
    }))
  }, [data])
  useEffect(() => {
    console.log(array)
  }, [array])
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>
            {data?.certificationName === '' ? 'Add' : 'Edit'} {name}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id='Certificates'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='certificate'
                >
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>* indicates required </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>Issuing organization*</label>
                        <CreatableSelect
                          options={array?.certification}
                          value={
                            array?.selectedCertification?.label !== '' &&
                            array?.selectedCertification?.value !== ''
                              ? array?.selectedCertification
                              : ''
                          }
                          isClearable={false}
                          onChange={(e: any) => {
                            setArray({
                              ...array,
                              selectedCertification: {label: e.label, value: e.value},
                            })
                          }}
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              height: '100%',
                              width: '100%',
                            }),
                            option: (base) => ({
                              ...base,
                              cursor: 'pointer',
                              margin: '0px',
                              boxShadow: 'unset',
                              color: '#7e8299',
                              fontWeight: 'bold',
                              background: 'white',
                              ':hover': {
                                color: '#009ef7',
                                backgroundColor: '#f1faff',
                              },
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Title*</label>
                      <input
                        type='text'
                        name='certificationName'
                        {...formik.getFieldProps('certificationName')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.certificationName && formik.errors.certificationName,
                          },
                          {
                            'is-valid':
                              formik.touched.certificationName && !formik.errors.certificationName,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>short Description *</label>
                      <textarea
                        name='shortDescr'
                        {...formik.getFieldProps('shortDescr')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.shortDescr && formik.errors.shortDescr,
                          },
                          {
                            'is-valid': formik.touched.shortDescr && !formik.errors.shortDescr,
                          }
                        )}
                        rows={3}
                      ></textarea>
                    </div>
                    {array?.selectedCertification?.label === 'other' && (
                      <div className='col-12 mb-5'>
                        <label className='form-label'>Issuer Name*</label>
                        <input
                          type='text'
                          name='issuerName'
                          {...formik.getFieldProps('issuerName')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.issuerName && formik.errors.issuerName,
                            },
                            {
                              'is-valid': formik.touched.issuerName && !formik.errors.issuerName,
                            }
                          )}
                        />
                      </div>
                    )}

                    <div className='col-md-6 col-12 mb-5 position-relative'>
                      <label className='form-label'>Issue Date</label>
                      <input
                        name='issueDate'
                        type='date'
                        {...formik.getFieldProps('issueDate')}
                        className={clsx(
                          'form-control bg-transparent p-1',
                          {
                            'is-invalid': formik.touched.issueDate && formik.errors.issueDate,
                          },
                          {
                            'is-valid': formik.touched.issueDate && !formik.errors.issueDate,
                          }
                        )}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {formik?.values?.issueDate ? formik?.values?.issueDate : 'Issue Date*'}
                      </div>
                    </div>
                    <div className='col-md-6 col-12 mb-5 position-relative'>
                      <label className='form-label'>Expiry Date</label>
                      <input
                        name='expirationDate'
                        type='date'
                        {...formik.getFieldProps('expirationDate')}
                        className={clsx(
                          'form-control bg-transparent p-1',
                          {
                            'is-invalid':
                              formik.touched.expirationDate && formik.errors.expirationDate,
                          },
                          {
                            'is-valid':
                              formik.touched.expirationDate && !formik.errors.expirationDate,
                          }
                        )}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {formik?.values?.expirationDate
                          ? formik?.values?.expirationDate
                          : 'Expiry Date*'}
                      </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-check-label me-5'>Certificate does not expire</label>
                      <input
                        type='checkbox'
                        className='form-check-input'
                        name='certificateDoesNotExpire'
                        onChange={() =>
                          setArray({
                            ...array,
                            certificateDoesNotExpire: !array.certificateDoesNotExpire,
                          })
                        }
                        checked={array.certificateDoesNotExpire}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>credential Image*</label>
                      <input
                        type='file'
                        name='credentialImg'
                        onChange={(e) => {
                          onHandleChangeFile(e)
                        }}
                        className={clsx('form-control bg-transparent')}
                      />
                      {images?.credentialImg ? (
                        <img
                          src={images?.credentialImg}
                          className='w-50px h-50px mt-2 rounded'
                          alt='credential'
                        />
                      ) : null}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Credential ID</label>
                      <input
                        type='text'
                        name='credentialID'
                        {...formik.getFieldProps('credentialID')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.credentialID && formik.errors.credentialID,
                          },
                          {
                            'is-valid': formik.touched.credentialID && !formik.errors.credentialID,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Credential URL</label>
                      <input
                        type='text'
                        name='credentialURL'
                        {...formik.getFieldProps('credentialURL')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.credentialURL && formik.errors.credentialURL,
                          },
                          {
                            'is-valid':
                              formik.touched.credentialURL && !formik.errors.credentialURL,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss={isSuccess ? 'modal' : ''}
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className='indicator-label d-flex align-items-center gap-2'>
                            <i
                              className={`ki-duotone ki-${
                                data?.certificationName === '' ? 'check-square' : 'message-edit'
                              } fs-1 `}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            {data?.certificationName === '' ? 'Add' : 'Update'}
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CertificateModel}
