import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Link} from 'react-router-dom'
import DataTables from '../../DataTable/DataTables'
import Tooltip from 'rc-tooltip'
import 'rc-tooltip/assets/bootstrap.css'
import {useDeleteJobMutation, useLazyAcademicFilterQuery} from '../../../Api/allApi'
import Swal from 'sweetalert2'
import {useSelector} from 'react-redux'
import {JobUpdateModal} from './JobUpdateModal'
import Select from 'react-select'
const ByClass = () => {
  const [selected, setSelected]: any = useState({})
  // for data table
  const {job} = useSelector((state: any) => state.JobSlice)
  // for filter
  const [filterData, setFilterData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: [],
    status: [
      {label: 'Applied', value: 'applied'},
      {label: 'Offered', value: 'offered'},
      {label: 'Rejected', value: 'rejected'},
      {label: 'Pending', value: 'pending'},
    ],
    statusId: [],
    selectedStatus: [],
  })
  // filter api
  const [filterTrigger, filterResult] = useLazyAcademicFilterQuery()
  const {isSuccess: isFilter, data: filterResultData, isFetching} = filterResult
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])
  // get job list
  // const [JobListByClass, result] = useJobListByClassMutation()
  // const {isSuccess, isLoading} = result
  // delete job list api
  const [deleteJob, deleteResult] = useDeleteJobMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete, isError, error} = deleteResult
  // when api calling is done successfully
  useEffect(() => {
    if (isFilter && !isFetching) {
      const data = filterResultData
      let stream: any = []
      data.stream?.map((item) => {
        stream.push({value: item?.dbId, label: item?.name})
        return item
      })
      setFilterData((oldData) => ({
        ...oldData,
        stream: stream,
      }))
    }
  }, [isFilter, isFetching, filterResultData])
  useEffect(() => {
    if (isDelete && !isNotDelete) {
      //   getJobList({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete])
  // call when api returns error
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: '#DC3545',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  })
  useEffect(() => {
    if (isError) {
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error, Toast])
  // data table data
  const columns = [
    {
      name: 'Position Title',
      selector: (row) => row?.title,
      center: true,
      sortable: true,
    },
    {
      name: 'Company Name',
      selector: (row) => row?.title,
      center: true,
      sortable: true,
    },
    {
      name: 'Contract type',
      selector: (row) => row?.contractType,
      center: true,
      sortable: true,
    },
    {
      name: 'Job Location',
      selector: (row) => row?.location,
      center: true,
      sortable: true,
    },
    {
      name: 'Job Date',
      selector: (row) => moment(row?.publishedAt).format('MM/DD/YYYY'),
      center: true,
      sortable: true,
    },
    {
      name: 'Job Time',
      selector: (row) => row?.contractType,
      center: true,
      sortable: true,
    },
    {
      name: 'Job Salary',
      selector: (row) => row?.salary,
      center: true,
      sortable: true,
    },
    {
      style: {
        justifyContent: 'end',
      },
      name: '',
      selector: (row) => {
        return (
          <>
            <Tooltip placement='bottom' overlay={<span>update</span>}>
              <button
                className='btn btn-sm btn-light-warning me-3'
                type='button'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='modal'
                style={{height: 'unset', width: 'unset'}}
                data-bs-target='#Update'
                onClick={() => setSelected(row)}
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Update
              </button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>View</span>}>
              <Link to={`/jobs/addJob?jobId=${row?.dbId}`} className='me-3'>
                <button className='btn btn-sm btn-light-primary'>
                  <i className='fa-solid fa-eye'></i> View
                </button>
              </Link>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>Delete</span>}>
              <button
                className='btn btn-sm btn-light-danger me-3'
                onClick={() => {
                  Swal.fire({
                    title: 'Confirm deletion?',
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText:
        "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
    confirmButtonColor: '#009EF7',
                  cancelButtonText:
                    '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                   
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      deleteJob(row?.dbId)
                    } else if (result.isDenied) {
                      Swal.fire('Record is not deleted', '', 'error')
                    }
                  })
                }}
              >
                <i className='ki-duotone ki-trash-square fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                delete
              </button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>View Applier</span>}>
              <button className='btn btn-sm btn-light-warning me-3'>
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                View Application
              </button>
            </Tooltip>
          </>
        )
      },
      width: '520px',
    },
  ]
  // pagination
  const [totalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const handlePageChange = (page) => {
    setPage(page)
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
  }
  const handleStreamSelect = (e) => {
    let classObj = []
    const classData = filterResultData.class[e.value]
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...filterData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: classObj,
      }
      setFilterData(data)
    } else {
      let data = {
        ...filterData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: [],
        classId: [],
      }
      setFilterData(data)
    }
  }
  const handleCLassSelect = (selectedClasses) => {
    // Handle the selection of multiple classes
    let classOptions = selectedClasses.map((classItem) => ({
      value: classItem.value,
      label: classItem.label,
    }))

    let data = {
      ...filterData,
      classId: classOptions,
    }

    setFilterData(data)
  }
  const handleStatusSelect = (selectedStatus) => {
    // Handle the selection of multiple statuses
    let statusOptions = selectedStatus.map((statusItem) => ({
      value: statusItem.value,
      label: statusItem.label,
    }))

    setFilterData({
      ...filterData,
      statusId: statusOptions.map((status) => status.value),
      selectedStatus: statusOptions,
    })
  }
  return (
    <>
      {/* header-start */}
      <div className='card my-5'>
        <div className='card-header justify-content-end'>
          <div className='card-toolbar'>
            {/* stream */}
            <div className='mb-5 mt-5 me-3'>
              <Select
                placeholder='select Stream'
                value={
                  filterData?.selectedStream?.label !== '' &&
                  filterData?.selectedStream?.value !== ''
                    ? filterData?.selectedStream
                    : null
                }
                options={filterData?.stream}
                styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    margin: '0px',
                    color: '#7e8299',
                    fontWeight: 'bold',
                    background: 'white', // this was the mistake (I needed to remove this)
                    ':hover': {
                      color: '#009ef7',
                      backgroundColor: '#f1faff',
                    },
                  }),
                }}
                onChange={(e: any) => {
                  handleStreamSelect(e)
                }}
              />
            </div>
            {/* class */}
            <div className=' mb-5 mt-5 me-3'>
              <Select
                placeholder='Select Class'
                value={filterData?.classId}
                isMulti={true}
                options={filterData?.class}
                isDisabled={filterData?.streamId === 0 ? true : false}
                styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    margin: '0px',
                    color: '#7e8299',
                    fontWeight: 'bold',
                    background: 'white',
                    ':hover': {
                      color: '#009ef7',
                      backgroundColor: '#f1faff',
                    },
                  }),
                }}
                onChange={(selectedClasses) => handleCLassSelect(selectedClasses)}
              />
            </div>
            {/* status */}
            <div className='mb-5 mt-5'>
              <Select
                value={filterData?.selectedStatus}
                options={filterData?.status}
                isMulti={true} // Enable multi-selection
                styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    margin: '0px',
                    color: '#7e8299',
                    fontWeight: 'bold',
                    background: 'white',
                    ':hover': {
                      color: '#009ef7',
                      backgroundColor: '#f1faff',
                    },
                  }),
                }}
                onChange={(selectedStatus) => handleStatusSelect(selectedStatus)}
              />
            </div>
          </div>
        </div>
        <div className='card-body'>
          <DataTables
            columns={columns}
            data={job}
            loading={job.length > 0}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            page={page}
            perPage={perPage}
          />
        </div>
        <div className='modal fade' id='Update' aria-hidden='true'>
          <JobUpdateModal data={selected} />
        </div>
      </div>
      {/* body-end */}
    </>
  )
}

export default ByClass
