import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  academic: [],
  notice: [],
  division: [],
}

export const AcademicSlice = createSlice({
  name: 'AcademicSlice',
  initialState,
  reducers: {
    setAcademic: (state, action) => {
      state.academic = action.payload
    },
    setNotice: (state, action) => {
      state.notice = action.payload
    },
    setDivision: (state, action) => {
      state.division = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setAcademic, setNotice, setDivision} = AcademicSlice.actions
export default AcademicSlice.reducer
