import React, {useEffect, useState} from 'react'
import AssessmentQuiz from './assessmentQuiz'
import {useSearchParams} from 'react-router-dom'
import {useLazyGetAssessmentsDetailsQuery} from '../../../Api/allApi'

export default function ViewAssessments() {
  // get  searchParams
  const [searchParams] = useSearchParams()
  const id = searchParams.getAll('id')
  // get assessment api
  const [getAssessmentsDetails, result] = useLazyGetAssessmentsDetailsQuery()
  const {isSuccess, isFetching} = result
  const [questionArray, setQuestionArray] = useState([])
  useEffect(() => {
    if (id) {
      getAssessmentsDetails(id)
    }
  }, [getAssessmentsDetails, id])
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setQuestionArray(result?.data?.questionArray)
    }
  }, [isSuccess, isFetching, result?.data])
  return (
    <div>
      <AssessmentQuiz questions={questionArray} />
    </div>
  )
}
