/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import DataTables from '../DataTable/DataTables'
import 'rc-tooltip/assets/bootstrap.css'
import {Link} from 'react-router-dom'
import {useDeleteFacultyMutation, useLazyFacultyQuery} from '../../Api/allApi'
import {setFaculty} from '../../redux/FacultySlice'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import Swal from 'sweetalert2'
export default function Faculty() {
  const dispatch = useDispatch()
  const {faculty} = useSelector((state: any) => state.FacultySlice)
  // all faculty trigger
  const [facultyTrigger, result] = useLazyFacultyQuery()
  const {isSuccess, isFetching} = result
  // all faculty trigger
  const [deleteFaculty, deleteResult] = useDeleteFacultyMutation()
  const {isSuccess: isDSuccess, isLoading, isError, error} = deleteResult
  useEffect(() => {
    facultyTrigger({})
  }, [facultyTrigger])

  // call when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(setFaculty(result?.data?.facultyList))
    }
  }, [isSuccess, isFetching, result, dispatch])
  useEffect(() => {
    if (isDSuccess && !isLoading) {
      facultyTrigger({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDSuccess, isLoading, facultyTrigger])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])
  const columns: any = [
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <div className='symbol  symbol-35px symbol-circle'>
              <img src={row?.facultyImg} alt='Pic' />
            </div>
          </>
        )
      },
      center: true,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Name',
      selector: (row: any) => `${row?.firstName} ${row?.lastName}`,
      center: true,
      sortable: true,
    },
    {
      name: 'Title',
      selector: (row: any) => row?.title,
      center: true,
      sortable: true,
    },
    {
      name: 'Education',
      selector: (row: any) => row?.highestEducation,
      center: true,
      sortable: true,
    },
    {
      name: 'Experience',
      selector: (row: any) => row?.yearsOfExperience,
      center: true,
      sortable: true,
    },
    {
      name: '',
      style: {
        justifyContent: 'end',
      },
      selector: (row: any) => {
        return (
          <>
            <Link className='me-5' to={`/faculty/View?id=${row?.dbId}`}>
              <button
                className='btn btn-sm btn-light-warning'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
                Edit
              </button>
            </Link>
            <button
              className='btn btn-sm btn-light-danger me-4'
              type='button'
              style={{height: 'unset', width: 'unset'}}
              onClick={() => {
                Swal.fire({
                  title: 'Confirm deletion?',
                  showDenyButton: false,
                  showCancelButton: true,
                  confirmButtonText:
        "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
    confirmButtonColor: '#009EF7',
                  cancelButtonText:
                    '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                 
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    deleteFaculty(row?.dbId)
                  } else if (result.isDenied) {
                    Swal.fire('Record is not deleted', '', 'error')
                  }
                })
              }}
            >
              <i className='ki-duotone ki-trash-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>{' '}
              Delete{' '}
            </button>
          </>
        )
      },
      center: true,
      sortable: true,
      width: '300px',
    },
  ]
  // pagination
  const [totalRows]: any = useState(0)
  const [perPage, setPerPage]: any = useState(20)
  const [page, setPage]: any = useState(1)
  const handlePageChange = (page: any) => {
    setPage(page)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }
  return (
    <>
      <div className={`card  mb-6`}>
        {/* begin::Header */}
        <div className='card-header pt-5 pb-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Faculty</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Check your Faculty </span>
          </h3>
          <div className='card-toolbar'>
            <Link to='/faculty/Add'>
              <button className='btn btn-light-primary'>
                <i className='ki-duotone ki-message-add fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
                Add Faculty
              </button>
            </Link>
          </div>
        </div>
        {/* end::Header */}
      </div>
      <div className={`card `}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTables
            columns={columns}
            data={faculty}
            loading={faculty?.length === 0}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            page={page}
            perPage={perPage}
          />
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}
