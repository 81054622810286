import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AllAssignment} from './components/allAssignment/allAssignment'
import {DueAssignment} from './components/dueAssignment/dueAssignment'
import {SubmittedAssignment} from './components/submittedAssignment/submittedAssignment'
import {ListAssignmentHeader} from './listAssignmentHeader'
import AddAssignment from './components/AddAssignement'
import './components/style.css'
const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'All',
    path: 'assignment/AllAssignment/all',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const LListAssignmentPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <ListAssignmentHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='all'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>All</PageTitle>
              <AllAssignment />
            </>
          }
        />
        <Route
          path='pending'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Pending</PageTitle>
              <DueAssignment />
            </>
          }
        />
        <Route
          path='submitted'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Submitted</PageTitle>
              <SubmittedAssignment />
            </>
          }
        />

        <Route index element={<Navigate to='/assignment/AllAssignment/all' />} />
      </Route>
      <Route path='/add' element={<AddAssignment />} />
    </Routes>
  )
}

export default LListAssignmentPage
