import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useLazyGetFacultyDetailsQuery, useUpdateAwardsMutation} from '../../Api/allApi'
import {generateYearOptions} from './AddFaculty'
// import {setAchievement} from '../../redux/achivementSlice'
const Certificate = Yup.object().shape({
  title: Yup.string().required('title is required'),
  awardYear: Yup.string().required('year is required'),
  shortDescr: Yup.string().required('shortDescr is required'),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const AwardModel = ({name, data}) => {
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    title: '',
    awardYear: '',
    shortDescr: '',
  })

  const [updateTrigger, result] = useUpdateAwardsMutation()
  const {isSuccess, isLoading} = result
  // Faculty details API
  const [facultyDetailsTrigger] = useLazyGetFacultyDetailsQuery()
  useEffect(() => {
    if (isSuccess && !isLoading) {
      const id = data?.facultyId
      facultyDetailsTrigger(id)
    }
  }, [isSuccess, isLoading, facultyDetailsTrigger, data?.facultyId]) // Fetch faculty details
  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Certificate,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: null,
          facultyId: Number(data?.facultyId),
          title: values?.title,
          awardYear: Number(values?.awardYear),
          shortDescr: values?.shortDescr,
        }
        updateTrigger(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // set data while updating
  useEffect(() => {
    setInitialValues(data)
  }, [data])
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>
            {data?.title === '' ? 'Add New' : 'Update'} {name}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id='Awards'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='Awards'>
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>* indicates required </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Title*</label>
                      <input
                        type='text'
                        name='title'
                        {...formik.getFieldProps('title')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.title && formik.errors.title},
                          {
                            'is-valid': formik.touched.title && !formik.errors.title,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>Receiving Year*</label>
                        <div className='d-flex'>
                          <select
                            name='awardYear'
                            {...formik.getFieldProps('awardYear')}
                            className={clsx(
                              'form-control bg-transparent',
                              {'is-invalid': formik.touched.awardYear && formik.errors.awardYear},
                              {
                                'is-valid': formik.touched.awardYear && !formik.errors.awardYear,
                              }
                            )}
                          >
                            {generateYearOptions()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>short Description *</label>
                      <textarea
                        name='shortDescr'
                        {...formik.getFieldProps('shortDescr')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.shortDescr && formik.errors.shortDescr},
                          {
                            'is-valid': formik.touched.shortDescr && !formik.errors.shortDescr,
                          }
                        )}
                        rows={3}
                      ></textarea>
                    </div>
                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss='modal'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className='indicator-label d-flex align-items-center gap-2'>
                            <i
                              className={`ki-duotone ki-${
                                data?.title === '' ? 'check-square' : 'message-edit'
                              } fs-1 `}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            {data?.title === '' ? 'Add' : 'Update'} Awards
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AwardModel}
