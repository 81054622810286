import React from 'react'
import { Link } from 'react-router-dom'

export default function CourseHeader() {
  return (
    <div>
      <div className={`card  mb-6`}>
        {/* begin::Header */}
        <div className='card-header align-tels-center'>
          <div className='card-title align-items-start flex-column'>
            <span className='fw-bold fs-3 mb-1'>Courses</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Check Courses status</span>
          </div>
          <div className='card-toolbar'>
            <Link to='/Courses/other/ManageCourse/Add'>
              <button className='btn btn-light-primary'>
                <i className='ki-duotone ki-message-add fs-1'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                  <span className='path3'></span>
                                </i> Add Course
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
