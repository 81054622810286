import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import clsx from 'clsx'
const Reject = Yup.object().shape({
  reason: Yup.string().required('Reason is required'),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const RejectModel = () => {
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    reason: '',
  })

  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    validationSchema: Reject,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload: any = {}

        payload = {
          reason: values?.reason,
        }
        console.log(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h3>Reject</h3>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id='Certificates'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='certificate'
                >
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>* indicates required </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label>Example textarea</label>
                      <textarea
                        name='reason'
                        {...formik.getFieldProps('reason')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.reason && formik.errors.reason},
                          {
                            'is-valid': formik.touched.reason && !formik.errors.reason,
                          }
                        )}
                        id='exampleFormControlTextarea1'
                        rows={3}
                      ></textarea>
                    </div>
                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss='modal'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && <span className='indicator-label'>Continue</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {RejectModel}
