import React, {useEffect, useRef, useState} from 'react'
import {
  useAddSubjectDetailsMutation,
  useLazyAcademicFilterQuery,
  useLazyFacultyQuery,
  useLazyGetImagesForCourseQuery,
  useLazyGetSubjectDetailsQuery,
} from '../../../Api/allApi'
import {setFilter} from '../../../redux/AssignmentSlice'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Select from 'react-select'
import clsx from 'clsx'
import ImageModal from './ImageModal'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import Swal from 'sweetalert2'

// Define a Yup schema for validation
const AssignmentSchema = Yup.object().shape({
  about: Yup.string().required('about is required'),
  courseImg: Yup.string().required('courseImg is required'),
  coverImg: Yup.string().required('coverImg is required'),
  aboutImg: Yup.string().required('aboutImg is required'),
  outcomeList: Yup.array().of(
    Yup.object().shape({
      iconLink: Yup.string().required('Icon Link is required'),
      outcome: Yup.string().required('Outcome is required'),
    })
  ),
  content: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required('Title is required'),
      content: Yup.string().required('Content is required'),
      duration: Yup.number()
        .required('Duration is required')
        .min(0, 'Duration must be a positive number'),
    })
  ),
  faqs: Yup.array().of(
    Yup.object().shape({
      query: Yup.string().required('Query is required'),
      response: Yup.string().required('Response is required'),
    })
  ),
})
export default function AddAcademicsCourse() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  // state for modal
  const [showModal, setShowModal] = useState({
    showAboutImageModal: false,
    showCoverImageModal: false,
    showIconLinkModal: false,
    index: 0,
  })
  // States for loading and preview
  const [loading, setLoading] = useState(false)
  // Initial form values
  const [initialValues, setInitialValues] = useState({
    about: '',
    outcomeList: [
      {
        iconLink: '',
        outcome: '',
      },
    ],
    content: [
      {
        title: '',
        content: '',
        duration: 0,
      },
    ],
    faqs: [
      {
        query: '',
        response: '',
      },
    ],
    courseImg: '',
    coverImg: '',
    aboutImg: '',
  })
  // Options for dropdown selects
  const [optionsData, setOptionData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    semester: [],
    semesterId: 0,
    selectedSemester: {value: '', label: ''},
    subject: [],
    subjectId: 0,
    selectedSubject: {value: '', label: ''},
    faculty: [],
    selectedFaculty: [],
    assignmentDate: '',
    assignmentDueDate: '',
  })
  const [selected, setSelected]: any = useState({
    streamId: 0,
    classId: 0,
    semesterId: 0,
    subjectId: [],
  })
  // Query data for filtering
  const [filterTrigger, fResult] = useLazyAcademicFilterQuery()
  const {isSuccess: isFSuccess, isFetching: isFFetching} = fResult
  // assignment api
  const [getImages, result] = useLazyGetImagesForCourseQuery()
  // Query data for faculty
  const [facultyTrigger, facultyResult] = useLazyFacultyQuery()
  const {isSuccess: isFaculty, isFetching: isNotFaculty} = facultyResult
  // Add Subject Details faculty
  const [addSubject, subjectResult] = useAddSubjectDetailsMutation()
  const {isSuccess: isAdd, isLoading: isNotAdd, isError, error} = subjectResult
  //  get Subject Details
  const [getSubjectDetails, detailsResult] = useLazyGetSubjectDetailsQuery()
  const {isSuccess: isDetails, isLoading: isNotDetails} = detailsResult

  // Trigger filter and faculty data loading
  useEffect(() => {
    filterTrigger({})
    facultyTrigger({})
    getImages({})
  }, [filterTrigger, facultyTrigger, getImages])
  useEffect(() => {
    if (id) {
      getSubjectDetails(Number(id))
    }
  }, [id, getSubjectDetails])

  // Handle stream data when it's loaded successfully
  useEffect(() => {
    let stream: any = []
    if (isFSuccess && !isFFetching) {
      fResult?.data?.stream?.map((item) => {
        stream.push({value: item?.dbId, label: item?.name})
        return item
      })
      setOptionData((prevData) => {
        return {...prevData, stream: stream}
      })
      const data = fResult?.data
      const lastStreamId = data.stream?.slice(-1)?.[0]?.dbId
      if (lastStreamId) {
        const lastClassId = (data.class[lastStreamId] || []).filter((item) => item !== null)
        const lastNonNullOrDbId = lastClassId.slice(-1)?.[0]?.dbId

        setSelected((prevSelected) => ({...prevSelected, classId: lastNonNullOrDbId}))

        if (lastNonNullOrDbId) {
          const lastSemesterId = data.semester[lastNonNullOrDbId]?.slice(-1)?.[0]?.dbId

          if (lastSemesterId) {
            const subjectIds = data.subject[lastSemesterId]?.map((item) => item?.dbId)
            setSelected((prevSelected) => ({
              ...prevSelected,
              semesterId: lastSemesterId,
              subjectId: subjectIds || [],
            }))
          }
        }
      }
      setSelected((prevSelected) => ({...prevSelected, streamId: lastStreamId || 0}))
    }
  }, [isFSuccess, isFFetching, fResult, dispatch])
  useEffect(() => {
    if (isAdd && !isNotAdd) {
      navigate('/Courses/Academic/View')
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: subjectResult?.data?.detail
          ? subjectResult?.data?.detail
          : 'Data Added successfully',
        showConfirmButton: false,
        timer: 1500,
      })
    }
    if (isDetails && !isNotDetails) {
      const data = detailsResult?.data
      setInitialValues((pervValue) => {
        return {
          ...pervValue,
          about: data?.about,
          outcomeList: data?.outcomeList,
          content: data?.content,
          faqs: data?.faqs,
          courseImg: data?.courseImg,
          coverImg: data?.coverImg,
          aboutImg: data?.aboutImg,
        }
      })
      let faculty = data?.facultyDetails?.map((item) => {
        return item?.dbId
      })
      setOptionData((prevData) => {
        return {
          ...prevData,
          selectedFaculty: [...faculty],
          selectedSubject: {value: data?.subjectAbbr, label: data?.subjectName},
        }
      })
    }
  }, [
    isAdd,
    isNotAdd,
    subjectResult?.data?.detail,
    isDetails,
    isNotDetails,
    detailsResult,
    navigate,
  ])

  useEffect(() => {
    if (
      selected?.streamId !== 0 &&
      selected?.classId !== 0 &&
      selected?.semesterId !== 0 &&
      selected?.subjectId?.length !== 0
    ) {
      dispatch(setFilter(selected))
    }
  }, [selected, dispatch])
  // Handle faculty data when it's loaded successfully
  useEffect(() => {
    let faculty: any = []
    if (isFaculty && !isNotFaculty) {
      facultyResult?.data?.facultyList?.map((item) => {
        faculty.push({
          value: item?.dbId,
          label: (
            <div className='d-flex align-items-center'>
              <img src={item?.facultyImg} className='h-40px w-40px me-5' alt={item?.firstName} />
              <span> {`${item?.firstName} ${item?.lastName}`}</span>
            </div>
          ),
        })
        return item
      })
      setOptionData((prevData) => {
        return {
          ...prevData,
          faculty: faculty,
        }
      })
    }
  }, [isFaculty, isNotFaculty, facultyResult])
  // Handle class data when it's loaded successfully
  useEffect(() => {
    if (optionsData?.streamId !== 0 && fResult?.data) {
      let classObj: any = []
      const classData = fResult.data.class[optionsData.streamId]
      if (classData && classData.length > 0) {
        classObj = classData.map((item) => ({
          value: item.dbId,
          label: item.name,
        }))
        setOptionData((prevData) => ({
          ...prevData,
          class: classObj,
        }))
      }
    }
  }, [optionsData.streamId, fResult.data])

  useEffect(() => {
    if (optionsData?.classId !== 0 && fResult?.data) {
      const semesterData = fResult.data.semester[optionsData.classId]
      if (semesterData && semesterData.length > 0) {
        let semesterOptions = semesterData.map((item) => ({
          value: item.dbId,
          label: item.name,
        }))
        setOptionData((prevData) => ({
          ...prevData,
          semester: semesterOptions,
          selectedSemester: {label: '', value: ''},
        }))
      } else {
        setOptionData((prevData) => ({
          ...prevData,
          semester: [],
          subject: [],
          selectedSemester: {label: '', value: ''},
          selectedSubject: {label: '', value: ''},
        }))
      }
    }
  }, [optionsData.classId, fResult.data])

  // Handle subject data when it's loaded successfully
  useEffect(() => {
    if (optionsData?.semesterId !== 0 && fResult?.data) {
      const subjectData = fResult.data.subject[optionsData.semesterId]

      if (subjectData && subjectData.length > 0) {
        let subject = subjectData.map((item) => ({
          value: item.abbr,
          label: item.name,
        }))

        setOptionData((prevData) => ({
          ...prevData,
          subject: subject,
          selectedSubject: {label: '', value: ''},
        }))
      } else {
        setOptionData((prevData) => ({
          ...prevData,
          subject: [],
          selectedSubject: {label: '', value: ''},
        }))
      }
    }
  }, [optionsData?.semesterId, fResult?.data])

  // Formik configuration for handling form submission
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: AssignmentSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: id ? Number(id) : null,
          semesterId: selected.semesterId,
          subjectName: optionsData?.selectedSubject?.label,
          subjectAbbr: optionsData?.selectedSubject?.value,
          about: values?.about,
          optional: true,
          facultyIds: optionsData.selectedFaculty,
          outcomeList: values?.outcomeList,
          content: values?.content,
          faqs: values?.faqs,
          courseImg: values.courseImg,
          coverImg: values.coverImg,
          aboutImg: values.aboutImg,
        }
        addSubject(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])
  const handleImageSelect = (imageUrl, fieldName) => {
    if (fieldName === 'aboutImg') {
      formik.setFieldValue(fieldName, imageUrl)
      setShowModal({
        ...showModal,
        showAboutImageModal: false,
      })
    } else if (fieldName === 'coverImg') {
      formik.setFieldValue(fieldName, imageUrl)
      setShowModal({
        ...showModal,
        showCoverImageModal: false,
      })
    }
  }
  // Return the JSX for rendering the component
  const fileInputRef: any = useRef(null)

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  const handleIconLinkSelect = (iconLink, fieldName) => {
    formik.setFieldValue(fieldName, iconLink)
    setShowModal({...showModal, showIconLinkModal: false})
  }
  return (
    <>
      <div className='card card-custom mb-5'>
        <div className='card-header d-flex justify-content-between'>
          <h2 className='card-title fs-2 fw-bold'>
            <Link to={'/Courses/Academic/View'}>
              <button
                className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                aria-label='Back'
                data-bs-original-title='Back'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </Link>
            Add Course
          </h2>
        </div>
      </div>
      <form className='form' onSubmit={formik.handleSubmit} noValidate>
        <div className='row'>
          <div className='col-12 mb-5'>
            <div className='card '>
              <div className='card-body'>
                <div className='row'>
                  {/* stream */}
                  <div className='col-6 mb-5'>
                    <h5 className='form-label'>Select Stream</h5>
                    <Select
                      value={
                        optionsData?.selectedStream?.label !== '' &&
                        optionsData?.selectedStream?.value !== ''
                          ? optionsData?.selectedStream
                          : null
                      }
                      options={optionsData?.stream}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      onChange={(e: any) => {
                        setOptionData({
                          ...optionsData,
                          streamId: e.value,
                          selectedStream: {label: e.label, value: e.value},
                          selectedClass: {label: '', value: ''},
                        })
                      }}
                    />
                  </div>
                  {/* class */}
                  <div className='col-6 mb-5'>
                    <h5 className='form-label'>Select Class</h5>
                    <Select
                      value={
                        optionsData?.selectedClass?.label !== '' &&
                        optionsData?.selectedClass?.value !== ''
                          ? optionsData?.selectedClass
                          : null
                      }
                      options={optionsData?.class}
                      isDisabled={optionsData?.streamId === 0 ? true : false}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      onChange={(e: any) => {
                        setOptionData({
                          ...optionsData,
                          selectedSemester: {label: '', value: ''},
                          classId: e.value,
                          selectedClass: {label: e.label, value: e.value},
                        })
                      }}
                    />
                  </div>
                  {/* semester */}
                  <div className='col-6 mb-5'>
                    <h5 className='form-label'>Select Semester</h5>
                    <Select
                      value={
                        optionsData?.selectedSemester?.label !== '' &&
                        optionsData?.selectedSemester?.value !== ''
                          ? optionsData?.selectedSemester
                          : null
                      }
                      isDisabled={optionsData?.classId === 0 ? true : false}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      options={optionsData?.semester}
                      onChange={(e: any) => {
                        setOptionData({
                          ...optionsData,
                          semesterId: e.value,
                          selectedSemester: {label: e.label, value: e.value},
                        })
                      }}
                    />
                  </div>
                  {/* subjects */}
                  <div className='col-6 mb-5'>
                    <h5 className='form-label'>Select Subject</h5>
                    <Select
                      value={
                        optionsData?.selectedSubject?.label !== '' &&
                        optionsData?.selectedSubject?.value !== ''
                          ? optionsData?.selectedSubject
                          : null
                      }
                      isDisabled={optionsData?.semesterId === 0 ? true : false}
                      options={optionsData?.subject}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      onChange={(e: any) => {
                        setOptionData({
                          ...optionsData,
                          subjectId: e.value,
                          selectedSubject: {label: e.label, value: e.value},
                        })
                      }}
                    />
                  </div>
                  {/* faculty */}
                  <div className='col-6 mb-5'>
                    <h5 className='form-label'>Faculty</h5>
                    <Select
                      placeholder='Select...'
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          width: '100%',
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          boxShadow: 'unset',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white',
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      options={optionsData?.faculty}
                      onChange={(selectedOptions: any, {action, removedValue}: any) => {
                        const selectedValues: any = selectedOptions.map(
                          (option: any) => option.value
                        )

                        if (action === 'remove' && removedValue) {
                          // Handle removal of a value
                          const removedIndex: any = optionsData.selectedFaculty.indexOf(
                            removedValue?.value
                          )
                          if (removedIndex !== -1) {
                            optionsData.selectedFaculty.splice(removedIndex, 1)
                          }
                        }
                        console.log(selectedValues, 'das')
                        setOptionData({
                          ...optionsData,
                          selectedFaculty: selectedValues,
                        })
                      }}
                      value={optionsData?.faculty.filter((option: any) =>
                        optionsData.selectedFaculty.includes(option?.value)
                      )}
                      isMulti // Enable multi-select
                    />
                  </div>
                  {/* about */}
                  <div className='col-12 mb-5'>
                    <label>About</label>
                    <textarea
                      name='about'
                      {...formik.getFieldProps('about')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.about && formik.errors.about,
                        },
                        {
                          'is-valid': formik.touched.about && !formik.errors.about,
                        }
                      )}
                      id='exampleFormControlTextarea1'
                      rows={3}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* out come */}
          <div className='col-12 mb-5'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  <h1>Outcome List</h1>
                </div>
              </div>
              <div className='card-body'>
                <div className='col-12'>
                  {formik.values.outcomeList?.map((outcome, index) => (
                    <div key={index} className='row align-items-center border-bottom border-3 mb-5'>
                      <div className='col-md-3 mb-5'>
                        <button
                          type='button'
                          className='btn btn-primary mb-5'
                          onClick={() =>
                            setShowModal({...showModal, showIconLinkModal: true, index: index})
                          }
                        >
                          Link Icon
                        </button>
                        {formik.values.outcomeList[index]?.iconLink && (
                          <div className='h-75px w-75px'>
                            <img
                              src={formik.values.outcomeList[index]?.iconLink}
                              className='h-100 w-100'
                              alt='IconLink'
                              style={{cursor: 'pointer', borderRadius: '20px'}}
                            />
                          </div>
                        )}
                        <ImageModal
                          show={showModal.showIconLinkModal}
                          onHide={() => setShowModal({...showModal, showIconLinkModal: false})}
                          images={result?.data?.iconLink}
                          onImageSelect={(iconLink) => {
                            handleIconLinkSelect(
                              iconLink,
                              `outcomeList[${showModal?.index}].iconLink`
                            )
                            console.log(`outcomeList[${index}].iconLink`)
                          }}
                        />
                      </div>
                      <div className='col-md-6 text-sta mb-5'>
                        <label>Outcome</label>
                        <input
                          type='text'
                          name={`outcomeList[${index}].outcome`}
                          value={outcome.outcome}
                          onChange={formik.handleChange}
                          className={clsx('form-control bg-transparent', {
                            'is-invalid':
                              formik.touched.outcomeList &&
                              formik.errors.outcomeList?.[index]?.outcome,
                          })}
                          placeholder='Outcome'
                        />
                        {formik.touched.outcomeList &&
                          formik.errors.outcomeList?.[index]?.outcome && (
                            <span className='text-danger'>
                              {formik.errors.outcomeList[index].outcome}
                            </span>
                          )}
                      </div>
                      <div className=' col-md-3 mb-5'>
                        {/* Add a button to remove this outcome */}
                        <button
                          type='button'
                          className='btn btn-danger mt-4'
                          onClick={() =>
                            formik.setFieldValue(
                              'outcomeList',
                              formik.values.outcomeList.filter((_, i) => i !== index)
                            )
                          }
                        >
                          <i className='ki-duotone ki-trash-square fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>{' '}
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                  {/* Add a button to add a new outcome */}
                  <div className='col-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() =>
                        formik.setFieldValue('outcomeList', [
                          ...formik.values.outcomeList,
                          {iconLink: '', outcome: ''},
                        ])
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                      Add Outcome
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* content */}
          <div className='col-12 mb-5'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  <h1>Content</h1>
                </div>
              </div>
              <div className='card-body'>
                <div className='col-12 '>
                  {formik.values.content.map((content, index) => (
                    <div key={index} className='mb-3 border-bottom border-3'>
                      <div className='row mb-5'>
                        {/* Title */}
                        <div className='col-md-5 col-4 mb-5'>
                          <label>Title</label>
                          <input
                            type='text'
                            name={`content[${index}].title`}
                            {...formik.getFieldProps(`content[${index}].title`)}
                            className={clsx('form-control', {
                              'is-invalid':
                                formik.touched.content?.[index]?.title &&
                                formik.errors.content?.[index]?.title,
                            })}
                          />
                        </div>
                        {/* Duration */}
                        <div className='col-md-5 col-4 mb-5'>
                          <label>Duration</label>
                          <input
                            type='number'
                            name={`content[${index}].duration`}
                            {...formik.getFieldProps(`content[${index}].duration`)}
                            className={clsx('form-control', {
                              'is-invalid':
                                formik.touched.content?.[index]?.duration &&
                                formik.errors.content?.[index]?.duration,
                            })}
                          />
                        </div>
                        <div className='col-md-2 col-4 mb-5 d-flex align-items-end'>
                          <button
                            type='button'
                            className='btn btn-danger'
                            onClick={() => {
                              const updatedContent = [...formik.values.content]
                              updatedContent.splice(index, 1)
                              formik.setValues({
                                ...formik.values,
                                content: updatedContent,
                              })
                            }}
                          >
                            <i className='ki-duotone ki-trash-square fs-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>{' '}
                            Remove
                          </button>
                        </div>
                        {/* Content */}
                        <div className='col-10'>
                          <label>Content</label>
                          <textarea
                            name={`content[${index}].content`}
                            {...formik.getFieldProps(`content[${index}].content`)}
                            className={clsx('form-control', {
                              'is-invalid':
                                formik.touched.content?.[index]?.content &&
                                formik.errors.content?.[index]?.content,
                            })}
                            rows={3} // Set the number of rows as needed
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* Add Content Button */}
                  <div className='col-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          content: [
                            ...formik.values.content,
                            {
                              title: '',
                              content: '',
                              duration: 0,
                            },
                          ],
                        })
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                      Add Content
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FAQs */}
          <div className='col-12 mb-5'>
            <div className='card'>
              <div className='card-header'>
                <div className='card-title'>
                  <h1>FAQs</h1>
                </div>
              </div>
              <div className='card-body'>
                <div className='col-12'>
                  {formik.values.faqs.map((faq, index) => (
                    <div key={index} className='mb-3 border-bottom'>
                      <div className='row'>
                        {/* Query */}
                        <div className='col-md-10 col-8 mb-5'>
                          <label>Query</label>
                          <input
                            type='text'
                            name={`faqs[${index}].query`}
                            {...formik.getFieldProps(`faqs[${index}].query`)}
                            className={clsx('form-control', {
                              'is-invalid':
                                formik.touched.faqs?.[index]?.query &&
                                formik.errors.faqs?.[index]?.query,
                            })}
                          />
                        </div>

                        {/* Remove Button */}
                        <div className='col-md-2  col-4 mb-5 d-flex align-items-end'>
                          <button
                            type='button'
                            className='btn btn-danger'
                            onClick={() => {
                              const updatedFaqs = [...formik.values.faqs]
                              updatedFaqs.splice(index, 1)
                              formik.setValues({
                                ...formik.values,
                                faqs: updatedFaqs,
                              })
                            }}
                          >
                            <i className='ki-duotone ki-trash-square fs-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>{' '}
                            Remove
                          </button>
                        </div>
                        {/* Response */}
                        <div className='col-md-10 mb-5'>
                          <label>Response</label>
                          <textarea
                            rows={3}
                            name={`faqs[${index}].response`}
                            {...formik.getFieldProps(`faqs[${index}].response`)}
                            className={clsx('form-control', {
                              'is-invalid':
                                formik.touched.faqs?.[index]?.response &&
                                formik.errors.faqs?.[index]?.response,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* Add FAQ Button */}
                  <div className='col-12 text-end'>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() =>
                        formik.setValues({
                          ...formik.values,
                          faqs: [
                            ...formik.values.faqs,
                            {
                              query: '',
                              response: '',
                            },
                          ],
                        })
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                      Add FAQ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CourseImg */}
          <div className='col-md-4 mb-5 text-center'>
            <div className='card'>
              <div className='card-body p-4 h-200px '>
                <input
                  type='file'
                  ref={fileInputRef}
                  accept='image/*'
                  onChange={(e: any) => {
                    const file = e.target.files[0]

                    if (file) {
                      const reader: any = new FileReader()

                      reader.onload = (e: any) => {
                        const base64Data = e.target.result
                        formik?.setFieldValue('courseImg', base64Data)
                      }
                      reader.readAsDataURL(file)
                    }
                  }}
                  className='form-control mb-5 d-none'
                />
                {formik.values.courseImg ? (
                  <div className='h-100 m-auto w-100'>
                    <img
                      src={formik?.values?.courseImg}
                      onClick={handleButtonClick}
                      className='h-100 w-100'
                      alt='AboutImg'
                      style={{cursor: 'pointer', objectFit: 'cover'}}
                    />
                  </div>
                ) : (
                  <div
                    className='h-100 w-100 bg-light-primary m-auto d-flex align-items-center justify-content-center'
                    onClick={handleButtonClick}
                    style={{cursor: 'pointer'}}
                  >
                    <h3 className='text-center text-primary'>Course Image</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Cover img */}
          <div className='col-md-4 mb-5 text-center'>
            <div className='card'>
              <div className='card-body p-4 h-200px'>
                {formik.values.coverImg ? (
                  <div className='h-100 m-auto w-100'>
                    <img
                      src={formik?.values?.coverImg}
                      className='h-100  w-100 '
                      alt='AboutImg'
                      onClick={() => {
                        setShowModal({
                          ...showModal,
                          showCoverImageModal: true,
                        })
                      }}
                      style={{cursor: 'pointer', objectFit: 'cover'}}
                    />
                  </div>
                ) : (
                  <div
                    className='h-100 w-100 bg-light-primary m-auto d-flex align-items-center justify-content-center'
                    onClick={() => {
                      setShowModal({
                        ...showModal,
                        showCoverImageModal: true,
                      })
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <h3 className='text-center text-primary'>Cover Image</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* about img */}
          <div className='col-md-4 mb-5 text-center'>
            <div className='card'>
              <div className='card-body  p-4 h-200px '>
                {formik.values.aboutImg ? (
                  <div className='h-100 m-auto w-100'>
                    <img
                      src={formik?.values?.aboutImg}
                      className='h-100 w-100 '
                      alt='AboutImg'
                      onClick={() => {
                        setShowModal({
                          ...showModal,
                          showAboutImageModal: true,
                        })
                      }}
                      style={{cursor: 'pointer', objectFit: 'cover'}}
                    />
                  </div>
                ) : (
                  <div
                    className='h-100 w-100 bg-light-primary m-auto d-flex align-items-center justify-content-center'
                    onClick={() => {
                      setShowModal({
                        ...showModal,
                        showAboutImageModal: true,
                      })
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <h3 className='text-center text-primary'>About Image</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-12 text-end  mb-5'>
            <button
              type='submit'
              className='btn  btn-primary'
              disabled={
                formik.isSubmitting || !formik.isValid || optionsData?.facultyId === 0
                  ? true
                  : false
              }
            >
              {!loading ? (
                <span className='indicator-label d-flex gap-2'>
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>
                  Add Course
                </span>
              ) : (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
        {/* Modal component */}
        <ImageModal
          show={showModal?.showAboutImageModal}
          onHide={() =>
            setShowModal({
              ...showModal,
              showAboutImageModal: false,
            })
          }
          images={result?.data?.aboutLink}
          onImageSelect={(imageUrl) => handleImageSelect(imageUrl, 'aboutImg')}
        />

        <ImageModal
          show={showModal?.showCoverImageModal}
          onHide={() => {
            setShowModal({
              ...showModal,
              showCoverImageModal: false,
            })
          }}
          images={result?.data?.coverLink} /* Use the appropriate data for cover images */
          onImageSelect={(imageUrl) => handleImageSelect(imageUrl, 'coverImg')}
        />
      </form>
    </>
  )
}
