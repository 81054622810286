var color = `${localStorage.getItem('kt_theme_mode_value') === 'dark' ? '#2B2B40' : '#f9f9f9'}`
export const customStyles: any = {
  background: {
    default: 'transparent',
  },
  headRow: {
    className: 'bg-danger',
    style: {
      border: 'none',
      backgroundColor: color,
      borderRadius: '8px',
      color: '#a1a5b7',
    },
  },
  headCells: {
    style: {
      backgroundColor: 'transparent',
      borderBottom: '0',
      textTransform: 'capitalize',
    },
  },
  rows: {
    style: {
      cursor: 'pointer',
      border: '0',
      backgroundColor: 'transparent',
    },
    highlightOnHoverStyle: {
      backgroundColor: '#F9F9F9',
    },
  },
  cells: {
    style: {
      backgroundColor: 'transparent',
    },
  },
  pagination: {
    style: {
      border: 'none',
      backgroundColor: 'transparent',
      color: 'unset',
    },
  },
}
