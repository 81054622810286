import React from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import CourseHeader from './CourseHeader'
import AddCourse from './AddCourse'
import ViewCourse from './ViewCourse'

const admission: Array<PageLink> = [
  {
    title: 'Course',
    path: '/Courses/other/ManageCourse/View',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export default function CoursePage() {
  return (
    <div>
      <Routes>
        <Route
          element={
            <>
              <CourseHeader />
              <Outlet />
            </>
          }
        >
          <Route
            path='View'
            element={
              <>
                <PageTitle breadcrumbs={admission}>Course View</PageTitle>
                <ViewCourse />
              </>
            }
          />
          <Route
            path='Add'
            element={
              <>
                <PageTitle breadcrumbs={admission}>Course Add</PageTitle>
                <AddCourse />
              </>
            }
          />
          <Route index element={<Navigate to='/Courses/other/ManageCourse/View' />} />
        </Route>
      </Routes>
    </div>
  )
}
