import Swal from 'sweetalert2'
import DataTables from '../../DataTable/DataTables'
import {useSelector} from 'react-redux'
import {useEffect, useState} from 'react'
import {useDeleteSubjectDetailsMutation, useGetSubjectListMutation} from '../../../Api/allApi'
import {useDispatch} from 'react-redux'
import {setAcademic} from '../../../redux/CourseSlice'
import {Link} from 'react-router-dom'
export default function AcademicCourse() {
  const {subjectFilter} = useSelector((state: any) => state.CourseSlice)

  const dispatch = useDispatch()
  // get subject list
  const [subjectList, result] = useGetSubjectListMutation()
  const {isSuccess, isLoading} = result
  // Delete Subject list
  const [deleteSubject, deleteResult] = useDeleteSubjectDetailsMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete} = deleteResult

  useEffect(() => {
    if (subjectFilter?.length !== 0) subjectList(subjectFilter)
  }, [subjectList, subjectFilter])
  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(setAcademic(result?.data?.subjectList))
      setTotalRows(result?.data?.subjectList?.length)
    }
  }, [isSuccess, isLoading, result?.data?.subjectList, dispatch])

  useEffect(() => {
    if (isDelete && !isNotDelete) {
      if (subjectFilter?.length !== 0) subjectList(subjectFilter)

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, deleteResult?.data?.detail, subjectList, subjectFilter])
  const {academic} = useSelector((state: any) => state.CourseSlice)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  // data
  const columns: any = [
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <div className='symbol  symbol-35px symbol-circle'>
              <img src={row?.courseImg} alt='Pic' />
            </div>
          </>
        )
      },
      center: true,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Subject Name',
      selector: (row: any) => row?.subjectName,
      center: true,
      sortable: true,
    },
    {
      style: {
        justifyContent: 'end',
      },
      name: '',
      selector: (row: any) => {
        return (
          <>
            <Link to={`/Courses/Academic/ViewDetails?subjectId=${row?.dbId}`}>
              <button
                className='btn btn-sm  m-0 me-5 btn-light-primary'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='fa-solid fa-eye'></i> View
              </button>
            </Link>
            <Link to={`/Courses/Academic/Add?id=${row?.dbId}`}>
              <button
                className='btn btn-sm  m-0 me-5 btn-light-warning'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Edit
              </button>
            </Link>
            <button
              className='btn btn-sm  m-0  btn-light-danger'
              onClick={() => {
                Swal.fire({
                  title: 'Confirm deletion?',
                  showDenyButton: false,
                  showCancelButton: true,
                  confirmButtonText:
                    '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
                  confirmButtonColor: '#009EF7',
                  cancelButtonText:
                    '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    deleteSubject(row?.dbId)
                  } else if (result.isDenied) {
                    Swal.fire('Record is not deleted', '', 'error')
                  }
                })
              }}
            >
              <i className='ki-duotone ki-trash-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>{' '}
              Delete
            </button>
          </>
        )
      },
      width: '400px',
    },
  ]
  // pagination
  const handlePageChange = (page: any) => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }
  return (
    <>
      <div className='col-12 mb-5'>
        <div className=' card card-custom '>
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <DataTables
              columns={columns}
              data={academic}
              loading={isLoading}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              page={page}
              perPage={perPage}
            />
            {/* end::Table container */}
          </div>
        </div>
      </div>
    </>
  )
}
