import React from 'react'
import DataTable from 'react-data-table-component'
import {customStyles} from './CustomeStyles'
import './css/DataTable.css'
export default function DataTables(props: any) {
  const {
    columns,
    data,
    loading,
    totalRows,
    handlePageChange,
    handlePerRowsChange,
    page,
    perPage,
    conditionalRowStyles,
  } = props
  return (
    <DataTable
      columns={columns}
      data={data}
      pagination
      paginationServer
      paginationTotalRows={totalRows}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handlePerRowsChange}
      fixedHeader={true}
      paginationRowsPerPageOptions={[1, 2, 5, 10, 20, 25, 50, 100]}
      paginationDefaultPage={page}
      paginationPerPage={perPage}
      defaultSortFieldId='_id'
      pointerOnHover={false}
      defaultSortAsc={false}
      persistTableHead={true}
      conditionalRowStyles={conditionalRowStyles}
      progressPending={loading}
      progressComponent={
        <i className='fa-solid fa-circle-notch text-primary text-center fa-spin fs-2 p-10'></i>
      }
      responsive={true}
      selectableRowsHighlight={true}
      highlightOnHover
      customStyles={customStyles}
    />
  )
}
