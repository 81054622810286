/* eslint-disable @typescript-eslint/no-unused-vars */
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getTokenInLocalStorage} from '../Utiles/LocalStorage'
// import { useAuth } from '../../app/modules/auth/core/Auth'

let time_zone = new Date()
  .toString()
  .substring(new Date().toString().indexOf('+') + 0)
  ?.slice(0, 5)
time_zone = time_zone.substring(0, 3) + ':' + time_zone.substring(3)

// generate header part in api
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    var token: any = getTokenInLocalStorage()
    if (token) {
      headers.set('token', token)
      return headers
    }
  },
})

const baseQueryMiddleware = async (args: any, api: any, extraOptions: any) => {
  let result: any = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    localStorage.clear()
    window.location.href = '/auth'
  }
  return result
}

export const allApiSlice = createApi({
  reducerPath: 'allApi',
  baseQuery: baseQueryMiddleware,
  endpoints: (builder) => ({
    // get api
    register: builder.query({
      query: (payload) => ({
        url: 'admin/sign-up',
        method: 'POST',
        body: payload,
      }),
    }),
    login: builder.query({
      query: (payload) => ({
        url: 'admin/sign-in',
        method: 'POST',
        body: payload,
      }),
    }),
    dashboard: builder.query({
      query: (payload) => ({
        url: 'dashboard',
        method: 'Get',
        params: payload,
      }),
    }),
    feeds: builder.query({
      query: (payload) => ({
        url: 'dashboard/feeds/all',
        method: 'Get',
        params: payload,
      }),
    }),
    event: builder.query({
      query: (payload) => ({
        url: '/events/today',
        method: 'Get',
        params: payload,
      }),
    }),
    ProfileDetails: builder.query({
      query: (payload) => ({
        url: '/user/profile/details',
        method: 'Get',
        params: payload,
      }),
    }),
    adminSKill: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/skill`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminSKillDownload: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/skill/all/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminSKillSample: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/skill/sample/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminOrganization: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/organisation`,
        method: 'Get',
        params: payload,
      }),
    }),
    publisher: builder.query({
      query: (payload) => ({
        url: `admin/achievements/publisher`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminOrganizationDownload: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/organisation/all/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminOrganizationSample: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/organisation/sample/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminPublisherDownload: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/organisation/all/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    adminPublisherSample: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/organisation/sample/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    faculty: builder.query({
      query: (payload) => ({
        url: `/admin/faculty/all`,
        method: 'Get',
        params: payload,
      }),
    }),
    getFacultyDetails: builder.query({
      query: (payload) => ({
        url: `/admin/faculty/${payload}`,
        method: 'Get',
      }),
    }),
    getCertification: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/certificate-issuer`,
        method: 'Get',
        params: payload,
      }),
    }),
    allCertificationDownload: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/certificate-issuer/all/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    sampleCertificationDownload: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/certificate-issuer/sample/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    academicFilter: builder.query({
      query: (payload) => ({
        url: `/admin/academics/filters`,
        method: 'Get',
        params: payload,
      }),
    }),
    learnerList: builder.query({
      query: (payload) => ({
        url: `/admin/achievements/learner/${payload}`,
        method: 'Get',
      }),
    }),
    allAuthor: builder.query({
      query: (payload) => ({
        url: `/admin/blogs/author/all`,
        method: 'Get',
        params: payload,
      }),
    }),
    blogDetails: builder.query({
      query: (payload) => ({
        url: `/admin/blogs/${payload}`,
        method: 'Get',
      }),
    }),
    getDraftedBlog: builder.query({
      query: (payload) => ({
        url: `/admin/blogs/draft/${payload?.authorId}?page=${payload?.page}&itemsPerPage=${payload?.itemsPerPage}`,
        method: 'Get',
      }),
    }),
    getSpeaker: builder.query({
      query: (payload) => ({
        url: `/admin/events/speaker/all`,
        method: 'Get',
        params: payload,
      }),
    }),
    getSpeakerDetails: builder.query({
      query: (payload) => ({
        url: `/admin/events/speaker/${payload}`,
        method: 'Get',
      }),
    }),
    getOrganiser: builder.query({
      query: (payload) => ({
        url: `/admin/events/organiser/all`,
        method: 'Get',
        params: payload,
      }),
    }),
    getOrganiserDetails: builder.query({
      query: (payload) => ({
        url: `/admin/events/organiser/${payload}`,
        method: 'Get',
      }),
    }),
    getEventTypes: builder.query({
      query: (payload) => ({
        url: `/admin/events/types`,
        method: 'Get',
        params: payload,
      }),
    }),
    getEvents: builder.query({
      query: (payload) => ({
        url: `/admin/events/list/${payload?.eventType}?page_no=${payload?.page}&items_per_page=${payload?.itemsPerPage}`,
        method: 'Get',
      }),
    }),
    getEventDetails: builder.query({
      query: (payload) => ({
        url: `/admin/events/details/${payload}`,
        method: 'Get',
      }),
    }),
    getCourseAuthor: builder.query({
      query: (payload) => ({
        url: `/admin/courses/author/all`,
        method: 'Get',
        params: payload,
      }),
    }),
    getCourseCategory: builder.query({
      query: (payload) => ({
        url: `/admin/courses/category/all`,
        method: 'Get',
        params: payload,
      }),
    }),
    getImagesForCourse: builder.query({
      query: (payload) => ({
        url: `/admin/courses/data/image-links`,
        method: 'Get',
        params: payload,
      }),
    }),
    getSubjectDetails: builder.query({
      query: (payload) => ({
        url: `/admin/courses/academic/subject/details/${payload}`,
        method: 'Get',
      }),
    }),

    getAssessmentsDetails: builder.query({
      query: (payload) => ({
        url: `/admin/assessment/details/${payload}`,
        method: 'Get',
      }),
    }),
    academicSampleDownload: builder.query({
      query: (payload) => ({
        url: `/admin/academics/academics/sample/download`,
        method: 'Get',
        params: payload,
      }),
    }),
    getHolidayList: builder.query({
      query: (payload) => ({
        url: `/admin/attendance/observance/${payload}`,
        method: 'Get',
      }),
    }),
    getJobByStatus: builder.query({
      query: (payload) => ({
        url: `/admin/jobs/applications/status/${payload}`,
        method: 'Get',
      }),
    }),
    getApplierList: builder.query({
      query: (payload) => ({
        url: `/admin/jobs/applications/job/${payload}`,
        method: 'Get',
      }),
    }),
    getNoticeDetails: builder.query({
      query: (payload) => ({
        url: `/admin/academics/notice/${payload}`,
        method: 'Get',
      }),
    }),

    // post api
    UpdatePassword: builder.mutation({
      query: (payload) => ({
        url: '/admin/password/update',
        method: 'POST',
        body: payload,
      }),
    }),
    skills: builder.mutation({
      query: (payload) => ({
        url: `admin/achievements/skill/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addOrganization: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/organisation/save`,
        method: 'POST',
        body: payload,
      }),
    }),

    publisheradd: builder.mutation({
      query: (payload) => ({
        url: `admin/achievements/publisher/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    assignmentList: builder.mutation({
      query: (payload) => ({
        url: `admin/assignment/list`,
        method: 'POST',
        body: payload,
      }),
    }),
    assignmentDetails: builder.mutation({
      query: (payload) => ({
        url: `admin/assignment/details/${payload}`,
        method: 'POST',
      }),
    }),
    submissionAssignment: builder.mutation({
      query: (payload) => ({
        url: `admin/assignment/submission`,
        method: 'POST',
        body: payload,
      }),
    }),
    submissionReason: builder.mutation({
      query: (payload) => ({
        url: `admin/assignment/submission/reject`,
        method: 'POST',
        body: payload,
      }),
    }),
    submissionGrade: builder.mutation({
      query: (payload) => ({
        url: `admin/assignment/submission/grade`,
        method: 'POST',
        body: payload,
      }),
    }),
    addAssignment: builder.mutation({
      query: (payload) => ({
        url: `admin/assignment/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addFaculty: builder.mutation({
      query: (payload) => ({
        url: `admin/faculty/add`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateFacultyDetails: builder.mutation({
      query: (payload) => ({
        url: `admin/faculty/details/update`,
        method: 'POST',
        body: payload,
      }),
    }),
    updatePublication: builder.mutation({
      query: (payload) => ({
        url: `admin/faculty/publication/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateCertification: builder.mutation({
      query: (payload) => ({
        url: `admin/faculty/certification/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateArticles: builder.mutation({
      query: (payload) => ({
        url: `admin/faculty/article/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateAwards: builder.mutation({
      query: (payload) => ({
        url: `admin/faculty/award/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addCertification: builder.mutation({
      query: (payload) => ({
        url: `admin/achievements/certificate-issuer/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    adminSKillUpload: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/skill/upload`,
        method: 'POST',
        body: payload,
      }),
    }),
    adminOrganisationUpload: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/organization/upload`,
        method: 'POST',
        body: payload,
      }),
    }),
    adminPublisherUpload: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/publisher/upload`,
        method: 'POST',
        body: payload,
      }),
    }),
    adminCertificateIssuerUpload: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/certificate-issuer/upload`,
        method: 'POST',
        body: payload,
      }),
    }),
    achievementLearner: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/learner/list`,
        method: 'POST',
        body: payload,
      }),
    }),
    addAuthor: builder.mutation({
      query: (payload) => ({
        url: `/admin/blogs/author/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    adminBLog: builder.mutation({
      query: (payload) => ({
        url: `/admin/blogs/blog-list`,
        method: 'POST',
        body: payload,
      }),
    }),
    adminAddBlog: builder.mutation({
      query: (payload) => ({
        url: `/admin/blogs/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addDraftBlog: builder.mutation({
      query: (payload) => ({
        url: `/admin/blogs/draft/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addSpeaker: builder.mutation({
      query: (payload) => ({
        url: `/admin/events/speaker/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addOrganiser: builder.mutation({
      query: (payload) => ({
        url: `/admin/events/organiser/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addEvents: builder.mutation({
      query: (payload) => ({
        url: `/admin/events/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addCourseAuthor: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/author/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addSocialProfile: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/author/social-profile/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addCategory: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/category/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addSubjectDetails: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/academic/subject/details/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addAssessment: builder.mutation({
      query: (payload) => ({
        url: `/admin/assessment/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    getAllAssessment: builder.mutation({
      query: (payload) => ({
        url: `/admin/assessment/all`,
        method: 'POST',
        body: payload,
      }),
    }),
    addStream: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/stream/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addClass: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/class/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addSemester: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/semester/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addSubject: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/subject/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    academicCsvUpload: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/academics/upload`,
        method: 'POST',
        body: payload,
      }),
    }),
    addHoliday: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/observance/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addTimeTable: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/class-schedule/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    getStudent: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/student-list`,
        method: 'POST',
        body: payload,
      }),
    }),
    getStudentDetails: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/student/details`,
        method: 'POST',
        body: payload,
      }),
    }),
    addAttendance: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    addJob: builder.mutation({
      query: (payload) => ({
        url: `/admin/jobs/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    getJobs: builder.mutation({
      query: (payload) => ({
        url: `/admin/jobs/`,
        method: 'POST',
        body: payload,
      }),
    }),
    jobListByClass: builder.mutation({
      query: (payload) => ({
        url: `/admin/jobs/applications/students/${payload}`,
        method: 'POST',
      }),
    }),
    addStatus: builder.mutation({
      query: (payload) => ({
        url: `/admin/jobs/applications/status/update`,
        method: 'POST',
        body: payload,
      }),
    }),
    getClassScheduleInfo: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/class-schedule/info`,
        method: 'POST',
        body: payload,
      }),
    }),
    addAttendanceLogs: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/student/attendance/log`,
        method: 'POST',
        body: payload,
      }),
    }),
    addNotice: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/notice/save`,
        method: 'POST',
        body: payload,
      }),
    }),
    getNotice: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/notice/all`,
        method: 'POST',
        body: payload,
      }),
    }),
    getSubjectList: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/academic/subject-list/{semester_id}`,
        method: 'POST',
        body: payload,
      }),
    }),
    // delete api
    deleSkills: builder.mutation({
      query: (payload) => ({
        url: `admin/achievements/skill/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleOrganization: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/organisation/${payload}`,
        method: 'DELETE',
      }),
    }),
    delePublisher: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/publisher/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteAssignment: builder.mutation({
      query: (payload) => ({
        url: `/admin/assignment/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteFaculty: builder.mutation({
      query: (payload) => ({
        url: `/admin/faculty/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteCertification: builder.mutation({
      query: (payload) => ({
        url: `/admin/achievements/certificate-issuer/${payload}`,
        method: 'DELETE',
      }),
    }),
    deletePublication: builder.mutation({
      query: (payload) => ({
        url: `/admin/faculty/publication/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteArticles: builder.mutation({
      query: (payload) => ({
        url: `/admin/faculty/article/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteCertificationF: builder.mutation({
      query: (payload) => ({
        url: `/admin/faculty/certification/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteAwards: builder.mutation({
      query: (payload) => ({
        url: `/admin/faculty/award/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteAuthor: builder.mutation({
      query: (payload) => ({
        url: `/admin/blogs/author/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteSpeaker: builder.mutation({
      query: (payload) => ({
        url: `/admin/events/speaker/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteOrganiser: builder.mutation({
      query: (payload) => ({
        url: `/admin/events/organiser/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteEvents: builder.mutation({
      query: (payload) => ({
        url: `/admin/events/details/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteCourseAuthor: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/author/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteProfile: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/author/social-profile/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteCategory: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/category/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteSubjectDetails: builder.mutation({
      query: (payload) => ({
        url: `/admin/courses/academic/subject/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteStream: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/stream/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteClass: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/class/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteSemester: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/semester/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteSubject: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/subject/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteHoliday: builder.mutation({
      query: (payload) => ({
        url: `/admin/attendance/observance/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteJob: builder.mutation({
      query: (payload) => ({
        url: `/admin/jobs/${payload}`,
        method: 'DELETE',
      }),
    }),
    deleteNotice: builder.mutation({
      query: (payload) => ({
        url: `/admin/academics/notice/${payload}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  // method get
  useLazyRegisterQuery,
  useLazyLoginQuery,
  useLazyDashboardQuery,
  useLazyFeedsQuery,
  useLazyEventQuery,
  useLazyProfileDetailsQuery,
  useLazyAdminSKillQuery,
  useLazyAdminSKillDownloadQuery,
  useLazyAdminOrganizationQuery,
  useLazyAdminSKillSampleQuery,
  useLazyPublisherQuery,
  useLazyAdminOrganizationDownloadQuery,
  useLazyAdminOrganizationSampleQuery,
  useLazyFacultyQuery,
  useLazyGetFacultyDetailsQuery,
  useLazyGetCertificationQuery,
  useLazyAllCertificationDownloadQuery,
  useLazySampleCertificationDownloadQuery,
  useLazyAcademicFilterQuery,
  useLazyLearnerListQuery,
  useLazyAllAuthorQuery,
  useLazyBlogDetailsQuery,
  useLazyGetDraftedBlogQuery,
  useLazyGetSpeakerQuery,
  useLazyGetSpeakerDetailsQuery,
  useLazyGetOrganiserQuery,
  useLazyGetOrganiserDetailsQuery,
  useLazyGetEventTypesQuery,
  useLazyGetEventsQuery,
  useLazyGetEventDetailsQuery,
  useLazyGetCourseAuthorQuery,
  useLazyGetCourseCategoryQuery,
  useLazyGetImagesForCourseQuery,
  useLazyGetSubjectDetailsQuery,
  useLazyGetAssessmentsDetailsQuery,
  useLazyAcademicSampleDownloadQuery,
  useLazyAdminPublisherDownloadQuery,
  useLazyAdminPublisherSampleQuery,
  useLazyGetHolidayListQuery,
  useLazyGetApplierListQuery,
  useLazyGetJobByStatusQuery,
  useLazyGetNoticeDetailsQuery,
  // method post
  useUpdatePasswordMutation,
  useSkillsMutation,
  useAddOrganizationMutation,
  usePublisheraddMutation,
  useAssignmentListMutation,
  useAssignmentDetailsMutation,
  useSubmissionAssignmentMutation,
  useSubmissionReasonMutation,
  useSubmissionGradeMutation,
  useAddAssignmentMutation,
  useAddFacultyMutation,
  useUpdateFacultyDetailsMutation,
  useUpdatePublicationMutation,
  useUpdateCertificationMutation,
  useUpdateArticlesMutation,
  useUpdateAwardsMutation,
  useAddCertificationMutation,
  useAdminSKillUploadMutation,
  useAdminPublisherUploadMutation,
  useAdminOrganisationUploadMutation,
  useAdminCertificateIssuerUploadMutation,
  useAchievementLearnerMutation,
  useAddAuthorMutation,
  useAdminBLogMutation,
  useAdminAddBlogMutation,
  useAddDraftBlogMutation,
  useAddSpeakerMutation,
  useAddOrganiserMutation,
  useAddEventsMutation,
  useAddCourseAuthorMutation,
  useAddSocialProfileMutation,
  useAddCategoryMutation,
  useAddSubjectDetailsMutation,
  useAddAssessmentMutation,
  useGetAllAssessmentMutation,
  useAddStreamMutation,
  useAddClassMutation,
  useAddSemesterMutation,
  useAddSubjectMutation,
  useAcademicCsvUploadMutation,
  useAddHolidayMutation,
  useAddTimeTableMutation,
  useGetStudentMutation,
  useGetStudentDetailsMutation,
  useAddAttendanceMutation,
  useAddJobMutation,
  useGetJobsMutation,
  useJobListByClassMutation,
  useAddStatusMutation,
  useGetClassScheduleInfoMutation,
  useAddAttendanceLogsMutation,
  useAddNoticeMutation,
  useGetNoticeMutation,
  useGetSubjectListMutation,
  // method delete
  useDeleOrganizationMutation,
  useDelePublisherMutation,
  useDeleteAssignmentMutation,
  useDeleteFacultyMutation,
  useDeleteCertificationMutation,
  useDeleSkillsMutation,
  useDeletePublicationMutation,
  useDeleteArticlesMutation,
  useDeleteCertificationFMutation,
  useDeleteAwardsMutation,
  useDeleteAuthorMutation,
  useDeleteSpeakerMutation,
  useDeleteOrganiserMutation,
  useDeleteEventsMutation,
  useDeleteCourseAuthorMutation,
  useDeleteProfileMutation,
  useDeleteCategoryMutation,
  useDeleteSubjectDetailsMutation,
  useDeleteStreamMutation,
  useDeleteClassMutation,
  useDeleteSemesterMutation,
  useDeleteSubjectMutation,
  useDeleteHolidayMutation,
  useDeleteJobMutation,
  useDeleteNoticeMutation,
} = allApiSlice
