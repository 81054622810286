import React, {useEffect, useState} from 'react'
import {AddModal, DiviSionModal} from './modal/AddModal'
import Swal from 'sweetalert2'
import {
  useAcademicCsvUploadMutation,
  useDeleteClassMutation,
  useDeleteSemesterMutation,
  useDeleteStreamMutation,
  useDeleteSubjectMutation,
  useLazyAcademicFilterQuery,
  useLazyAcademicSampleDownloadQuery,
} from '../../Api/allApi'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setAcademic} from '../../redux/AcademicSlice'

const Academics = () => {
  const dispatch = useDispatch()

  // get data from redux
  const {academic, division} = useSelector((state: any) => state.AcademicSlice)
  // delete stream
  const [deleteStream, streamResult] = useDeleteStreamMutation()
  const {isSuccess: isStream, isLoading: isNotStream} = streamResult
  // delete class
  const [deleteClass, classResult] = useDeleteClassMutation()
  const {isSuccess: isClass, isLoading: isNotClass} = classResult
  // delete semester
  const [deleteSemester, semesterResult] = useDeleteSemesterMutation()
  const {isSuccess: isSemester, isLoading: isNotSemester} = semesterResult
  // delete subjects
  const [deleteSubject, subjectResult] = useDeleteSubjectMutation()
  const {isSuccess: isSubject, isLoading: isNotSubject} = subjectResult
  // get sample download
  const [sampleDownload, downloadResult] = useLazyAcademicSampleDownloadQuery()
  const {isSuccess: isDownload, isFetching: isNotDownload} = downloadResult
  // Cas Upload
  const [uploadCsv, uploadResult] = useAcademicCsvUploadMutation()
  const {isSuccess: isUpload, isLoading: isNotUpload, isError} = uploadResult
  // this state stores treeData with children count
  const [updatedTreeData, setUpdatedTreeData] = useState([])
  const [divisionData, setDivisionData]: any = useState([])
  const [selected, setSelected] = useState({
    streamId: 0,
    classId: 0,
    semesterId: 0,
    subjectId: 0,
    name: '',
  })
  const [showStreamContent, setShowStreamContent] = useState({})
  // this state is for render in jsx element
  const [tableData, setTableData]: any = useState([])
  // filter trigger
  const [getData, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    getData({})
  }, [getData])
  // call when api returns 200
  useEffect(() => {
    if (isSuccess && !isFetching) {
      let originalData = result?.data
      const transformedData = originalData.stream.map((stream) => ({
        id: stream.dbId,
        name: stream.name,
        children: (originalData.class[stream.dbId] || []).map((classItem) => ({
          id: classItem.dbId,
          name: classItem.name,
          children: (originalData.semester[classItem.dbId] || []).map((semester) => ({
            id: semester.dbId,
            name: semester.name,
            children: (originalData.subject[semester.dbId] || []).map((subject) => ({
              id: subject.dbId,
              name: subject.name,
            })),
          })),
        })),
      }))
      dispatch(setAcademic(transformedData))
    }
  }, [isSuccess, isFetching, result?.data, dispatch])
  useEffect(() => {
    if (isDownload && !isNotDownload) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Sample Csv Downloaded',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDownload, isNotDownload])
  useEffect(() => {
    if (isUpload && !isNotUpload) {
      // Show success message
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'CSV Uploaded Successfully',
        showConfirmButton: false,
        timer: 1500,
      })
    }
    if (isError) {
      Swal.fire('Error Uploading CSV', '', 'error')
    }
  }, [isUpload, isNotUpload, isError])
  const handleDelete = (nodeId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteStream(nodeId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const handleDeleteClass = (classId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteClass(classId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const handleDeleteSemester = (semesterId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteSemester(semesterId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const handleDeleteSubject = (subjectId) => {
    Swal.fire({
      title: 'Confirm deletion?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText:
        '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
      confirmButtonColor: '#009EF7',
      cancelButtonText:
        '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteSubject(subjectId)
      } else if (result.isDenied) {
        Swal.fire('Record is not deleted', '', 'error')
      }
    })
  }
  const updateCounts = (node) => {
    if (node.children) {
      node.count = node.children.reduce((count, child) => updateCounts(child) + count, 0)
    } else {
      node.count = 0
    }
    return node.count + 1
  }
  useEffect(() => {
    const updatedData = JSON.parse(JSON.stringify(academic)) // Create a deep copy of academic
    updatedData.forEach((stream) => {
      stream.children.forEach((classItem) => {
        classItem.children.forEach((semester) => {
          semester.children.forEach((subject) => {
            updateCounts(semester) // Modify the counts as needed
          })
          // Update the semester count
          const semcount = semester.children.reduce((total, subject) => total + 1, 0)
          semester.count = semcount !== 0 ? semcount : 1
        })
        // Update the class count
        const classcount = classItem.children.reduce((total, semester) => total + semester.count, 0)
        classItem.count = classcount !== 0 ? classcount : 1
      })
      // Update the stream count
      const streamcount = stream.children.reduce((total, classItem) => total + classItem.count, 0)
      stream.count = streamcount !== 0 ? streamcount : 1
    })

    // Check if there are no streams, and if so, set the count to 1
    if (updatedData.length === 0) {
      const newStream = {
        id: Date.now(),
        name: 'New Stream',
        children: [],
      }
      updatedData.push(newStream)
    }
    // Update the state with the modified data
    setUpdatedTreeData(updatedData)
  }, [academic])
  useEffect(() => {
    const rows: any = []
    let previousStream = {} // Keep track of the previous stream
    let PreviousClass = {} // Keep track of the previous stream
    const defaultStream: any = []
    if (updatedTreeData?.length > 0) {
      updatedTreeData?.map((item: any, index) => {
        defaultStream[item?.id] = false
        if (item.children.length === 0) {
          rows.push({
            stream: {
              id: item?.id,
              name: item?.name,
              count: item?.count,
            },
            class: {}, // An empty class object
            semester: {},
            subject: {},
          })
        }
        item.children?.map((classItem, index1) => {
          const stream = {
            id: item?.id,
            name: item?.name,
            count: item?.count,
          }
          // Check if this is a new stream
          const isStreamChanged = JSON.stringify(previousStream) !== JSON.stringify(stream)
          if (classItem.children.length === 0) {
            previousStream = stream
            rows.push({
              stream: isStreamChanged ? stream : {},
              class: {
                id: classItem?.id,
                name: classItem?.name,
                count: classItem?.count,
                streamId: item?.id,
              }, // An empty class object
              semester: {},
              subject: {},
            })
          }
          classItem.children?.map((semester, index2) => {
            const stream = {
              id: item?.id,
              name: item?.name,
              count: item?.count,
            }
            const classObj = {
              id: classItem?.id,
              name: classItem?.name,
              count: classItem?.count,
              streamId: item?.id,
            }

            // Check if this is a new stream
            const isStreamChanged = JSON.stringify(previousStream) !== JSON.stringify(stream)
            const isClassChanged = JSON.stringify(PreviousClass) !== JSON.stringify(classObj)
            if (semester.children.length === 0) {
              PreviousClass = classObj
              previousStream = stream
              rows.push({
                stream: isStreamChanged ? stream : {},
                class: isClassChanged ? classObj : {}, // An empty class object
                semester: {
                  id: semester?.id,
                  name: semester?.name,
                  count: semester?.count,
                  streamId: item?.id,
                  classId: classItem?.id,
                },
                subject: {},
              })
            }
            semester.children?.map((subject, index3) => {
              const stream =
                index3 === 0
                  ? {
                      id: item?.id,
                      name: item?.name,
                      count: item?.count,
                    }
                  : {}
              const classObj =
                index3 === 0
                  ? {
                      id: classItem?.id,
                      name: classItem?.name,
                      count: classItem?.count,
                      streamId: item?.id,
                    }
                  : {}
              // Check if this is a new stream
              const isStreamChanged = JSON.stringify(previousStream) !== JSON.stringify(stream)
              const isClassChanged = JSON.stringify(PreviousClass) !== JSON.stringify(classObj)

              rows.push({
                stream: isStreamChanged ? stream : {},
                class: isClassChanged ? classObj : {},
                semester:
                  index3 === 0
                    ? {
                        id: semester?.id,
                        name: semester?.name,
                        count: semester?.count,
                        streamId: item?.id,
                        classId: classItem?.id,
                      }
                    : {},
                subject: {
                  id: subject?.id,
                  name: subject?.name,
                  streamId: item?.id,
                  classId: classItem?.id,
                  semesterId: semester?.id,
                },
              })
              // Update the previousStream
              if (index3 === 0) {
                previousStream = stream
              }
              if (index3 === 0) {
                PreviousClass = classObj
              }
            })
          })
        })
      })
    }
    setTableData(rows)
    setShowStreamContent(defaultStream)
  }, [updatedTreeData])
  useEffect(() => {
    if (
      (isStream && !isNotStream) ||
      (isClass && !isNotClass) ||
      (isSemester && !isNotSemester) ||
      (isSubject && !isNotSubject)
    ) {
      getData({})
    }
  }, [
    isStream,
    isNotStream,
    isClass,
    isNotClass,
    isSemester,
    isNotSemester,
    isSubject,
    isNotSubject,
    getData,
  ])
  // function to handle CSV upload
  const handleUploadCsv = (file: any) => {
    const formData = new FormData()
    // formData.append('upload_file', file)
    formData.append('upload_file', file)
    // Send the FormData to the API
    uploadCsv(formData)
  }
  useEffect(() => {
    if (division) {
      // Update the state with colored division data
      setDivisionData(division)
    }
  }, [division])
  console.log(showStreamContent)
  // Function to toggle showing or hiding content for a specific stream
  const toggleStreamContent = (streamId) => {
    setShowStreamContent((prevState) => ({
      ...prevState,
      [streamId]: !prevState[streamId], // Toggle the state for the specific stream
    }))
    console.log(tableData[streamId], 'dasdsa')
  }

  return isFetching ? (
    <div className='card p-4 pb-0' style={{height: '300px'}}>
      <div className='row h-100 align-items-center justify-content-center'>
        <div className='col-12 text-center'>
          <i
            className='fa-solid fa-circle-notch fa-spin text-primary '
            style={{fontSize: '30px', color: 'unset'}}
          ></i>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title'>Academics</div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-primary me-2'
              onClick={() => {
                // Trigger file input click
                document?.getElementById('fileInput')?.click()
              }}
            >
              Upload CSV
            </button>
            <input
              id='fileInput'
              type='file'
              style={{display: 'none'}}
              onChange={(e) => handleUploadCsv(e?.target?.files)}
            />
            <button
              type='button'
              className='btn btn-sm btn-primary me-2'
              onClick={() => {
                sampleDownload({})
              }}
            >
              Sample CSV
            </button>
            <button
              type='button'
              className='btn btn-sm btn-primary me-2'
              onClick={() => {
                console.log(updatedTreeData)
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Json is ready!. Please check your console..',
                  showConfirmButton: false,
                  timer: 1500,
                })
              }}
            >
              Json
            </button>
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='table-responsive' style={{overflow: 'auto'}}>
            <table className='table border border-dark align-middle gs-0 gy-4 text-center'>
              <thead>
                <tr className='fw-bold text-center text-muted bg-light'>
                  <th className='ps-4'>Stream</th>
                  <th className='ps-4'>Class</th>
                  <th className='ps-4'>Semester</th>
                  <th className='ps-4'>Subject</th>
                </tr>
              </thead>
              <tbody className='border border-dark'>
                {tableData &&
                  tableData?.map((item: any) => {
                    // console.log(tableData,item.stream.id)
                    return (
                      <tr className='border border-bottom border-dark' key={item.subject.id}>
                        {Object.keys(item.stream).length > 0 && (
                          <td className='p-2' rowSpan={item.stream.count}>
                            <div className='d-flex align-items-center justify-content-center flex-column'>
                              <div className='d-flex align-items-center gap-2'>
                                <span className='mb-5'> {item.stream.name}</span>
                                {/* Button to toggle showing/hiding content */}
                                <button
                                  className='btn btn-sm btn-primary btn-icon mb-5'
                                  onClick={() => toggleStreamContent(item.stream.id)}
                                >
                                  {showStreamContent[item?.stream?.id] ? (
                                    <i className='ki-duotone ki-down-square fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  ) : (
                                    <i className='ki-duotone ki-up-square fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                  )}
                                </button>
                              </div>
                              <div className='d-flex' style={{gap: '10px'}}>
                                {/* Save button for Stream */}
                                <button
                                  className='btn btn-sm btn-primary btn-icon'
                                  type='button'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip='top-end'
                                  data-bs-toggle='modal'
                                  data-bs-target='#EditStream'
                                  onClick={() => {
                                    setSelected({
                                      ...selected,
                                      streamId: item.stream.id,
                                      name: item?.stream.name,
                                    })
                                  }}
                                >
                                  <i className='ki-duotone ki-message-edit fs-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </button>
                                {/* Delete button for Stream */}
                                <button
                                  className='btn btn-icon btn-sm btn-danger'
                                  onClick={() => {
                                    // Call handleDelete for Stream
                                    handleDelete(item.stream.id)
                                  }}
                                >
                                  <i className='ki-duotone ki-trash-square fs-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </button>
                                <button
                                  className='btn  btn-sm btn-success'
                                  type='button'
                                  data-kt-menu-trigger='click'
                                  data-kt-menu-placement='bottom-end'
                                  data-kt-menu-flip='top-end'
                                  data-bs-toggle='modal'
                                  data-bs-target='#AddClass'
                                  onClick={() => {
                                    setSelected({...selected, streamId: item?.stream?.id})
                                  }}
                                >
                                  <i className='ki-duotone ki-message-add fs-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>{' '}
                                  Add Class
                                </button>
                              </div>
                              <div className='modal fade' id='AddClass' aria-hidden='true'>
                                <AddModal flag='Add' name='Class' data={selected?.streamId} />
                              </div>
                              <div className='modal fade' id='EditStream' aria-hidden='true'>
                                <AddModal flag='Save' name='Stream' data={selected} />
                              </div>
                            </div>
                          </td>
                        )}

                        {item &&
                          Object?.keys(item.class).length > 0 &&
                          showStreamContent[item?.class?.streamId] && (
                            <td className='p-2' rowSpan={item.class.count}>
                              <div className='d-flex align-items-center justify-content-center flex-column'>
                                <span className='mb-5'> {item.class.name}</span>
                                <div className='d-flex mb-5  gap-2'>
                                  {divisionData?.[item?.class?.id]?.division.map((item, index) => (
                                    <div
                                      key={index}
                                      className={`badge mb-2 badge-light-${item?.color}`}
                                    >
                                      {item?.name}
                                    </div>
                                  ))}
                                </div>
                                {/* Save button for Class */}
                                <div className='d-flex align-items-center' style={{gap: '10px'}}>
                                  <button
                                    className='btn  btn-sm btn-primary btn-icon'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#EditClass'
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        streamId: item.class.streamId,
                                        classId: item?.class.id,
                                        name: item?.class?.name,
                                      })
                                    }}
                                  >
                                    <i className='ki-duotone ki-message-edit fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </button>
                                  {/* Delete button for Class */}
                                  <button
                                    className='btn btn-sm btn-icon btn-danger'
                                    onClick={() => {
                                      // Call handleDeleteChild for Class
                                      handleDeleteClass(item.class.id)
                                    }}
                                  >
                                    <i className='ki-duotone ki-trash-square fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </button>
                                  <button
                                    className='btn  btn-sm btn-success'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#AddSemester'
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        streamId: item.class.streamId,
                                        classId: item.class.id,
                                        name: '',
                                      })
                                    }}
                                  >
                                    {' '}
                                    <i className='ki-duotone ki-message-add fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>{' '}
                                    Add Semester
                                  </button>
                                  <button
                                    className='btn btn-sm btn-primary'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#Division'
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        streamId: item.class.streamId,
                                        classId: item.class.id,
                                      })
                                    }}
                                  >
                                    <i className='ki-duotone fs-2 ki-notepad-edit'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                    </i>
                                    Add or Modify Division
                                  </button>

                                  <div className='modal fade' id='Division' aria-hidden='true'>
                                    <DiviSionModal selected={selected} />
                                  </div>
                                  <div className='modal fade' id='AddSemester' aria-hidden='true'>
                                    <AddModal flag='Add' name='Semester' data={selected} />
                                  </div>
                                  <div className='modal fade' id='EditClass' aria-hidden='true'>
                                    <AddModal flag='Save' name='Class' data={selected} />
                                  </div>
                                </div>
                              </div>
                            </td>
                          )}
                        {item &&
                          Object?.keys(item.semester).length > 0 &&
                          showStreamContent[item?.semester?.streamId] && (
                            <td className='p-2' rowSpan={item.semester.count}>
                              <div className='d-flex align-items-center justify-content-center flex-column'>
                                <span className='mb-5'> {item.semester.name}</span>
                                {/* Save button for Semester */}
                                <div className='d-flex align-items-center ' style={{gap: '10px'}}>
                                  <button
                                    className='btn  btn-sm btn-primary btn-icon'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#EditSemester'
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        streamId: item.semester.streamId,
                                        classId: item?.semester.classId,
                                        semesterId: item.semester.id,
                                        name: item?.semester?.name,
                                      })
                                    }}
                                  >
                                    <i className='ki-duotone ki-message-edit fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </button>
                                  <div className='modal fade' id='EditSemester' aria-hidden='true'>
                                    <AddModal flag='Save' name='Semester' data={selected} />
                                  </div>
                                  {/* Delete button for Semester */}
                                  <button
                                    className='btn btn-sm btn-icon btn-danger'
                                    onClick={() => {
                                      // Call handleDeleteSemester for Semester
                                      handleDeleteSemester(item.semester.id)
                                    }}
                                  >
                                    <i className='ki-duotone ki-trash-square fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </button>
                                  <button
                                    className='btn  btn-sm btn-success'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#AddSubject'
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        streamId: item.semester.streamId,
                                        classId: item.semester.classId,
                                        semesterId: item.semester.id,
                                        name: '',
                                      })
                                    }}
                                  >
                                    <i className='ki-duotone ki-message-add fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                    Add Subject
                                  </button>
                                  <div className='modal fade' id='AddSubject' aria-hidden='true'>
                                    <AddModal flag='Add' name='Subject' data={selected} />
                                  </div>
                                </div>
                              </div>
                            </td>
                          )}
                        {item &&
                          Object?.keys(item.subject).length > 0 &&
                          showStreamContent[item?.subject?.streamId] && (
                            <td>
                              <div className='d-flex align-items-center justify-content-center flex-column'>
                                <span className='mb-5'>{item.subject.name}</span>
                                <div className='d-flex align-items-center' style={{gap: '10px'}}>
                                  <button
                                    className='btn  btn-sm btn-primary btn-icon'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#EditSubject'
                                    onClick={() => {
                                      setSelected({
                                        ...selected,
                                        streamId: item.subject.streamId,
                                        classId: item?.subject.classId,
                                        semesterId: item?.subject?.semesterId,
                                        subjectId: item?.subject?.id,
                                        name: item?.subject?.name,
                                      })
                                    }}
                                  >
                                    <i className='ki-duotone ki-message-edit fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </button>
                                  <div className='modal fade' id='EditSubject' aria-hidden='true'>
                                    <AddModal flag='Save' name='Subject' data={selected} />
                                  </div>
                                  {/* Delete button for Subject */}
                                  <button
                                    className='btn btn-sm btn-icon btn-danger'
                                    onClick={() => {
                                      // Call handleDeleteSubject for Subject
                                      handleDeleteSubject(item.subject.id)
                                    }}
                                  >
                                    <i className='ki-duotone ki-trash-square fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                      </tr>
                    )
                  })}
                <tr>
                  <td>
                    <button
                      className='btn  btn-sm btn-primary'
                      type='button'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      data-bs-toggle='modal'
                      data-bs-target='#AddStream'
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>{' '}
                      Add Stream
                    </button>
                    <div className='modal fade' id='AddStream' aria-hidden='true'>
                      <AddModal flag='Add' name='Stream' data={''} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

export default Academics
