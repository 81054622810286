import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  holiday: [],
  student: [],
}

export const AttendanceSlice = createSlice({
  name: 'AttendanceSlice',
  initialState,
  reducers: {
    setHoliday: (state, action) => {
      state.holiday = action.payload
    },
    setStudent: (state, action) => {
      state.student = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setHoliday, setStudent} = AttendanceSlice.actions
export default AttendanceSlice.reducer
