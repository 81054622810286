import React, {useEffect, useState} from 'react'
import {Link, useSearchParams} from 'react-router-dom'

export default function ViewCompony() {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [data, setData]: any = useState([
    {
      id: 1,
      name: 'Micro Soft',
      startDate: '2023-16-9',
      service: 'It Service',
      websiteLik: 'www.microsoft.com',
      email: 'microsoft@gmail.com',
      size: 'large',
      about:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum eaque ducimus odit illo at sit blanditiis aspernatur rem, officiis recusandae iusto repellat voluptate cupiditate enim culpa dolores provident libero voluptates.',
      specialty: ['search', 'ads', 'mobile', 'android', 'video', 'map', 'machine'],
    },
    {
      id: 2,
      name: 'dell',
      startDate: '2023-16-9',
      service: 'It Service',
      webLink: 'www.dell.com',
      email: 'dell@gmail.com',
      size: 'small',
      specialty: ['search', 'ads', 'mobile', 'android', 'video', 'map', 'machine'],
      about:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum eaque ducimus odit illo at sit blanditiis aspernatur rem, officiis recusandae iusto repellat voluptate cupiditate enim culpa dolores provident libero voluptates.',
    },
    {
      id: 3,
      name: 'hp',
      startDate: '2023-16-9',
      service: 'It Service',
      specialty: ['search', 'ads', 'mobile', 'android', 'video', 'map', 'machine'],
      webLink: 'www.h.com',
      email: 'hp@gmail.com',
      size: 'medium',
      about:
        'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum eaque ducimus odit illo at sit blanditiis aspernatur rem, officiis recusandae iusto repellat voluptate cupiditate enim culpa dolores provident libero voluptates.',
    },
  ])
  const [skills, setSkills] = useState([
    {name: 'react js'},
    {name: 'node js'},
    {name: 'js'},
    {name: 'Tailwind'},
    {name: 'css'},
    {name: 'html'},
    {name: 'Bootstrap'},
    {name: 'Tailwind'},
    {name: 'react js'},
    {name: 'Bootstrap'},
    {name: 'Bootstrap'},
  ])
  const [details, setDetails]: any = useState({})
  useEffect(() => {
    if (id) {
      let filter = data?.find((item) => item?.id === Number(id))
      setDetails(filter)
    }
  }, [id])
  return (
    <>
      <div className='card shadow mb-5 card-custom '>
        <div className='card-header'>
          <div className='card-title'>
            <h3>View Compony</h3>
          </div>
          <div className='card-toolbar'>
            <Link to='/achievements/Organizations'>
              <div className='btn btn-sm btn-primary'>
                <i className='fa-solid fa-arrow-left p-0'></i>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className='row '>
        <div className='col-lg-4 mb-5'>
          <div className='p-3 card  shadow rounded'>
            <div className='row align-items-center '>
              <div className='col-12  mb-5 text-center'>
                <div className='m-auto mb-2 ' style={{height: '100px', width: '100px'}}>
                  <img
                    src={require('../../../../_metronic/assets/Google__G__Logo.svg.png')}
                    alt=''
                    style={{objectFit: 'cover'}}
                    className='h-100 text-center w-100'
                  />
                </div>
                <h4 className='fw-bold fs-5'>{details?.name}</h4>
                <h4 className='fw-bold fs-7 text-muted mb-8'>{details?.service}</h4>
                <div className='border border-secondary'></div>
              </div>
              <div className='col-12 mb-5 text-start'>
                <h4 className='fs-6 mb-3 text-muted'>Custom Information</h4>
                <div className='d-flex mb-3  flex-column'>
                  <h4 className='fs-7 text-muted'>Founded:</h4>
                  <span className='text-dark fw-bold'>{details?.startDate} </span>
                </div>
                <div className='d-flex  mb-3 flex-column'>
                  <h4 className='fs-7 text-muted'>WebSite:</h4>
                  <span className='text-dark fw-bold'>{details?.webLink} </span>
                </div>
                <div className='d-flex  mb-3 flex-column'>
                  <h4 className='fs-7 text-muted'>Email:</h4>
                  <span className='text-dark fw-bold'>{details?.email} </span>
                </div>
                <div className='d-flex flex-column'>
                  <h4 className='fs-7 text-muted'>Size:</h4>
                  <span className='text-dark fw-bold'>{details?.size} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-8 mb-5'>
          <div className='p-3  card rounded shadow'>
            <div className='row align-items-center'>
              <div className='col-12 mb-5'>
                <div className='d-flex mb-3  flex-column'>
                  <h4 className=' text-muted'>About Compony:</h4>
                  <span className='text-dark fw-bold'>{details?.about} </span>
                </div>
                <div className='d-flex mb-5  flex-column'>
                  <h4 className='fs-6 text-muted'>Compony's Specialty:</h4>
                  <span className='text-dark fw-bold'>{details?.specialty?.toString()} </span>
                </div>
                <div className='border border-secondary'></div>
              </div>
              <div className='col-12 mb-5'>
                <h4 className='fs-6 text-muted'>Affiliated Compony</h4>
                <div
                  className='d-flex flex-wrap py-2 align-items-center justify-between flex-wrap'
                  style={{gap: '20px'}}
                >
                  {skills?.map((item) => {
                    return (
                      <div
                        className='d-flex px-2 py-1 border border-dark justify align-items-center'
                        style={{borderRadius: '25px'}}
                      >
                        <img
                          src={'/media/avatars/300-6.jpg'}
                          alt=''
                          className='me-5  rounded-circle'
                          style={{width: '30px', height: '30px'}}
                        />
                        <h6 className='me-5 m-0'>{item?.name}</h6>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
