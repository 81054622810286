/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import DataTables from '../../DataTable/DataTables'
import 'rc-tooltip/assets/bootstrap.css'
import Tooltip from 'rc-tooltip'

// const subjectList = uniq(map(assignmentTableBody, 'subject'))
let batch = [
  {value: 'b.tech', label: 'b.tech'},
  {value: 'bca', label: 'bca'},
  {value: 'bba', label: 'bba'},
  {value: 'b.com', label: 'b.com'},
]
const subjectFilter = ['maths', 'science', 'english', 'social science', 'chemistry']
const semesterFilter = [
  'semester 1',
  'semester 2',
  'semester 3',
  'semester 4',
  'semester 5',
  'semester 6',
]
const FessStructure = () => {
  const [tableData, setTableData] = useState([
    {
      id: 1,
      name: 'jhon',
      lastName: 'Deo',
      motherName: 'jhon',
      fatherName: 'jhone',
      fieldName: 'Bca',
      semester: 'semester 1',
      status: 'accept',
      formFees: true,
    },
    {
      id: 2,
      name: 'jhon',
      lastName: 'Deo',
      motherName: 'jhon',
      fatherName: 'jhone',
      fieldName: 'Bca',
      semester: 'semester 1',
      status: 'reject',
      formFees: false,
    },
  ])
  // data
  const columns: any = [
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <div className='symbol  symbol-35px symbol-circle'>
              <img src='/media/avatars/300-6.jpg' alt='Pic' />
            </div>
          </>
        )
      },
      center: true,
      sortable: true,
      width: '80px',
    },
    {
      name: 'first name',
      selector: (row: any) => row?.name,
      center: true,
      sortable: true,
    },
    {
      name: 'last name',
      selector: (row: any) => (row?.lastName ? row?.lastName : '-'),
      center: true,
      sortable: true,
    },
    {
      name: "mother's name",
      selector: (row: any) => (row?.motherName ? row?.motherName : '-'),
      center: true,
      sortable: true,
    },
    {
      name: "father's name",
      selector: (row: any) => (row?.fatherName ? row?.fatherName : '-'),
      center: true,
      sortable: true,
    },
    {
      name: 'fieldName',
      selector: (row: any) => (row?.fieldName ? row?.fieldName : '-'),
      center: true,
      sortable: true,
    },
    {
      name: 'semester',
      selector: (row: any) => (row?.semester ? row?.semester : '-'),
      center: true,
      sortable: true,
    },
    {
      name: 'Status',
      center: true,
      sortable: true,
      selector: (row: any) => {
        return (
          <span className={`badge badge-light-${row?.status === 'accept' ? 'success' : 'danger'}`}>
            {row?.status}
          </span>
        )
      },
    },
    {
      name: 'Form Fees',
      center: true,
      sortable: true,
      selector: (row: any) => {
        return (
          <span className={`badge badge-light-${row?.formFees ? 'success' : 'danger'}`}>
            {row?.formFees ? 'done' : 'pending'}
          </span>
        )
      },
    },
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <Tooltip placement='bottom' overlay={<span>View</span>}>
              <button
                className='btn btn-sm btn-light-primary me-4'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='fa-solid fa-eye'></i> View
              </button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>Approve</span>}>
              <button
                className='btn btn-sm btn-light-success'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='ki-duotone ki-check-square fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Approve
              </button>
            </Tooltip>
          </>
        )
      },
      width:'300px'
    },
  ]

  // pagination
  const [totalRows]: any = useState(0)
  const [perPage, setPerPage]: any = useState(20)
  const [page, setPage]: any = useState(1)
  const handlePageChange = (page: any) => {
    setPage(page)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }
  const [filterText, setFilterText] = React.useState('')
  var filteredItems: any
  useEffect(() => {
    filteredItems = tableData.filter(
      (item: any) => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    )
    if (filterText) {
      setTableData(filteredItems)
    } else if (filterText === '') {
      setTableData([
        {
          id: 1,
          name: 'jhon',
          lastName: 'Deo',
          motherName: 'jhon',
          fatherName: 'jhone',
          fieldName: 'Bca',
          semester: 'semester 1',
          status: 'accept',
          formFees: true,
        },
        {
          id: 2,
          name: 'jhon',
          lastName: 'Deo',
          motherName: 'jhon',
          fatherName: 'jhone',
          fieldName: 'Bca',
          semester: 'semester 1',
          status: 'reject',
          formFees: false,
        },
      ])
    }
  }, [filterText, filteredItems])
  return (
    <div className={`card `}>
      {/* begin::Header */}
      {/* <div className='card-header border-0 pt-5'>
        <div className='row align-items-center mb-0 mt-5 w-100 '>
          <div className='mb-5 col-sm-12 col-xl-2 col-lg-12'>
            <div className='d-flex mb-5 align-items-center position-relative '>
              <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3 ' />
              <input
                type='text'
                id='kt_filter_search'
                className='form-control   w-100 ps-9'
                placeholder='Search'
                value={filterText}
                onChange={(e) => {
                  setFilterText(e.target.value)
                }}
              />
            </div>
          </div>
          <div className=' mb-5 col-sm-12 col-lg-12 col-xl-10 p-0'>
            <div className='row align-items-center h-100 w-100 m-0'>
              <div className='col-md-3 col-sm-6 mb-5 h-100  position-relative'>
                <div className='d-flex w-100 h-100 align-items-center' style={{gap: '5px'}}>
                  <label className='from-label'>Batch:</label>
                  <Select
                    options={batch}
                    isSearchable={false}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '100%',
                        width: '100%',
                        border: 0,
                        boxShadow: 'unset',
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        boxShadow: 'unset',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                  />
                </div>
              </div>
              <div className='col-md-3 col-sm-6 mb-5 h-100 position-relative'>
                <div className='w-100 h-100 text-center'>
                  <button
                    className={`btn btn-sm btn-light-primary  h-100 w-100  ${
                      show?.subject ? 'show menu-dropdown' : ''
                    }`}
                    type='button'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => {
                      show?.subject
                        ? setShow({...show, subject: false})
                        : setShow({...show, subject: true})
                    }}
                  >
                    <i className='fas fa-circle fs-1x'></i>
                    Subject
                  </button>
                  <div
                    className={`menu menu-sub menu-sub-dropdown position-absolute menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px pb-3 ${
                      show?.subject ? 'show' : ''
                    }`}
                    data-kt-menu='true'
                    data-popper-placement={`bottom-end`}
                    style={{
                      zIndex: show.subject ? '105' : '',
                      inset: show?.subject ? '0px 0px auto auto' : '',
                      margin: show?.subject ? '0px' : '',
                      top: show?.subject ? '110%' : '',
                      left: show?.subject ? '0' : '',
                    }}
                  >
                    <div className='menu-item px-3'>
                      <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>
                        Subject Filter
                      </div>
                    </div>
                    <div className='separator mb-3 opacity-75'></div>
                    {subjectFilter?.map((item: any, index: any) => {
                      return (
                        <label
                          key={index?.toString()}
                          className='form-check form-check-sm form-check-custom form-check-solid me-5 menu-item px-3 py-3'
                          onClick={() => {
                            show?.subject
                              ? setShow({...show, subject: false})
                              : setShow({...show, subject: true})
                          }}
                        >
                          <input
                            className='form-check-input m-0'
                            // checked={checked[index]}
                            type='checkbox'
                            id={`main_checkout${index}`}
                            onChange={(e: any) => {
                              // checked[index] = e.target.checked
                              // setChecked([...checked])
                              show?.subject
                                ? setShow({...show, subject: false})
                                : setShow({...show, subject: true})
                            }}
                          ></input>
                          <span className='form-check-label'>{item}</span>
                        </label>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6 mb-5 h-100 position-relative'>
                <div className='w-100 h-100 '>
                  <button
                    className={` btn position-relative btn-sm btn-light-primary w-100 h-100 ${
                      show.semester ? 'show menu-dropdown' : ''
                    }`}
                    type='button'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => {
                      show?.semester
                        ? setShow({...show, semester: false})
                        : setShow({...show, semester: true})
                    }}
                  >
                    <i className='fas fa-filter fs-1x'></i>
                    Semester
                  </button>

                  <div
                    className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded position-absolute  menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px pb-3 ${
                      show?.semester ? 'show' : ''
                    }`}
                    data-kt-menu='true'
                    data-popper-placement={`${show?.semester ? 'bottom-end' : ''}`}
                    style={{
                      zIndex: show.semester ? '105' : '',
                      inset: show?.semester ? '0px 0px auto auto' : '',
                      margin: show?.semester ? '0px' : '',
                      top: show?.semester ? '110%' : '',
                      left: show?.semester ? '0' : '',
                    }}
                  >
                    <div className='menu-item px-3'>
                      <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>
                        Semester Filter
                      </div>
                    </div>
                    <div className='separator mb-3 opacity-75'></div>
                    {semesterFilter?.map((item: any, index: any) => {
                      return (
                        <div className='menu-item px-3' key={index.toString()}>
                          <div
                            className='menu-link px-3'
                            onClick={() => {
                              // setValues(item?.semesterId)
                              show?.semester
                                ? setShow({...show, semester: false})
                                : setShow({...show, semester: true})
                            }}
                          >
                            {item}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className='col-md-3 col-sm-6 mb-5 text-center h-100 position-relative'>
                <div className='w-100 h-100'>
                  <div
                    data-kt-daterangepicker='true'
                    data-kt-daterangepicker-opens='left'
                    className='btn btn-sm text-center btn-light d-flex align-items-center justify-content-center'
                    onClick={() => {
                      show?.date ? setShow({...show, date: false}) : setShow({...show, date: true})
                    }}
                  >
                    <div className='text-gray-600 fw-bold'>1 Sep 2023</div>

                    <i className='ki-duotone ki-calendar-8 fs-1 ms-2 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                      <span className='path4'></span>
                      <span className='path5'></span>
                      <span className='path6'></span>
                    </i>
                  </div>
                  <div
                    className=' position-absolute shadow-lg'
                    style={{
                      display: `${show?.date ? 'block' : 'none'}`,
                      top: '50px',
                      left: 'auto',
                      right: '60.0678px',
                      zIndex: '99999',
                    }}
                  >
                    <Calendar date={new Date()} onChange={handleSelect} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body'>
        <DataTables
          columns={columns}
          data={tableData}
          loading={tableData?.length === 0}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          page={page}
          perPage={perPage}
        />
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {FessStructure}
