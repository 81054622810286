import React, {useState} from 'react'
import Select from 'react-select'
export default function AddAdmission() {
  let batch = [{value: 'B.Sc in Civil Engineering', label: 'B.Sc in Civil Engineering'}]
  let apply = [{value: 'FresherMan', label: 'FresherMan'}]
  let program = [{value: 'Diploma', label: 'Diploma'}]
  let blood = [{value: 'A', label: 'A'}]
  let income = [{value: '$1,00,000', label: '$1,00,000'}]
  let financing = [{value: 'Guardian', label: 'Guardian'}]

  const [open, setOpen] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
  })
  const [status, setStatus] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
  })
  const [ErrorMessage, setErrorMessage] = useState({
    batch: '',
    apply: '',
    shift: '',
    program: '',
    name: '',
    blood: '',
    gender: '',
    status: '',
    number: '',
    nationality: '',
    contactnumber: '',
    contactRelationship: '',
    emgContact: '',
    address: '',
    division: '',
    district: '',
    policeStation: '',
    postoffice: '',
    country: '',
    fathername: '',
    email: '',
    fathernumber: '',
    idnumber: '',
    jobtitle: '',
    organizationname: '',
    organizationaddress: '',
    mothername: '',
    memail: '',
    mothernumber: '',
    midnumber: '',
    occupation: '',
    toefl: '',
    ielts: '',
    sat: '',
    gmat: '',
    extra: '',
    aboutus: '',
    income: '',
    financing: '',
    fighter: '',
    addmissiontest: '',
    adebefore: '',
    enroll: '',
    contagious: '',
    mental: '',
    paymentemail: '',
    cardno: '',
    paymentdate: '',
  })
  const [data, setData] = useState({
    batch: '',
    apply: '',
    shift: '',
    program: '',
    name: '',
    blood: '',
    gender: '',
    status: '',
    number: '',
    nationality: '',
    contactnumber: '',
    contactRelationship: '',
    emgContact: '',
    address: '',
    division: '',
    district: '',
    policeStation: '',

    date: '',
    postoffice: '',
    country: '',
    fathername: '',
    email: '',
    fathernumber: '',
    idnumber: '',
    jobtitle: '',
    organizationname: '',
    organizationaddress: '',
    mothername: '',
    memail: '',
    mothernumber: '',
    midnumber: '',
    occupation: '',
    toefl: '',
    ielts: '',
    sat: '',
    gmat: '',
    extra: '',
    aboutus: '',
    income: '',
    financing: '',
    fighter: '',
    addmissiontest: '',
    adebefore: '',
    enroll: '',
    contagious: '',
    mental: '',
    paymentemail: '',
    cardno: '',
    paymentdate: '',
  })

  const handelChange: any = (e) => {
    setData({...data, [e.target.name]: e.target.value})
    setErrorMessage({
      ...ErrorMessage,
      [e.target.name]: '',
    })
  }
  function handleSubmit() {
    if (data?.batch === '') {
      setErrorMessage({
        ...ErrorMessage,
        batch: 'Batch is required',
      })
    } else if (data?.apply === '') {
      setErrorMessage({
        ...ErrorMessage,
        apply: 'Apply  is required.',
      })
    } else if (data?.shift === '') {
      setErrorMessage({
        ...ErrorMessage,
        shift: 'Shift  is required.',
      })
    } else if (data?.program === '') {
      setErrorMessage({
        ...ErrorMessage,
        program: 'Program  is required.',
      })
    } else {
      setOpen({...open, step2: true, step1: false})
      setStatus({...status, step2: true})
    }
  }
  function handleSubmitPageTwo() {
    if (data?.name === '') {
      setErrorMessage({
        ...ErrorMessage,
        name: 'Please enter Name',
      })
    } else if (data?.blood === '') {
      setErrorMessage({
        ...ErrorMessage,
        blood: 'Blood group is required',
      })
    } else if (data?.gender === '') {
      setErrorMessage({
        ...ErrorMessage,
        gender: 'Gender is required',
      })
    } else if (data?.status === '') {
      setErrorMessage({
        ...ErrorMessage,
        status: 'Status is required',
      })
    } else if (data?.number === '') {
      setErrorMessage({
        ...ErrorMessage,
        number: 'Number is required',
      })
    } else if (data?.nationality === '') {
      setErrorMessage({
        ...ErrorMessage,
        nationality: 'Nationality is required',
      })
    } else if (data?.contactnumber === '') {
      setErrorMessage({
        ...ErrorMessage,
        contactnumber: 'Contact number is required',
      })
    } else if (data?.contactRelationship === '') {
      setErrorMessage({
        ...ErrorMessage,
        contactRelationship: 'Contact Relationship Number is required',
      })
    } else if (data?.emgContact === '') {
      setErrorMessage({
        ...ErrorMessage,
        emgContact: 'Emergency Contact Number is required',
      })
    } else {
      setOpen({...open, step2: false, step1: false, step3: true})
      setStatus({...status, step3: true})
    }
  }
  function handleSubmitPageThree() {
    if (data?.address === '') {
      setErrorMessage({
        ...ErrorMessage,
        address: 'Address is required',
      })
    } else if (data?.division === '') {
      setErrorMessage({
        ...ErrorMessage,
        division: 'Division is required',
      })
    } else if (data?.district === '') {
      setErrorMessage({
        ...ErrorMessage,
        district: 'District is required',
      })
    } else if (data?.policeStation === '') {
      setErrorMessage({
        ...ErrorMessage,
        policeStation: 'Police Station is required',
      })
    } else if (data?.postoffice === '') {
      setErrorMessage({
        ...ErrorMessage,
        postoffice: 'Post office is required',
      })
    } else if (data?.country === '') {
      setErrorMessage({
        ...ErrorMessage,
        country: 'Country is required',
      })
    } else {
      setOpen({...open, step2: false, step1: false, step3: false, step4: true, step5: false})
      setStatus({...status, step4: true})
    }
  }
  function handleSubmitPageFour() {
    if (data?.fathername === '') {
      setErrorMessage({
        ...ErrorMessage,
        fathername: 'Father name is required',
      })
    } else if (data?.email === '') {
      setErrorMessage({
        ...ErrorMessage,
        email: 'Email is required',
      })
    } else if (data?.fathernumber === '') {
      setErrorMessage({
        ...ErrorMessage,
        fathernumber: 'Father Number is required',
      })
    } else if (data?.idnumber === '') {
      setErrorMessage({
        ...ErrorMessage,
        idnumber: 'ID number is required',
      })
    } else if (data?.jobtitle === '') {
      setErrorMessage({
        ...ErrorMessage,
        jobtitle: 'Job Title is required',
      })
    } else if (data?.organizationname === '') {
      setErrorMessage({
        ...ErrorMessage,
        organizationname: 'Organization Name is required',
      })
    } else if (data?.organizationaddress === '') {
      setErrorMessage({
        ...ErrorMessage,
        organizationaddress: 'Organization Address is required',
      })
    } else if (data?.mothername === '') {
      setErrorMessage({
        ...ErrorMessage,
        mothername: 'Mother Name is required',
      })
    } else if (data?.memail === '') {
      setErrorMessage({
        ...ErrorMessage,
        memail: 'Email is required',
      })
    } else if (data?.mothernumber === '') {
      setErrorMessage({
        ...ErrorMessage,
        mothernumber: 'Number is required',
      })
    } else if (data?.midnumber === '') {
      setErrorMessage({
        ...ErrorMessage,
        midnumber: 'Id number is required',
      })
    } else if (data?.occupation === '') {
      setErrorMessage({
        ...ErrorMessage,
        occupation: 'Occupation is required',
      })
    } else {
      setOpen({...open, step2: false, step1: false, step3: false, step4: false, step5: true})
      setStatus({...status, step5: true})
    }
  }
  function handleSubmitPageFive() {
    if (data?.toefl === '') {
      setErrorMessage({
        ...ErrorMessage,
        toefl: 'TOEFL is required',
      })
    } else if (data?.ielts === '') {
      setErrorMessage({
        ...ErrorMessage,
        ielts: 'IELTS is required',
      })
    } else if (data?.sat === '') {
      setErrorMessage({
        ...ErrorMessage,
        sat: 'SAT is required',
      })
    } else if (data?.gmat === '') {
      setErrorMessage({
        ...ErrorMessage,
        gmat: 'GMAT is required',
      })
    } else {
      setOpen({
        ...open,
        step2: false,
        step1: false,
        step3: false,
        step4: false,
        step5: false,
        step6: true,
      })
      setStatus({...status, step6: true})
    }
  }
  function handleSubmitPageSix() {
    if (data?.extra === '') {
      setErrorMessage({
        ...ErrorMessage,
        extra: 'Extra-Academic Attitude is required',
      })
    } else if (data?.aboutus === '') {
      setErrorMessage({
        ...ErrorMessage,
        aboutus: 'About US is required',
      })
    } else if (data?.income === '') {
      setErrorMessage({
        ...ErrorMessage,
        income: 'Income  is required',
      })
    } else if (data?.financing === '') {
      setErrorMessage({
        ...ErrorMessage,
        financing: 'Financing  is required',
      })
    } else if (data?.fighter === '') {
      setErrorMessage({
        ...ErrorMessage,
        fighter: 'This field  is required',
      })
    } else if (data?.addmissiontest === '') {
      setErrorMessage({
        ...ErrorMessage,
        addmissiontest: 'This field  is required',
      })
    } else if (data?.adebefore === '') {
      setErrorMessage({
        ...ErrorMessage,
        adebefore: 'This field  is required',
      })
    } else if (data?.enroll === '') {
      setErrorMessage({
        ...ErrorMessage,
        enroll: 'This field  is required',
      })
    } else if (data?.contagious === '') {
      setErrorMessage({
        ...ErrorMessage,
        contagious: 'This field  is required',
      })
    } else if (data?.mental === '') {
      setErrorMessage({
        ...ErrorMessage,
        mental: 'This field  is required',
      })
    } else {
      alert(JSON.stringify(data))
    }
  }
  // function handleSubmitPageSeven() {
  //   if (data?.paymentemail === '') {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       paymentemail: 'Email is required',
  //     })
  //   } else if (data?.cardno === '') {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       cardno: 'Card No. is required',
  //     })
  //   } else if (data?.paymentdate === '') {
  //     setErrorMessage({
  //       ...ErrorMessage,
  //       paymentdate: 'Date is required',
  //     })
  //   } else {
  //     alert()
  //   }
  // }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title'>Admission</div>
        </div>
        <div className='card-body py-2'>
          <div className='d-flex align-items-center w-100'>
            <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={true} />
              <div
                className={`${status?.step2 ? 'bg-primary' : 'bg-secondary'}`}
                style={{height: '2px', width: '100%'}}
              ></div>
            </div>
            <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={status?.step2} />
              <div
                className={`${status?.step3 ? 'bg-primary' : 'bg-secondary'}`}
                style={{height: '2px', width: '100%'}}
              ></div>
            </div>
            <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={status?.step3} />
              <div
                className={`${status?.step4 ? 'bg-primary' : 'bg-secondary'}`}
                style={{height: '2px', width: '100%'}}
              ></div>
            </div>
            <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={status?.step4} />
              <div
                className={`${status?.step5 ? 'bg-primary' : 'bg-secondary'}`}
                style={{height: '2px', width: '100%'}}
              ></div>
            </div>
            <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={status?.step5} />
              <div
                className={`${status?.step6 ? 'bg-primary' : 'bg-secondary'}`}
                style={{height: '2px', width: '100%'}}
              ></div>
            </div>
            <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={status?.step6} />
              {/* <div
                className={`${status?.step7 ? 'bg-primary' : 'bg-secondary'}`}
                style={{height: '2px', width: '100%'}}
              ></div> */}
            </div>
            {/* <div className='d-flex align-items-center w-100'>
              <input className='form-check-input' type='radio' checked={status?.step7} />
            </div> */}
          </div>
        </div>
      </div>
      <div className='card'>
        <div className='card-body'>
          {open?.step1 ? (
            <div className='row align-items-center'>
              <div className='col-md-6 mb-5'>
                <label className='from-label'>Your Desired Program:</label>
                <Select
                  onChange={(e: any) => {
                    setErrorMessage({
                      ...ErrorMessage,
                      batch: '',
                    })
                    setData({
                      ...data,
                      batch: e.value,
                    })
                  }}
                  options={batch}
                  isSearchable={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      width: '100%',
                      boxShadow: 'unset',
                    }),
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      boxShadow: 'unset',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  placeholder='search and select batch'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.batch}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label className='from-label'>Apply As:</label>
                <Select
                  onChange={(e: any) => {
                    setErrorMessage({
                      ...ErrorMessage,
                      apply: '',
                    })
                    setData({
                      ...data,
                      apply: e.value,
                    })
                  }}
                  options={apply}
                  isSearchable={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      width: '100%',
                      boxShadow: 'unset',
                    }),
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      boxShadow: 'unset',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  placeholder='search and select apply'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.apply}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label className='from-label'>
                  Program Type<span className='text-danger'>*</span>
                </label>
                <Select
                  onChange={(e: any) => {
                    setErrorMessage({
                      ...ErrorMessage,
                      program: '',
                    })
                    setData({
                      ...data,
                      program: e.value,
                    })
                  }}
                  options={program}
                  isSearchable={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      width: '100%',
                      boxShadow: 'unset',
                    }),
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      boxShadow: 'unset',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  placeholder='search and select program'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.program}</span>
              </div>
              <div className='col-md-6 mb-5 '>
                <label className='from-label'>Which Shift do you want?</label>
                <br />
                <div className='d-flex gap-5 align-items-center'>
                  <div className='d-flex gap-3 align-items-center'>
                    <input
                      type='radio'
                      value='Day Shift'
                      name='shift'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <label htmlFor='Day Shift'>Day Shift</label>
                  </div>
                  <div className='d-flex gap-3 align-items-center'>
                    <input
                      type='radio'
                      className='form-check-input'
                      value='Evening Shift'
                      name='shift'
                      onChange={handelChange}
                    />
                    <label htmlFor='Evening Shift'>Evening Shift</label>
                  </div>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.shift}</span>
              </div>
              <div className='col-12'>
                <button
                  className='btn d-flex align-items-center gap-2 btn-primary'
                  onClick={handleSubmit}
                >
                  Next
                  <i className='ki-duotone ki-right-square fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </div>
            </div>
          ) : null}
          {open?.step2 ? (
            <>
              <div className='row align-items-center'>
                <div className='col-md-6 mb-5'>
                  <label htmlFor='name'>
                    Name<span className='text-danger'>*</span>
                  </label>
                  <input type='text' onChange={handelChange} className='form-control' name='name' />
                  <span className='text-danger fs-8'>{ErrorMessage?.name}</span>
                </div>
                <div className='col-md-6 mb-5'>
                  <label htmlFor=''>
                    Blood Group<span className='text-danger'>*</span>
                  </label>
                  <Select
                    onChange={(e: any) => {
                      setErrorMessage({
                        ...ErrorMessage,
                        blood: '',
                      })
                      setData({
                        ...data,
                        blood: e.value,
                      })
                    }}
                    options={blood}
                    isSearchable={true}
                    styles={{
                      control: (baseStyles) => ({
                        ...baseStyles,
                        height: '100%',
                        width: '100%',
                        boxShadow: 'unset',
                      }),
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        boxShadow: 'unset',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    placeholder='search and select program'
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.blood}</span>
                </div>
                <div className='col-md-4 mb-5'>
                  <label className='from-label'>
                    Gender<span className='text-danger'>*</span>
                  </label>
                  <br />
                  <div className='d-flex gap-5'>
                    <div className='d-flex gap-2'>
                      <input
                        type='radio'
                        value='Male'
                        name='gender'
                        className='form-check-input'
                        onChange={handelChange}
                      />
                      <span>Male</span>
                    </div>
                    <div className='d-flex gap-2'>
                      <input
                        type='radio'
                        value='Female'
                        name='gender'
                        className='form-check-input'
                        onChange={handelChange}
                      />
                      <span>Female</span>
                    </div>
                    <div className='d-flex gap-2'>
                      <input
                        type='radio'
                        value='Other'
                        name='gender'
                        className='form-check-input'
                        onChange={handelChange}
                      />
                      <span>Other</span>
                    </div>
                  </div>
                  <span className='text-danger fs-8'>{ErrorMessage?.gender}</span>
                </div>
                <div className='col-md-4 mb-5'>
                  <label className='from-label'>
                    Marital status<span className='text-danger'>*</span>
                  </label>
                  <br />
                  <div className='d-flex gap-5'>
                    <div className='d-flex gap-2'>
                      <input
                        type='radio'
                        value='Married'
                        name='status'
                        className='form-check-input'
                        onChange={handelChange}
                      />
                      <span>Married</span>
                    </div>
                    <div className='d-flex gap-2'>
                      <input
                        type='radio'
                        value='Unmarried'
                        name='status'
                        className='form-check-input'
                        onChange={handelChange}
                      />
                      <span>Unmarried</span>
                    </div>
                  </div>

                  <span className='text-danger fs-8'>{ErrorMessage?.status}</span>
                </div>
                <div className='col-md-4 mb-5'>
                  <label className='form-label'>Date</label>
                  <input type='date' name='date' onChange={handelChange} id='' />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {data?.date ? data?.date : 'Date *'}
                  </div>
                </div>
                <div className='col-md-6 mb-5'>
                  <label htmlFor='name'>
                    Home Phone Number<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='number'
                    onChange={handelChange}
                    className='form-control'
                    name='number'
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.number}</span>
                </div>
                <div className='col-md-6 mb-5'>
                  <label htmlFor='name'>Nationality*</label>
                  <input
                    type='text'
                    onChange={handelChange}
                    className='form-control'
                    name='nationality'
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.nationality}</span>
                </div>
                <div className='col-md-4 mb-5'>
                  <label htmlFor='name'>
                    Contact Number<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='number'
                    onChange={handelChange}
                    className='form-control'
                    name='contactnumber'
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.contactnumber}</span>
                </div>
                <div className='col-md-4 mb-5'>
                  <label htmlFor='name'>
                    Emergency Contact Relationship<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='number'
                    onChange={handelChange}
                    className='form-control'
                    name='contactRelationship'
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.contactRelationship}</span>
                </div>
                <div className='col-md-4 mb-5'>
                  <label htmlFor='name'>
                    Emergency Contact Number<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='number'
                    onChange={handelChange}
                    className='form-control'
                    name='emgContact'
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.emgContact}</span>
                </div>
                <div className='col-12'>
                  <button
                    className='btn d-flex align-items-center gap-2 btn-primary'
                    onClick={handleSubmitPageTwo}
                  >
                    Next
                    <i className='ki-duotone ki-right-square fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </>
          ) : null}
          {open.step3 ? (
            <div className='row'>
              <div className='col-12 mb-5'>
                <label htmlFor=''>
                  Address<span className='text-danger'>*</span>
                </label>
                <textarea
                  name='address'
                  // cols={20}
                  // rows={10}
                  onChange={handelChange}
                  className='form-control'
                ></textarea>
                <span className='text-danger fs-8'>{ErrorMessage?.address}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor=''>
                  Division<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='division'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.division}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor=''>
                  District<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='district'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.district}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor=''>
                  Police Station<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='policeStation'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.policeStation}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor=''>
                  Post Office<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='postoffice'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.postoffice}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor=''>
                  Country<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='country'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.country}</span>
              </div>
              <div className='col-12'>
                <button
                  className='btn d-flex align-items-center gap-2 btn-primary'
                  onClick={handleSubmitPageThree}
                >
                  Next
                  <i className='ki-duotone ki-right-square fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </div>
            </div>
          ) : null}
          {open.step4 ? (
            <div className='row'>
              <h3 className='my-3'>Father's Information</h3>
              <div className='col-md-6 mb-5'>
                <label htmlFor='name'>
                  Father's Name<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='fathername'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.fathername}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor='name'>
                  Email<span className='text-danger'>*</span>
                </label>
                <input type='email' onChange={handelChange} className='form-control' name='email' />
                <span className='text-danger fs-8'>{ErrorMessage?.email}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor='name'>
                  Father's Number<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='fathernumber'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.fathernumber}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor='name'>
                  National ID No.<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='idnumber'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.idnumber}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor='name'>
                  Job Title<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='jobtitle'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.jobtitle}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor='name'>
                  Organization Name<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='organizationname'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.organizationname}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor='name'>
                  Organization Address<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='organizationaddress'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.organizationaddress}</span>
              </div>
              <h3 className='my-3'>Mother's Information</h3>

              <div className='col-md-6 mb-5'>
                <label htmlFor='name'>
                  Mother's Name<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='mothername'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.mothername}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label htmlFor='name'>
                  Email<span className='text-danger'>*</span>
                </label>
                <input
                  type='email'
                  onChange={handelChange}
                  className='form-control'
                  name='memail'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.memail}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor='name'>
                  Mother's Number<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='mothernumber'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.mothernumber}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor='name'>
                  National ID No.<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='midnumber'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.midnumber}</span>
              </div>
              <div className='col-md-4 mb-5'>
                <label htmlFor='name'>
                  Occupation<span className='text-danger'>*</span>
                </label>
                <input
                  type='text'
                  onChange={handelChange}
                  className='form-control'
                  name='occupation'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.occupation}</span>
              </div>
              <div className='col-12'>
                <button
                  className='btn d-flex align-items-center gap-2 btn-primary'
                  onClick={handleSubmitPageFour}
                >
                  Next
                  <i className='ki-duotone ki-right-square fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </div>
            </div>
          ) : null}
          {open.step5 ? (
            <div className='row'>
              <div className='col-12 mb-5'>
                <label htmlFor='name'>
                  TOEFL Test score<span className='text-danger'>*</span>
                </label>
                <input type='text' onChange={handelChange} className='form-control' name='toefl' />
                <span className='text-danger fs-8'>{ErrorMessage?.toefl}</span>
              </div>
              <div className='col-12 mb-5'>
                <label htmlFor='name'>
                  IELTS Test score<span className='text-danger'>*</span>
                </label>
                <input type='text' onChange={handelChange} className='form-control' name='ielts' />
                <span className='text-danger fs-8'>{ErrorMessage?.ielts}</span>
              </div>
              <div className='col-12 mb-5'>
                <label htmlFor='name'>
                  SAT Test score<span className='text-danger'>*</span>
                </label>
                <input type='text' onChange={handelChange} className='form-control' name='sat' />
                <span className='text-danger fs-8'>{ErrorMessage?.sat}</span>
              </div>
              <div className='col-12 mb-5'>
                <label htmlFor='name'>
                  GMAT Test score<span className='text-danger'>*</span>
                </label>
                <input type='text' onChange={handelChange} className='form-control' name='gmat' />
                <span className='text-danger fs-8'>{ErrorMessage?.gmat}</span>
              </div>
              <div className='col-12'>
                <button
                  className='btn d-flex align-items-center gap-2 btn-primary'
                  onClick={handleSubmitPageFive}
                >
                  Next
                  <i className='ki-duotone ki-right-square fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </div>
            </div>
          ) : null}
          {open.step6 ? (
            <div className='row'>
              <div className='col-md-6 mb-5'>
                <label className='from-label mb-2'>
                  Extra Academic Attitude<span className='text-danger'>*</span>
                </label>
                <div className='d-flex flex-wrap gap-5'>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Sports'
                      name='extra'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Sports</span>
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Singing'
                      name='extra'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Singing</span>
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Drama'
                      name='extra'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Drama</span>
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Others'
                      name='extra'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Others</span>
                  </div>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.extra}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label className='from-label mb-2'>
                  How Did You Come To Know About Us<span className='text-danger'>*</span>
                </label>
                <div className='d-flex flex-wrap gap-5'>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Friends/Relatives'
                      name='aboutus'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Friends/Relatives</span>
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Banner'
                      name='aboutus'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Banner</span>
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Website'
                      name='aboutus'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Websites</span>
                  </div>
                  <div className='d-flex gap-2'>
                    <input
                      type='radio'
                      value='Others'
                      name='aboutus'
                      className='form-check-input'
                      onChange={handelChange}
                    />
                    <span>Others</span>
                  </div>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.aboutus}</span>
              </div>
              <div className='col-md-6 mb-5'>
                <label className='from-label'>Family Income:</label>
                <Select
                  onChange={(e: any) => {
                    setErrorMessage({
                      ...ErrorMessage,
                      income: '',
                    })
                    setData({
                      ...data,
                      income: e.value,
                    })
                  }}
                  options={income}
                  isSearchable={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      width: '100%',
                      boxShadow: 'unset',
                    }),
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      boxShadow: 'unset',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  placeholder='search and select Income'
                />
              </div>
              <div className='col-md-6 mb-5'>
                <label className='from-label'>Student's Source of Financing:</label>
                <Select
                  onChange={(e: any) => {
                    setErrorMessage({
                      ...ErrorMessage,
                      financing: '',
                    })
                    setData({
                      ...data,
                      financing: e.value,
                    })
                  }}
                  options={financing}
                  isSearchable={true}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      height: '100%',
                      width: '100%',
                      boxShadow: 'unset',
                    }),
                    option: (base) => ({
                      ...base,
                      cursor: 'pointer',
                      margin: '0px',
                      boxShadow: 'unset',
                      color: '#7e8299',
                      fontWeight: 'bold',
                      background: 'white', // this was the mistake (I needed to remove this)
                      ':hover': {
                        color: '#009ef7',
                        backgroundColor: '#f1faff',
                      },
                    }),
                  }}
                  placeholder='search and select Income'
                />
              </div>
              <div className='col-md-4 mb-5 '>
                <label className='from-label'>Where Your Parents Freedom Fighter?</label>
                <br />
                <div className='d-flex gap-3 align-items-center'>
                  <input
                    type='radio'
                    value='Check If Yes'
                    name='fighter'
                    className='form-check-input'
                    onChange={handelChange}
                  />
                  <label htmlFor=''>Check If Yes</label>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.fighter}</span>
              </div>
              <div className='col-md-4 mb-5 '>
                <label className='from-label'>Did you appear For Our Addmission Test Before?</label>
                <br />
                <div className='d-flex gap-3 align-items-center'>
                  <input
                    type='radio'
                    value='Check If Yes'
                    name='addmissiontest'
                    className='form-check-input'
                    onChange={handelChange}
                  />
                  <label htmlFor=''>Check If Yes</label>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.addmissiontest}</span>
              </div>
              <div className='col-md-4 mb-5 '>
                <label className='from-label'>Where Your Parents Freedom Fighter?</label>
                <br />
                <div className='d-flex gap-3 align-items-center'>
                  <input
                    type='radio'
                    value='Check If Yes'
                    name='adebefore'
                    className='form-check-input'
                    onChange={handelChange}
                  />
                  <label htmlFor=''>Check If Yes</label>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.adebefore}</span>
              </div>
              <div className='col-md-4 mb-5 '>
                <label className='from-label'>Do You Have Any Siblings Enrolls At EDE?</label>
                <br />
                <div className='d-flex gap-3 align-items-center'>
                  <input
                    type='radio'
                    value='Check If Yes'
                    name='enroll'
                    className='form-check-input'
                    onChange={handelChange}
                  />
                  <label htmlFor=''>Check If Yes</label>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.enroll}</span>
              </div>
              <div className='col-md-4 mb-5 '>
                <label className='from-label'>
                  Are you Suffering from Any Contagious Diseases?
                </label>
                <br />
                <div className='d-flex gap-3 align-items-center'>
                  <input
                    type='radio'
                    value='Check If Yes'
                    name='contagious'
                    className='form-check-input'
                    onChange={handelChange}
                  />
                  <label htmlFor=''>Check If Yes</label>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.contagious}</span>
              </div>
              <div className='col-md-4 mb-5 '>
                <label className='from-label'>Do You Have Any Physical Or Mental Disability?</label>
                <br />
                <div className='d-flex gap-3 align-items-center'>
                  <input
                    type='radio'
                    value='Check If Yes'
                    name='mental'
                    className='form-check-input'
                    onChange={handelChange}
                  />
                  <label htmlFor=''>Check If Yes</label>
                </div>
                <span className='text-danger fs-8'>{ErrorMessage?.mental}</span>
              </div>
              <div className='col-12'>
                <input
                  type='submit'
                  onClick={() => {
                    handleSubmitPageSix()
                  }}
                  className='btn btn-primary'
                  value='Submit'
                />
              </div>
            </div>
          ) : null}
          {/* {open.step7 ? (
            <div className='row'>
              <div className='col-12 mb-5'>
                <label htmlFor='name'>Email*</label>
                <input
                  type='email'
                  onChange={handleChange}
                  className='form-control'
                  name='paymentemail'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.paymentemail}</span>
              </div>
              <div className='col-6 mb-5'>
                <label htmlFor='name'>Card No.*</label>
                <input
                  type='number'
                  onChange={handleChange}
                  className='form-control'
                  name='cardno'
                />
                <span className='text-danger fs-8'>{ErrorMessage?.cardno}</span>
              </div>
              <div className='col-6 mb-5'>
                <div className='col-4 mb-5'>
                  <label className='form-label'>Expiry Date*</label>
                  <div className='w-100 h-100 position-relative'>
                    <div
                      data-kt-daterangepicker='true'
                      data-kt-daterangepicker-opens='left'
                      className='btn btn-sm text-center btn-light d-flex align-items-center justify-content-center'
                      onClick={() => {
                        show?.date
                          ? setShow({...show, date: false})
                          : setShow({...show, date: true})
                      }}
                    >
                      <div className='text-gray-600 fw-bold'>
                        {moment(date).format('MM/DD/YYYY')}
                      </div>

                      <i className='ki-duotone ki-calendar-8 fs-1 ms-2 me-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                        <span className='path4'></span>
                        <span className='path5'></span>
                        <span className='path6'></span>
                      </i>
                    </div>
                    <div
                      className=' position-absolute shadow-lg'
                      style={{
                        display: ${show?.date ? 'block' : 'none'},
                        top: '50px',
                        left: 'auto',
                        right: '60.0678px',
                        zIndex: '99999',
                      }}
                    >
                      <Calendar
                        onChange={(e: any) => {
                          handleSelectStart(e)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </>
  )
}
