import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {
  useAddCourseAuthorMutation,
  useAddSocialProfileMutation,
  useDeleteProfileMutation,
  useLazyGetCourseAuthorQuery,
} from '../../../Api/allApi'
import Swal from 'sweetalert2'
import {setAuthor} from '../../../redux/authorSlice'
import {useDispatch} from 'react-redux'
const Author = Yup.object().shape({
  authorName: Yup.string().required('Name is required'),
  authorImg: Yup.string().required('Image is required'),
})
const SocialProfileSchema = Yup.object().shape({
  platform: Yup.string().required('platform is required'),
  link: Yup.string().required('link is required'),
})
/*eslint-disable jsx-a11y/anchor-is-valid*/
const AuthorModel = ({name, data, type}) => {
  const dispatch = useDispatch()
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: '#DC3545',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  })
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({authorName: '', authorImg: ''})
  const [socialProfileValue, setSocialProfileValue] = useState({platform: '', link: ''})
  // add author api
  const [addAuthor, result] = useAddCourseAuthorMutation()
  const {isSuccess, isLoading} = result
  // get author list api
  const [authorList, authorResult] = useLazyGetCourseAuthorQuery()
  const {isSuccess: isAuthor, isFetching: isNotAuthor} = authorResult
  // add social platform api
  const [addSocialProfile, profileResult] = useAddSocialProfileMutation()
  const {isSuccess: isProfile, isLoading: isNotProfile} = profileResult
  // delete platform api
  const [deleteProfile, deleteProfileResult] = useDeleteProfileMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete} = deleteProfileResult
  const formik = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Author,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: data?.dbId || null,
          authorName: values?.authorName,
          authorImg: values?.authorImg,
        }
        addAuthor(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const socialProfile = useFormik({
    initialValues: socialProfileValue,
    enableReinitialize: data,
    validationSchema: SocialProfileSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: data?.selectedProfile?.dbId ? data?.selectedProfile?.dbId : 0,
          authorId: data?.author?.dbId,
          platform: values?.platform,
          link: values?.link,
        }
        addSocialProfile(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // call when api calling done successfully
  useEffect(() => {
    if ((isSuccess && !isLoading) || (isProfile && !isNotProfile)) {
      authorList({})
    }
  }, [isSuccess, isLoading, isProfile, isNotProfile, authorList])
  useEffect(() => {
    if (isAuthor && !isNotAuthor) {
      dispatch(setAuthor(result?.data?.authorList))
    }
  }, [isAuthor, isNotAuthor, dispatch, result?.data?.authorList])
  useEffect(() => {
    if (isDelete && !isNotDelete) {
      authorList({})
      Toast.fire({
        icon: 'error',
        title: deleteProfileResult?.data?.detail,
      })
    }
  }, [isDelete, isNotDelete, deleteProfileResult?.data?.detail, authorList, Toast])

  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      formik.setFieldValue('authorImg', base64String)
    }
    if (file) {
      reader.readAsDataURL(file)
    }
  }
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setInitialValues({
        authorName: data?.authorName,
        authorImg: data?.authorImg,
      })
      setSocialProfileValue({
        platform: data?.platform,
        link: data?.selectedProfile?.link,
      })
    }
  }, [data])
  return (
    <div className={`modal-dialog modal-dialog-centered mw-1000px `}>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>Add {name}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id={name}>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                {type === 'addAuthor' ? (
                  <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    id='Author'
                  >
                    <div className='row'>
                      <div className='col-12 mb-7'>
                        <div className='fs-6 text-muted'>
                          <span className='text-danger'>
                            <span className='text-danger'>*</span>
                          </span>{' '}
                          indicates required{' '}
                        </div>
                      </div>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          Author Name
                          <span className='text-danger'>
                            <span className='text-danger'>*</span>
                          </span>
                        </label>
                        <input
                          type='text'
                          placeholder='Add Author Name'
                          {...formik.getFieldProps('authorName')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': formik.touched.authorName && formik.errors.authorName,
                            },
                            {
                              'is-valid': formik.touched.authorName && !formik.errors.authorName,
                            }
                          )}
                        />
                      </div>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          Author Image<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='file'
                          onChange={onHandleChangeFile}
                          className={clsx(
                            'form-control bg-transparent',
                            {'is-invalid': formik.touched.authorImg && formik.errors.authorImg},
                            {
                              'is-valid': formik.touched.authorImg && !formik.errors.authorImg,
                            }
                          )}
                        />
                        {formik?.values?.authorImg ? (
                          <img
                            src={formik?.values?.authorImg}
                            className='w-50px h-50px mt-2 rounded'
                            alt='credential'
                          />
                        ) : null}
                      </div>
                      <div className='col-12 border-top text-end'>
                        <button
                          type='submit'
                          data-bs-dismiss={'modal'}
                          className='btn btn-primary'
                          disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && <span className='indicator-label'>Continue</span>}
                          {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form
                    className='form w-100'
                    onSubmit={socialProfile.handleSubmit}
                    noValidate
                    id='Author'
                  >
                    <div className='row'>
                      <div className='col-12 mb-7'>
                        <div className='fs-6 text-muted'>
                          <span className='text-danger'>*</span> indicates required{' '}
                        </div>
                      </div>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          platform<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          placeholder='Add Platform'
                          {...socialProfile.getFieldProps('platform')}
                          disabled={true}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid':
                                socialProfile.touched.platform && socialProfile.errors.platform,
                            },
                            {
                              'is-valid':
                                socialProfile.touched.platform && !socialProfile.errors.platform,
                            }
                          )}
                        />
                      </div>
                      <div className='col-12 mb-5'>
                        <label className='form-label'>
                          Link<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          placeholder='Add Link'
                          {...socialProfile.getFieldProps('link')}
                          className={clsx(
                            'form-control bg-transparent',
                            {
                              'is-invalid': socialProfile.touched.link && socialProfile.errors.link,
                            },
                            {
                              'is-valid': socialProfile.touched.link && !socialProfile.errors.link,
                            }
                          )}
                        />
                      </div>

                      <div className='col-12 border-top text-end'>
                        <div className='d-flex gap-2 mt-5 align-items-center justify-content-end'>
                          <button
                            type='submit'
                            data-bs-dismiss='modal'
                            className='btn btn-light-primary'
                            disabled={socialProfile.isSubmitting || !socialProfile.isValid}
                          >
                            {!loading && (
                              <span className='indicator-label d-flex align-items-center gap-2'>
                                {' '}
                                <i className='ki-duotone ki-message-add fs-1 '>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                  <span className='path3'></span>
                                </i>
                                {data?.selectedProfile?.dbId ? 'Update Profile' : 'Create Profile'}
                              </span>
                            )}
                            {loading && (
                              <span className='indicator-progress' style={{display: 'block'}}>
                                Please wait...
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                          {data?.selectedProfile?.dbId && (
                            <div
                              className='btn btn-light-danger'
                              data-bs-dismiss='modal'
                              onClick={() => {
                                deleteProfile(data?.selectedProfile?.dbId)
                              }}
                            >
                              <i className='ki-duotone ki-trash-square fs-1'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>{' '}
                              Delete
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthorModel}
