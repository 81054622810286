import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  assessment: [],
}

export const AssessmentSlice = createSlice({
  name: 'AssessmentSlice',
  initialState,
  reducers: {
    setAssessment: (state, action) => {
      state.assessment = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setAssessment} = AssessmentSlice.actions
export default AssessmentSlice.reducer
