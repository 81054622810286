import React, {useEffect, useState} from 'react'
import {
  useAddClassMutation,
  useAddSemesterMutation,
  useAddStreamMutation,
  useAddSubjectMutation,
  useLazyAcademicFilterQuery,
  useLazyGetSubjectDetailsQuery,
} from '../../../Api/allApi'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import Swal from 'sweetalert2'
import {setAcademic, setDivision} from '../../../redux/AcademicSlice'
import clsx from 'clsx'
import {useSelector} from 'react-redux'
// Define a Yup schema for validation
const SubjectSchema = Yup.object().shape({
  outcomeList: Yup.array().of(Yup.string().required('Outcome is required')),
  about: Yup.string().required('About is required'),
  content: Yup.array().of(
    Yup.object().shape({
      contentHead: Yup.string().required('Content Head is required'),
      contentBody: Yup.string().required('Content Body is required'),
    })
  ),
  imgLink: Yup.string().required('image is required'),
  faqs: Yup.array().of(
    Yup.object().shape({
      query: Yup.string().required('Query is required'),
      reply: Yup.string().required('Response is required'),
    })
  ),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const AddModal = ({name, data, flag}) => {
  // Initial form values
  const [initialValues, setInitialValues] = useState({
    outcomeList: [''],
    content: [
      {
        contentHead: '',
        contentBody: '',
      },
    ],
    faqs: [
      {
        query: '',
        reply: '',
      },
    ],
    imgLink: '',
    about: '',
  })
  const dispatch = useDispatch()
  // filter trigger
  const [getData, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  // add stream trigger
  const [addStream, streamResult] = useAddStreamMutation()
  const {isSuccess: isStream, isLoading: isNotStream, isError: isStreamError} = streamResult
  // add class trigger
  const [addClass, classResult] = useAddClassMutation()
  const {isSuccess: isClass, isLoading: isNotClass, isError: isClassError} = classResult
  // add semester trigger
  const [addSemester, semesterResult] = useAddSemesterMutation()
  const {isSuccess: isSemester, isLoading: isNotSemester, isError: isSemesterError} = semesterResult
  // add subject trigger
  const [addSubject, subjectResult] = useAddSubjectMutation()
  const {isSuccess: isSubject, isLoading: isNotSubject, isError: isSubjectError} = subjectResult
  // add subject trigger
  const [getSubjectDetails, detailsResult]: any = useLazyGetSubjectDetailsQuery()
  const {isSuccess: isDetails, isLoading: isNotDetails} = detailsResult
  const [value, setValues] = useState({
    inputValue: '',
    name: name,
    semesterId: 0,
    subjectId: 0,
  })
  const handleAddStream = (stream) => {
    setValues({
      ...value,
      inputValue: '',
    })
    let payload = {
      dbId: 0,
      streamName: stream,
      streamAbbr: stream,
    }
    addStream(payload)
  }
  const handleAddClass = (streamId, className) => {
    setValues({
      ...value,
      inputValue: '',
    })
    let payload = {
      dbId: null,
      streamId: streamId,
      className: className,
      classAbbr: className,
      sequenceNo: 1,
    }
    addClass(payload)
  }
  const handleAddSemester = (classId, semesterName) => {
    setValues({
      ...value,
      inputValue: '',
    })
    let payload = {
      dbId: null,
      classId: classId,
      semesterName: semesterName,
      semesterAbbr: semesterName,
      sequenceNo: 1,
    }
    addSemester(payload)
  }
  const handleEdit = (nodeId, newName) => {
    setValues({
      ...value,
      inputValue: '',
    })
    let payload = {
      dbId: nodeId,
      streamName: newName,
      streamAbbr: newName,
    }
    addStream(payload)
  }
  const handleEditClass = (streamId, classId, newName) => {
    setValues({
      ...value,
      inputValue: '',
    })
    let payload = {
      dbId: classId,
      streamId: streamId,
      className: newName,
      classAbbr: newName,
      sequenceNo: 0,
    }
    addClass(payload)
  }
  const handleEditSemester = (classId, semesterId, newName) => {
    setValues({
      ...value,
      inputValue: '',
    })
    let payload = {
      dbId: semesterId,
      classId: classId,
      semesterName: newName,
      semesterAbbr: newName,
      sequenceNo: 0,
    }
    addSemester(payload)
  }
  useEffect(() => {
    if (name) {
      setValues({
        ...value,
        name: name,
      })
    }

    if (data?.name) {
      setValues({
        ...value,
        inputValue: data?.name,
      })
    } else {
      setValues({
        ...value,
        inputValue: '',
      })
      setInitialValues({
        outcomeList: [''],
        content: [
          {
            contentHead: '',
            contentBody: '',
          },
        ],
        faqs: [
          {
            query: '',
            reply: '',
          },
        ],
        imgLink: '',
        about: '',
      })
    }
    if (data?.subjectId > 0) {
      getSubjectDetails(data?.subjectId)
    }
  }, [name, data, getSubjectDetails, value])
  const handleChange = (e) => {
    setValues({
      ...value,
      inputValue: e.target.value,
    })
  }
  // call when Add Api returns 200
  useEffect(() => {
    if (
      (isStream && !isNotStream) ||
      (isClass && !isNotClass) ||
      (isSemester && !isNotSemester) ||
      (isSubject && !isNotSubject)
    ) {
      getData({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your record has been Added',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [
    isStream,
    isNotStream,
    isClass,
    isNotClass,
    isSemester,
    isNotSemester,
    isSubject,
    isNotSubject,
    getData,
  ])
  useEffect(() => {
    if (isDetails && !isNotDetails) {
      setInitialValues({
        outcomeList: detailsResult?.data?.outcomeList,
        content: detailsResult?.data?.content,
        faqs: detailsResult?.data?.faqs,
        imgLink: detailsResult?.data?.coverImg,
        about: detailsResult?.data?.about,
      })
    }
  }, [isDetails, isNotDetails, detailsResult?.data])
  // console.log(initialValues)
  // call when api returns 200
  useEffect(() => {
    if (isSuccess && !isFetching) {
      let originalData = result?.data
      const transformedData = originalData.stream.map((stream) => ({
        id: stream.dbId,
        name: stream.name,
        children: (originalData.class[stream.dbId] || []).map((classItem) => ({
          id: classItem.dbId,
          name: classItem.name,
          children: (originalData.semester[classItem.dbId] || []).map((semester) => ({
            id: semester.dbId,
            name: semester.name,
            children: (originalData.subject[semester.dbId] || []).map((subject) => ({
              id: subject.dbId,
              name: subject.name,
            })),
          })),
        })),
      }))
      dispatch(setAcademic(transformedData))
    }
  }, [isSuccess, isFetching, result?.data, dispatch])
  // call when api returns error
  useEffect(() => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      iconColor: '#DC3545',
      customClass: {
        popup: 'colored-toast',
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    })
    if (isStreamError || isClassError || isSemesterError || isSubjectError) {
      Toast.fire({
        icon: 'error',
        title: 'Something Went Wrong',
      })
    }
  }, [isStreamError, isSemesterError, isSubjectError, isClassError])
  // Formik configuration for handling form submission
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: SubjectSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: value?.subjectId,
          semesterId: value?.semesterId,
          subjectName: value?.inputValue,
          subjectAbbr: value?.inputValue,
          about: values?.about,
          outcomeList: values?.outcomeList,
          content: values?.content,
          faqs: values?.faqs,
          imgLink: values?.imgLink,
        }
        addSubject(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
      }
    },
  })
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>
            {flag} {value?.name}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id={value?.name}>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                {value?.name === 'Subject' && (
                  <form className='form' onSubmit={formik.handleSubmit} noValidate>
                    <div className='row'>
                      <div className='col-12 mb-5'>
                        <label htmlFor='form-label' style={{width: '100%', textAlign: 'left'}}>
                          Subject Name<span className='text-danger'>*</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          name={name}
                          value={value?.inputValue}
                          onChange={handleChange}
                          placeholder={`Enter ${value?.name}`}
                        />
                      </div>
                      <div className='col-12 mb-5'>
                        <label htmlFor='form-label' style={{width: '100%', textAlign: 'left'}}>
                          Subject Description<span className='text-danger'>*</span>
                        </label>
                        <textarea
                          name='about'
                          value={formik?.values?.about}
                          onChange={formik.handleChange}
                          className={clsx('form-control bg-transparent', {
                            'is-invalid': formik.touched.about && formik.errors.about,
                          })}
                          placeholder='Description'
                        />
                      </div>
                      <div className='col-12 mb-5'>
                        <input
                          type='file'
                          name='imgLink'
                          onChange={(e) => {
                            const file: any = e.target.files?.[0]
                            const reader = new FileReader()
                            reader.onloadend = () => {
                              const base64String = reader.result
                              formik.setFieldValue('imgLink', base64String)
                            }
                            reader.readAsDataURL(file)
                          }}
                          className={clsx('form-control bg-transparent', {
                            'is-invalid': formik.touched.imgLink && formik.errors.imgLink,
                          })}
                        />
                      </div>
                      {/* out come */}
                      <div className='col-12 mb-5 border-bottom border-3 py-2'>
                        <div className='card '>
                          <div className='card-header p-1'>
                            <div className='card-title'>
                              <h3 className=''> Subject Outcome</h3>
                            </div>
                          </div>
                          <div className='card-body mt-3 p-1'>
                            <div className='col-12'>
                              {formik.values.outcomeList?.map((outcome, index) => (
                                <div key={index} className='row align-items-center  mb-5'>
                                  <div className='col-12 text-start d-flex align-items-center gap-2 mb-5'>
                                    <span>{index + 1}</span>
                                    <input
                                      type='text'
                                      name={`outcomeList[${index}]`}
                                      value={outcome}
                                      onChange={formik.handleChange}
                                      className={clsx('form-control bg-transparent', {
                                        'is-invalid':
                                          formik.touched.outcomeList &&
                                          formik.errors.outcomeList?.[index],
                                      })}
                                      placeholder='Outcome'
                                    />
                                    {formik.touched.outcomeList &&
                                      formik.errors.outcomeList?.[index] && (
                                        <span className='text-danger'>
                                          {formik.errors.outcomeList[index]}
                                        </span>
                                      )}
                                    {/* Add a button to remove this outcome */}
                                    <button
                                      type='button'
                                      className='btn btn-light-danger p-3  btn-sm '
                                      onClick={() =>
                                        formik.setFieldValue(
                                          'outcomeList',
                                          formik.values.outcomeList.filter((_, i) => i !== index)
                                        )
                                      }
                                    >
                                      <i className='ki-duotone ki-trash-square text-center p-0 fs-1'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                        <span className='path3'></span>
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              ))}
                              {/* Add a button to add a new outcome */}
                              <div className='col-12 text-end'>
                                <button
                                  type='button'
                                  className='btn btn-sm btn-primary'
                                  onClick={() =>
                                    formik.setFieldValue('outcomeList', [
                                      ...formik.values.outcomeList,
                                      '',
                                    ])
                                  }
                                >
                                  <i className='ki-duotone ki-message-add fs-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>{' '}
                                  Add Outcome
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* content */}
                      <div className='col-12 mb-5'>
                        <div className='card'>
                          <div className='card-header p-1'>
                            <div className='card-title'>
                              <h2>Content</h2>
                            </div>
                          </div>
                          <div className='card-body p-1'>
                            <div className='col-12 '>
                              <div className='mb-3 border-bottom border-3'>
                                <div className='row mb-5'>
                                  {formik.values.content?.map((content, index) => (
                                    <React.Fragment key={index}>
                                      {/* Title */}
                                      <div className='col-11 mb-5'>
                                        <input
                                          type='text'
                                          placeholder='Title'
                                          name={`content[${index}].contentHead`}
                                          {...formik.getFieldProps(`content[${index}].contentHead`)}
                                          className={clsx('form-control', {
                                            'is-invalid':
                                              formik.touched.content?.[index]?.contentHead &&
                                              formik.errors.content?.[index]?.contentHead,
                                          })}
                                        />
                                      </div>
                                      {/* Add a button to remove this FAQ */}
                                      <div className='col-1  mb-5 '>
                                        <button
                                          type='button'
                                          className='btn btn-light-danger p-3  btn-sm '
                                          onClick={() =>
                                            formik.setFieldValue(
                                              'content',
                                              formik.values.content.filter((_, i) => i !== index)
                                            )
                                          }
                                        >
                                          <i className='ki-duotone ki-trash-square text-center p-0 fs-1'>
                                            <span className='path1'></span>
                                            <span className='path2'></span>
                                            <span className='path3'></span>
                                          </i>
                                        </button>
                                      </div>
                                      {/* Body */}
                                      <div className='col-11 text-start mb-5'>
                                        <input
                                          placeholder='Body'
                                          type='text'
                                          name={`content[${index}].contentBody`}
                                          {...formik.getFieldProps(`content[${index}].contentBody`)}
                                          className={clsx('form-control', {
                                            'is-invalid':
                                              formik.touched.content?.[index]?.contentBody &&
                                              formik.errors.content?.[index]?.contentBody,
                                          })}
                                        />
                                      </div>
                                    </React.Fragment>
                                  ))}
                                  {/* Add a button to add a new content */}
                                  <div className='col-12 text-end'>
                                    <button
                                      type='button'
                                      className='btn btn-sm btn-primary'
                                      onClick={() =>
                                        formik.setFieldValue('content', [
                                          ...formik.values.content,
                                          {contentHead: '', contentBody: ''},
                                        ])
                                      }
                                    >
                                      <i className='ki-duotone ki-message-add fs-1'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                        <span className='path3'></span>
                                      </i>{' '}
                                      Add Content
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* FAQs */}
                      <div className='col-12 mb-5'>
                        <div className='card'>
                          <div className='card-header p-1'>
                            <div className='card-title'>
                              <h2>FAQs</h2>
                            </div>
                          </div>
                          <div className='card-body p-1'>
                            <div className='col-12'>
                              <div className='mb-3 border-bottom border-3'>
                                <div className='row'>
                                  {formik.values.faqs?.map((faq, index) => (
                                    <React.Fragment key={index}>
                                      {/* Query */}
                                      <div className='col-11   mb-5'>
                                        <input
                                          type='text'
                                          placeholder='Query'
                                          name={`faqs[${index}].query`}
                                          {...formik.getFieldProps(`faqs[${index}].query`)}
                                          className={clsx('form-control', {
                                            'is-invalid':
                                              formik.touched.faqs?.[index]?.query &&
                                              formik.errors.faqs?.[index]?.query,
                                          })}
                                        />
                                      </div>
                                      {/* Add a button to remove this FAQ */}
                                      <div className='col-1 mb-5 text-end'>
                                        <button
                                          type='button'
                                          className='btn btn-light-danger p-3  btn-sm '
                                          onClick={() =>
                                            formik.setFieldValue(
                                              'faqs',
                                              formik.values.faqs.filter((_, i) => i !== index)
                                            )
                                          }
                                        >
                                          <i className='ki-duotone ki-trash-square text-center p-0 fs-1'>
                                            <span className='path1'></span>
                                            <span className='path2'></span>
                                            <span className='path3'></span>
                                          </i>
                                        </button>
                                      </div>
                                      {/* Response */}
                                      <div className='col-11 mb-5'>
                                        <textarea
                                          rows={2}
                                          placeholder='Response'
                                          name={`faqs[${index}].reply`}
                                          {...formik.getFieldProps(`faqs[${index}].reply`)}
                                          className={clsx('form-control', {
                                            'is-invalid':
                                              formik.touched.faqs?.[index]?.reply &&
                                              formik.errors.faqs?.[index]?.reply,
                                          })}
                                        />
                                      </div>
                                    </React.Fragment>
                                  ))}
                                  {/* Add a button to add a new FAQ */}
                                  <div className='col-12 mb-5 text-end'>
                                    <button
                                      type='button'
                                      className='btn btn-primary btn-sm'
                                      onClick={() =>
                                        formik.setFieldValue('faqs', [
                                          ...formik.values.faqs,
                                          {query: '', reply: ''},
                                        ])
                                      }
                                    >
                                      <i className='ki-duotone ki-message-add fs-1'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                        <span className='path3'></span>
                                      </i>{' '}
                                      Add FAQ
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-12'>
                        <button
                          data-bs-dismiss='modal'
                          type='submit'
                          className='btn  btn-primary'
                          disabled={formik.isSubmitting || !formik.isValid ? true : false}
                          onClick={() => {
                            setValues({
                              ...value,
                              semesterId: data?.semesterId,
                              subjectId: data?.subjectId,
                            })
                          }}
                        >
                          {flag === 'Add' ? (
                            <i className='ki-duotone ki-message-add fs-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                          ) : (
                            <i className='ki-duotone ki-message-edit fs-1'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                          )}
                          {flag} {value?.name}
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {value?.name !== 'Subject' && (
                  <div className='row'>
                    <div className='col-12 mb-5'>
                      <input
                        type='text'
                        className='form-control'
                        name={name}
                        value={value?.inputValue}
                        onChange={handleChange}
                        placeholder={`Enter ${value?.name} name`}
                      />
                    </div>
                    <div className='col-12'>
                      <button
                        data-bs-dismiss='modal'
                        className='btn btn-primary'
                        onClick={() => {
                          if (flag === 'Add') {
                            if (value?.name === 'Stream') {
                              handleAddStream(value?.inputValue)
                            } else if (value?.name === 'Class') {
                              handleAddClass(data, value?.inputValue)
                            } else if (value?.name === 'Semester') {
                              handleAddSemester(data?.classId, value?.inputValue)
                            }
                          } else {
                            if (value?.name === 'Stream') {
                              handleEdit(data?.streamId, value?.inputValue)
                            } else if (value?.name === 'Class') {
                              handleEditClass(data?.streamId, data?.classId, value?.inputValue)
                            } else if (value?.name === 'Semester') {
                              handleEditSemester(data?.classId, data?.semesterId, value?.inputValue)
                            }
                          }
                        }}
                      >
                        {flag === 'Add' ? (
                          <i className='ki-duotone ki-message-add fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        ) : (
                          <i className='ki-duotone ki-message-edit fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        )}
                        {flag} {value?.name}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
const DiviSionModal = ({selected}) => {
  const [formData, setFormData] = useState({})
  const dispatch = useDispatch()
  const {division} = useSelector((state: any) => state.AcademicSlice)

  useEffect(() => {
    if (selected && selected.classId) {
      setFormData((prevData) => ({
        ...prevData,
        [selected.classId]: prevData[selected.classId] || {division: []},
      }))
    }
  }, [selected])
  useEffect(() => {
    setFormData(division)
  }, [selected, division])
  const handleChange = (classId, index, event) => {
    const {name, value} = event.target
    const updatedDivision = [...formData[classId]?.division]
    updatedDivision[index] = {...updatedDivision[index], [name]: value} // Update the name property
    setFormData({
      ...formData,
      [classId]: {
        ...formData[classId],
        division: updatedDivision,
      },
    })
  }

  const addDivisionField = (classId) => {
    setFormData({
      ...formData,
      [classId]: {
        ...formData[classId],
        division: [...(formData[classId]?.division || []), {name: '', color: getRandomColor()}],
      },
    })
  }

  const removeDivisionField = (classId, index) => {
    const updatedDivision = [...formData[classId]?.division]
    updatedDivision.splice(index, 1)
    setFormData({
      ...formData,
      [classId]: {
        ...formData[classId],
        division: updatedDivision,
      },
    })
  }

  const handleSave = () => {
    dispatch(setDivision(formData)) // Dispatch the formData to your Redux store
  }

  // Function to generate a random color
  const getRandomColor = () => {
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'dark']
    const randomIndex = Math.floor(Math.random() * colors.length)
    return colors[randomIndex]
  }
  return (
    <>
      <div className='modal-dialog modal-dialog-centered mw-1000px'>
        <div className='modal-content'>
          <div className='modal-header py-7 d-flex justify-content-between'>
            <h2>Add or Modify Division</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <span className='svg-icon svg-icon-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect
                    opacity='0.5'
                    x='6'
                    y='17.3137'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(-45 6 17.3137)'
                    fill='black'
                  />
                  <rect
                    x='7.41422'
                    y='6'
                    width='16'
                    height='2'
                    rx='1'
                    transform='rotate(45 7.41422 6)'
                    fill='black'
                  />
                </svg>
              </span>
            </div>
          </div>
          <div className='modal-body scroll-y '>
            <div className='stepper stepper-links d-flex flex-column' id='Division'>
              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                  <form>
                    <div className='row'>
                      {formData[selected.classId]?.division.map((division, index) => (
                        <div key={index} className='mb-3 col-6'>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter Division Name'
                              id={`divisionName${index}`}
                              name='name'
                              value={division.name}
                              onChange={(e) => handleChange(selected.classId, index, e)}
                            />
                            <button
                              className='btn btn-light-danger p-3  btn-sm '
                              type='button'
                              onClick={() => removeDivisionField(selected.classId, index)}
                            >
                              <i className='ki-duotone ki-trash-square text-center p-0 fs-1'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    {formData[selected.classId] && formData[selected.classId]?.division.length > 0 && (
                      <div
                        className='btn btn-primary me-5'
                        data-bs-dismiss='modal'
                        onClick={handleSave}
                      >
                        <i className='ki-duotone ki-double-check-circle fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        Save
                      </div>
                    )}
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={() => addDivisionField(selected.classId)}
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                      Add Division
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AddModal, DiviSionModal}
