import {useEffect, useState} from 'react'
import ReactSelect from 'react-select'
import {useAddStatusMutation} from '../../../Api/allApi'
import moment from 'moment'
import Swal from 'sweetalert2'

/* eslint-disable jsx-a11y/anchor-is-valid */
const JobUpdateModal = ({data}) => {
  // Options for dropdown selects
  const [optionsData, setOptionData] = useState({
    status: [
      {label: 'Applied', value: 'applied'},
      {label: 'Offered', value: 'offered'},
      {label: 'Rejected', value: 'rejected'},
      {label: 'Pending', value: 'pending'},
    ],
    statusId: '',
    selectedStatus: {value: '', label: ''},
  })
  // add status api
  const [updateStatus, result] = useAddStatusMutation()
  const {isSuccess, isLoading, isError, error} = result
  const handleSubmit = () => {
    const today = new Date()
    let payload = {
      dbId: 0,
      jobId: data?.dbId,
      studentId: 0,
      appliedOn: moment(today).format('FFFF/MM/DD'),
      status: optionsData?.statusId,
    }
    if (optionsData?.statusId !== '') {
      updateStatus(payload)
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Please Select Status First',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  // call when api returns 200
  useEffect(() => {
    if (isSuccess && !isLoading) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Status Updated..',
        showConfirmButton: false,
        timer: 1500,
      })
      setOptionData((oldValue) => ({
        ...oldValue,
        statusId: '',
        selectedStatus: {value: '', label: ''},
      }))
    }
  }, [isSuccess, isLoading])
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
      setOptionData((oldValue) => ({
        ...oldValue,
        statusId: '',
        selectedStatus: {value: '', label: ''},
      }))
    }
  }, [isError, error?.error])
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>Update Job Status</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y' style={{height: '300px'}}>
          <div className='stepper stepper-links d-flex flex-column' id='Update'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className='col-4 mb-5'>
                        <h4>Title</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.title}</div>
                      <div className='col-4 mb-5'>
                        <h4>Salary</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.salary}</div>
                      <div className='col-4 mb-5'>
                        <h4>Job Date</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.publishedAt}</div>
                      <div className='col-4 mb-5'>
                        <h4>Organization Details</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>
                        <div className='symbol me-2 symbol-35px symbol-circle text-center'>
                          <img src={data?.organisationDetails?.organisationImg} alt='Pic' />
                        </div>
                        <h4>{data?.organisationDetails?.organisationName}</h4>
                        <h6>{data?.organisationDetails?.industry}</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='row'>
                      <div className='col-4 mb-5'>
                        <h4>Skills</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.skills}</div>
                      <div className='col-4 mb-5'>
                        <h4>Job Summery</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.jobSummary}</div>
                      <div className='col-4 mb-5'>
                        <h4>Experience Level</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.experienceLevel}</div>
                      <div className='col-4 mb-5'>
                        <h4>Contract Type</h4>
                      </div>
                      <div className='col-8 mb-5 text-start'>{data?.contractType}</div>
                      <div className='col-8 mb-5'>
                        <ReactSelect
                          value={
                            optionsData?.selectedStatus?.label !== '' &&
                            optionsData?.selectedStatus?.value !== ''
                              ? optionsData?.selectedStatus
                              : null
                          }
                          options={optionsData?.status}
                          styles={{
                            option: (base) => ({
                              ...base,
                              cursor: 'pointer',
                              margin: '0px',
                              color: '#7e8299',
                              fontWeight: 'bold',
                              background: 'white', // this was the mistake (I needed to remove this)
                              ':hover': {
                                color: '#009ef7',
                                backgroundColor: '#f1faff',
                              },
                            }),
                          }}
                          onChange={(e: any) => {
                            setOptionData({
                              ...optionsData,
                              statusId: e.value,
                              selectedStatus: {label: e.label, value: e.value},
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 text-end'>
                    <button
                      className='btn btn-primary'
                      disabled={optionsData?.statusId === '' ? true : false}
                      onClick={() => handleSubmit()}
                    >
                      Update Status
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {JobUpdateModal}
