import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import './faculty.css'
import clsx from 'clsx'
import {
  useAddFacultyMutation,
  useLazyFacultyQuery,
  useLazyGetCertificationQuery,
  useLazyPublisherQuery,
} from '../../Api/allApi'
import {Link, useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setFaculty} from '../../redux/FacultySlice'
import Swal from 'sweetalert2'
import moment from 'moment'
import CreatableSelect from 'react-select'
import {setCertification, setPublisher} from '../../redux/achivementSlice'
import {useSelector} from 'react-redux'
const Faculty = Yup.object().shape({
  firstName: Yup.string().required('firstName is required'),
  lastName: Yup.string().required('lastName is required'),
  title: Yup.string().required('title is required'),
  highest_education: Yup.string().required('highest education is required'),
  years_of_experience: Yup.string().required('years_of_experience is required'),
  about: Yup.string().required('about is required'),
  facultyEnrollementId: Yup.string()
    .required('facultyEnrollementId is required')
    .matches(/^[a-zA-Z0-9]*$/, 'faculty Enrollement Id should only contain A-Z or 0-9 characters'),
})

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function AddFaculty() {
  // get data from redux
  const {publisher} = useSelector((state: any) => state.achievementSlice)
  const {certification} = useSelector((state: any) => state.achievementSlice)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const today = new Date().toISOString().split('T')[0]
  const [loading, setLoading] = useState(false)
  const [array, setArray]: any = useState({
    articles: {
      title: '',
      articleImg: '',
      articleLink: '',
      articleDate: '',
      shortDescr: '',
    },
    awards: {
      title: '',
      awardYear: 0,
      shortDescr: '',
    },
    certification: {
      certificationName: '',
      issuerDetails: '',
      issuerName: '',
      issueDate: '',
      certificateDoesNotExpire: false,
      credentialImg: '',
      credentialID: '',
      shortDescr: '',
      credentialURL: '',
    },
    publication: {
      mediumIcon: '',
      publicationDate: '',
      publicationLink: '',
      shortDescr: '',
      title: '',
    },
    facultyArticles: [],
    facultyAwards: [],
    facultyCertification: [],
    facultyPublication: [],
  })
  const [initialValues] = useState({
    firstName: '',
    lastName: '',
    title: '',
    highest_education: '',
    years_of_experience: '',
    facultyEnrollementId: '',
    about: '',
  })
  // const [filterData, setFilterData]: any = useState({
  //   stream: [],
  //   streamId: 0,
  //   selectedStream: {value: '', label: ''},
  //   class: [],
  //   classId: 0,
  //   selectedClass: {value: '', label: ''},
  //   semester: [],
  //   semesterId: 0,
  //   selectedSemester: {value: '', label: ''},
  //   subject: [],
  //   subjectId: 0,
  // })
  const [index, setIndex]: any = useState({
    publication: null,
    Articles: null,
    certificate: null,
    Awards: null,
  })
  const [buttonValue, SetButtonValue]: any = useState({
    publication: 'Add',
    Articles: 'Add',
    certificate: 'Add',
    Awards: 'Add',
  })
  const [list, setList] = useState({
    publisher: [],
    certification: [],
  })
  const [flag, setFlag] = useState({
    facultyArticles: false,
    facultyAwards: false,
    facultyCertification: false,
    facultyPublication: false,
    story: false,
    publication: false,
    Articles: false,
    certificate: false,
    Awards: false,
  })
  // onchange for publication
  const onPublicationChange: any = (e) => {
    const currentDate: any = new Date()
    if (e.target.name === 'publicationDate') {
      const selectedDate: any = moment(e.target.value).format('YYYY-MM-DD')
      if (selectedDate > moment(currentDate).format('YYYY-MM-DD')) {
        // Handle the validation error, for example, display an error message
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: "You can't select publication data grater than today's date",
          showConfirmButton: false,
          timer: 1500,
        })
      } else {
        setArray({
          ...array,
          publication: {
            ...array.publication,
            publicationDate: selectedDate,
          },
        })
      }
    } else {
      setArray({
        ...array,
        publication: {
          ...array?.publication,
          [e.target.name]: e.target?.value,
        },
      })
    }
    setFlag({...flag, facultyPublication: false})
  }
  // onchange for Articles
  const onArticlesChange: any = (e) => {
    if (e.target.name === 'articleDate') {
      setArray({
        ...array,
        articles: {
          ...array?.articles,
          [e.target.name]: moment(e.target?.value).format('YYYY-DD-MM'),
        },
      })
    } else {
      setArray({
        ...array,
        articles: {
          ...array?.articles,
          [e.target.name]: e.target?.value,
        },
      })
    }
    setFlag({...flag, facultyArticles: false})
  }
  // onchange for certification
  const onCertificationChange: any = (e) => {
    if (e.target.name === 'expirationDate' && e.target.name === 'issueDate') {
      const updatedArray = {...array}

      const selectedData = moment(e.target?.value).format('YYYY-DD-MM')
      updatedArray.certification[e.target.name] = selectedData

      setArray(updatedArray)
    }

    setArray({
      ...array,
      certification: {
        ...array?.certification,
        [e.target.name]: e.target?.value,
      },
    })
    setFlag({...flag, facultyCertification: false})
  }
  // onchange for Awards
  const onAwardsChange: any = (e) => {
    setArray({
      ...array,
      awards: {
        ...array?.awards,
        [e.target.name]: e.target?.value,
      },
    })
    setFlag({...flag, facultyAwards: false})
  }
  const [images, setImages]: any = useState({})
  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setImages({
        ...images,
        [e.target.name]: base64String,
      })
      if (e.target.name === 'articleImg') {
        setArray({
          ...array,
          articles: {
            ...array?.articles,
            [e.target.name]: base64String,
          },
        })
      }
      if (e.target.name === 'credentialImg') {
        setArray({
          ...array,
          certification: {
            ...array?.certification,
            [e.target.name]: base64String,
          },
        })
      }
      if (e.target.name === 'issueByImg') {
        setArray({
          ...array,
          certification: {
            ...array?.certification,
            [e.target.name]: base64String,
          },
        })
      }
      if (e.target.name === 'mediumIcon') {
        setArray({
          ...array,
          publication: {
            ...array?.publication,
            [e.target.name]: base64String,
          },
        })
      }
    }
    if (file) {
      reader.readAsDataURL(file)
      setImages({
        ...images,
        [e.target.name]: file,
      })
    }
  }
  // filter api
  // const [filterTrigger, filterResult] = useLazyAcademicFilterQuery()
  // const {isSuccess: isFilter, isFetching: isNotFilter} = filterResult
  // add Faculty
  const [addFaculty, result] = useAddFacultyMutation()
  const {isSuccess, isLoading, isError, error} = result
  // all faculty trigger
  const [facultyTrigger, facultyResult] = useLazyFacultyQuery()
  const {isSuccess: isASuccess, isFetching} = facultyResult
  // publisher api
  const [PublisherTrigger, PublisherResult] = useLazyPublisherQuery()
  const {isSuccess: isPublisher, isFetching: isNotPublisher} = PublisherResult
  // certificate api
  const [CertificationTrigger, CertificateResult] = useLazyGetCertificationQuery()
  const {isSuccess: isCertification, isFetching: isNotCertification} = CertificateResult
  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    validationSchema: Faculty,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload: any = {}
        payload = {
          firstName: values?.firstName,
          lastName: values?.lastName,
          title: values?.title,
          facultyEnrollementId: values.facultyEnrollementId,
          highestEducation: values?.highest_education,
          yearsOfExperience: values?.years_of_experience,
          facultyImg: images?.facultyimage,
          about: values?.about,
          facultyArticles: array?.facultyArticles,
          facultyAwards: array?.facultyAwards,
          facultyCertification: array?.facultyCertification,
          facultyPublications: array?.facultyPublication,
        }
        addFaculty(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    CertificationTrigger({})
    PublisherTrigger({})
  }, [PublisherTrigger, CertificationTrigger])

  // call when api calling done successfully
  useEffect(() => {
    // Effect for Faculty Trigger
    if (isSuccess && !isLoading) {
      facultyTrigger({})
    }

    // Effect for Faculty Success and Navigation
    if (isASuccess && !isFetching && isSuccess) {
      dispatch(setFaculty(facultyResult?.data?.facultyList))
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: result?.data?.detail ? result?.data?.detail : 'Faculty Added successfully',
        showConfirmButton: false,
        timer: 1500,
      })
      navigate('/faculty')
    }

    // Effect for Publisher
    if (isPublisher && !isNotPublisher) {
      dispatch(setPublisher(PublisherResult?.data?.publisherList))
    }

    // Effect for Certification
    if (isCertification && !isNotCertification) {
      dispatch(setCertification(CertificateResult?.data?.certificateIssuerList))
    }
  }, [
    isSuccess,
    isLoading,
    result?.data?.detail,
    facultyTrigger,
    isASuccess,
    isFetching,
    dispatch,
    navigate,
    facultyResult,
    isPublisher,
    isNotPublisher,
    PublisherResult,
    isCertification,
    isNotCertification,
    CertificateResult,
  ])
  // useEffect(() => {
  //   if (isFilter && !isNotFilter) {
  //     const data = filterResult?.data
  //     let stream = data.stream?.map((item) => {
  //       return {value: item?.dbId, label: item?.name}
  //     })
  //     setFilterData((prevData) => {
  //       return {...prevData, stream: stream ? stream : []}
  //     })
  //   }
  // }, [isFilter, isNotFilter, filterResult?.data])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error])
  useEffect(() => {
    let ArrayPub: any = []
    let array: any = []
    if (publisher?.length !== 0) {
      publisher?.map((item: any, index) => {
        ArrayPub.push({value: item?.dbId, label: item?.publishingMedium})
        if (publisher?.length === index + 1) {
          ArrayPub.push({value: 'other', label: 'other'})
        }
        return item
      })
    }
    if (certification?.length !== 0) {
      certification?.map((item: any, index) => {
        array.push({value: item?.dbId, label: item?.issuerName})
        if (certification?.length === index + 1) {
          array.push({value: 'other', label: 'other'})
        }
        return item
      })
    }
    setList((oldList) => ({
      ...oldList,
      certification: array,
      publisher: ArrayPub,
    }))
  }, [publisher, certification])
  // submit for publications
  const AddPublication = () => {
    if (
      array?.publication?.publisher !== '' &&
      array?.publication?.title !== '' &&
      array?.publication?.publicationLink !== '' &&
      array?.publication?.publicationDate !== ''
    ) {
      if (index?.publication === null) {
        setArray({
          ...array,
          facultyPublication: [
            ...array?.facultyPublication,
            {
              title: array?.publication?.title,
              publicationDate: array?.publication?.publicationDate,
              publisherId: array?.publication?.publisherId,
              publisherDetails: {
                publishingMedium: array?.publication?.publisherDetails?.publishingMedium
                  ? array?.publication?.publisherDetails?.publishingMedium
                  : '',
              },
              publicationLink: array?.publication?.publicationLink,
              shortDescr: array?.publication?.shortDescr,
              mediumIcon: array?.publication?.mediumIcon,
            },
          ],
          publication: {
            mediumIcon: '',
            publicationDate: '',
            publicationLink: '',
            shortDescr: '',
            title: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          publication: 'Add',
        })
        let data = array?.facultyPublication
        data[index?.publication] = array?.publication
        setArray({
          ...array,
          facultyPublication: data,
          publication: {
            medium: '',
            mediumIcon: '',
            publicationDate: '',
            publicationLink: '',
            shortDescr: '',
            title: '',
          },
        })
        setIndex({
          ...index,
          publication: null,
        })
      }
    }
  }
  // submit for Articles
  const AddArticles = () => {
    if (
      array?.articles?.title !== '' &&
      array?.articles?.articleImg !== '' &&
      array?.articles?.articleLink !== '' &&
      array?.articles?.articleDate !== '' &&
      array?.articles?.shortDescr !== ''
    ) {
      if (index?.Articles === null) {
        setArray({
          ...array,
          facultyArticles: [
            ...array?.facultyArticles,
            {
              title: array?.articles?.title,
              articleDate: array?.articles?.articleDate,
              shortDescr: array?.articles?.shortDescr,
              articleImg: array?.articles?.articleImg,
              articleLink: array?.articles?.articleLink,
            },
          ],
          articles: {
            title: '',
            articleImg: null,
            articleLink: '',
            articleDate: '',
            shortDescr: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          Articles: 'Add',
        })
        let data: any = array?.facultyArticles
        data[index?.Articles] = array?.articles
        setArray({
          ...array,
          facultyArticles: data,
          articles: {
            title: '',
            articleImg: null,
            articleLink: '',
            articleDate: '',
            shortDescr: '',
          },
        })
        setIndex({
          ...index,
          Articles: null,
        })
      }
    }
  }
  // submit for certification
  const AddCertificate = () => {
    if (
      array?.certification?.certificationName !== '' &&
      array?.certification?.shortDescr !== '' &&
      array?.certification?.issueDate !== '' &&
      array?.certification?.credentialImg !== '' &&
      array?.certification?.credentialID !== '' &&
      array?.certification?.credentialURL !== ''
    ) {
      if (index?.certificate === null) {
        setArray({
          ...array,
          facultyCertification: [
            ...array?.facultyCertification,
            {
              certificationName: array?.certification?.certificationName,
              issuerId: array?.certification?.issuerId,
              issuerDetails: {
                issuerName: array?.certification?.issuerName,
              },
              issueDate: array?.certification?.issueDate,
              expirationDate: array?.certification?.expirationDate,
              credentialId: array?.certification?.credentialID,
              credentialUrl: array?.certification?.credentialURL,
              credentialImg: array?.certification?.credentialImg,
              shortDescr: array?.certification?.shortDescr,
              certificateDoesNotExpire: array?.certification?.certificateDoesNotExpire
                ? true
                : null,
            },
          ],
          certification: {
            certificationName: '',
            issueByImg: '',
            shortDescr: '',
            issueDate: '',
            credentialImg: '',
            credentialID: '',
            credentialURL: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          certificate: 'Add',
        })
        let data = array?.facultyCertification
        data[index?.certificate] = array?.certification
        setArray({
          ...array,
          facultyCertification: data,
          certification: {
            certificationName: '',
            issueByImg: '',
            shortDescr: '',
            issueDate: '',
            credentialImg: '',
            credentialID: '',
            credentialURL: '',
          },
        })
        setIndex({
          ...index,
          certificate: null,
        })
      }
    }
  }
  // submit for certification
  const AddAwards = () => {
    if (
      array?.awards?.title !== '' &&
      array?.awards?.awardYear !== '' &&
      array?.awards?.shortDescr !== ''
    ) {
      if (index?.Awards === null) {
        setArray({
          ...array,
          facultyAwards: [
            ...array?.facultyAwards,
            {
              title: array?.awards?.title,
              awardYear: Number(array?.awards?.awardYear),
              shortDescr: array?.awards?.shortDescr,
            },
          ],
          awards: {
            title: '',
            awardYear: '',
            shortDescr: '',
          },
        })
      } else {
        SetButtonValue({
          ...buttonValue,
          Awards: 'Add',
        })
        let data = array?.facultyAwards
        data[index?.Awards] = array?.awards
        setArray({
          ...array,
          facultyAwards: data,
          awards: {
            title: '',
            awardYear: '',
            shortDescr: '',
          },
        })
        setIndex({
          ...index,
          Awards: null,
        })
      }
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your details have been Saved',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  // const handleStreamSelect = (e) => {
  //   let classObj = []
  //   const classData = filterResult.data.class[e.value]
  //   if (classData && classData.length > 0) {
  //     classObj = classData.map((item) => ({
  //       value: item.dbId,
  //       label: item.name,
  //     }))
  //     let data = {
  //       ...filterData,
  //       streamId: e.value,
  //       selectedStream: {label: e.label, value: e.value},
  //       class: classObj,
  //     }
  //     setFilterData(data)
  //   } else {
  //     let data = {
  //       ...filterData,
  //       streamId: e.value,
  //       selectedStream: {label: e.label, value: e.value},
  //       class: [],
  //       classId: 0,
  //       selectedClass: {value: '', label: ''},
  //       semester: [],
  //       semesterId: 0,
  //       selectedSemester: {value: '', label: ''},
  //       selectedSubject: {value: '', label: ''},
  //       subject: [],
  //       subjectId: 0,
  //     }
  //     setFilterData(data)
  //   }
  // }
  // const handleCLassSelect = (e) => {
  //   const semesterData = filterResult.data.semester[e.value]
  //   let semesterOptions = []
  //   if (semesterData && semesterData.length > 0) {
  //     semesterOptions = semesterData.map((item) => ({
  //       value: item.dbId,
  //       label: item.name,
  //     }))
  //     let data = {
  //       ...filterData,
  //       classId: e.value,
  //       selectedClass: {label: e.label, value: e.value},
  //       semester: semesterOptions,
  //     }
  //     setFilterData(data)
  //   } else {
  //     let data = {
  //       ...filterData,
  //       classId: e.value,
  //       selectedClass: {label: e.label, value: e.value},
  //       selectedSubject: {label: '', value: ''},
  //       semester: [],
  //       subject: [],
  //       subjectId: 0,
  //       semesterId: 0,
  //       selectedSemester: {value: '', label: ''},
  //     }
  //     setFilterData(data)
  //   }
  // }
  // const handleSemesterSelect = (e) => {
  //   let subject = []
  //   const subjectData = filterResult.data.subject[e.value]
  //   if (subjectData && subjectData.length > 0) {
  //     subject = subjectData.map((item) => ({
  //       value: item.dbId,
  //       label: item.name,
  //     }))
  //     let data = {
  //       ...filterData,
  //       semesterId: e.value,
  //       selectedSemester: {label: e.label, value: e.value},
  //       subject: subject,
  //     }
  //     setFilterData(data)
  //   } else {
  //     let data = {
  //       ...filterData,
  //       semesterId: e.value,
  //       selectedSemester: {label: e.label, value: e.value},
  //       selectedSubject: {label: '', value: ''},
  //       subject: [],
  //       subjectId: 0,
  //     }
  //     setFilterData(data)
  //   }
  // }
  return (
    <>
      <div className='card card-custom mb-5'>
        <div className='card-header d-flex justify-content-between'>
          <h2 className='card-title fs-2 fw-bold'>
            <Link to={'/faculty'}>
              <button
                className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                aria-label='Back'
                data-bs-original-title='Back'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </Link>
            Add Faculty
          </h2>
        </div>
      </div>
      <div className='row card '>
        <div className='card-body'>
          <div className='col-12 shadow rounded'>
            <div className='card mb-5 card-custom'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-12 mb-7'>
                    <div className='fs-6 text-muted'>* indicates required </div>
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      First Name<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='firstName'
                      {...formik.getFieldProps('firstName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                        {
                          'is-valid': formik.touched.firstName && !formik.errors.firstName,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Last Name<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='lastName'
                      {...formik.getFieldProps('lastName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                        {
                          'is-valid': formik.touched.lastName && !formik.errors.lastName,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Faculty Enrollment ID<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='facultyEnrollementId'
                      {...formik.getFieldProps('facultyEnrollementId')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.facultyEnrollementId &&
                            formik.errors.facultyEnrollementId,
                        },
                        {
                          'is-valid':
                            formik.touched.facultyEnrollementId &&
                            !formik.errors.facultyEnrollementId,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Faculty Image<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='file'
                      name='facultyimage'
                      onChange={onHandleChangeFile}
                      className={clsx('form-control bg-transparent', {
                        'is-valid': images?.facultyimage,
                      })}
                    />
                    {images?.facultyimage ? (
                      <img
                        src={images?.facultyimage}
                        className='w-50px h-50px mt-2 rounded'
                        alt='credential'
                      />
                    ) : null}
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Position<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='title'
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.title && formik.errors.title},
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Highest Qualification<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='highest_education'
                      {...formik.getFieldProps('highest_education')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.highest_education && formik.errors.highest_education,
                        },
                        {
                          'is-valid':
                            formik.touched.highest_education && !formik.errors.highest_education,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Experience<span className='text-danger'>*</span>
                    </label>
                    <input
                      name='years_of_experience'
                      type='text'
                      placeholder='5+ years'
                      {...formik.getFieldProps('years_of_experience')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.years_of_experience && formik.errors.years_of_experience,
                        },
                        {
                          'is-valid':
                            formik.touched.years_of_experience &&
                            !formik.errors.years_of_experience,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label>
                      Faculty Information<span className='text-danger'>*</span>
                    </label>
                    <textarea
                      name='about'
                      {...formik.getFieldProps('about')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.about && formik.errors.about,
                        },
                        {
                          'is-valid': formik.touched.about && !formik.errors.about,
                        }
                      )}
                      id='exampleFormControlTextarea1'
                      rows={3}
                    ></textarea>
                  </div>
                  {/* stream */}
                  {/* <div className='mb-5 col-md-6 '>
                    <label className='from-label'>Select Stream:</label>
                    <Select
                      placeholder='select Stream'
                      value={
                        filterData?.selectedStream?.label !== '' &&
                        filterData?.selectedStream?.value !== ''
                          ? filterData?.selectedStream
                          : null
                      }
                      options={filterData?.stream}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', 
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      onChange={(e: any) => {
                        handleStreamSelect(e)
                      }}
                    />
                  </div> */}
                  {/* class */}
                  {/* <div className='col-md-6 mb-5'>
                    <label className='from-label'>Select Class:</label>
                    <Select
                      placeholder='select Class'
                      value={
                        filterData?.selectedClass?.label !== '' &&
                        filterData?.selectedClass?.value !== ''
                          ? filterData?.selectedClass
                          : null
                      }
                      options={filterData?.class}
                      isDisabled={filterData?.streamId === 0 ? true : false}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      onChange={(e: any) => {
                        handleCLassSelect(e)
                      }}
                    />
                  </div> */}
                  {/* semester */}
                  {/* <div className='mb-5 col-md-6'>
                    <label className='from-label'>Select Semester:</label>
                    <Select
                      placeholder='select semester'
                      value={
                        filterData?.selectedSemester?.label !== '' &&
                        filterData?.selectedSemester?.value !== ''
                          ? filterData?.selectedSemester
                          : null
                      }
                      isDisabled={filterData?.classId === 0 ? true : false}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      options={filterData?.semester}
                      onChange={(e: any) => {
                        handleSemesterSelect(e)
                      }}
                    />
                  </div> */}
                  {/* subject */}
                  {/* <div className='col-md-6 mb-5'>
                    <label className='from-label'>Select Subject:</label>
                    <Select
                      value={
                        filterData?.selectedSubject?.label !== '' &&
                        filterData?.selectedSubject?.value !== ''
                          ? filterData?.selectedSubject
                          : null
                      }
                      isMulti
                      isDisabled={filterData?.semesterId === 0 ? true : false}
                      options={filterData?.subject}
                      styles={{
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      onChange={(selectedOptions) => {
                        // Map selected options to label-value pairs
                        const selectedValues = selectedOptions.map((option) => ({
                          label: option.label,
                          value: option.value,
                        }))
                        setFilterData({
                          ...filterData,
                          subjectIds: selectedValues.map((option) => option.value), // Update subjectIds array with selected values
                          selectedSubjects: selectedValues, // Update selectedSubjects array with selected options
                        })
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12'>
            <div className='row'>
              <div className='col-xl-6'>
                <div className='row'>
                  {/* publication */}
                  <div className='col-12'>
                    <div
                      className={`card mb-5 shadow card-custom ${
                        flag?.facultyPublication ? 'border border-danger border-2' : ''
                      }`}
                    >
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Publications</h2>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-12'>
                            {array?.facultyPublication?.length !== 0 && (
                              <div>
                                <div className='card border shadow mb-5 card-custom'>
                                  <div className='card-header '>
                                    <div
                                      className='card-toolbar w-100  text-end'
                                      style={{margin: 'auto', display: 'unset'}}
                                    >
                                      <i
                                        className={`${
                                          flag?.publication
                                            ? 'fa-solid fa-check text-success'
                                            : 'fa-solid fa-pen'
                                        } fs-2`}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => {
                                          flag?.publication
                                            ? setFlag({...flag, publication: false})
                                            : setFlag({...flag, publication: true})
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                  <div className='card-body card-scroll h-200px  py-5'>
                                    <div className='row'>
                                      {array?.facultyPublication?.map((item, index) => {
                                        return (
                                          <div key={item._id} className='col-12 mb-5 border-bottom'>
                                            <div className='d-flex align-items-baseline justify-content-between'>
                                              <div className='d-md-flex' style={{gap: '20px'}}>
                                                <div className=' mb-5'>
                                                  <img
                                                    src={item?.mediumIcon}
                                                    className='text-center rounded symbol-badge symbol-35px'
                                                    alt=''
                                                    style={{
                                                      height: '50px',
                                                      width: '50px',
                                                      objectFit: 'cover',
                                                    }}
                                                  />
                                                </div>
                                                <div className=''>
                                                  <h3 className='m-0 fs-5'>{item?.title}</h3>
                                                  <div className='fs-7'>
                                                    {item?.publicationDate}
                                                  </div>
                                                  <div className='fw-bold text-muted fs-8'>
                                                    {item?.shortDescr}
                                                  </div>
                                                  <div className='text-muted fw-bold fs-8'>
                                                    medium: {item?.medium}
                                                  </div>
                                                  <a
                                                    href={item?.publicationLink}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                  >
                                                    <button
                                                      className='btn fs-8 mt-2 mb-5 px-2 py-2 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                                      style={{borderRadius: '20px'}}
                                                    >
                                                      Show Credential
                                                      <i className='fa-solid fa-arrow-up-right-from-square p-0 m-2 '></i>
                                                    </button>
                                                  </a>
                                                </div>
                                              </div>
                                              <div>
                                                {flag?.publication ? (
                                                  <div className='d-flex align-items-center'>
                                                    {/* <button
                                                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                                      type='button'
                                                    >
                                                      <i
                                                        className='fa-solid fa-pen fs-5 me-3 '
                                                        onClick={() => {
                                                          SetButtonValue({
                                                            ...buttonValue,
                                                            publication: 'Edit',
                                                          })
                                                          setArray({
                                                            ...array,
                                                            publication: item,
                                                          })
                                                          setIndex({
                                                            ...index,
                                                            publication: index,
                                                          })
                                                        }}
                                                      ></i>
                                                    </button> */}
                                                    <i
                                                      className='ki-duotone ki-trash-square fs-1 text-danger'
                                                      style={{cursor: 'pointer'}}
                                                      onClick={() => {
                                                        Swal.fire({
                                                          title: 'Confirm deletion?',
                                                          showDenyButton: false,
                                                          showCancelButton: true,
                                                          confirmButtonText:
                                                            "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                                          confirmButtonColor: '#009EF7',
                                                          cancelButtonText:
                                                            '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                                        }).then((result) => {
                                                          /* Read more about isConfirmed, isDenied below */
                                                          if (result.isConfirmed) {
                                                            let data = array?.facultyPublication
                                                            data.splice(index, 1)
                                                            setArray({
                                                              ...array,
                                                              facultyPublication: data,
                                                            })
                                                          } else if (result.isDenied) {
                                                            Swal.fire(
                                                              'Record is not deleted',
                                                              '',
                                                              'error'
                                                            )
                                                          }
                                                        })
                                                      }}
                                                    >
                                                      <span className='path1'></span>
                                                      <span className='path2'></span>
                                                      <span className='path3'></span>
                                                    </i>
                                                  </div>
                                                ) : null}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Publisher<span className='text-danger'>*</span>
                            </label>
                            <CreatableSelect
                              isClearable={false}
                              onChange={(e: any) => {
                                if (e.value !== 'other') {
                                  const filterData = publisher.find(
                                    (item) => item?.dbId === e?.value
                                  )
                                  setArray({
                                    ...array,
                                    publication: {
                                      ...array?.publication,
                                      publisherId: e.value,
                                      publisherName: e.label,
                                      mediumIcon: filterData?.publisherImg,
                                    },
                                  })
                                } else {
                                  setArray({
                                    ...array,
                                    publication: {
                                      ...array?.publication,
                                      publisherId: null,
                                      publisherName: e.label,
                                      mediumIcon: PublisherResult?.data?.defaultPublisherImg,
                                    },
                                  })
                                }
                              }}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  height: '100%',
                                  width: '100%',
                                }),
                                option: (base) => ({
                                  ...base,
                                  cursor: 'pointer',
                                  margin: '0px',
                                  boxShadow: 'unset',
                                  color: '#7e8299',
                                  fontWeight: 'bold',
                                  background: 'white',
                                  ':hover': {
                                    color: '#009ef7',
                                    backgroundColor: '#f1faff',
                                  },
                                }),
                              }}
                              options={list?.publisher}
                            />
                          </div>

                          <div className='col-12 d-flex flex-column mb-5'>
                            <label className='form-label'>
                              Medium Image<span className='text-danger'>*</span>
                            </label>
                            {array?.publication?.mediumIcon ? (
                              <img
                                src={array?.publication?.mediumIcon}
                                className='w-50px h-50px mt-2 rounded'
                                alt='credential'
                              />
                            ) : null}
                          </div>
                          {array?.publication?.publisherName === 'other' && (
                            <>
                              <div className='col-12 mb-5'>
                                <label className='form-label'>
                                  Publishing Medium<span className='text-danger'>*</span>
                                </label>
                                <input
                                  type='text'
                                  name='publishingMedium'
                                  value={array?.publication?.publisherDetails?.publishingMedium}
                                  onChange={(e) => {
                                    setArray({
                                      ...array,
                                      publication: {
                                        ...array?.publication,
                                        publisherDetails: {
                                          ...array?.publication?.publisherDetails,
                                          publishingMedium: e.target.value,
                                        },
                                      },
                                    })
                                  }}
                                  className={clsx('form-control bg-transparent')}
                                  id='exampleFormControlTextarea1'
                                />
                              </div>
                              <div className='col-12 mb-5'>
                                <label className='form-label'>
                                  Short Description <span className='text-danger'>*</span>
                                </label>
                                <textarea
                                  name='shortDescr'
                                  value={array?.publication?.shortDescr}
                                  onChange={(e) => {
                                    onPublicationChange(e)
                                  }}
                                  className={clsx('form-control bg-transparent')}
                                  id='exampleFormControlTextarea1'
                                  rows={3}
                                ></textarea>
                              </div>
                            </>
                          )}
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Title<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              name='title'
                              value={array?.publication?.title}
                              onChange={(e) => {
                                onPublicationChange(e)
                              }}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 mb-5  position-relative'>
                            <label className='form-label'>
                              Publication Date<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='date'
                              name='publicationDate'
                              id='publicationDate'
                              value={array?.publication?.publicationDate}
                              onChange={(e) => {
                                onPublicationChange(e)
                              }}
                              className={clsx('form-control bg-secondary p-0')}
                              max={today}
                            />
                            <div
                              className='position-absolute form-label'
                              style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                            >
                              <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                              {array?.publication?.publicationDate
                                ? array?.publication?.publicationDate
                                : 'Publication Date*'}
                            </div>
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Publication Link<span className='text-danger'>*</span>
                            </label>
                            <input
                              name='publicationLink'
                              type='text'
                              value={array?.publication?.publicationLink}
                              onChange={(e) => {
                                onPublicationChange(e)
                              }}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 text-end mb-5'>
                            <button
                              disabled={
                                array?.publication?.publisher !== '' &&
                                array?.publication?.title !== '' &&
                                array?.publication?.publicationLink !== '' &&
                                array?.publication?.publicationDate !== ''
                                  ? false
                                  : true
                              }
                              className='btn btn-primary'
                              type='button'
                              onClick={AddPublication}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.publication === 'Add'
                                    ? 'message-add'
                                    : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              {buttonValue?.publication}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Articles */}
                  <div className='col-12 '>
                    <div
                      className={`card mb-5 shadow card-custom ${
                        flag?.facultyArticles ? 'border border-danger border-2' : ''
                      }`}
                    >
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Articles</h2>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-12'>
                            {array?.facultyArticles?.length !== 0 && (
                              <div className='card border shadow mb-5 card-custom'>
                                <div className='card-header '>
                                  <div
                                    className='card-toolbar w-100 m-auto text-end'
                                    style={{display: 'unset'}}
                                  >
                                    <i
                                      className={`${
                                        flag?.Articles
                                          ? 'fa-solid fa-check text-success'
                                          : 'fa-solid fa-pen'
                                      } fs-2`}
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        flag?.Articles
                                          ? setFlag({...flag, Articles: false})
                                          : setFlag({...flag, Articles: true})
                                      }}
                                    ></i>
                                  </div>
                                </div>
                                <div className='card-body card-scroll h-200px  py-5'>
                                  <div className='row'>
                                    {array?.facultyArticles?.map((item, index) => {
                                      return (
                                        <div key={item._id} className='col-12 mb-5 border-bottom'>
                                          <div className='d-flex align-items-baseline justify-content-between'>
                                            <div className='d-md-flex' style={{gap: '20px'}}>
                                              <div className=' mb-5'>
                                                <img
                                                  src={item?.articleImg}
                                                  className='text-center rounded symbol-badge symbol-35px'
                                                  alt=''
                                                  style={{
                                                    height: '100px',
                                                    width: '100px',
                                                    objectFit: 'cover',
                                                  }}
                                                />
                                              </div>
                                              <div className=''>
                                                <h3 className='m-0 fs-5'>{item?.title}</h3>
                                                <div className='fs-7'>{item?.articleDate}</div>
                                                <div className='fw-bold text-muted fs-8'>
                                                  {item?.shortDescr}
                                                </div>
                                                <a
                                                  href={item?.articleLink}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                >
                                                  <button
                                                    className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                                    style={{borderRadius: '20px'}}
                                                  >
                                                    Show Credential
                                                    <i className='fa-solid fa-arrow-up-right-from-square  m-2'></i>
                                                  </button>
                                                </a>
                                              </div>
                                            </div>
                                            <div>
                                              {flag?.Articles ? (
                                                <div className='d-flex align-items-center'>
                                                  <button
                                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                                    type='button'
                                                  >
                                                    <i
                                                      className='fa-solid fa-pen fs-5 me-3 '
                                                      onClick={() => {
                                                        SetButtonValue({
                                                          ...buttonValue,
                                                          Articles: 'Edit',
                                                        })
                                                        setArray({...array, articles: item})
                                                        setIndex({
                                                          ...index,
                                                          Articles: index,
                                                        })
                                                      }}
                                                    ></i>
                                                  </button>
                                                  <i
                                                    className='ki-duotone ki-trash-square fs-1'
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                      let data = array?.facultyArticles
                                                      data.splice(index, 1)
                                                      setArray({
                                                        ...array,
                                                        facultyArticles: data,
                                                      })
                                                    }}
                                                  >
                                                    <span className='path1'></span>
                                                    <span className='path2'></span>
                                                    <span className='path3'></span>
                                                  </i>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='col-12 mb-7'>
                            <div className='fs-6 text-muted'>
                              <span className='text-danger'>*</span> indicates required{' '}
                            </div>
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Title<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              name='title'
                              onChange={(e) => {
                                onArticlesChange(e)
                              }}
                              value={array?.articles?.title}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Article Reference Image<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='file'
                              name='articleImg'
                              onChange={(e) => {
                                onHandleChangeFile(e)
                              }}
                              className={clsx('form-control bg-transparent')}
                            />
                            {array?.articles?.articleImg ? (
                              <img
                                src={array?.articles?.articleImg}
                                className='w-50px h-50px mt-2 rounded'
                                alt='credential'
                              />
                            ) : null}
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>Article Link*</label>
                            <input
                              type='text'
                              name='articleLink'
                              onChange={(e) => {
                                onArticlesChange(e)
                              }}
                              value={array?.articles?.articleLink}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>

                          <div className='col-12 mb-5 position-relative'>
                            <label className='form-label'>
                              Article Date<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='date'
                              name='articleDate'
                              id='articleDate'
                              onChange={(e) => {
                                onArticlesChange(e)
                              }}
                              value={array?.articles?.articleDate}
                              className={clsx('form-control bg-secondary p-0')}
                              max={today}
                            />
                            <div
                              className='position-absolute form-label'
                              style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                            >
                              <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                              {array?.articles?.articleDate
                                ? array?.articles?.articleDate
                                : 'Article Date*'}
                            </div>
                          </div>
                          <div className='col-12 mb-5'>
                            <label>
                              Short Description<span className='text-danger'>*</span>
                            </label>
                            <textarea
                              name='shortDescr'
                              onChange={(e) => {
                                onArticlesChange(e)
                              }}
                              value={array?.articles?.shortDescr}
                              className={clsx('form-control bg-transparent')}
                              id='exampleFormControlTextarea1'
                              rows={3}
                            ></textarea>
                          </div>
                          <div className='col-12 text-end mb-5'>
                            <button
                              className='btn btn-primary'
                              type='button'
                              disabled={
                                array?.articles?.title !== '' &&
                                array?.articles?.articleImg !== '' &&
                                array?.articles?.articleLink !== '' &&
                                array?.articles?.articleDate !== '' &&
                                array?.articles?.shortDescr !== ''
                                  ? false
                                  : true
                              }
                              onClick={AddArticles}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.Articles === 'Add' ? 'message-add' : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              {buttonValue?.Articles}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='row'>
                  {/* Certification */}
                  <div className='col-12'>
                    <div
                      className={` card shadow mb-5 card-custom ${
                        flag?.facultyCertification ? 'border border-danger border-2' : ''
                      }`}
                    >
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Certification</h2>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          <div className='col-12'>
                            {array?.facultyCertification?.length !== 0 && (
                              <div className='card card-custom shadow border mb-5'>
                                <div className='card-header '>
                                  <div
                                    className='card-toolbar w-100 m-auto text-end'
                                    style={{display: 'unset'}}
                                  >
                                    <i
                                      className={`${
                                        flag?.certificate
                                          ? 'fa-solid fa-check text-success'
                                          : 'fa-solid fa-pen'
                                      } fs-2`}
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        flag?.certificate
                                          ? setFlag({...flag, certificate: false})
                                          : setFlag({...flag, certificate: true})
                                      }}
                                    ></i>
                                  </div>
                                </div>
                                <div className='card-body card-scroll h-200px  py-5'>
                                  <div className='row'>
                                    {array?.facultyCertification?.map((item, index) => {
                                      return (
                                        <div key={item._id} className='col-12 mb-5 border-bottom'>
                                          <div className='d-flex align-items-baseline justify-content-between'>
                                            <div className='d-md-flex' style={{gap: '20px'}}>
                                              <div className=' mb-5'>
                                                <img
                                                  src={item?.credentialImg}
                                                  className='text-center rounded symbol-badge symbol-35px'
                                                  alt=''
                                                  style={{
                                                    height: '50px',
                                                    width: '50px',
                                                    objectFit: 'cover',
                                                  }}
                                                />
                                              </div>
                                              <div className=''>
                                                <h3 className='m-0 fs-5'>
                                                  {item?.certificationName}
                                                </h3>
                                                <div className='fs-7'>
                                                  {item?.expirationDate
                                                    ? `${item?.issueDate} - ${item?.expirationDate}`
                                                    : `${item?.issueDate} `}
                                                </div>
                                                <div className='fw-bold text-muted fs-8'>
                                                  {item?.issuedBy}
                                                </div>
                                                <div className='text-muted fw-bold fs-8'>
                                                  Credential ID {item?.credentialID}
                                                </div>
                                                <a
                                                  href={item?.credentialURL}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                >
                                                  <button
                                                    className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                                    style={{borderRadius: '20px'}}
                                                  >
                                                    Show Credential
                                                    <i className='fa-solid fa-arrow-up-right-from-square  m-2'></i>
                                                  </button>
                                                </a>
                                              </div>
                                            </div>
                                            <div>
                                              {flag?.certificate ? (
                                                <>
                                                  {/* <button
                                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                                    type='button'
                                                  >
                                                    <i
                                                      className='fa-solid fa-pen fs-5 me-3 '
                                                      onClick={() => {
                                                        setArray({...array, certification: item})
                                                        setIndex({
                                                          ...index,
                                                          certificate: index,
                                                        })
                                                        SetButtonValue({
                                                          ...buttonValue,
                                                          certificate: 'Edit',
                                                        })
                                                      }}
                                                    ></i>
                                                  </button> */}
                                                  <i
                                                    className='ki-duotone ki-trash-square fs-1'
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                      Swal.fire({
                                                        title: 'Confirm deletion?',
                                                        showDenyButton: false,
                                                        showCancelButton: true,
                                                        confirmButtonText:
                                                          "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                                        confirmButtonColor: '#009EF7',
                                                        cancelButtonText:
                                                          '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                                      }).then((result) => {
                                                        /* Read more about isConfirmed, isDenied below */
                                                        if (result.isConfirmed) {
                                                          let data = array?.facultyCertification
                                                          data.splice(index, 1)
                                                          setArray({
                                                            ...array,
                                                            facultyCertification: data,
                                                          })
                                                        } else if (result.isDenied) {
                                                          Swal.fire(
                                                            'Record is not deleted',
                                                            '',
                                                            'error'
                                                          )
                                                        }
                                                      })
                                                    }}
                                                  >
                                                    <span className='path1'></span>
                                                    <span className='path2'></span>
                                                    <span className='path3'></span>
                                                  </i>
                                                </>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='col-12 mb-7'>
                            <div className='fs-6 text-muted'>* indicates required </div>
                          </div>
                          <div className='col-12 mb-5'>
                            <div className='col-12 mb-5'>
                              <label className='form-label'>
                                Issuing organisation<span className='text-danger'>*</span>
                              </label>
                              <CreatableSelect
                                options={list?.certification}
                                isClearable={false}
                                onChange={(e: any) => {
                                  if (e.label !== 'other') {
                                    setArray({
                                      ...array,
                                      certification: {
                                        ...array?.certification,
                                        issuerId: e.value,
                                        issuerDetails: {
                                          issuerName: e.label,
                                        },
                                        issuerName: e.label,
                                      },
                                    })
                                  } else {
                                    setArray({
                                      ...array,
                                      certification: {
                                        ...array?.certification,
                                        issuerId: null,
                                        issuerName: '',
                                        issuerDetails: {
                                          issuerName: '',
                                        },
                                      },
                                    })
                                  }
                                }}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    height: '100%',
                                    width: '100%',
                                  }),
                                  option: (base) => ({
                                    ...base,
                                    cursor: 'pointer',
                                    margin: '0px',
                                    boxShadow: 'unset',
                                    color: '#7e8299',
                                    fontWeight: 'bold',
                                    background: 'white',
                                    ':hover': {
                                      color: '#009ef7',
                                      backgroundColor: '#f1faff',
                                    },
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          {array?.certification?.issuerId === null && (
                            <div className='col-12 mb-5'>
                              <label className='form-label'>
                                Issuer<span className='text-danger'>*</span>
                              </label>
                              <input
                                type='text'
                                name='issuerName'
                                onChange={(e) => {
                                  onCertificationChange(e)
                                }}
                                className={clsx('form-control bg-transparent')}
                              />
                            </div>
                          )}
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Title<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              name='certificationName'
                              onChange={(e) => {
                                onCertificationChange(e)
                              }}
                              value={array?.certification?.certificationName}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Short Description <span className='text-danger'>*</span>
                            </label>
                            <textarea
                              name='shortDescr'
                              onChange={(e) => {
                                onCertificationChange(e)
                              }}
                              value={array?.certification?.shortDescr}
                              className={clsx('form-control bg-transparent')}
                              rows={3}
                            ></textarea>
                          </div>

                          <div className='col-md-6 mb-5 position-relative'>
                            <label className='form-label'>
                              Issue Date<span className='text-danger'>*</span>
                            </label>
                            <input
                              name='issueDate'
                              id='issueDate'
                              type='date'
                              onChange={(e) => {
                                onCertificationChange(e)
                              }}
                              value={array?.certification?.issueDate}
                              className={clsx('form-control bg-secondary p-0')}
                              max={today}
                            />
                            <div
                              className='position-absolute form-label'
                              style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                            >
                              <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                              {array?.certification?.issueDate
                                ? array?.certification?.issueDate
                                : 'Issue Date*'}
                            </div>
                          </div>
                          {!array?.certification?.certificateDoesNotExpire && (
                            <div className='col-md-6 mb-5 position-relative'>
                              <label className='form-label'>Expiry Date</label>
                              <input
                                name='expirationDate'
                                id='expirationDate'
                                type='date'
                                onChange={(e) => {
                                  onCertificationChange(e)
                                }}
                                value={array?.certification?.expirationDate}
                                className='form-control bg-secondary p-0'
                                min={array?.certification?.issueDate}
                              />
                              <div
                                className='position-absolute form-label'
                                style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                              >
                                <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                                {array?.certification?.expirationDate
                                  ? array?.certification?.expirationDate
                                  : 'Expiry Date*'}
                              </div>
                            </div>
                          )}

                          <div className='col-12 mb-5'>
                            <label className='form-check-label me-5'>
                              Credential does not expire
                            </label>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name='certificateDoesNotExpire'
                              onChange={() =>
                                setArray({
                                  ...array,
                                  certification: {
                                    ...array.certification,
                                    certificateDoesNotExpire:
                                      !array.certification.certificateDoesNotExpire,
                                  },
                                })
                              }
                              checked={array.certification.certificateDoesNotExpire}
                            />
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>Credential Reference Image*</label>
                            <input
                              type='file'
                              name='credentialImg'
                              onChange={(e) => {
                                onHandleChangeFile(e)
                              }}
                              className={clsx('form-control bg-transparent')}
                            />
                            {array?.certification?.credentialImg ? (
                              <img
                                src={array?.certification?.credentialImg}
                                className='w-50px h-50px mt-2 rounded'
                                alt='credential'
                              />
                            ) : null}
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>Credential ID</label>
                            <input
                              type='text'
                              name='credentialID'
                              onChange={(e) => {
                                onCertificationChange(e)
                              }}
                              value={array?.certification?.credentialID}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>Credential URL</label>
                            <input
                              type='text'
                              name='credentialURL'
                              value={array?.certification?.credentialURL}
                              onChange={(e) => {
                                onCertificationChange(e)
                              }}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 text-end mb-5'>
                            <button
                              className='btn btn-primary'
                              disabled={
                                array?.certification?.certificationName !== '' &&
                                array?.certification?.issueDate !== '' &&
                                array?.certification?.credentialImg !== '' &&
                                array?.certification?.credentialID !== '' &&
                                array?.certification?.credentialURL !== ''
                                  ? false
                                  : true
                              }
                              onClick={AddCertificate}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.certificate === 'Add'
                                    ? 'message-add'
                                    : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                              {buttonValue?.certificate}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Awards */}
                  <div className='col-12'>
                    <div
                      className={` card shadow mb-5 card-custom ${
                        flag?.facultyAwards ? 'border border-danger border-2' : ''
                      }`}
                    >
                      <div className='card-header'>
                        <div className='card-title'>
                          <h2>Awards</h2>
                        </div>
                      </div>
                      <div className='card-body'>
                        <div className='row'>
                          {/* <div className='col-12'>
                            {array?.facultyAwards?.length !== 0 && (
                              <div className='card border shadow mb-5 card-custom'>
                                <div className='card-header '>
                                  <div
                                    className='card-toolbar w-100 m-auto text-end'
                                    style={{display: 'unset'}}
                                  >
                                    <i
                                      className={`${
                                        flag?.Awards
                                          ? 'fa-solid fa-check text-success'
                                          : 'fa-solid fa-pen'
                                      } fs-2`}
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        flag?.Awards
                                          ? setFlag({...flag, Awards: false})
                                          : setFlag({...flag, Awards: true})
                                      }}
                                    ></i>
                                  </div>
                                </div>
                                <div className='card-body card-scroll h-200px py-5'>
                                  <div className='row'>
                                    {array?.facultyAwards?.map((item, index) => {
                                      return (
                                        <div key={item._id} className='col-12 mb-5 border-bottom'>
                                          <div className='d-flex align-items-baseline justify-content-between'>
                                            <div className='d-md-flex' style={{gap: '20px'}}>
                                              <div className=''>
                                                <h3 className='m-0 fw-bold fs-5'>{item?.title}</h3>
                                                <div className='fs-7'>{item?.awardYear}</div>
                                                <div className='fw-bold text-muted fs-8'>
                                                  {item?.shortDescr}
                                                </div>
                                              </div>
                                            </div>
                                            <div>
                                              {flag?.Awards ? (
                                                <div className='d-flex align-items-center'>
                                                  <button
                                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                                    type='button'
                                                  >
                                                    <i
                                                      className='fa-solid fa-pen fs-5  '
                                                      onClick={() => {
                                                        setArray({...array, awards: item})
                                                        setIndex({
                                                          ...index,
                                                          Awards: index,
                                                        })
                                                        SetButtonValue({
                                                          ...buttonValue,
                                                          Awards: 'Edit',
                                                        })
                                                      }}
                                                    ></i>
                                                  </button>
                                                  <i
                                                    className='fa-solid fs-5 text-danger fa-trash-can'
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                      let data = array?.facultyAwards
                                                      data.splice(index, 1)
                                                      setArray({
                                                        ...array,
                                                        facultyAwards: data,
                                                      })
                                                    }}
                                                  ></i>
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className='col-12 mb-7'>
                            <div className='fs-6 text-muted'>* indicates required </div>
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Awards Title<span className='text-danger'>*</span>
                            </label>
                            <input
                              type='text'
                              name='title'
                              onChange={(e) => {
                                onAwardsChange(e)
                              }}
                              value={array?.awards?.title}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Receiving Year<span className='text-danger'>*</span>
                            </label>
                            <div className='d-flex'>
                              <select
                                name='awardYear'
                                onChange={onAwardsChange}
                                value={array.awards.awardYear}
                                className='form-control bg-transparent'
                              >
                                {generateYearOptions()}
                              </select>
                            </div>
                          </div>
                          <div className='col-12 mb-5'>
                            <label className='form-label'>
                              Short Description <span className='text-danger'>*</span>
                            </label>
                            <textarea
                              name='shortDescr'
                              onChange={(e) => {
                                onAwardsChange(e)
                              }}
                              value={array?.awards?.shortDescr}
                              className={clsx('form-control bg-transparent')}
                              rows={3}
                            ></textarea>
                          </div>
                          <div className='col-12 text-end mb-5'>
                            <button
                              className='btn btn-primary'
                              disabled={
                                array?.awards?.title !== '' &&
                                array?.awards?.awardYear !== '' &&
                                array?.awards?.shortDescr !== ''
                                  ? false
                                  : true
                              }
                              onClick={AddAwards}
                            >
                              <i
                                className={`ki-duotone ki-${
                                  buttonValue?.Awards === 'Add' ? 'message-add' : 'message-edit'
                                } fs-1`}
                              >
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>

                              {buttonValue?.Awards}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 text-end'>
            <button
              type='submit'
              className='btn btn-primary'
              onClick={() => {
                if (
                  array?.facultyPublication?.length !== 0 &&
                  array?.facultyCertification?.length !== 0 &&
                  array?.facultyAwards?.length !== 0 &&
                  images?.facultyimage
                ) {
                  formik.handleSubmit()
                  setFlag({
                    ...flag,
                    facultyPublication: true,
                    facultyArticles: true,
                    facultyAwards: true,
                    facultyCertification: true,
                  })
                }
              }}
            >
              {!loading && (
                <span className='indicator-label d-flex gap-2'>
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>{' '}
                  Add Faculty
                </span>
              )}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
// Generate an array of year options
export function generateYearOptions() {
  const currentYear = new Date().getFullYear()
  const yearOptions: any = []
  for (let year = 2000; year <= currentYear + 1; year++) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    )
  }
  return yearOptions
}
