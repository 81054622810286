import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useLazyAcademicFilterQuery} from '../../Api/allApi'
import {setFilter} from '../../redux/AssignmentSlice'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {memo} from 'react'

const Filter = (props: any) => {
  const {type} = props
  const dispatch = useDispatch()

  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, data: filterResultData, isFetching} = result
  useEffect(() => {
    filterTrigger({})
  }, [])

  const [filterData, setFilterData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    semester: [],
    division: [],
    semesterId: 0,
    divisionId: 0,
    selectedSemester: {value: '', label: ''},
    selectedDivision: {value: '', label: ''},
    subject: [],
    subjectId: [],
  })
  const [selected, setSelected]: any = useState({
    streamId: 0,
    classId: 0,
    semesterId: 0,
    subjectId: [],
  })
  // when api calling is done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = filterResultData
      let stream: any = []
      data.stream?.map((item) => {
        stream.push({value: item?.dbId, label: item?.name})
      })

      const get = localStorage.getItem('filterDataKey')
      let localStorageData = {
        ...filterData,
        stream: stream,
      }
      if (!get) {
        localStorage.setItem('filterDataKey', JSON.stringify(localStorageData))
        setFilterData(localStorageData)
      }
    }
  }, [isSuccess, isFetching, filterResultData])
  const handleStreamSelect = (e) => {
    let classObj = []
    const classData = filterResultData.class[e.value]
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...filterData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: classObj,
        classId: 0,
        selectedClass: {value: '', label: ''},
        semester: [],
        semesterId: 0,
        selectedSemester: {value: '', label: ''},
        divisionId: 0,
        division: [],
        selectedDivision: {value: '', label: ''},
        subject: [],
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    } else {
      let data = {
        ...filterData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: [],
        classId: 0,
        selectedClass: {value: '', label: ''},
        semester: [],
        semesterId: 0,
        selectedSemester: {value: '', label: ''},
        subject: [],
        subjectId: [],
        divisionId: 0,
        division: [],
        selectedDivision: {value: '', label: ''},
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    }
  }
  const handleClassSelect = (e) => {
    const semesterData = filterResultData.semester[e.value]
    const divisionData = filterResultData.division[e.value]

    let semesterOptions = []
    let divisionOptions = []

    if (semesterData && semesterData.length > 0) {
      semesterOptions = semesterData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
    }

    if (divisionData && divisionData.length > 0) {
      divisionOptions = divisionData.map((item, index) => ({
        value: item.dbId ? item.dbId : index + 1,
        label: item.division,
      }))
    }
    console.log(divisionOptions)
    const data = {
      ...filterData,
      classId: e.value,
      selectedClass: {label: e.label, value: e.value},
      semester: semesterOptions,
      subject: [],
      subjectId: [],
      semesterId: 0,
      selectedSemester: {value: '', label: ''},
      divisionId: 0,
      division: divisionOptions,
      selectedDivision: {value: '', label: ''},
    }

    localStorage.setItem('filterDataKey', JSON.stringify(data))
    setFilterData(data)
  }
  const handleSemesterSelect = (e) => {
    let subject = []
    const subjectData = filterResultData.subject[e.value]
    if (subjectData && subjectData.length > 0) {
      subject = subjectData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...filterData,
        semesterId: e.value,
        selectedSemester: {label: e.label, value: e.value},
        subject: subject,
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    } else {
      let data = {
        ...filterData,
        semesterId: e.value,
        selectedSemester: {label: e.label, value: e.value},
        subject: [],
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    }
  }
  const handleSubjectSelect = (selectedItemId) => {
    setFilterData((prevSelected) => {
      // Check if the subjectId is already in the array
      if (prevSelected?.subjectId.includes(selectedItemId)) {
        // If it's already in the array, remove it
        return {
          ...prevSelected,
          subjectId: prevSelected?.subjectId?.filter((id) => id !== selectedItemId),
        }
      } else {
        // If it's not in the array, add it
        return {
          ...prevSelected,
          subjectId: [...prevSelected.subjectId, selectedItemId],
        }
      }
    })
  }
  const handleSelectAllSubjects = (selectAll) => {
    if (selectAll) {
      // Select all subjects by mapping through subject items and extracting their dbIds
      const allSubjectIds =
        filterResultData?.subject[selected?.semesterId]?.map((item) => item?.dbId) || []
      let data = {
        ...filterData,
        subjectId: allSubjectIds,
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    } else {
      // Deselect all subjects by setting an empty array for subjectId

      let data = {
        ...filterData,
        subjectId: [],
      }
      localStorage.setItem('filterDataKey', JSON.stringify(data))
      setFilterData(data)
    }
  }
  const handleDivisionSelect = (e) => {
    let data = {
      ...filterData,
      divisionId: e.value,
      selectedDivision: {label: e.label, value: e.value},
    }
    localStorage.setItem('filterDataKey', JSON.stringify(data))
    setFilterData(data)
  }
  useEffect(() => {
    dispatch(setFilter(selected))
  }, [selected, dispatch])

  useEffect(() => {
    const storedFilterData = localStorage.getItem('filterDataKey')
    if (storedFilterData) {
      setFilterData(JSON.parse(storedFilterData))
    }
  }, [])

  useEffect(() => {
    if (filterData?.streamId > 0) {
      localStorage.setItem('filterDataKey', JSON.stringify(filterData))
    }
  }, [filterData])

  useEffect(() => {
    setSelected({
      streamId: filterData?.streamId,
      classId: filterData?.classId,
      semesterId: filterData?.semesterId,
      subjectId: filterData?.subjectId,
      divisionId: filterData.divisionId,
    })
  }, [filterData])

  return (
    <>
      <div
        className={` ${type === 'logs' && 'justify-content-end'} ${
          type === 'kpis' || type === 'Learner' || type === 'logs' ? '' : ' card-header pt-5'
        }`}
      >
        {type !== 'kpis' && type !== 'Learner' && type !== 'logs' ? (
          type === 'Assessment' ? (
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-3 mb-1'> {type}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'> Review {type} status</span>
            </h3>
          ) : (
            <h3 className={`align-items-start flex-column ${type === 'kpis' ? '' : 'card-title'}`}>
              <span className='card-label fw-bold fs-3 mb-1'>Assignment {type}</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>{type}</span>
            </h3>
          )
        ) : (
          ''
        )}
        <div
          className={`${
            type === 'kpis' || type === 'Learner' || type === 'logs'
              ? 'd-flex align-items-end'
              : 'card-toolbar align-items-start'
          } `}
        >
          {type === 'Review assignment submission status' && (
            <Link to='/assignment/AllAssignment/add' className=' me-6 mb-5'>
              <button className='btn btn-sm btn-light-primary'>
                <i className='ki-duotone ki-message-add fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
                Add Assignment
              </button>
            </Link>
          )}
          {type === 'Assessment' && (
            <Link to={`/assessments/Add`} className=' me-6 mb-5'>
              <button className='btn btn-sm btn-light-primary '>
                <i className='ki-duotone ki-message-add fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Add Assessment
              </button>
            </Link>
          )}

          {/* stream */}
          <div className='mb-5 me-3'>
            <Select
              placeholder='select Stream'
              value={
                filterData?.selectedStream?.label !== '' && filterData?.selectedStream?.value !== ''
                  ? filterData?.selectedStream
                  : null
              }
              options={filterData?.stream}
              styles={{
                option: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  margin: '0px',
                  color: '#7e8299',
                  fontWeight: 'bold',
                  background: 'white', // this was the mistake (I needed to remove this)
                  ':hover': {
                    color: '#009ef7',
                    backgroundColor: '#f1faff',
                  },
                }),
              }}
              onChange={(e: any) => {
                handleStreamSelect(e)
              }}
            />
            {/* end::Menu 2 */}
          </div>
          {/* class */}
          <div className=' mb-5 me-3'>
            <Select
              placeholder='select Class'
              value={
                filterData?.selectedClass?.label !== '' && filterData?.selectedClass?.value !== ''
                  ? filterData?.selectedClass
                  : null
              }
              options={filterData?.class}
              isDisabled={filterData?.streamId === 0 ? true : false}
              styles={{
                option: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  margin: '0px',
                  color: '#7e8299',
                  fontWeight: 'bold',
                  background: 'white', // this was the mistake (I needed to remove this)
                  ':hover': {
                    color: '#009ef7',
                    backgroundColor: '#f1faff',
                  },
                }),
              }}
              onChange={(e: any) => {
                handleClassSelect(e)
              }}
            />
          </div>
          {/* semester */}
          <div className='mb-5 me-3'>
            <Select
              placeholder='select semester'
              value={
                filterData?.selectedSemester?.label !== '' &&
                filterData?.selectedSemester?.value !== ''
                  ? filterData?.selectedSemester
                  : null
              }
              isDisabled={filterData?.classId === 0 ? true : false}
              styles={{
                option: (base) => ({
                  ...base,
                  cursor: 'pointer',
                  margin: '0px',
                  color: '#7e8299',
                  fontWeight: 'bold',
                  background: 'white', // this was the mistake (I needed to remove this)
                  ':hover': {
                    color: '#009ef7',
                    backgroundColor: '#f1faff',
                  },
                }),
              }}
              options={filterData?.semester}
              onChange={(e: any) => {
                handleSemesterSelect(e)
              }}
            />
          </div>
          {/* division */}
          <div className='mb-5 me-3'>
            <Select
              placeholder='Select Division'
              value={
                filterData?.selectedDivision?.label !== '' &&
                filterData?.selectedDivision?.value !== ''
                  ? filterData?.selectedDivision
                  : null
              }
              options={filterData?.division}
              isDisabled={filterData?.classId === 0 ? true : false}
              styles={
                {
                  // ... existing styles
                }
              }
              onChange={(e: any) => {
                handleDivisionSelect(e)
              }}
            />
          </div>
          {/* subject */}
          {type !== 'kpis' && type !== 'Learner' && type !== 'logs' && (
            <div className='mb-5'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                disabled={selected?.semesterId === 0 ? true : false}
              >
                <i className='fas fa-circle fs-1x'></i>
                {filterData?.subjectId?.length > 0
                  ? `${filterData?.subjectId?.length} Selected`
                  : 'Subject'}
              </button>
              {/* begin::Menu 2 */}
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px pb-3'
                data-kt-menu='true'
                style={{overflowY: 'scroll', height: '70vh'}}
              >
                {/* begin::Menu item */}
                <div
                  className='menu-item px-3 bg-white'
                  style={{
                    position: 'sticky',
                    top: '0',
                    left: '0',
                    width: '100%',
                    textAlign: 'center',
                    borderBottom: '2px solid #AFB2C2',
                  }}
                >
                  <div className='menu-content  fs-6 text-dark fw-bold px-3 py-4'>
                    Subject Filter
                  </div>
                </div>
                {/* end::Menu item */}
                {/* begin::Menu separator */}
                <div className='separator mb-3 opacity-75'></div>
                {/* end::Menu separator */}
                {/* begin::Menu item */}
                <label className='form-check   mb-5 form-check-sm form-check-custom form-check-solid me-5 menu-item px-5 py-5'>
                  <input
                    className='form-check-input m-0'
                    type='checkbox'
                    id='selectAllSubjects'
                    onChange={(e) => handleSelectAllSubjects(e.target.checked)}
                    checked={
                      filterData?.subjectId?.length ===
                      (filterResultData?.subject[selected?.semesterId]?.length || 0)
                    }
                  />
                  <span className='form-check-label'>Select All</span>
                </label>
                {filterData?.subject?.map((item: any) => (
                  <label
                    key={item?.dbId?.toString()}
                    className='form-check mb-5 form-check-sm form-check-custom form-check-solid me-5 menu-item px-5 py-5'
                  >
                    <input
                      className='form-check-input m-0'
                      type='checkbox'
                      checked={filterData?.subjectId?.includes(item?.value)}
                      id={`subject_${item?.dbId}`} // Use a unique ID for each checkbox
                      onChange={() => handleSubjectSelect(item?.value)} // Call the handler function
                    ></input>
                    <span className='form-check-label'>{item?.label}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          {/* end::Menu */}
        </div>
      </div>
    </>
  )
}

export default memo(Filter)
