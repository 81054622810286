import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useLazyGetFacultyDetailsQuery, useUpdateArticlesMutation} from '../../Api/allApi'
const Certificate = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  shortDescr: Yup.string().required('Description is required'),
  articleLink: Yup.string().required('Link is required'),
  imgLink: Yup.string().required('Link is required'),
  articleDate: Yup.string(),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const ArticlesModel = ({name, data}) => {
  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    title: '',
    shortDescr: '',
    articleLink: '',
    articleDate: '',
  })
  const [images, setImages]: any = useState({})
  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setImages({
        ...images,
        [e.target.name]: base64String,
      })
      formik.setFieldValue('imgLink', base64String)
    }
    if (file) {
      reader.readAsDataURL(file)
      setImages({
        ...images,
        [e.target.name]: file,
      })
    }
  }
  // update api
  const [updateTrigger, result] = useUpdateArticlesMutation()
  const {isSuccess, isLoading} = result
  // Faculty details API
  const [facultyDetailsTrigger] = useLazyGetFacultyDetailsQuery() // Fetch faculty details
  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Certificate,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: data?.dbId,
          facultyId: Number(data?.facultyId),
          title: values?.title,
          shortDescr: values?.shortDescr,
          articleLink: values?.articleLink,
          articleDate: values?.articleDate,
          articleImg: images?.articleImg,
        }
        updateTrigger(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    if (isSuccess && !isLoading) {
      facultyDetailsTrigger(data?.facultyId)
    }
  }, [isSuccess, isLoading, data?.facultyId, facultyDetailsTrigger])
  // set data while updating
  useEffect(() => {
    setInitialValues(data)
  }, [data])
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>
            {data?.title === '' ? 'Add' : 'Update'} {name}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id='Articles'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='Publication'
                >
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>* indicates required </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Article Title*</label>
                      <input
                        type='text'
                        name='title'
                        {...formik.getFieldProps('title')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.title && formik.errors.title,
                          },
                          {
                            'is-valid': formik.touched.title && !formik.errors.title,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>imgLink*</label>
                      <input
                        type='file'
                        name='imgLink'
                        onChange={onHandleChangeFile}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.imgLink && formik.errors.imgLink,
                          },
                          {
                            'is-valid': formik.touched.imgLink && !formik.errors.imgLink,
                          }
                          // {'is-invalid': !images?.imgLink},
                          // {
                          //   'is-valid': images?.imgLink,
                          // }
                        )}
                      />
                      {data?.imgLink || images?.imgLink ? (
                        <img
                          src={images?.imgLink ? images?.imgLink : data?.imgLink}
                          className='w-50px h-50px mt-2 rounded'
                          alt='credential'
                        />
                      ) : null}
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>short Description *</label>
                      <textarea
                        name='shortDescr'
                        {...formik.getFieldProps('shortDescr')}
                        className={clsx(
                          'form-control bg-transparent',
                          {'is-invalid': formik.touched.shortDescr && formik.errors.shortDescr},
                          {
                            'is-valid': formik.touched.shortDescr && !formik.errors.shortDescr,
                          }
                        )}
                        rows={3}
                      ></textarea>
                    </div>
                    <div className='col-md-6 mb-5'>
                      <label className='form-label'>Article Link</label>
                      <input
                        name='articleLink'
                        type='text'
                        {...formik.getFieldProps('articleLink')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.articleLink && formik.errors.articleLink,
                          },
                          {
                            'is-valid': formik.touched.articleLink && !formik.errors.articleLink,
                          }
                        )}
                      />
                    </div>
                    <div className='col-md-6 col-12 mb-5 position-relative'>
                      <label className='form-label'>Article Date</label>
                      <input
                        name='articleDate'
                        type='date'
                        {...formik.getFieldProps('articleDate')}
                        className={clsx(
                          'form-control bg-transparent p-1',
                          {
                            'is-invalid': formik.touched.articleDate && formik.errors.articleDate,
                          },
                          {
                            'is-valid': formik.touched.articleDate && !formik.errors.articleDate,
                          }
                        )}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {formik?.values?.articleDate
                          ? formik?.values?.articleDate
                          : 'Article Date*'}
                      </div>
                    </div>

                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss='modal'
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className='indicator-label d-flex align-items-center gap-2'>
                            <i
                              className={`ki-duotone ki-${
                                data?.title === '' ? 'check-square' : 'message-edit'
                              } fs-1 `}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            {data?.title === '' ? 'Add' : 'Update'} Article
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ArticlesModel}
