import Swal from 'sweetalert2'
import Tooltip from 'rc-tooltip'
import DataTables from '../../DataTable/DataTables'
import {useEffect, useState} from 'react'
import {AuthorModel} from './AuthorModel'
import {useDeleteCourseAuthorMutation, useLazyGetCourseAuthorQuery} from '../../../Api/allApi'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setAuthor} from '../../../redux/CourseSlice'
export default function CourseAuthor() {
  // pagination
  const dispatch = useDispatch()
  const {author} = useSelector((state: any) => state.CourseSlice)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({author: {}, platform: '', selectedProfile: {}})
  // author list api
  const [authorList, result] = useLazyGetCourseAuthorQuery()
  const {isSuccess, isFetching} = result
  // delete author api
  const [deleteAuthor, deleteResult] = useDeleteCourseAuthorMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete} = deleteResult
  useEffect(() => {
    authorList({})
  }, [authorList])

  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(setAuthor(result?.data?.authorList))
      setTotalRows(result?.data?.authorList?.length)
    }
  }, [isSuccess, isFetching, dispatch, result?.data?.authorList])

  useEffect(() => {
    if (isDelete && !isNotDelete) {
      authorList({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, authorList, deleteResult?.data?.detail])

  const handlePageChange = (page: any) => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }
  const handleSocialProfileClick = (row: any, platform: string) => {
    // Find the selected social profile data
    const selectedProfile = row.authorSocialProfiles.find(
      (profile) => profile.platform === platform
    )

    // Do something with the selected profile data
    console.log('Selected Profile:', selectedProfile)

    // If needed, you can also update the state or perform any other actions
    setSelected({
      ...selected,
      author: row,
      platform: platform,
      selectedProfile: selectedProfile ? selectedProfile : {platform: platform, link: ''},
    })
  }
  // data
  const columns: any = [
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <div className='symbol  symbol-35px symbol-circle'>
              <img src={row?.authorImg} alt='Pic' />
            </div>
          </>
        )
      },
      center: true,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Author Name',
      selector: (row: any) => row?.authorName,
      center: true,
      sortable: true,
    },
    {
      name: 'Social Profile',
      selector: (row: any) => {
        const socialProfiles = [
          {platform: 'Facebook', icon: 'fa-facebook'},
          {platform: 'Twitter', icon: 'fa-twitter'},
          {platform: 'Instagram', icon: 'fa-instagram'},
          // Add more social platforms as needed
        ]
        return (
          <>
            {socialProfiles.map((profile) => {
              const allPlatforms: any = [
                ...row.authorSocialProfiles.map((profile) => profile.platform),
              ]
              const isProfileAdded = allPlatforms.includes(profile.platform)
              return (
                <button
                  key={profile.platform}
                  className={`btn btn-sm me-2 btn-light-${
                    isProfileAdded ? 'primary' : 'secondary'
                  }`}
                  type='button'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='modal'
                  style={{height: 'unset', width: 'unset'}}
                  data-bs-target='#SocialProfile'
                  onClick={() => handleSocialProfileClick(row, profile.platform)}
                >
                  <i
                    className={`fab ${profile.icon} p-0 fs-3`}
                    style={{cursor: 'pointer'}}
                    title={profile.platform}
                  ></i>
                </button>
              )
            })}
          </>
        )
      },
      center: true,
      sortable: true,
    },

    {
      style: {
        justifyContent: 'end',
      },
      name: '',
      selector: (row: any) => {
        return (
          <>
            <Tooltip placement='bottom' overlay={<span>Edit</span>}>
              <button
                className='btn btn-sm btn-light-warning me-2'
                type='button'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='modal'
                style={{height: 'unset', width: 'unset'}}
                data-bs-target='#Author'
                onClick={() =>
                  setSelected({
                    ...selected,
                    author: row,
                  })
                }
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Edit
              </button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>Delete</span>}>
              <button
                className='btn btn-sm btn-light-danger me-2'
                type='button'
                onClick={() => {
                  Swal.fire({
                    title: 'Confirm deletion?',
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText:
                      '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
                    confirmButtonColor: '#009EF7',
                    cancelButtonText:
                      '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      deleteAuthor(row?.dbId)
                    } else if (result.isDenied) {
                      Swal.fire('Record is not deleted', '', 'error')
                    }
                  })
                }}
              >
                <i className='ki-duotone ki-trash-square fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Delete
              </button>
            </Tooltip>
          </>
        )
      },
      width: '300px',
    },
  ]

  return (
    <>
      {isFetching ? ( // Loading spinner if data is being fetched
        <>
          <div className='px-5'>
            <div className='col-12 text-center card mb-5 p-5'>
              <i
                className='fa-solid fa-circle-notch fa-spin p-10 text-primary'
                style={{fontSize: '30px', color: 'unset'}}
              ></i>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-header'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Author</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>You can Add or edit Author</span>
              </h3>
              <div className='card-toolbar' style={{gap: '10px'}}>
                {/* add */}
                <button
                  className='btn btn-sm  m-0  btn-light-primary'
                  type='button'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='modal'
                  style={{height: 'unset', width: 'unset'}}
                  data-bs-target='#Author'
                  onClick={() =>
                    setSelected({
                      ...selected,
                      author: {authorName: '', about: '', authorImg: ''},
                    })
                  }
                >
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>{' '}
                  Add Author
                </button>
              </div>
            </div>
          </div>
          <div className='col-12 mb-5'>
            <div className=' card card-custom '>
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <DataTables
                  columns={columns}
                  data={author}
                  loading={isFetching}
                  totalRows={totalRows}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                  page={page}
                  perPage={perPage}
                />
                {/* end::Table container */}
              </div>
              <div className='modal fade' id='Author' aria-hidden='true'>
                <AuthorModel name='Author' type='addAuthor' data={selected?.author} />
              </div>
              <div className='modal fade' id='SocialProfile' aria-hidden='true'>
                <AuthorModel name='Social Profile' type='SocialProfile' data={selected} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
