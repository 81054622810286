import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {
  useLazyGetFacultyDetailsQuery,
  useLazyPublisherQuery,
  useUpdatePublicationMutation,
} from '../../Api/allApi'
import CreatableSelect from 'react-select'
import {setPublisher} from '../../redux/achivementSlice'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {setUpdateData} from '../../redux/FacultySlice'
const Certificate = Yup.object().shape({
  publicationLink: Yup.string().required('Link is required'),
  title: Yup.string().required('title is required'),
  publicationDate: Yup.string()
    .test('date', 'Publication date cannot be in the future', function (value: any) {
      // Parse the input date string into a Date object
      const publicationDate = new Date(value)

      // Get the current date
      const currentDate = new Date()

      // Check if the publication date is greater than the current date
      if (publicationDate > currentDate) {
        return false // Validation fails
      }

      return true // Validation passes
    })
    .required('Date  is required'),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const PublicationModel = ({name, data}) => {
  const dispatch = useDispatch()
  const {publisher} = useSelector((state: any) => state.achievementSlice)

  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    shortDescr: '',
    title: '',
    publicationLink: '',
    publicationDate: '',
  })
  const [array, setArray]: any = useState({
    publisher: [],
    selectedPublication: {label: '', value: ''},
  })
  const [images, setImages]: any = useState({})

  // update or add publication
  const [publication, result] = useUpdatePublicationMutation()
  const {isSuccess, isLoading} = result
  // publisher api
  const [PublisherTrigger, PublisherResult] = useLazyPublisherQuery()
  const {isSuccess: isPublisher, isFetching: isNotPublisher} = PublisherResult
  // Faculty details API
  const [facultyDetailsTrigger, facultyResult] = useLazyGetFacultyDetailsQuery() // Fetch faculty details
  const {isSuccess: isFaculty, isFetching: isNotFaculty} = facultyResult
  useEffect(() => {
    PublisherTrigger({})
  }, [PublisherTrigger])
  useEffect(() => {
    if (isSuccess && !isLoading) {
      const id = data?.facultyId
      facultyDetailsTrigger(id)
    }
  }, [isSuccess, isLoading, facultyDetailsTrigger, data?.facultyId])
  // call when all data fill wellS
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Certificate,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          facultyId: Number(data?.facultyId),
          dbId: data?.dbId ? data?.dbId : 0,
          title: values?.title,
          shortDescr: values?.shortDescr,
          publicationLink: values?.publicationLink,
          publicationDate: values?.publicationDate,
          publisherId: array?.publisherId,
          publisherDetails: {
            publishingMedium: images?.mediumIcon,
          },
        }
        publication(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    if (isFaculty && !isNotFaculty) {
      dispatch(setUpdateData(facultyResult?.data))
    }
  }, [isFaculty, isNotFaculty, result?.data, dispatch])
  useEffect(() => {
    if (isPublisher && !isNotPublisher) {
      dispatch(setPublisher(PublisherResult?.data?.publisherList))
    }
  }, [isPublisher, isNotPublisher, PublisherResult, dispatch])
  useEffect(() => {
    let ArrayPub: any = []
    let array: any = []
    if (publisher?.length !== 0) {
      publisher?.map((item: any, index) => {
        ArrayPub.push({value: item?.dbId, label: item?.publishingMedium})
        if (publisher?.length === index + 1) {
          ArrayPub.push({value: 'other', label: 'other'})
        }
        return item
      })
    }
    setArray({...array, publisher: ArrayPub})
  }, [publisher])
  // set data while updating
  useEffect(() => {
    console.log(data)
    setInitialValues(data)
  }, [data])
  return (
    <div className='modal-dialog modal-dialog-centered mw-1000px'>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>
            {data?.title === '' ? 'New' : 'Update'} {name}
          </h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id='Publication'>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='Publication'
                >
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>* indicates required </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>Title*</label>
                      <CreatableSelect
                        isClearable={false}
                        onChange={(e: any) => {
                          if (e.value !== 'other') {
                            const filterData = publisher.find((item) => item?.dbId === e?.value)
                            console.log(e.value, publisher)
                            setArray({
                              ...array,
                              publisherId: e.value,
                              publisherName: e.label,
                            })
                            setImages({
                              ...images,
                              mediumIcon: filterData?.publisherImg,
                            })
                          } else {
                            setArray({
                              ...array,
                              publisherId: null,
                              publisherName: e.label,
                            })
                            setImages({
                              ...images,
                              mediumIcon: PublisherResult?.data?.defaultPublisherImg,
                            })
                          }
                        }}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: '100%',
                            width: '100%',
                          }),
                          option: (base) => ({
                            ...base,
                            cursor: 'pointer',
                            margin: '0px',
                            boxShadow: 'unset',
                            color: '#7e8299',
                            fontWeight: 'bold',
                            background: 'white',
                            ':hover': {
                              color: '#009ef7',
                              backgroundColor: '#f1faff',
                            },
                          }),
                        }}
                        options={array?.publisher}
                        value={
                          array?.selectedPublication?.label !== '' &&
                          array?.selectedPublication?.value !== ''
                            ? array?.selectedPublication
                            : ''
                        }
                      />
                    </div>
                    {array?.publication?.title === 'other' && (
                      <>
                        <div className='col-12 mb-5'>
                          <label>Publishing Medium</label>
                          <input
                            type='text'
                            name='publishingMedium'
                            {...formik.getFieldProps('publishingMedium')}
                            onChange={(e) => {
                              setArray({
                                ...array,
                                publication: {
                                  ...array?.publication,
                                  publisherDetails: {
                                    ...array?.publication?.publisherDetails,
                                    publishingMedium: e.target.value,
                                  },
                                },
                              })
                            }}
                            className={clsx(
                              'form-control bg-transparent',
                              {
                                'is-invalid':
                                  formik.touched.publishingMedium && formik.errors.publishingMedium,
                              },
                              {
                                'is-valid':
                                  formik.touched.publishingMedium &&
                                  !formik.errors.publishingMedium,
                              }
                            )}
                          />
                        </div>

                        <div className='col-12 mb-5'>
                          <label>Short Description</label>
                          <textarea
                            name='shortDescr'
                            {...formik.getFieldProps('shortDescr')}
                            className={clsx(
                              'form-control bg-transparent',
                              {
                                'is-invalid': formik.touched.shortDescr && formik.errors.shortDescr,
                              },
                              {
                                'is-valid': formik.touched.shortDescr && !formik.errors.shortDescr,
                              }
                            )}
                            rows={3}
                          ></textarea>
                        </div>
                      </>
                    )}
                    <div className='col-12 mb-5 flex-column d-flex'>
                      {images?.mediumIcon ? (
                        <>
                          <label className='form-label'>Medium Icon*</label>
                          <img
                            src={images?.mediumIcon}
                            className='w-50px h-50px mt-2 rounded'
                            alt='credential'
                          />
                        </>
                      ) : null}
                    </div>
                    <div className=' col-12 mb-5 position-relative'>
                      <label className='form-label'>Publication Date</label>
                      <input
                        name='publicationDate'
                        type='date'
                        {...formik.getFieldProps('publicationDate')}
                        className={clsx(
                          'form-control bg-transparent p-1',
                          {
                            'is-invalid':
                              formik.touched.publicationDate && formik.errors.publicationDate,
                          },
                          {
                            'is-valid':
                              formik.touched.publicationDate && !formik.errors.publicationDate,
                          }
                        )}
                      />
                      <div
                        className='position-absolute form-label'
                        style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                      >
                        <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                        {formik?.values?.publicationDate
                          ? formik?.values?.publicationDate
                          : 'Publication Date*'}
                      </div>
                    </div>

                    <div className='col-12 mb-5'>
                      <label className='form-label'>Title</label>
                      <input
                        name='title'
                        type='text'
                        {...formik.getFieldProps('title')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.title && formik.errors.title,
                          },
                          {
                            'is-valid': formik.touched.title && !formik.errors.title,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>publication Link</label>
                      <input
                        name='publicationLink'
                        type='text'
                        {...formik.getFieldProps('publicationLink')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid':
                              formik.touched.publicationLink && formik.errors.publicationLink,
                          },
                          {
                            'is-valid':
                              formik.touched.publicationLink && !formik.errors.publicationLink,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss={'modal'}
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && (
                          <span className='indicator-label d-flex align-items-center gap-2'>
                            <i
                              className={`ki-duotone ki-${
                                data?.title === '' ? 'check-square' : 'message-edit'
                              } fs-1 `}
                            >
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            {data?.title === '' ? 'Add' : 'Update'} Publication
                          </span>
                        )}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PublicationModel}
