import React, {useEffect, useState} from 'react'
import '../Achievement.css'
import {RejectModel} from './model/RejectModel'
import {Link, useSearchParams} from 'react-router-dom'
import {useLazyLearnerListQuery} from '../../../Api/allApi'
const ViewLearnerAchievement: React.FC = () => {
  const [flag, setFlag]: any = useState({
    certificate: false,
    skills: false,
    project: false,
    experience: false,
  })
  const [achievement, setAchievements]: any = useState([])
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [learnerList, result] = useLazyLearnerListQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    if (id) {
      learnerList(id)
    }
  }, [learnerList, id])
  useEffect(() => {
    if (isSuccess && !isFetching) {
      console.log(result?.data)
      setAchievements(result?.data)
    }
  }, [isSuccess, isFetching, result?.data])
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title'>
            <Link to='/achievements/Learner'>
              <button
                className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                aria-label='Back'
                data-bs-original-title='Back'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </Link>
            View Learner's Information
          </div>
        </div>
      </div>
      {achievement?.certifications?.length > 0 &&
      achievement?.experiences?.length > 0 &&
      achievement?.projects?.length > 0 &&
      achievement?.skillList?.length > 0 ? (
        <>
          <div className='row'>
            {/* certificates */}
            <div className='col-12 mb-5'>
              <div className='card card-custom'>
                <div className='card-header '>
                  <h2 className='card-title fs-2 fw-bold'>Certificates ,Awards and Publications</h2>
                  <div className='card-toolbar'>
                    <i
                      className='ki-duotone ki-message-edit fs-1'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.certificate
                          ? setFlag({...flag, certificate: false})
                          : setFlag({...flag, certificate: true})
                      }}
                    >
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='row'>
                    <div className='col-12 d-flex align-items-center'>
                      {achievement?.certificate?.map((item) => {
                        return (
                          <div key={item._id} className='col-md-6 mb-5 border-bottom'>
                            <div className='d-flex align-items-baseline justify-content-between'>
                              <div className='d-md-flex' style={{gap: '20px'}}>
                                <div className=' mb-5'>
                                  <img
                                    src={item?.issueByImg}
                                    className='text-center rounded symbol-badge symbol-35px'
                                    alt=''
                                    style={{height: '50px', width: '50px', objectFit: 'cover'}}
                                  />
                                </div>
                                <div className=''>
                                  <h3 className='m-0 fs-5'>{item?.name}</h3>
                                  <div className='fs-7'>
                                    {item?.expirationDate
                                      ? `${item?.issueDate} - ${item?.expirationDate}`
                                      : `${item?.issueDate} `}
                                  </div>
                                  <div className='fw-bold text-muted fs-8'>{item?.issuedBy}</div>
                                  <div className='text-muted fw-bold fs-8'>
                                    Credential ID {item?.credentialID}
                                  </div>
                                  <a
                                    href={item?.credentialURL}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    <button
                                      className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                      style={{borderRadius: '20px'}}
                                    >
                                      Show Credential{' '}
                                      <i className='fa-solid fa-arrow-up-right-from-square m-2'></i>
                                    </button>
                                  </a>
                                </div>
                              </div>
                              <div>
                                {flag?.certificate ? (
                                  <>
                                    <button
                                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                      type='button'
                                      data-kt-menu-trigger='click'
                                      data-kt-menu-placement='bottom-end'
                                      data-kt-menu-flip='top-end'
                                      data-bs-toggle='modal'
                                      data-bs-target='#Reject'
                                    >
                                      <i className='ki-duotone ki-message-edit fs-1'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                        <span className='path3'></span>
                                      </i>
                                    </button>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                      {flag?.certificate ? (
                        <div>
                          <button
                            className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                            type='button'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                            data-bs-toggle='modal'
                            data-bs-target='#Reject'
                          >
                            <i
                              className='fa-solid fa-xmark text-danger fs-5 '
                              onClick={() => {}}
                            ></i>
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='modal fade' id='Reject' aria-hidden='true'>
                  <RejectModel />
                </div>
              </div>
            </div>
            {/* skills */}
            <div className='col-12 mb-5'>
              <div className=' card card-custom '>
                <div className='card-header'>
                  <h2 className='card-title fs-2 fw-bold'>Skills</h2>
                  <div className='card-toolbar'>
                    <i
                      className='ki-duotone ki-message-edit fs-1'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.skills
                          ? setFlag({...flag, skills: false})
                          : setFlag({...flag, skills: true})
                      }}
                    >
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='col-md-12'>
                    <div className='row align-items-center justify-content-center'>
                      <div className='col-12'>
                        <div className='row py-2 align-items-center justify-between flex-wrap'>
                          {achievement?.skills?.map((item) => {
                            return (
                              <div className='col-12 col-sm-6'>
                                <div className='d-flex align-items-baseline justify-content-between'>
                                  <div className='d-flex align-items-center'>
                                    <img
                                      src={item?.skillImg}
                                      alt=''
                                      className='me-5 mb-3'
                                      style={{width: '70px', height: '70px'}}
                                    />
                                    <h6 className='mb-5 me-5'>{item?.name}</h6>
                                  </div>
                                  <div>
                                    {flag?.skills ? (
                                      <>
                                        <button
                                          className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                          type='button'
                                          data-kt-menu-trigger='click'
                                          data-kt-menu-placement='bottom-end'
                                          data-kt-menu-flip='top-end'
                                          data-bs-toggle='modal'
                                          data-bs-target='#Reject'
                                        >
                                          <i className='fa-solid fa-xmark text-danger fs-5 '></i>
                                        </button>
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='modal fade' id='Reject' aria-hidden='true'>
                <RejectModel />
              </div>
            </div>
            {/* experience */}
            <div className='col-12 mb-5'>
              <div className=' card card-custom '>
                <div className='card-header'>
                  <h2 className='card-title fs-2 fw-bold'>Experience </h2>
                  <div className='card-toolbar'>
                    <i
                      className='ki-duotone ki-message-edit fs-1'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.experience
                          ? setFlag({...flag, experience: false})
                          : setFlag({...flag, experience: true})
                      }}
                    >
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </div>
                </div>
                <div className='card-body p-3 pb-0'>
                  <div className='timeline-label'>
                    {achievement?.experience?.map((item: any, index) => {
                      return (
                        <div className='timeline-item py-3 mb-0'>
                          <div
                            className={`timeline-label fw-bold fs-6 text-${
                              true ? 'dark' : 'muted'
                            }`}
                          ></div>
                          <div className='timeline-badge'>
                            <i
                              className={`fa fa-genderless text-${
                                index === 0 ? 'primary' : ''
                              } fs-1`}
                            ></i>
                          </div>
                          <div
                            className={`fw-normal timeline-content text-${
                              true ? 'dark' : 'muted'
                            } ps-3`}
                          >
                            <div className='row me-5 justify-content-between'>
                              <div className='col-9 mb-3'>
                                <div className='fw-bold fs-5'>
                                  {item?.title} - {item?.organisation}{' '}
                                </div>
                                <div className='fw-bold fs-7'>
                                  <span className='text-muted'>{item?.industry}</span>{' '}
                                </div>
                                <div className='fw-bold fs-7 '>
                                  Location : <span className='text-muted'>{item?.location}</span>
                                </div>
                                <div className='fw-bold  fs-7'>
                                  Work Mode : <span className='text-muted'>{item?.worKMode}</span>
                                </div>
                              </div>
                              <div className='col-3 mb-3 fs-7'>
                                <div className='text-muted fw-bold'>{item?.duration}</div>
                                <div>
                                  {item?.endDate
                                    ? `${item?.startDate} - ${item?.endDate}`
                                    : `${item?.startDate} - currently`}
                                </div>
                              </div>
                              <div className='col-12 mb-3 fs-8'>
                                <div className='text-muted'>{item?.description}</div>
                              </div>
                            </div>
                          </div>
                          {flag?.experience ? (
                            <>
                              <button
                                className='btn btn-icon btn-color-gray-400   btn-active-color-primary'
                                type='button'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-end'
                                data-kt-menu-flip='top-end'
                                data-bs-toggle='modal'
                                data-bs-target='#Reject'
                              >
                                <i className='fa-solid fa-xmark text-danger fs-5 me-3 '></i>
                              </button>
                            </>
                          ) : null}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='modal fade' id='Reject' aria-hidden='true'>
                  <RejectModel />
                </div>
              </div>
            </div>
            {/* project */}
            <div className='col-12 mb-5'>
              <div className='card card-custom '>
                <div className='card-header'>
                  <h2 className='card-title fs-2 fw-bold'>Projects</h2>
                  <div className='card-toolbar'>
                    <i
                      className='ki-duotone ki-message-edit fs-1'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.project
                          ? setFlag({...flag, project: false})
                          : setFlag({...flag, project: true})
                      }}
                    >
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='row'>
                    {achievement?.projects?.map((item) => {
                      return (
                        <div key={item._id} className='col-12 mb-5 border-bottom'>
                          <div className='d-flex align-item-baseline justify-content-between'>
                            <div>
                              <h3 className='m-0 fs-5'>{item?.name}</h3>
                              <div className='fs-7'>
                                {item?.startDate} - {item?.endDate}
                              </div>
                              <h5 className='mt-2 fs-7'>
                                Skills :{' '}
                                <span className='text-muted fs-8 fw-normal'>
                                  {item?.skills?.toString()}
                                </span>
                              </h5>
                              <a href={item?.link} target='_blank' rel='noopener noreferrer'>
                                <button
                                  className='btn mt-2 fs-7 mb-3 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                  style={{borderRadius: '20px'}}
                                >
                                  Show Project{' '}
                                  <i className='fa-solid fa-arrow-up-right-from-square m-2'></i>
                                </button>
                              </a>
                              <div className='fw-normal text-muted mb-5 fs-8'>
                                {item?.description}
                              </div>
                            </div>
                            <div>
                              {flag?.project ? (
                                <div className='d-flex'>
                                  <button
                                    className='btn btn-icon btn-color-gray-400   btn-active-color-primary'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#Reject'
                                  >
                                    <i className='fa-solid fa-xmark  text-danger fs-5  '></i>
                                  </button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='modal fade' id='Reject' aria-hidden='true'>
                  <RejectModel />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex flex-column align-items-center justify-content-between'>
              <i
                className='fa-solid fa-circle-xmark text-danger mb-5'
                style={{fontSize: '80px'}}
              ></i>
              <p className='fw-bold'>
                There are no experience/projects/certifications/skills/projects found for the
                learner
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ViewLearnerAchievement
