/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, useLocation} from 'react-router-dom'

function JobHeader() {
  const location = useLocation()
  return (
    <div className={`card  mb-6`}>
      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Jobs</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Manage Jobs & Applications</span>
        </h3>
        <div className='card-toolbar'>
          <Link className='btn btn-primary btn-sm' to='/jobs/addJob'>
            <i className='ki-duotone ki-message-add fs-1'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
            Add Jobs
          </Link>
        </div>
      </div>
      <div className='card-body pb-0 pt-0'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch justify-content-start p-2 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'></li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/all' && 'active')
                }
                to='/jobs/all'
              >
                Open Positions
              </Link>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/applied' && 'active')
                }
                to='/jobs/applied'
              >
                Applied
              </Link>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/offered' && 'active')
                }
                to='/jobs/offered'
              >
                Offered
              </Link>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/rejected' && 'active')
                }
                to='/jobs/rejected'
              >
                Rejected
              </Link>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/pending' && 'active')
                }
                to='/jobs/pending'
              >
                Pending
              </Link>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/closed' && 'active')
                }
                to='/jobs/closed'
              >
                Closed Positions
              </Link>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/jobs/class' && 'active')
                }
                to='/jobs/class'
              >
                By Class
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {JobHeader}
