import Swal from 'sweetalert2'
import Tooltip from 'rc-tooltip'
import DataTables from '../../DataTable/DataTables'
import {useState} from 'react'
import {useSelector} from 'react-redux'
export default function ViewCourse() {
  //   const dispatch = useDispatch()
  const {course} = useSelector((state: any) => state.CourseSlice)
  const [totalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({author: {}})
  //   // author list api
  //   const [authorList, result] = useLazyAllAuthorQuery()
  //   const {isSuccess, isFetching} = result
  // delete author api
  //   const [deleteAuthor, deleteResult] = useDeleteAuthorMutation()
  //   const {isSuccess: isDelete, isLoading: isNotDelete} = deleteResult
  //   useEffect(() => {
  //     authorList({})
  //   }, [authorList])

  //   useEffect(() => {
  //     if (isSuccess && !isFetching) {
  //       dispatch(setAuthor(result?.data?.blogAuthorList))
  //       setTotalRows(result?.data?.blogAuthorList?.length)
  //     }
  //   }, [isSuccess, isFetching])

  //   useEffect(() => {
  //     if (isDelete && !isNotDelete) {
  //       authorList({})
  //       Swal.fire({
  //         position: 'center',
  //         icon: 'success',
  //         title: 'Your record has been deleted',
  //         showConfirmButton: false,
  //         timer: 1500,
  //       })
  //     }
  //   }, [isDelete, isNotDelete])
  const handlePageChange = (page: any) => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }
  // data
  const columns: any = [
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <div className='symbol  symbol-35px symbol-circle'>
              <img src={row?.CourseImage} alt='Pic' />
            </div>
          </>
        )
      },
      center: true,
      sortable: true,
      width: '80px',
    },
    {
      name: 'Course Name',
      selector: (row: any) => row?.authorName,
      center: true,
      sortable: true,
    },
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            <Tooltip placement='bottom' overlay={<span>Edit</span>}>
              <button
                className='btn btn-sm  m-0 me-5 btn-light-primary'
                type='button'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='modal'
                style={{height: 'unset', width: 'unset'}}
                data-bs-target='#Author'
                onClick={() =>
                  setSelected({
                    ...selected,
                    author: row,
                  })
                }
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>Delete</span>}>
              <i
                className='ki-duotone ki-trash-square fs-1'
                onClick={() => {
                  Swal.fire({
                    title: 'Confirm deletion?',
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText:
                      "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                    confirmButtonColor: '#009EF7',
                    cancelButtonText:
                      '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      //   deleteAuthor(row?.dbId)
                    } else if (result.isDenied) {
                      Swal.fire('Record is not deleted', '', 'error')
                    }
                  })
                }}
              >
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </Tooltip>
          </>
        )
      },
      width: '150px',
    },
  ]

  return (
    <>
      {/* {isFetching ? ( // Loading spinner if data is being fetched
        <>
          <div className='px-5'>
            <div className='col-12 text-center card mb-5 p-5'>
              <i
                className='fa-solid fa-circle-notch fa-spin p-10 text-primary'
                style={{fontSize: '30px', color: 'unset'}}
              ></i>
            </div>
          </div>
        </>
      ) : ( */}
      <>
        <div className='col-12 mb-5'>
          <div className=' card card-custom '>
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <DataTables
                columns={columns}
                data={course}
                loading={course?.length !== 0}
                totalRows={totalRows}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                page={page}
                perPage={perPage}
              />
              {/* end::Table container */}
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  )
}
