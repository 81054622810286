import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  job: [],
}

export const JobSlice = createSlice({
  name: 'JobSlice',
  initialState,
  reducers: {
    setJob: (state, action) => {
      state.job = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setJob} = JobSlice.actions
export default JobSlice.reducer
