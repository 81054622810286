import React, {useEffect, useRef, useState} from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import Select from 'react-select'
import {
  useAddAssessmentMutation,
  useGetAllAssessmentMutation,
  useLazyAcademicFilterQuery,
  useLazyFacultyQuery,
} from '../../../Api/allApi'
import AssessmentQuiz from './assessmentQuiz'
import Swal from 'sweetalert2'
import {useSelector} from 'react-redux'
import {setAssessment} from '../../../redux/AssessmentSlice'
import {useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {langs, options} from '../../SunEditorConfiguration/config'

export default function AddAssessment() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // get data from redux
  const {filter} = useSelector((state: any) => state.assignmentSlice)
  const today = new Date().toISOString().split('T')[0]
  const [open, setOpen] = useState({
    step1: true,
    step2: false,
  })
  const [status, setStatus] = useState({
    step1: true,
    step2: false,
  })
  const [ErrorMessage, setErrorMessage]: any = useState({
    title: '',
    description: '',
    question: '',
    type: '',
  })
  const [data, setData]: any = useState({
    title: '',
    description: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    totalQuestion: '',
    question: '',
    marks: '',
    shortAns: '',
    notesCheck: false,
    note: '',
    time: 30,
    notes: '',
    durationInMinutes: 0,
    longAns: '',
    type: '',
    options: [
      {text: '', isCorrect: false},
      {text: '', isCorrect: false},
      {text: '', isCorrect: false},
      {text: 'sds', isCorrect: false},
    ],
    typeArray: [
      {
        label: (
          <div className='d-flex align-items-center'>
            <i className='ki-duotone ki-questionnaire-tablet fs-1 me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span>Multiple Choice</span>
          </div>
        ),
        value: 'multiple',
      },
      {
        label: (
          <div className='d-flex align-items-center'>
            <i className='ki-duotone ki-tablet-ok fs-1 me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
            <span>Single Choice</span>
          </div>
        ),
        value: 'single',
      },
      {
        label: (
          <div className='d-flex align-items-center'>
            <i className='ki-duotone ki-tablet-text-up fs-1 me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span>Short Answer</span>
          </div>
        ),
        value: 'short',
      },
      {
        label: (
          <div className='d-flex align-items-center'>
            <i className='ki-duotone ki-tablet-text-up fs-1 me-2'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <span>Long Answer</span>
          </div>
        ),
        value: 'long',
      },
    ],
    selectedType: {label: '', value: ''},
  })
  const [filterData, setFilterData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    semester: [],
    semesterId: 0,
    selectedSemester: {value: '', label: ''},
    subject: [],
    subjectId: 0,
  })
  const [questions, setQuestions]: any = useState([])
  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  // Query data for faculty
  const [facultyTrigger, facultyResult] = useLazyFacultyQuery()
  const {isSuccess: isFaculty, isFetching: isNotFaculty} = facultyResult
  // add assessments api
  const [addAssessment, assessmentResult] = useAddAssessmentMutation()
  const {isSuccess: isAdd, isLoading, isError, error} = assessmentResult
  // assessment get api
  const [getAssessment, getResult] = useGetAllAssessmentMutation()
  const {isSuccess: isGet, isLoading: isNotGet} = getResult
  useEffect(() => {
    filterTrigger({})
    facultyTrigger({})
  }, [filterTrigger, facultyTrigger])
  // when api calling is done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = result?.data
      let streams = data.stream?.map((item) => {
        return {value: item?.dbId, label: item?.name}
      })
      setFilterData((prevData) => {
        return {...prevData, stream: streams || []}
      })
    }
  }, [isSuccess, isFetching, result?.data])
  // Handle faculty data when it's loaded successfully
  useEffect(() => {
    let faculty: any = []
    if (isFaculty && !isNotFaculty) {
      facultyResult?.data?.facultyList?.map((item) => {
        faculty.push({
          value: item?.dbId,
          label: (
            <div className='d-flex align-items-center'>
              <img src={item?.facultyImg} className='h-40px w-40px me-5' alt={item?.firstName} />
              <span> {`${item?.firstName} ${item?.lastName}`}</span>
            </div>
          ),
        })
        return item
      })
      setFilterData((prevData) => {
        return {...prevData, faculty: faculty}
      })
    }
  }, [isFaculty, isNotFaculty, facultyResult?.data])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  // call when APi returns 200
  useEffect(() => {
    if (isAdd && !isLoading) {
      if (Object.keys(filter).length !== 0) {
        if (filter?.streamId > 0 && filter?.classId > 0) {
          let payload = {
            ...filter,
            itemsPerPage: 10,
            page: 1,
          }
          getAssessment(payload)
        }
      }
    }
  }, [isAdd, isLoading, getAssessment, filter])
  useEffect(() => {
    if (isGet && !isNotGet) {
      dispatch(setAssessment(getResult?.data?.assessmentList))
      navigate('/assessments')
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: assessmentResult?.data?.detail,
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isGet, isNotGet, getResult?.data, assessmentResult?.data?.detail, dispatch, navigate])
  // calculation of start time and end time
  useEffect(() => {
    if (data?.startTime && data?.endTime && data?.startDate && data?.endDate) {
      // Parse the time strings to Date objects
      const startDate: any = new Date(`${data?.startDate}T${data?.startTime}`)
      const endDate: any = new Date(`${data?.endDate}T${data?.endTime}`)

      // Calculate the time difference in milliseconds
      const timeDifference = endDate - startDate

      // Convert milliseconds to minutes
      const durationInMinutes = timeDifference / (1000 * 60)
      setData((prevData) => {
        return {...prevData, duration: durationInMinutes}
      })
    }
  }, [data?.startTime, data?.startDate, data?.endTime, data?.endDate])
  const handleStreamSelect = (e) => {
    const classData = result.data.class[e.value]
    const classObj =
      classData && classData.length > 0
        ? classData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      streamId: e.value,
      selectedStream: {label: e.label, value: e.value},
      class: classObj,
      classId: 0,
      selectedClass: {value: '', label: ''},
      semester: [],
      semesterId: 0,
      selectedSemester: {value: '', label: ''},
      selectedSubject: {value: '', label: ''},
      subject: [],
      subjectId: 0,
    }
    setFilterData(data)
  }
  const handleClassSelect = (e) => {
    const semesterData = result.data.semester[e.value]
    const semesterOptions =
      semesterData && semesterData.length > 0
        ? semesterData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      classId: e.value,
      selectedClass: {label: e.label, value: e.value},
      semester: semesterOptions,
      selectedSubject: {label: '', value: ''},
      subject: [],
      subjectId: 0,
      semesterId: 0,
      selectedSemester: {value: '', label: ''},
    }
    setFilterData(data)
  }
  const handleSemesterSelect = (e) => {
    const subjectData = result.data.subject[e.value]
    const subject =
      subjectData && subjectData.length > 0
        ? subjectData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      semesterId: e.value,
      selectedSemester: {label: e.label, value: e.value},
      subject: subject,
      selectedSubject: {label: '', value: ''},
      subjectId: 0,
    }
    setFilterData(data)
  }
  const handelChange = (e) => {
    const {name, value} = e.target
    let updatedData = {...data, [name]: value}

    if (name === 'startDate' || name === 'endDate') {
      const startDate = name === 'startDate' ? value : data.startDate
      const endDate = name === 'endDate' ? value : data.endDate

      if (startDate && endDate && startDate > endDate) {
        // Handle error: Start date cannot be after end date
        setErrorMessage({...ErrorMessage, [name]: 'Start date cannot be after end date'})
        return
      }

      if (startDate && endDate && startDate === endDate) {
        // Handle error: Start date and end date cannot be the same
        setErrorMessage({...ErrorMessage, [name]: 'Start date and end date cannot be the same'})
        return
      }

      // Clear error message if no errors
      setErrorMessage({...ErrorMessage, [name]: ''})
    } else {
      updatedData = {...data, [name]: value}
    }

    // Update data state
    setData(updatedData)
  }
  const handelTypeChange = (e) => {
    const newType = e.value
    let defaultOptions = Array.from({length: 4}, () => ({
      text: '',
      isCorrect: false,
    }))
    if (newType === 'long' || newType === 'short') {
      defaultOptions = []
    }
    setData({
      ...data,
      type: newType,
      shortAns: '',
      longAns: '',
      options: defaultOptions,
      selectedType: e,
    })
  }
  function handleSubmit() {
    if (data?.title === '') {
      setErrorMessage({
        ...ErrorMessage,
        title: 'Title is required',
      })
    } else if (data?.description === '') {
      setErrorMessage({
        ...ErrorMessage,
        description: 'Description  is required.',
      })
    } else if (data?.startDate === '') {
      setErrorMessage({
        ...ErrorMessage,
        startDate: 'Start Date is required.',
      })
    } else if (data?.endDate === '') {
      setErrorMessage({
        ...ErrorMessage,
        endDate: 'End Date is required.',
      })
    } else if (data?.startTime === '') {
      setErrorMessage({
        ...ErrorMessage,
        startTime: 'Start Time is required.',
      })
    } else if (data?.endTime === '') {
      setErrorMessage({
        ...ErrorMessage,
        endTime: 'End Time is required.',
      })
    } else if (data?.totalQuestion === '') {
      setErrorMessage({
        ...ErrorMessage,
        totalQuestion: 'Total Question is required.',
      })
    } else if (filterData?.streamId === 0) {
      setErrorMessage({
        ...ErrorMessage,
        streamId: 'Stram is required.',
      })
    } else if (filterData?.classId === 0) {
      setErrorMessage({
        ...ErrorMessage,
        classId: 'Class is required.',
      })
    } else if (filterData?.semesterId === 0) {
      setErrorMessage({
        ...ErrorMessage,
        semesterId: 'Semester is required.',
      })
    } else if (filterData?.subjectId === 0) {
      setErrorMessage({
        ...ErrorMessage,
        subjectId: 'Subject is required.',
      })
    } else {
      setOpen({...open, step2: true, step1: false})
      setStatus({...status, step2: true})
    }
  }
  const editor: any = useRef()
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor
  }
  const handleOptionChange = (e, index) => {
    const {value} = e.target

    setData((prevData) => {
      const updatedOptions = [...prevData.options]
      updatedOptions[index] = {
        ...updatedOptions[index],
        text: value,
      }
      return {...prevData, options: updatedOptions}
    })
  }
  const removeOptions = (index: any) => {
    setData((item) => {
      let updatedOptions = item.options.filter((_, i) => i !== index)
      return {...item, options: updatedOptions}
    })
  }
  const handleCorrectAnswerChange = (index) => {
    setData((prevData) => {
      const updatedOptions = [...prevData.options]

      // For "radio" type, unselect all other options
      if (data.type === 'single') {
        updatedOptions.forEach((option, i) => {
          option.isCorrect = i === index
        })
      } else {
        // For "checkbox" type, toggle the selected option
        updatedOptions[index].isCorrect = !updatedOptions[index].isCorrect
      }

      return {...prevData, options: updatedOptions}
    })
  }
  const handelEditorChange = (content: any) => {
    setData((prevData) => ({
      ...prevData,
      question: content, // Assuming 'content' holds the question from the editor
    }))
  }
  const callApi = () => {
    let payload = {
      dbId: 0,
      streamId: filterData?.streamId,
      classId: filterData?.classId,
      semesterId: filterData?.semesterId,
      subjectId: filterData?.subjectId,
      facultyId: filterData?.facultyId,
      title: data?.title,
      description: data?.description,
      startDate: data?.startDate,
      startTime: data?.startTime,
      endDate: data?.endDate,
      endTime: data?.endTime,
      durationInMinutes: 0,
      totalQuestions: Number(data?.totalQuestion),
      questionArray: questions?.questionArray,
    }
    addAssessment(payload)
  }
  // onCLick of Add Question button
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: '#DC3545',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  })
  const handleAddQuestion = () => {
    const isSingleOrMultiple = data.type === 'single' || data.type === 'multiple'
    const optionsValid = isSingleOrMultiple && data.options.some((option) => option.isCorrect)
    // Check if options' values are blank
    const optionsNotEmpty = data.options.every((option) => option.text.trim() !== '')

    // Check if marks and time are provided
    if (!data.marks || !data.time) {
      Toast.fire({
        icon: 'error',
        title: 'Mark Question and time are required fields.',
      })
      return // Prevent further execution if marks or time are missing
    }

    if (!optionsNotEmpty || (isSingleOrMultiple && !optionsValid)) {
      Toast.fire({
        icon: 'error',
        title: 'Question cannot be added. Please ensure all options are valid.',
      })
      return // Prevent further execution if options are not valid
    }

    const newQuestion = {
      dbId: 0,
      questionNo: 0,
      multipleChoice: data.type === 'multiple',
      singleChoice: data.type === 'single',
      shortAns: data.type === 'short',
      longAns: data.type === 'long',
      totalMarks: Number(data.marks), // Ensure marks are converted to a number
      questionHTMLContent: data.question || 'string', // Replace 'string' with the actual content
      options: isSingleOrMultiple
        ? data.options.map((option, index) => ({
            optionNo: index,
            content: option.text || '', // Replace 'string' with the actual content
            correct: option.isCorrect || false,
            note: data.notes ? data.notes : '', // Replace 'string' with the actual content
          }))
        : data.type === 'short'
        ? data.shortAns
        : data.longAns,
    }

    const resetData = {
      question: '',
      marks: '',
      shortAns: '',
      longAns: '',
      type: '',
      notesCheck: false,
      note: '',
      options: [
        {text: '', isCorrect: false},
        {text: '', isCorrect: false},
        {text: '', isCorrect: false},
        {text: '', isCorrect: false},
      ],
    }

    setQuestions((prevQuestions) => ({
      questionArray:
        prevQuestions.length === 0 ? [newQuestion] : [...prevQuestions.questionArray, newQuestion],
    }))
    setData((prevData) => ({...prevData, ...resetData}))
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <div className='card-title'>
            <h3>
              <Link to={'/assessments'}>
                <button
                  className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                  data-bs-toggle='tooltip'
                  data-bs-placement='top'
                  aria-label='Back'
                  data-bs-original-title='Back'
                  data-kt-initialized='1'
                >
                  <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </Link>
              Add Assessment
            </h3>
          </div>
        </div>
        <div className='card-body '>
          {open?.step1 && (
            <div className='row align-items-center px-7 py-6'>
              <div className='row'>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-md-6 mb-5'>
                      <label className='from-label'>
                        Assessment Title<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        name='title'
                        value={data?.title}
                        onChange={handelChange}
                      />
                      <span className='text-danger fs-8'>{ErrorMessage?.title}</span>
                    </div>
                    <div className='col-md-6 mb-5'>
                      <div className='row'>
                        {/* stream */}
                        <div className=' col-md-6 mb-5 '>
                          <label className='from-label'>
                            Select Stream<span className='text-danger'>*</span>
                          </label>
                          <Select
                            placeholder='select Stream'
                            value={
                              filterData?.selectedStream?.label !== '' &&
                              filterData?.selectedStream?.value !== ''
                                ? filterData?.selectedStream
                                : null
                            }
                            options={filterData?.stream}
                            styles={{
                              option: (base) => ({
                                ...base,
                                cursor: 'pointer',
                                margin: '0px',
                                color: '#7e8299',
                                fontWeight: 'bold',
                                background: 'white', // this was the mistake (I needed to remove this)
                                ':hover': {
                                  color: '#009ef7',
                                  backgroundColor: '#f1faff',
                                },
                              }),
                            }}
                            onChange={(e: any) => {
                              handleStreamSelect(e)
                              setErrorMessage({
                                ...ErrorMessage,
                                streamId: '',
                              })
                            }}
                          />
                          <span className='text-danger fs-8'>{ErrorMessage?.streamId}</span>

                          {/* end::Menu 2 */}
                        </div>
                        {/* class */}
                        <div className='col-md-6  mb-5'>
                          <label className='from-label'>
                            Select Class<span className='text-danger'>*</span>
                          </label>
                          <Select
                            placeholder='select Class'
                            value={
                              filterData?.selectedClass?.label !== '' &&
                              filterData?.selectedClass?.value !== ''
                                ? filterData?.selectedClass
                                : null
                            }
                            options={filterData?.class}
                            isDisabled={filterData?.streamId === 0 ? true : false}
                            styles={{
                              option: (base) => ({
                                ...base,
                                cursor: 'pointer',
                                margin: '0px',
                                color: '#7e8299',
                                fontWeight: 'bold',
                                background: 'white', // this was the mistake (I needed to remove this)
                                ':hover': {
                                  color: '#009ef7',
                                  backgroundColor: '#f1faff',
                                },
                              }),
                            }}
                            onChange={(e: any) => {
                              handleClassSelect(e)
                              setErrorMessage({
                                ...ErrorMessage,
                                classId: '',
                              })
                            }}
                          />
                          <span className='text-danger fs-8'>{ErrorMessage?.classId}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-md-6 mb-5'>
                      <label className='from-label'>
                        Assessment Description<span className='text-danger'>*</span>
                      </label>
                      <textarea
                        className='form-control'
                        rows={4}
                        value={data?.description}
                        name='description'
                        onChange={handelChange}
                      />
                      <span className='text-danger fs-8'>{ErrorMessage?.description}</span>
                    </div>
                    <div className='col-md-6 mb-5'>
                      <div className='row'>
                        {/* semester */}
                        <div className='mb-5 col-md-6'>
                          <label className='from-label'>
                            Select Semester<span className='text-danger'>*</span>
                          </label>
                          <Select
                            placeholder='select semester'
                            value={
                              filterData?.selectedSemester?.label !== '' &&
                              filterData?.selectedSemester?.value !== ''
                                ? filterData?.selectedSemester
                                : null
                            }
                            isDisabled={filterData?.classId === 0 ? true : false}
                            styles={{
                              option: (base) => ({
                                ...base,
                                cursor: 'pointer',
                                margin: '0px',
                                color: '#7e8299',
                                fontWeight: 'bold',
                                background: 'white', // this was the mistake (I needed to remove this)
                                ':hover': {
                                  color: '#009ef7',
                                  backgroundColor: '#f1faff',
                                },
                              }),
                            }}
                            options={filterData?.semester}
                            onChange={(e: any) => {
                              handleSemesterSelect(e)
                              setErrorMessage({
                                ...ErrorMessage,
                                semesterId: '',
                              })
                            }}
                          />
                          <span className='text-danger fs-8'>{ErrorMessage?.semesterId}</span>
                        </div>
                        {/* subject */}
                        <div className='col-md-6 mb-5'>
                          <label className='from-label'>
                            Select Subject<span className='text-danger'>*</span>
                          </label>
                          <Select
                            value={
                              filterData?.selectedSubject?.label !== '' &&
                              filterData?.selectedSubject?.value !== ''
                                ? filterData?.selectedSubject
                                : null
                            }
                            isDisabled={filterData?.semesterId === 0 ? true : false}
                            options={filterData?.subject}
                            styles={{
                              option: (base) => ({
                                ...base,
                                cursor: 'pointer',
                                margin: '0px',
                                color: '#7e8299',
                                fontWeight: 'bold',
                                background: 'white', // this was the mistake (I needed to remove this)
                                ':hover': {
                                  color: '#009ef7',
                                  backgroundColor: '#f1faff',
                                },
                              }),
                            }}
                            onChange={(e: any) => {
                              setFilterData({
                                ...filterData,
                                subjectId: e.value,
                                selectedSubject: {label: e.label, value: e.value},
                              })
                              setErrorMessage({
                                ...ErrorMessage,
                                subjectId: '',
                              })
                            }}
                          />
                          <span className='text-danger fs-8'>{ErrorMessage?.subjectId}</span>
                        </div>
                        <div className='col-md-6 mb-5 position-relative'>
                          <label className='from-label'>
                            Start Date<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='date'
                            className='form-control p-0'
                            name='startDate'
                            value={data?.startDate}
                            onChange={handelChange}
                            min={today}
                          />
                          <div
                            className='position-absolute form-label'
                            style={{top: '48%', left: '27%', pointerEvents: 'none'}}
                          >
                            <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                            {data?.startDate ? data?.startDate : 'Start Date*'}
                          </div>
                          <span className='text-danger fs-8'>{ErrorMessage?.startDate}</span>
                        </div>
                        <div className='col-md-6 mb-5 position-relative'>
                          <label className='from-label'>
                            End Date<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='date'
                            className='form-control p-0'
                            name='endDate'
                            value={data?.endDate}
                            onChange={handelChange}
                            min={data?.startDate}
                          />
                          <div
                            className='position-absolute form-label'
                            style={{top: '48%', left: '27%', pointerEvents: 'none'}}
                          >
                            <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                            {data?.endDate ? data?.endDate : 'End Date*'}
                          </div>
                          <span className='text-danger fs-8'>{ErrorMessage?.endDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12'>
                  <div className='row'>
                    <div className='col-md-6 mb-5'>
                      <div className='row'>
                        <div className='col-md-6 mb-5'>
                          <label className='from-label'>
                            Total Questions<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='totalQuestion'
                            min={1}
                            value={data.totalQuestion}
                            onChange={handelChange}
                          />
                          <span className='text-danger fs-8'>{ErrorMessage?.totalQuestion}</span>
                        </div>
                        <div className='col-md-6 mb-5'>
                          <h5 className='form-label'>
                            Faculty<span className='text-danger'>*</span>
                          </h5>
                          <Select
                            value={
                              filterData?.selectedFaculty?.label !== '' &&
                              filterData?.selectedFaculty?.value !== ''
                                ? filterData?.selectedFaculty
                                : null
                            }
                            options={filterData?.faculty}
                            styles={{
                              option: (base) => ({
                                ...base,
                                cursor: 'pointer',
                                margin: '0px',
                                color: '#7e8299',
                                fontWeight: 'bold',
                                background: 'white', // this was the mistake (I needed to remove this)
                                ':hover': {
                                  color: '#009ef7',
                                  backgroundColor: '#f1faff',
                                },
                              }),
                            }}
                            filterOption={(option: any, inputValue) =>
                              option?.label?.props?.children[0]?.props?.alt
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                            }
                            onChange={(e: any) => {
                              setFilterData({
                                ...filterData,
                                facultyId: e.value,
                                selectedFaculty: {label: e.label, value: e.value},
                              })
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 mb-5'>
                      <div className='row'>
                        <div className='col-md-6 mb-5 position-relative '>
                          <label className='from-label'>
                            Start Time<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='time'
                            className='form-control p-0'
                            name='startTime'
                            onChange={(e) => {
                              setData({...data, startTime: e.target.value})
                            }}
                          />
                          <div
                            className='position-absolute form-label'
                            style={{top: '48%', left: '27%', pointerEvents: 'none'}}
                          >
                            <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                            {data?.startTime ? data?.startTime : 'Start Time*'}
                          </div>
                          <span className='text-danger fs-8'>{ErrorMessage?.startTime}</span>
                        </div>
                        <div className='col-md-6 mb-5 position-relative '>
                          <label className='from-label'>
                            End Time<span className='text-danger'>*</span>
                          </label>
                          <input
                            type='time'
                            className='form-control p-0'
                            name='endTime'
                            onChange={(e) => {
                              setData({...data, endTime: e.target.value})
                            }}
                          />
                          <div
                            className='position-absolute form-label'
                            style={{top: '48%', left: '27%', pointerEvents: 'none'}}
                          >
                            <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                            {data?.endTime ? data?.endTime : 'Start Time*'}
                          </div>
                          <span className='text-danger fs-8'>{ErrorMessage?.endTime}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 text-end'>
                <button
                  onClick={() => {
                    handleSubmit()
                  }}
                  className='btn d-flex  gap-2 btn-primary'
                >
                  Next
                  <i className='ki-duotone ki-right-square fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-md-7'>
              {open?.step2 && (
                <>
                  <div className=' px-7 py-6'>
                    <div className='d-flex flex-wrap align-items-center  justify-content-between'>
                      <div className='d-flex flex-column flex-wrap mb-5 align-items-center gap-2'>
                        <h2>{data?.title}</h2>
                        <p className='text-muted'>{data?.description}</p>
                      </div>
                      <div className='d-flex align-items-center mb-5 gap-2'>
                        <button className='btn btn-light-primary text-center'>
                          <i className='ki-duotone ki-eye fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                          <span className='d-md-block d-none'>Preview</span>
                        </button>
                        <button className='btn btn-primary text-center' onClick={callApi}>
                          <i className='ki-duotone ki-notepad fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                            <span className='path4'></span>
                            <span className='path5'></span>
                          </i>
                          <span className='d-md-block d-none'>Publish</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='section pt-8 border-top'>
                    <div className='row'>
                      <div className='col-md-4 mb-5'>
                        <Select
                          placeholder='select Type'
                          value={
                            data?.selectedType?.label !== '' && data?.selectedType?.value !== ''
                              ? data?.selectedType
                              : null
                          }
                          options={data?.typeArray}
                          styles={{
                            option: (base) => ({
                              ...base,
                              cursor: 'pointer',
                              margin: '0px',
                              color: '#7e8299',
                              fontWeight: 'bold',
                              background: 'white', // this was the mistake (I needed to remove this)
                              ':hover': {
                                color: '#009ef7',
                                backgroundColor: '#f1faff',
                              },
                            }),
                          }}
                          onChange={(e: any) => {
                            handelTypeChange(e)
                          }}
                        />
                      </div>
                      <div className='col-md-12 text-start'>
                        <label className='form-label'>
                          Question Text <span className='text-danger'>*</span>
                        </label>
                        <div className='row align-items-center'>
                          <div className='col-12 mb-5'>
                            <SunEditor
                              setAllPlugins={true}
                              setOptions={options}
                              placeholder='Add Question here...'
                              lang={langs}
                              autoFocus={true}
                              height='100%'
                              setDefaultStyle='font-family: Arial, sans-serif; font-size: 18px;'
                              width='100%'
                              getSunEditorInstance={getSunEditorInstance}
                              onChange={(e) => handelEditorChange(e)}
                            />
                          </div>
                          <label className='form-label'>
                            Answers <span className='text-danger'>*</span>
                          </label>
                          {Number(data?.totalQuestion) === questions?.questionArray?.length ? (
                            <div className='col-12  mb-5'>
                              <div className='row'>
                                <div className='col-6'>
                                  <button className='btn w-100 d-flex align-items-center gap-2  justify-content-center btn-light-primary text-center'>
                                    <i className='fa-solid fa-eye p-0'></i>
                                    <span className='d-md-block d-none'>Preview</span>
                                  </button>
                                </div>
                                <div className='col-6'>
                                  <button
                                    className='btn btn-primary w-100 text-center d-flex align-items-center gap-2  justify-content-center'
                                    onClick={callApi}
                                  >
                                    <i className='fa-solid fa-save p-0'></i>
                                    <span className='d-md-block d-none'>Publish</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className='col-12 mb-5'>
                                {(data?.type === 'multiple' || data?.type === 'single') && (
                                  <div className='row'>
                                    {data?.options?.map((item, index) => {
                                      return (
                                        <div className='col-12 mb-5'>
                                          <div
                                            className='d-flex w-100 align-items-center'
                                            style={{gap: '5px'}}
                                          >
                                            <input
                                              type={data?.type === 'single' ? 'radio' : 'checkbox'}
                                              checked={item?.isCorrect}
                                              className='form-check-input'
                                              id={index}
                                              name={data?.type === 'single' ? 'radio' : 'checkbox'}
                                              onChange={() => handleCorrectAnswerChange(index)}
                                            />
                                            <input
                                              type='text'
                                              className='form-control'
                                              id={index + `option${index + 1}`}
                                              name={`option${index + 1}`}
                                              value={item.text}
                                              onChange={(e) => handleOptionChange(e, index)}
                                              placeholder='Add option'
                                            />
                                            {data?.options?.length > 2 && (
                                              <div
                                                className='p-2 btn btn-danger  rounded'
                                                onClick={() => {
                                                  removeOptions(index)
                                                }}
                                                style={{cursor: 'pointer'}}
                                              >
                                                <i className='ki-duotone ki-cross-circle fs-1 p-0'>
                                                  <span className='path1'></span>
                                                  <span className='path2'></span>
                                                </i>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                                {(data?.type === 'short' || data?.type === 'long') && (
                                  <textarea
                                    placeholder='Provide Concise Response Here'
                                    className='form-control h-100'
                                    name={data?.type}
                                    rows={5}
                                    value={data?.type === 'short' ? data?.shortAns : data?.longAns}
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        [`${data?.type}Ans`]: e.target.value,
                                      })
                                    }}
                                  ></textarea>
                                )}
                              </div>
                              <div className='row'>
                                <div className='col-12 mb-5'>
                                  <label className='form-label'>Mark Question</label>
                                  <input
                                    type='number'
                                    className='form-control'
                                    placeholder='Add Mark Question Here'
                                    name='marks'
                                    onChange={handelChange}
                                  />
                                </div>
                                <div className='col-12 mb-5 position-relative'>
                                  <label className='from-label'>
                                    Duration in seconds<span className='text-danger'>*</span>
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control'
                                    name='time'
                                    value={data?.time}
                                    onChange={handelChange}
                                  />
                                  <span className='text-danger fs-8'>{ErrorMessage?.time}</span>
                                </div>
                                <div className='col-12 mb-5'>
                                  <div className='form-check'>
                                    <input
                                      type='checkbox'
                                      className='form-check-input'
                                      id='addNotesCheckbox'
                                      checked={data?.notesCheck}
                                      onChange={(e) => {
                                        setData({
                                          ...data,
                                          notesCheck: e.target.checked,
                                        })
                                      }}
                                    />
                                    <label htmlFor='addNotesCheckbox'>Add Notes?</label>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`col-12 d-flex gap-5 align-items-center justify-content-end mb-5 text-end`}
                              >
                                <button
                                  style={{cursor: 'pointer'}}
                                  className='btn btn-primary '
                                  onClick={handleAddQuestion}
                                >
                                  <i className='ki-duotone ki-message-add fs-1'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>{' '}
                                  Add Question
                                </button>
                                {(data?.type === 'multiple' || data?.type === 'single') && (
                                  <button
                                    className='btn btn-light-primary '
                                    onClick={() => {
                                      setData({...data, options: [...data?.options, '']})
                                    }}
                                  >
                                    <i className='ki-duotone ki-message-add fs-1'>
                                      <span className='path1'></span>
                                      <span className='path2'></span>
                                      <span className='path3'></span>
                                    </i>{' '}
                                    Add Options
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                          {data?.notesCheck && (
                            <div className='col-12'>
                              <textarea
                                className='form-control'
                                name='notes'
                                onChange={(e) => {
                                  setData({...data, note: e.target.value})
                                }}
                                rows={3}
                                placeholder='Add notes'
                              ></textarea>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className='col-md-5'>
              {Object.keys(questions).length > 0 && (
                <AssessmentQuiz questions={questions?.questionArray} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
