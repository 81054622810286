import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLazyAcademicFilterQuery} from '../../../Api/allApi'
import Select from 'react-select'
import {useDispatch} from 'react-redux'
import {setSubjectFilter} from '../../../redux/CourseSlice'

export default function AcademicCourseHeader() {
  const dispatch = useDispatch()
  // Query data for filtering
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  const [filterData, setFilterData] = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    semester: [],
    semesterId: [],
    selectedSemester: [],
  })

  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])

  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = result?.data

      // Select the last stream
      const lastStream = data.stream[data.stream.length - 1]
      const selectedStream = {value: lastStream?.dbId, label: lastStream?.name}

      // Get classes of the last stream
      const lastStreamClasses = data.class[lastStream.dbId]
      const classObj =
        lastStreamClasses && lastStreamClasses.length > 0
          ? lastStreamClasses.map((item) => ({value: item.dbId, label: item.name}))
          : []

      const semester = data?.semester[classObj[classObj.length - 1].value] || []
      var semesterObj =
        semester?.length > 0 ? semester?.map((item) => ({value: item.dbId, label: item.name})) : []
      // Set all semester IDs
      const semesterIds = semesterObj.map((semester) => semester.value)
      // Set default values for stream, class, streamId, and classId
      setFilterData((prevData) => ({
        ...prevData,
        stream: data.stream.map((item) => ({value: item.dbId, label: item.name})) || [],
        selectedStream,
        streamId: lastStream?.dbId || 0, // Set stream ID
        class: classObj,
        selectedClass:
          classObj.length > 0 ? classObj[classObj?.length - 1] : {value: '', label: ''},
        classId: classObj.length > 0 ? classObj[classObj?.length - 1].value : 0, // Set class ID
        semester: semesterObj,
        selectedSemester: semesterObj,
        semesterId: semesterIds,
      }))
    }
  }, [isSuccess, isFetching, result?.data])
  const handleStreamSelect = (e) => {
    const classData = result.data.class[e.value]
    const classObj =
      classData && classData.length > 0
        ? classData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      streamId: e.value,
      selectedStream: {label: e.label, value: e.value},
      class: classObj,
      classId: 0,
      selectedClass: {value: '', label: ''},
      semester: [],
      semesterId: [],
      selectedSemester: [],
      selectedSubject: {value: '', label: ''},
      subject: [],
      subjectId: 0,
    }
    setFilterData(data)
  }

  const handleClassSelect = (e) => {
    const semesterData = result.data.semester[e.value]
    const semesterOptions =
      semesterData && semesterData.length > 0
        ? semesterData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      classId: e.value,
      selectedClass: {label: e.label, value: e.value},
      semester: semesterOptions,
      selectedSemester: [],
    }
    setFilterData(data)
  }

  const handleSemesterSelect = (selectedOptions) => {
    const selectedSemesterValues = selectedOptions.map((option) => option.value)

    setFilterData((prevData) => ({
      ...prevData,
      selectedSemester: selectedOptions,
      semesterId: selectedSemesterValues,
    }))
  }
  useEffect(() => {
    dispatch(
      setSubjectFilter({
        streamId: filterData?.streamId,
        classId: filterData?.classId,
        semesterId: filterData?.semesterId,
      })
    )
  }, [filterData, dispatch])
  return (
    <div>
      <div className={`card  mb-6`}>
        {/* begin::Header */}
        <div className='card-header align-tels-center'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Academic Courses</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Manage Academic Courses and Progress
            </span>
          </h3>
          <div className='card-toolbar'>
            {/* stream */}
            <div className='me-3 mb-3'>
              <Select
                placeholder='select Stream'
                value={
                  filterData?.selectedStream?.label !== '' &&
                  filterData?.selectedStream?.value !== ''
                    ? filterData?.selectedStream
                    : null
                }
                options={filterData?.stream}
                onChange={(e) => {
                  handleStreamSelect(e)
                }}
              />
            </div>
            {/* class */}
            <div className='me-3 mb-3'>
              <Select
                placeholder='select Class'
                value={
                  filterData?.selectedClass?.label !== '' && filterData?.selectedClass?.value !== ''
                    ? filterData?.selectedClass
                    : null
                }
                options={filterData?.class}
                isDisabled={filterData?.streamId === 0 ? true : false}
                onChange={(e) => {
                  handleClassSelect(e)
                }}
              />
            </div>
            {/* semester */}
            <div className='me-3 mb-3'>
              <Select
                placeholder='select semester'
                value={filterData?.selectedSemester}
                isDisabled={filterData?.classId === 0 ? true : false}
                isMulti
                options={filterData?.semester}
                onChange={(selectedOptions) => {
                  handleSemesterSelect(selectedOptions)
                }}
              />
            </div>
            <Link to='/Courses/Academic/Add'>
              <button className='btn btn-light-primary'>
                <i className='ki-duotone ki-message-add fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Add Course
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
