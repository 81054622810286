import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Link, useLocation} from 'react-router-dom'
import DataTables from '../../DataTable/DataTables'
import {
  useDeleteJobMutation,
  useGetJobsMutation,
  useLazyGetJobByStatusQuery,
} from '../../../Api/allApi'
import Swal from 'sweetalert2'
import {useDispatch} from 'react-redux'
import {setJob} from '../../../redux/JobSlice'
import {useSelector} from 'react-redux'
import {JobUpdateModal} from './JobUpdateModal'
const AllJobs = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [selected, setSelected]: any = useState({})
  // for data table
  const {job} = useSelector((state: any) => state.JobSlice)
  // get jobList api
  const [getJobList, result] = useGetJobsMutation()
  const {isSuccess, isLoading} = result
  // get jobList by status api
  const [getJobListByStatus, statusResult] = useLazyGetJobByStatusQuery()
  const {
    isSuccess: isStatus,
    isFetching: isNotStatus,
    isError: statusError,
    error: sError,
  } = statusResult
  // delete job list api
  const [deleteJob, deleteResult] = useDeleteJobMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete, isError, error} = deleteResult
  // get job list
  useEffect(() => {
    if (location?.pathname === '/jobs/all') {
      getJobList({})
    } else {
      let status = location.pathname.split('/')[2]
      getJobListByStatus(status)
    }
  }, [getJobList, location, getJobListByStatus])
  // call when api returns 200 status
  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(setJob(result?.data?.jobList))
    }
    if (isStatus && !isNotStatus) {
      dispatch(setJob(statusResult?.data?.jobList))
    }
  }, [
    isSuccess,
    isLoading,
    result?.data?.jobList,
    dispatch,
    isStatus,
    isNotStatus,
    statusResult?.data?.jobList,
  ])
  useEffect(() => {
    if (isDelete && !isNotDelete) {
      getJobList({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, getJobList, deleteResult?.data?.detail])
  // call when api returns error
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-right',
    iconColor: '#DC3545',
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton: false,
    timer: 1500,
    timerProgressBar: true,
  })
  useEffect(() => {
    if (statusError) {
      Toast.fire({
        icon: 'error',
        title: sError?.error ? sError?.error : 'Something went wrong',
      })
    }
  }, [statusError, sError?.error, Toast])
  useEffect(() => {
    if (isError) {
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error?.error, Toast])
  // data table data
  const columns = [
    {
      name: 'Position Title',
      selector: (row) => row?.title,
      center: true,
      sortable: true,
    },
    {
      name: 'Company Name',
      selector: (row) => row?.title,
      center: true,
      sortable: true,
    },
    {
      name: 'Contract type',
      selector: (row) => row?.contractType,
      center: true,
      sortable: true,
    },
    {
      name: 'Job Location',
      selector: (row) => row?.location,
      center: true,
      sortable: true,
    },
    {
      name: 'Job Date',
      selector: (row) => moment(row?.publishedAt).format('MM/DD/YYYY'),
      center: true,
      sortable: true,
    },
    {
      name: 'Job Time',
      selector: (row) => row?.contractType,
      center: true,
      sortable: true,
    },
    {
      name: 'Job Salary',
      selector: (row) => row?.salary,
      center: true,
      sortable: true,
    },
    {
      style: {
        justifyContent: 'end',
      },
      name: '',
      selector: (row) => {
        return (
          <>
            {location?.pathname === '/jobs/all' || location?.pathname !== '/jobs/closed' ? null : (
              <button
                className='btn btn-sm btn-light-warning me-3'
                type='button'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='modal'
                style={{height: 'unset', width: 'unset'}}
                data-bs-target='#Update'
                onClick={() => setSelected(row)}
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                View Application
              </button>
            )}

            <Link to={`/jobs/addJob?jobId=${row?.dbId}`} className='me-3'>
              <button className='btn btn-sm btn-light-primary'>
                <i className='fa-solid fa-eye'></i> Edit
              </button>
            </Link>
            <button
              className='btn btn-sm btn-light-danger me-3'
              onClick={() => {
                Swal.fire({
                  title: 'Confirm deletion?',
                  showDenyButton: false,
                  showCancelButton: true,
                  confirmButtonText:
                    "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                  confirmButtonColor: '#009EF7',
                  cancelButtonText:
                    '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    deleteJob(row?.dbId)
                  } else if (result.isDenied) {
                    Swal.fire('Record is not deleted', '', 'error')
                  }
                })
              }}
            >
              <i className='ki-duotone ki-trash-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>{' '}
              Delete
            </button>
          </>
        )
      },
      width: '300px',
    },
  ]
  // pagination
  const [totalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const handlePageChange = (page) => {
    setPage(page)
  }
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
  }
  return (
    <>
      {/* header-start */}
      <div className='card my-5'>
        <div className='card-body'>
          <DataTables
            columns={columns}
            data={job}
            loading={location?.pathname === '/jobs/all' ? isLoading : isNotStatus}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            page={page}
            perPage={perPage}
          />
        </div>
        <div className='modal fade' id='Update' aria-hidden='true'>
          <JobUpdateModal data={selected} />
        </div>
      </div>
      {/* body-end */}
    </>
  )
}

export default AllJobs
