import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useAddCategoryMutation, useLazyGetCourseCategoryQuery} from '../../../Api/allApi'
import Swal from 'sweetalert2'
import {useDispatch} from 'react-redux'
import {setCategory} from '../../../redux/CourseSlice'
const Author = Yup.object().shape({
  categoryName: Yup.string().required('Name is required'),
})
/* eslint-disable jsx-a11y/anchor-is-valid */
const CategoryModel = ({name, data, type}) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({categoryName: ''})
  // add Category  api
  const [addCategory, result] = useAddCategoryMutation()
  const {isSuccess, isLoading} = result
  // get Category list api
  const [categoryList, authorResult] = useLazyGetCourseCategoryQuery()
  const {isSuccess: isAuthor, isFetching: isNotAuthor} = authorResult

  const formik = useFormik({
    initialValues,
    enableReinitialize: data,
    validationSchema: Author,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: data?.dbId || 0,
          categoryName: values?.categoryName,
        }
        addCategory(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // call when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isLoading) {
      categoryList({})
    }
  }, [isSuccess, isLoading, categoryList])
  useEffect(() => {
    if (isAuthor && !isNotAuthor) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your record has been Added',
        showConfirmButton: false,
        timer: 1500,
      })
      dispatch(setCategory(result?.data?.courseCategoryList))
    }
  }, [isAuthor, isNotAuthor, result?.data?.courseCategoryList, dispatch])

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setInitialValues({
        categoryName: data?.categoryName,
      })
    }
  }, [data])
  return (
    <div className={`modal-dialog modal-dialog-centered mw-1000px `}>
      <div className='modal-content'>
        <div className='modal-header py-7 d-flex justify-content-between'>
          <h2>Add {name}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x='6'
                  y='17.3137'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y='6'
                  width='16'
                  height='2'
                  rx='1'
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
          </div>
        </div>
        <div className='modal-body scroll-y '>
          <div className='stepper stepper-links d-flex flex-column' id={name}>
            <div className='tab-content' id='myTabContent'>
              <div className='tab-pane fade show active' id='Details' role='tabpanel'>
                <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='Author'>
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <div className='fs-6 text-muted'>
                        <span className='text-danger'>*</span> indicates required{' '}
                      </div>
                    </div>
                    <div className='col-12 mb-5'>
                      <label className='form-label'>
                        Category Name<span className='text-danger'>*</span>
                      </label>
                      <input
                        type='text'
                        placeholder='Add Author Name'
                        {...formik.getFieldProps('categoryName')}
                        className={clsx(
                          'form-control bg-transparent',
                          {
                            'is-invalid': formik.touched.categoryName && formik.errors.categoryName,
                          },
                          {
                            'is-valid': formik.touched.categoryName && !formik.errors.categoryName,
                          }
                        )}
                      />
                    </div>
                    <div className='col-12 border-top text-end'>
                      <button
                        type='submit'
                        data-bs-dismiss={'modal'}
                        className='btn btn-primary'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && <span className='indicator-label'>Continue</span>}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {CategoryModel}
