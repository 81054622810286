import React, {useState, useEffect} from 'react'

import Layout from './components/Layout'
import Loader from './components/Loader'
import Quiz from './components/Quiz'

const App = ({questions}) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [isQuizStarted, setIsQuizStarted] = useState(true)

  useEffect(() => {
    let qna = []
    questions?.map((item, index) => {
      qna.push({
        id: index + 1,
        options: item?.options,
        question: item?.questionHTMLContent,
        optionType: item?.multipleChoice
          ? 'multiple'
          : item?.singleChoice
          ? 'single'
          : item?.shortAns
          ? 'short'
          : item?.longAns
          ? 'long'
          : '',
        user_answer: item?.submittedOptionIndex,
      })
    })
    setData(qna)
  }, [questions])

  const endQuiz = (resultData) => {
    setLoading(true)
    setTimeout(() => {
      setIsQuizStarted(false)
      setLoading(false)
    }, 2000)
  }

  return (
    <Layout>
      {loading && <Loader />}
      {/* {!loading && !isQuizStarted && !isQuizCompleted && <Main startQuiz={startQuiz} />} */}
      {!loading && isQuizStarted && <Quiz data={data} endQuiz={endQuiz} />}
    </Layout>
  )
}

export default App
