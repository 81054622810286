import React, {useState} from 'react'
import Select from 'react-select'
import {WithContext as ReactTags} from 'react-tag-input'
import {FileUploader} from 'react-drag-drop-files'
import './Course.css'
export default function AddAdmission() {
  let type = [
    {value: 'Academic', label: 'Academic'},
    {value: 'Explore Courses', label: 'Explore Courses'},
  ]
  let type1 = [
    {value: 'Academic', label: 'Academic'},
    {value: 'stringed', label: 'stringed'},
    {value: 'university', label: 'university'},
    {value: 'others', label: 'others'},
  ]
  let apply = [{value: 'FresherMan', label: 'FresherMan'}]
  let program = [{value: 'Diploma', label: 'Diploma'}]

  const [open, setOpen] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  })
  const [status, setStatus] = useState({
    step1: true,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
  })
  const [ErrorMessage, setErrorMessage]: any = useState({
    title: '',
    coverImage: '',
    category: '',
    level: '',
    description: '',
    type: '',
    categoryType: '',
    list: '',
    language: '',
    text: '',
    FAQTitle: '',
    FAQDesc: '',
    ContentDesc: '',
    ContentTitle: '',
    video: '',
    cost: '',
    price: '',
  })
  const [data, setData]: any = useState({
    title: '',
    coverImage: '',
    category: '',
    level: '',
    tags: [],
    language: [],
    description: '',
    type: '',
    categoryType: '',
    list: '',
    learnList: [],
    text: '',
    textList: [],
    FAQTitle: '',
    FAQDesc: '',
    FAQ: [],
    ContentDesc: '',
    ContentTitle: '',
    CourseContent: [],
    videoDesc: '',
    videoTitle: '',
    instructorName: '',
    instructorImage: '',
    instructorDesc: '',
    cost: false,
    price: '',
  })
  // skills and contributor
  const KeyCodes: any = {
    comma: 188,
    enter: 13,
  }
  const delimiters: any = [KeyCodes?.comma, KeyCodes?.enter]
  const handleTagDelete = (i: any) => {
    setData({
      ...data,
      tags: data?.tags.filter((tag: any, index: any) => index !== i),
    })
  }
  const handleAddition = (tag: any) => {
    if (ErrorMessage?.tags !== '') {
      setErrorMessage({
        ...ErrorMessage,
        tags: '',
      })
    }
    let newValues: any = []
    data?.tags?.forEach((tag: any) => {
      newValues.push(tag.text)
    })
    setData({
      ...data,
      tags: [...data?.tags, tag],
    })
    document.getElementById('metaValue')!.style.border = '1px solid rgb(238, 238, 238)'
  }
  const handleTagDeleteL = (i: any) => {
    setData({
      ...data,
      language: data?.language.filter((tag: any, index: any) => index !== i),
    })
  }
  const handleAdditionL = (tag: any) => {
    if (ErrorMessage?.language !== '') {
      setErrorMessage({
        ...ErrorMessage,
        language: '',
      })
    }
    let newValues: any = []
    data?.language?.forEach((tag: any) => {
      newValues.push(tag.text)
    })
    setData({
      ...data,
      language: [...data?.language, tag],
    })
    document.getElementById('metaValue')!.style.border = '1px solid rgb(238, 238, 238)'
  }
  const handelChange: any = (e) => {
    setData({...data, [e.target.name]: e.target.value})
    setErrorMessage({
      ...ErrorMessage,
      [e.target.name]: '',
    })
  }
  function handleSubmit() {
    if (data?.title === '') {
      setErrorMessage({
        ...ErrorMessage,
        title: 'Title is required',
      })
    } else if (data?.coverImage === '') {
      setErrorMessage({
        ...ErrorMessage,
        coverImage: 'Image is required',
      })
    } else if (data?.category === '') {
      setErrorMessage({
        ...ErrorMessage,
        category: 'Category is required',
      })
    } else if (data?.level === '') {
      setErrorMessage({
        ...ErrorMessage,
        level: 'Level is required',
      })
    } else if (data?.description === '') {
      setErrorMessage({
        ...ErrorMessage,
        description: 'Description is required',
      })
    } else if (data?.tags?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        tags: 'Tags is required',
      })
    } else if (data?.language?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        language: 'Language is required',
      })
    } else if (data?.type === '') {
      setErrorMessage({
        ...ErrorMessage,
        type: 'Type is required',
      })
    } else if (data?.categoryType === '') {
      setErrorMessage({
        ...ErrorMessage,
        categoryType: 'categoryType is required',
      })
    } else {
      setOpen({...open, step2: true, step1: false})
      setStatus({...status, step2: true})
    }
  }
  function handleSubmitPageTwo() {
    if (data?.learnList?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        list: 'Please Enter Learn List',
      })
    } else if (data?.textList?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        text: 'Please Enter Text List',
      })
    } else if (data?.FAQ?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        FAQTitle: 'FAQTitle is required',
        FAQDesc: 'FAQDesc is required',
      })
    } else if (data?.CourseContent?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        ContentDesc: 'ContentDesc is required',
        ContentTitle: 'ContentTitle is required',
      })
    } else if (data?.instructorName === '') {
      setErrorMessage({
        ...ErrorMessage,
        instructorName: 'instructorName is required',
      })
    } else if (data?.instructorImage === '') {
      setErrorMessage({
        ...ErrorMessage,
        instructorImage: 'instructorImage is required',
      })
    } else if (data?.instructorDesc === '') {
      setErrorMessage({
        ...ErrorMessage,
        instructorDesc: 'instructorDesc is required',
      })
    } else {
      setOpen({...open, step2: false, step1: false, step3: true})
      setStatus({...status, step3: true})
    }
  }
  function handleSubmitPageThree() {
    if (video?.length === 0) {
      setErrorMessage({
        ...ErrorMessage,
        video: 'Video is required',
      })
    } else if (data?.videoDesc === '') {
      setErrorMessage({
        ...ErrorMessage,
        videoDesc: 'videoDesc is required',
      })
    } else if (data?.videoTitle === '') {
      setErrorMessage({
        ...ErrorMessage,
        videoTitle: 'videoTitle is required',
      })
    } else {
      setOpen({...open, step2: false, step1: false, step3: false, step4: true, step5: false})
      setStatus({...status, step4: true})
    }
  }
  function handleSubmitPageFour() {
    // else {
    setStatus({...status, step5: true})
    setOpen({...open, step2: false, step1: false, step3: false, step4: false, step5: true})
    // }
  }

  const [video, setVideo]: any = useState({
    coverVideo: [],
    courseMaterial: [],
  })
  const handleChange: any = (files: any, type: any) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      if (type === 2) {
        setData({
          ...data,
          instructorImage: base64String,
        })
      } else {
        setData({
          ...data,
          coverImage: base64String,
        })
      }
    }
    if (files[0]) {
      reader.readAsDataURL(files[0])
      if (type === 2) {
        setData({
          ...data,
          instructorImage: files[0],
        })
      } else {
        setData({
          ...data,
          coverImage: files[0],
        })
      }
    }
  }
  const handleChangeVideo: any = (file: any, type: any) => {
    const selectedFIles: any = []
    const targetFiles: any = file
    const targetFilesObject: any = [...targetFiles]
    targetFilesObject?.map((file: any) => {
      return selectedFIles.push(URL.createObjectURL(file))
    })

    if (type === 2) {
      setVideo({...video, courseMaterial: [...selectedFIles]})
    } else {
      setVideo({...video, coverVideo: [...selectedFIles]})
    }
  }
  const removeVideo: any = (index: any) => {
    let arr = video
    arr.splice(index, 1)
    setVideo([...arr])
  }
  return (
    <>
      <div className='card'>
        <div className='card-body'>
          <div className='d-flex align-items-center flex-wrap mb-5' style={{gap: '20px'}}>
            <div
              className='d-flex align-items-center'
              style={{gap: '10px', cursor: 'pointer'}}
              onClick={() => {
                status?.step1 &&
                  setOpen({
                    ...open,
                    step1: true,
                    step2: false,
                    step3: false,
                    step4: false,
                    step5: false,
                  })
              }}
            >
              <div className='d-flex align-items-center'>
                <div className='px-2 py-1 me-1  rounded-circle bg-primary'>
                  <h5 className='text-white'>1</h5>
                </div>
                <span className={`fw-bold fs-7 ${status?.step1 ? 'text-primary' : 'text-muted'}`}>
                  Course Information
                </span>
              </div>
              <div>
                <i
                  className={`fa-solid fa-chevron-right ${
                    status?.step2 ? 'text-primary' : 'text-muted'
                  }`}
                ></i>
              </div>
            </div>
            <div
              className='d-flex align-items-center '
              style={{gap: '10px', cursor: 'pointer'}}
              onClick={() => {
                status?.step1 &&
                  status?.step2 &&
                  setOpen({
                    ...open,
                    step1: false,
                    step2: true,
                    step3: false,
                    step4: false,
                    step5: false,
                  })
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className={`px-2 py-1 me-1 ${
                    status?.step2 ? 'bg-primary' : 'bg-secondary'
                  }  rounded-circle `}
                >
                  <h5 className='text-white'>2</h5>
                </div>
                <span className={`fw-bold fs-7 ${status?.step2 ? 'text-primary' : 'text-muted'}`}>
                  Additional Information
                </span>
              </div>
              <div>
                <i
                  className={`${
                    status?.step3 ? 'text-primary' : 'text-muted'
                  } fa-solid fa-chevron-right`}
                ></i>
              </div>
            </div>
            <div
              className='d-flex align-items-center '
              style={{gap: '10px', cursor: 'pointer'}}
              onClick={() => {
                status?.step1 &&
                  status?.step2 &&
                  status?.step3 &&
                  setOpen({
                    ...open,
                    step1: false,
                    step2: false,
                    step3: true,
                    step4: false,
                    step5: false,
                  })
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className={`px-2 py-1 me-1 ${
                    status?.step3 ? 'bg-primary' : 'bg-secondary'
                  }  rounded-circle `}
                >
                  <h5 className='text-white'>3</h5>
                </div>
                <span className={`fw-bold fs-7 ${status?.step3 ? 'text-primary' : 'text-muted'}`}>
                  Upload Course Material
                </span>
              </div>
              <div>
                <i
                  className={`${
                    status?.step4 ? 'text-primary' : 'text-muted'
                  } fa-solid fa-chevron-right`}
                ></i>
              </div>
            </div>
            <div
              className='d-flex align-items-center '
              style={{gap: '10px', cursor: 'pointer'}}
              onClick={() => {
                status?.step1 &&
                  status?.step2 &&
                  status?.step3 &&
                  status?.step4 &&
                  setOpen({
                    ...open,
                    step1: false,
                    step2: false,
                    step3: false,
                    step4: true,
                    step5: false,
                  })
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className={`px-2 py-1 me-1 ${
                    status?.step4 ? 'bg-primary' : 'bg-secondary'
                  }  rounded-circle `}
                >
                  <h5 className='text-white'>4</h5>
                </div>
                <span className={`fw-bold fs-7 ${status?.step4 ? 'text-primary' : 'text-muted'}`}>
                  Price
                </span>
              </div>
              <div>
                <i
                  className={`${
                    status?.step5 ? 'text-primary' : 'text-muted'
                  } fa-solid fa-chevron-right`}
                ></i>
              </div>
            </div>
            <div
              className='d-flex align-items-center '
              style={{gap: '10px', cursor: 'pointer'}}
              onClick={() => {
                status?.step1 &&
                  status?.step2 &&
                  status?.step3 &&
                  status?.step4 &&
                  status?.step5 &&
                  setOpen({
                    ...open,
                    step1: false,
                    step2: false,
                    step3: false,
                    step4: false,
                    step5: true,
                  })
              }}
            >
              <div className='d-flex align-items-center'>
                <div
                  className={`px-2 py-1 me-1 ${
                    status?.step5 ? 'bg-primary' : 'bg-secondary'
                  }  rounded-circle `}
                >
                  <h5 className='text-white'>5</h5>
                </div>
                <span className={`fw-bold fs-7 ${status?.step5 ? 'text-primary' : 'text-muted'}`}>
                  Publish
                </span>
              </div>
            </div>
          </div>
          {open?.step1 ? (
            <div className='row'>
              <div className='col-12 mb-5'>
                <h3>Course Information</h3>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                  <div className='col-12 mb-5'>
                    <label className='from-label'>Title:</label>
                    <input
                      type='text'
                      placeholder='Introduction to Data Analysis'
                      name='title'
                      value={data?.title}
                      className='form-control'
                      onChange={handelChange}
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.title}</span>
                  </div>
                  <div className='col-6 mb-5'>
                    <label className='from-label'>Category:</label>
                    <Select
                      onChange={(e: any) => {
                        setErrorMessage({
                          ...ErrorMessage,
                          category: '',
                        })
                        setData({
                          ...data,
                          category: e.value,
                        })
                      }}
                      value={{label: data?.category, value: data?.category}}
                      options={apply}
                      isSearchable={true}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          width: '100%',
                          boxShadow: 'unset',
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          boxShadow: 'unset',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      placeholder='search and select category'
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.category}</span>
                  </div>
                  <div className='col-6 mb-5'>
                    <label className='from-label'>
                      Level<span className='text-danger'>*</span>
                    </label>
                    <Select
                      onChange={(e: any) => {
                        setErrorMessage({
                          ...ErrorMessage,
                          level: '',
                        })
                        setData({
                          ...data,
                          level: e.value,
                        })
                      }}
                      value={{label: data?.level, value: data?.level}}
                      options={program}
                      isSearchable={true}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          width: '100%',
                          boxShadow: 'unset',
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          boxShadow: 'unset',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      placeholder='search and select level'
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.level}</span>
                  </div>
                  <div className='col-12 mb-5'>
                    <div className='form-group'>
                      <label>Description</label>
                      <textarea
                        className='form-control'
                        onChange={handelChange}
                        name='description'
                        rows={3}
                        value={data?.description}
                      ></textarea>
                      <span className='text-danger fs-8'>{ErrorMessage?.description}</span>
                    </div>
                  </div>
                  <div className='col-6 mb-5'>
                    <label> Tags:</label>
                    <ReactTags
                      tags={data?.tags}
                      id='metaValue'
                      value={data?.tags}
                      classNames={{
                        tagInput: 'mb-3',
                        tagInputField: 'form-control',
                        tag: 'tagsClass fs-6',
                        remove: 'bg-transparent text-primary px-2 remove border-0 fs-4',
                      }}
                      placeholder='Add tags and Press Enter'
                      delimiters={delimiters}
                      handleDelete={handleTagDelete}
                      handleAddition={handleAddition}
                      allowDragDrop={false}
                      inputFieldPosition='top'
                      autocomplete
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.tags}</span>
                  </div>
                  <div className='col-6 mb-5'>
                    <label> Language:</label>
                    <ReactTags
                      tags={data?.language}
                      id='metaValue'
                      value={data?.language}
                      classNames={{
                        tagInput: 'mb-3',
                        tagInputField: 'form-control',
                        tag: 'tagsClass fs-6',
                        remove: 'bg-transparent text-primary px-2 remove border-0 fs-4',
                      }}
                      placeholder='Add language and Press Enter'
                      delimiters={delimiters}
                      handleDelete={handleTagDeleteL}
                      handleAddition={handleAdditionL}
                      allowDragDrop={false}
                      inputFieldPosition='top'
                      autocomplete
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.language}</span>
                  </div>
                  <div className='col-6 mb-5'>
                    <label htmlFor=''>
                      Course Type<span className='text-danger'>*</span>
                    </label>
                    <Select
                      onChange={(e: any) => {
                        setErrorMessage({
                          ...ErrorMessage,
                          type: '',
                        })
                        setData({
                          ...data,
                          type: e.value,
                        })
                      }}
                      value={{label: data?.type, value: data?.type}}
                      options={type}
                      isSearchable={true}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          width: '100%',
                          boxShadow: 'unset',
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          boxShadow: 'unset',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      placeholder='search and select type '
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.type}</span>
                  </div>
                  <div className='col-6 mb-5'>
                    <label htmlFor=''>
                      Category Type<span className='text-danger'>*</span>
                    </label>
                    <Select
                      onChange={(e: any) => {
                        setErrorMessage({
                          ...ErrorMessage,
                          categoryType: '',
                        })
                        setData({
                          ...data,
                          categoryType: e.value,
                        })
                      }}
                      value={{label: data?.categoryType, value: data?.categoryType}}
                      options={type1}
                      isSearchable={true}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          width: '100%',
                          boxShadow: 'unset',
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          boxShadow: 'unset',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      placeholder='search and select type '
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.categoryType}</span>
                  </div>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row h-100'>
                  <div className='col-12 w-100 mb-5'>
                    {data?.coverImage === '' ? (
                      <>
                        <FileUploader
                          handleChange={(e) => {
                            handleChange(e, 1)
                          }}
                          multiple={true}
                          name='file'
                          width='100%'
                          hoverTitle='Drop Here'
                          label='Upload Cover Image'
                          classes='dropzone bg-light-primary maxWidth-unset p-8 fs-5 h-100 w-100 mb-2 border-primary fs-7 fw-bold text-gray-400'
                        />
                        <span className='text-danger fs-8'>{ErrorMessage?.coverImage}</span>
                      </>
                    ) : (
                      <div className='position-relative'>
                        <img
                          src={data?.coverImage}
                          style={{height: '200px', width: '100%', objectFit: 'cover'}}
                          className='border border-2 me-5 mb-5 position-relative'
                          alt='profile'
                        />
                        <div className='position-absolute' style={{top: '0', right: '20px'}}>
                          <i
                            className='fa-solid fa-xmark fs-1'
                            style={{cursor: 'pointer'}}
                            onClick={() => setData({...data, coverImage: ''})}
                          ></i>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-12  m-auto text-center'>
                    <h3>OR</h3>
                  </div>
                  <div className='col-12 w-100 mb-5'>
                    {video?.coverVideo?.length === 0 ? (
                      <FileUploader
                        handleChange={handleChangeVideo}
                        multiple={true}
                        name='file'
                        width='100%'
                        hoverTitle='Drop Here'
                        label='Upload Cover Video'
                        classes='dropzone bg-light-primary p-8 maxWidth-unset fs-5 h-100 w-100 mb-2 border-primary fs-7 fw-bold text-gray-400'
                      />
                    ) : (
                      video?.coverVideo?.map((item: any, index: any) => {
                        return (
                          <div className='position-relative'>
                            <video className='w-100' controls src={item}></video>
                            <div className='position-absolute' style={{top: '0', right: '20px'}}>
                              <i
                                className='fa-solid text-danger fa-xmark fs-1'
                                style={{cursor: 'pointer'}}
                                onClick={() => removeVideo(index)}
                              ></i>
                            </div>
                          </div>
                        )
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <input
                  type='submit'
                  onClick={() => {
                    handleSubmit()
                  }}
                  className='btn btn-primary'
                  value='next'
                />
              </div>
            </div>
          ) : null}
          {open?.step2 ? (
            <>
              <div className='row '>
                <div className='col-12 mb-5'>
                  <h3>Additional Information</h3>
                </div>
                <div className='col-12 mb-5 '>
                  <div className='p-5 border border-dashed border-secondary border-2 rounded'>
                    <h5>Add Instructor</h5>
                    <div className='mb-5'>
                      <div className='col-12 mb-5'>
                        {data?.instructorImage === '' ? (
                          <>
                            <FileUploader
                              handleChange={(e) => {
                                handleChange(e, 2)
                              }}
                              multiple={true}
                              name='file'
                              width='100%'
                              hoverTitle='Drop Here'
                              label='Upload Cover Image'
                              classes='dropzone bg-light-primary width-unset p-8 fs-5 h-100 w-100 mb-2 border-primary fs-7 fw-bold text-gray-400'
                            />
                            <span className='text-danger fs-8'>
                              {ErrorMessage?.instructorImage}
                            </span>
                          </>
                        ) : (
                          <div className='position-relative h-100px w-100px'>
                            <img
                              src={data?.instructorImage}
                              className='h-100 w-100 mt-2  rounded'
                              alt='credential'
                            />
                            <div className='position-absolute' style={{top: '10px', right: '10px'}}>
                              <i
                                className='fa-solid fa-xmark fs-1 text-danger'
                                style={{cursor: 'pointer'}}
                                onClick={() => setData({...data, instructorImage: ''})}
                              ></i>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='mb-5'>
                      <label htmlFor='instructorName'>Instructor Name</label>
                      <input
                        type='text'
                        value={data?.instructorName}
                        name='instructorName'
                        className='form-control'
                        onChange={handelChange}
                      />

                      <span className='text-danger fs-8'>{ErrorMessage?.instructorName}</span>
                    </div>
                    <div className='mb-5'>
                      <label htmlFor='instructorDesc'>Instructor Description</label>
                      <textarea
                        value={data?.instructorDesc}
                        name='instructorDesc'
                        className='form-control'
                        onChange={handelChange}
                        rows={2}
                      ></textarea>
                      <span className='text-danger fs-8'>{ErrorMessage?.instructorDesc}</span>
                    </div>
                  </div>
                </div>
                <div className='col-6 mb-5'>
                  <div className='p-5 border border-dashed border-secondary border-2 rounded'>
                    <label htmlFor='list'>
                      List your Learning array<span className='text-danger'>*</span>
                    </label>
                    <div className='d-flex align-items-center' style={{gap: '10px'}}>
                      <input
                        type='text'
                        placeholder='Enter Text'
                        value={data?.list}
                        onChange={handelChange}
                        className='form-control'
                        name='list'
                      />

                      <button
                        disabled={data?.list === ''}
                        className='btn btn-icon btn-sm btn-primary'
                        onClick={() => {
                          if (data?.list !== '') {
                            setData({
                              ...data,
                              learnList: [...data?.learnList, data?.list],
                              list: '',
                            })
                          }
                        }}
                      >
                        <i className='ki-duotone ki-message-add fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      </button>
                    </div>
                    <span className='text-danger fs-8'>{ErrorMessage?.list}</span>
                    <ul className='mt-2'>
                      {data?.learnList?.map((item) => {
                        return (
                          <li
                            className='mb-2  fs-6'
                            style={{listStyleType: 'circle', listStyle: 'inside'}}
                          >
                            {item}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className='col-6 mb-5 '>
                  <div className='p-5 border border-dashed border-secondary border-2 rounded'>
                    <label htmlFor='list'>
                      List your Key Text array<span className='text-danger'>*</span>
                    </label>
                    <div className='d-flex align-items-center' style={{gap: '10px'}}>
                      <input
                        type='text'
                        value={data?.text}
                        placeholder='Enter text'
                        onChange={handelChange}
                        className='form-control'
                        name='text'
                      />
                      <button
                        disabled={data?.text === ''}
                        className='btn btn-icon btn-sm btn-primary'
                        onClick={() => {
                          if (data?.text !== '') {
                            setData({...data, textList: [...data?.textList, data?.text], text: ''})
                          }
                        }}
                      >
                        <i className='ki-duotone ki-message-add fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      </button>
                    </div>
                    <span className='text-danger fs-8'>{ErrorMessage?.text}</span>
                    <ul className='mt-2'>
                      {data?.textList?.map((item) => {
                        return (
                          <li
                            className='mb-2  fs-6'
                            style={{listStyleType: 'circle', listStyle: 'inside'}}
                          >
                            {item}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
                <div className='col-12 mb-5'>
                  <div className='p-5 border border-dashed border-secondary border-2 rounded'>
                    <label htmlFor='list'>
                      FAQ<span className='text-danger'>*</span>
                    </label>
                    <div className='d-flex align-items-center' style={{gap: '10px'}}>
                      <div className='d-flex flex-column w-100'>
                        <input
                          type='text'
                          placeholder='Enter Titles'
                          value={data?.FAQTitle}
                          onChange={handelChange}
                          className='form-control mb-5'
                          name='FAQTitle'
                        />
                        <span className='text-danger fs-8'>{ErrorMessage?.FAQTitle}</span>
                        <textarea
                          className='form-control'
                          placeholder='Enter Description'
                          onChange={handelChange}
                          name='FAQDesc'
                          value={data?.FAQDesc}
                          rows={3}
                        ></textarea>
                        <span className='text-danger fs-8'>{ErrorMessage?.FAQDesc}</span>
                      </div>
                      <div style={{alignSelf: 'end'}}>
                        <button
                          disabled={data?.FAQTitle === '' || data?.FAQDesc === ''}
                          className='btn btn-icon btn-sm btn-primary'
                          onClick={() => {
                            if (data?.FAQTitle !== '' || data?.FAQDesc !== '') {
                              setData({
                                ...data,
                                FAQ: [
                                  ...data?.FAQ,
                                  {title: data?.FAQTitle, description: data?.FAQDesc},
                                ],
                                FAQTitle: '',
                                FAQDesc: '',
                              })
                            }
                          }}
                        >
                          <i className='ki-duotone ki-message-add fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </button>
                      </div>
                    </div>
                    {data?.FAQ?.length !== 0 && (
                      <div className='card mt-2 card-stretch'>
                        <div className='card-body p-0'>
                          <div className={`pb-5   border-secondary`}>
                            {data?.FAQ?.map((item, count) => {
                              return <FaqList count={count + 1} item={item} />
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12 mb-5'>
                  <div className='p-5 border border-dashed border-secondary border-2 rounded'>
                    <label htmlFor='list'>
                      Course Content<span className='text-danger'>*</span>
                    </label>
                    <div className='d-flex align-items-center' style={{gap: '10px'}}>
                      <div className='d-flex flex-column w-100'>
                        <input
                          placeholder='Enter Title'
                          type='text'
                          value={data?.ContentTitle}
                          onChange={handelChange}
                          className='form-control mb-5'
                          name='ContentTitle'
                        />
                        <span className='text-danger fs-8'>{ErrorMessage?.ContentTitle}</span>
                        <textarea
                          placeholder='Enter Description'
                          className='form-control'
                          onChange={handelChange}
                          name='ContentDesc'
                          rows={3}
                          value={data?.ContentDesc}
                        ></textarea>
                        <span className='text-danger fs-8'>{ErrorMessage?.ContentDesc}</span>
                      </div>
                      <div style={{alignSelf: 'end'}}>
                        <button
                          disabled={data?.ContentTitle === '' || data?.ContentDesc === ''}
                          className='btn btn-icon btn-sm btn-primary'
                          onClick={() => {
                            if (data?.ContentTitle !== '' || data?.ContentDesc !== '') {
                              setData({
                                ...data,
                                CourseContent: [
                                  ...data?.CourseContent,
                                  {title: data?.ContentTitle, description: data?.ContentDesc},
                                ],
                                ContentTitle: '',
                                ContentDesc: '',
                              })
                            }
                          }}
                        >
                          <i className='ki-duotone ki-message-add fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </button>
                      </div>
                    </div>
                    {data?.CourseContent?.length !== 0 && (
                      <div className='card mt-2 card-stretch'>
                        <div className='card-body p-0'>
                          <div className={`pb-5   border-secondary`}>
                            {data?.CourseContent?.map((item, count) => {
                              return <FaqList count={count + 1} item={item} />
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-12'>
                  <input
                    type='submit'
                    onClick={() => {
                      handleSubmitPageTwo()
                    }}
                    className='btn btn-primary'
                    value='next'
                  />
                </div>
              </div>
            </>
          ) : null}
          {open.step3 ? (
            <div className='row'>
              <div className='col-12 mb-5'>
                <h3>Upload Course Material</h3>
              </div>
              <div className='col-md-6 mb-5'>
                {video?.courseMaterial?.length === 0 ? (
                  <>
                    <FileUploader
                      handleChange={(e) => handleChangeVideo(e, 2)}
                      multiple={true}
                      name='video'
                      hoverTitle='Drop Here'
                      label='Upload Course Video'
                      classes='dropzone maxWidth-unset w-100 h-100 bg-light-primary p-8 fs-5  w-100 mb-2 border-primary fs-7 fw-bold text-gray-400'
                    />
                    <span className='text-danger fs-8'>{ErrorMessage?.video}</span>
                  </>
                ) : (
                  video?.courseMaterial?.map((item: any, index: any) => {
                    return (
                      <div className='position-relative'>
                        <video className='w-100' controls src={item}></video>
                        <div className='position-absolute' style={{top: '0', right: '20px'}}>
                          <i
                            className='fa-solid text-danger fa-xmark fs-1'
                            style={{cursor: 'pointer'}}
                            onClick={() => removeVideo(index)}
                          ></i>
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
              <div className='col-md-6 mb-5 '>
                <div className='row border p-2 bg-light-primary border-dashed rounded border-2 border-primary'>
                  <div className='col-12 mb-5'>
                    <h3>Specification</h3>
                  </div>
                  <div className='col-4 mb-3  fw-bold'>FILE TYPE </div>
                  <div className='col-8 mb-3  text-muted'>.mp3 , .mov</div>
                  <div className='col-4 mb-3  fw-bold'>RESOLUTION </div>
                  <div className='col-8 mb-3  text-muted'>1280x720</div>
                  <div className='col-4 mb-3  fw-bold'>ASPECT RATIO </div>
                  <div className='col-8 mb-3  text-muted'>16:9</div>
                  <div className='col-4 mb-3  fw-bold'>LENGTH </div>
                  <div className='col-8  mb-3 text-muted'>6 seconds , 15 seconds</div>
                </div>
              </div>
              <div className='col-12 mb-5 mt-2 border-bottom border-2'></div>
              <div className='col-12'>
                <h3>Spot Details</h3>
                <div className='row'>
                  <div className='col-12 mb-5'>
                    <label>Title </label>
                    <input
                      type='text'
                      placeholder='Enter Title for Course Video'
                      name='videoTitle'
                      className='form-control'
                      onChange={handelChange}
                      value={data?.videoTitle}
                    />
                    <span className='text-danger'>{ErrorMessage?.videoTitle}</span>
                  </div>
                  <div className='col-12 mb-5'>
                    <label htmlFor=''>Description</label>
                    <textarea
                      name='videoDesc'
                      placeholder='Enter Description for Course Video'
                      onChange={handelChange}
                      value={data?.videoDesc}
                      className='form-control'
                      rows={2}
                    ></textarea>
                    <span className='text-danger'>{ErrorMessage?.videoDesc}</span>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <input
                  type='submit'
                  onClick={() => {
                    handleSubmitPageThree()
                  }}
                  className='btn btn-primary'
                  value='next'
                />
              </div>
            </div>
          ) : null}
          {open.step4 ? (
            <div className='row'>
              <div className='col-12 mb-5'>
                <div className='p-5 border w-100 border-2 rounded d-flex'>
                  <div className='d-flex align-items-center w-100 justify-content-between'>
                    <div>
                      <h3>Free</h3>
                      <span>Allow Access to your Course free to charge</span>
                    </div>
                    <input
                      type='radio'
                      name='cost'
                      checked={data?.cost ? false : true}
                      onChange={() => {
                        setData({...data, cost: false})
                      }}
                      className='form-check-input'
                    />
                  </div>
                </div>
              </div>
              <div className='col-12 mb-5'>
                <div className='p-5 border w-100 border-2 rounded d-flex'>
                  <div className='d-flex align-items-center w-100 justify-content-between'>
                    <div>
                      <h3>One - Time Purchase </h3>
                      <span>Allow Access to your Course free to charge</span>
                    </div>
                    <input
                      type='radio'
                      name='cost'
                      checked={data?.cost ? true : false}
                      onChange={() => {
                        setData({...data, cost: true})
                      }}
                      className='form-check-input'
                    />
                  </div>
                </div>
              </div>
              {data?.cost && (
                <div className='col-12 mb-5'>
                  <label htmlFor=''>Price:</label>
                  <input
                    type='text '
                    className='form-control'
                    placeholder='enter price'
                    name='price'
                    value={data?.price}
                    onChange={handelChange}
                  />
                  <span className='text-danger fs-8'>{ErrorMessage?.price}</span>
                </div>
              )}
              <div className='col-12'>
                <input
                  type='submit'
                  onClick={() => {
                    handleSubmitPageFour()
                  }}
                  className='btn btn-primary'
                  value='Publish'
                />
              </div>
            </div>
          ) : null}
          {open?.step5 && (
            <div className='row'>
              <div className='col-12 mb-5'>
                <div className='row align-items-center'>
                  <div className='col-md-7 mb-3'>
                    <div className=' h-250px '>
                      <img
                        src={data?.coverImage}
                        alt='coverImage'
                        style={{objectFit: 'cover'}}
                        className='h-100 w-100'
                      />
                    </div>
                  </div>
                  <div className='col-md-5 mb-3'>
                    <div className='row justify-content-center'>
                      <div className='col-6'>
                        <div className='text-center border  border-gray-300 border rounded py-2 px-3 mb-5'>
                          <label htmlFor=''>Level</label>
                          <h4>{data?.level}</h4>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='text-center border  border-gray-300 border rounded py-2 px-3 mb-5'>
                          <label htmlFor=''>Type</label>
                          <h4>{data?.type}</h4>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='text-center border  border-gray-300 border rounded py-2 px-3 mb-5'>
                          <label htmlFor=''>Category</label>
                          <h4>{data?.category}</h4>
                        </div>
                      </div>
                      <div className='col-6'>
                        <div className='text-center border  border-gray-300 border rounded py-2 px-3 mb-5'>
                          <label htmlFor=''>Category Type</label>
                          <h4>{data?.categoryType}</h4>
                        </div>
                      </div>
                      {data?.price !== '' && (
                        <div className='col-6'>
                          <div className='text-center border  border-gray-300 border rounded py-2 px-3 mb-5'>
                            <label htmlFor=''>price</label>
                            <h4>{data?.price}</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mb-5'>
                <div className='row h-100'>
                  <div
                    className='col-md-7 mb-5 col-sm-12 course-des-txt'
                    style={{letterSpacing: '0.4px', boxSizing: 'border-box'}}
                  >
                    <div className='border h-100 rounded p-5 border-gray-500 pb-12'>
                      <h2 className='py-7'>{data?.title}</h2>
                      {data?.description}
                    </div>
                  </div>
                  <div className='col-md-5 col-sm-12 '>
                    <div
                      className='flex-column flex-lg-row-auto border border-gray-500'
                      style={{borderRadius: '5px'}}
                    >
                      <div className='card'>
                        <div className='card-body p-3'>
                          <div className='row align-items-center'>
                            <div className='col-12 border-bottom mb-5'>
                              <h1 style={{textAlign: 'center'}}>Instructor</h1>
                            </div>
                            <div className='col-4 mb-5  text-center'>
                              <div className='symbol symbol-100px symbol-circle h-80px w-80px '>
                                <img
                                  src={data?.instructorImage}
                                  className='h-100 w-100 rounded'
                                  alt=''
                                />
                              </div>
                            </div>
                            <div className='col-8  mb-5'>
                              <div className='fs-3 text-hover-primary fw-bold '>
                                Name:{' '}
                                <span className='fs-6 text-muted'>{data?.instructorName}</span>
                              </div>
                              <div className=' text-hover-primary fw-bold '>
                                Description:{' '}
                                <span className='fs-7 text-muted'>{data?.instructorDesc}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6  mb-5'>
                <div className='card h-100 border border-2  card-stretch'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>Course content</h3>
                    </div>
                  </div>
                  <div className='card-body p-0'>
                    <div className={`pb-5   border-secondary`}>
                      {data?.CourseContent?.map((item, count) => {
                        return <FaqList count={count + 1} item={item} />
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 mb-5'>
                <div className='card  h-100 border-2 card-stretch border'>
                  <div className='card-header'>
                    <div className='card-title'>
                      <h3>FAQ list</h3>
                    </div>
                  </div>
                  <div className='card-body p-0'>
                    <div className={`pb-5   border-secondary`}>
                      {data?.FAQ?.map((item, count) => {
                        return <FaqList count={count + 1} item={item} />
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mb-5'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='row h-100'>
                      <div className='col-6 mb-5  '>
                        <div
                          className=' h-100  border border-gray-500'
                          style={{borderRadius: '5px'}}
                        >
                          <div className='card'>
                            <div className='card-body p-3 p-0'>
                              <h3 className='card-label fw-bold'>Languages</h3>
                              <ol
                                className='fs-6 fw-semibold course-ol'
                                style={{position: 'relative', right: '7px'}}
                              >
                                {data?.language?.map((item) => {
                                  return <li className=''>{item?.text}</li>
                                })}
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 mb-5'>
                        <div
                          className='flex-column h-100 flex-lg-row-auto mb-5 ribbon ribbon-start border border-gray-500'
                          style={{borderRadius: '5px'}}
                        >
                          <div className='card'>
                            <div className='card-body p-3 p-0'>
                              <h3 className='card-label fw-bold'>Tags</h3>
                              <ol
                                className='fs-6 fw-semibold course-ol'
                                style={{position: 'relative', right: '7px'}}
                              >
                                {data?.tags?.map((item) => {
                                  return <li className=''>{item?.text}</li>
                                })}
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 mb-5'>
                        <div
                          className='flex-column h-100 flex-lg-row-auto mb-5 ribbon ribbon-start border border-gray-500'
                          style={{borderRadius: '5px'}}
                        >
                          <div className='card'>
                            <div className='card-body p-3 p-0'>
                              <h3 className='card-label fw-bold'>Learn List</h3>
                              <ol
                                className='fs-6 fw-semibold course-ol'
                                style={{position: 'relative', right: '7px'}}
                              >
                                {data?.learnList?.map((item) => {
                                  return <li className=''>{item}</li>
                                })}
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-6 mb-5'>
                        <div
                          className='flex-column h-100 flex-lg-row-auto mb-5 ribbon ribbon-start border border-gray-500'
                          style={{borderRadius: '5px'}}
                        >
                          <div className='card'>
                            <div className='card-body p-3 p-0'>
                              <h3 className='card-label fw-bold'>Text List</h3>
                              <ol
                                className='fs-6 fw-semibold course-ol'
                                style={{position: 'relative', right: '7px'}}
                              >
                                {data?.textList?.map((item) => {
                                  return <li className='  '>{item}</li>
                                })}
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='p-5  border rounded'>
                      <h3>Course Material</h3>
                      {video?.courseMaterial?.map((item: any) => {
                        return (
                          <div>
                            <video className='w-100' controls src={item}></video>
                          </div>
                        )
                      })}
                      <h3>{data?.videoDesc}</h3>
                      <div>{data?.videoTitle}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
const FaqList = (props) => {
  const {count, item} = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className='col-md-12  mb-10 mb-md-0 p-5 pr-0 pb-0'>
        {/* <h2 className='text-gray-800 fw-bold mb-4'>Buying Course</h2> */}
        <div className='m-0 p-3 border border-secondary shadow-sm' style={{borderRadius: '10px'}}>
          <div
            className='d-flex align-items-center justify-content-between cursor-pointer'
            onClick={() => {
              isOpen ? setIsOpen(false) : setIsOpen(true)
            }}
          >
            <h4 role='button' className=' fw-bold cursor-pointer mb-0'>
              <span className='m-2'>{count}</span>
              {item?.title}
            </h4>
            <div className='btn btn-sm btn-icon bg-dark btn-active-color-primary card  p-5'>
              <i
                className={`${
                  !isOpen ? 'fa-solid fa-plus text-primary' : 'fa-solid fa-minus text-white'
                }`}
                onClick={() => {
                  isOpen ? setIsOpen(false) : setIsOpen(true)
                }}
              ></i>
            </div>
          </div>

          <div id='kt_job_4_1' className='fs-6 ms-1 '>
            {isOpen && <div className='mb-4  fw-semibold fs-6 ps-10 p-3'>{item?.description}</div>}
          </div>
        </div>
      </div>
    </>
  )
}
