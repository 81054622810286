import {configureStore} from '@reduxjs/toolkit'
import authSlice from './Auth'
import assignmentSlice from './AssignmentSlice'
import EventSlice from './EventSlice'
import FacultySlice from './FacultySlice'
import achievementSlice from './achivementSlice'
import CourseSlice from './CourseSlice'
import authorSlice from './authorSlice'
import BlogSlice from './BlogSlice'
import AttendanceSlice from './AttendanceSlice'
import AssessmentSlice from './AssessmentSlice'
import AcademicSlice from './AcademicSlice'
import JobSlice from './JobSlice'
import {allApiSlice} from '../Api/allApi'
export const store = configureStore({
  reducer: {
    authSlice,
    assignmentSlice,
    EventSlice,
    achievementSlice,
    CourseSlice,
    FacultySlice,
    authorSlice,
    BlogSlice,
    AttendanceSlice,
    AssessmentSlice,
    AcademicSlice,
    JobSlice,
    [allApiSlice.reducerPath]: allApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(allApiSlice.middleware),
})
