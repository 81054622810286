import {useEffect, useState} from 'react' // Importing React hooks
import {
  useDeleteArticlesMutation,
  useDeleteAwardsMutation,
  useDeleteCertificationFMutation,
  useDeletePublicationMutation,
  useLazyGetFacultyDetailsQuery,
  useUpdateFacultyDetailsMutation, // Fetch faculty details
} from '../../Api/allApi' // Import API functions
import {CertificateModel} from './CertificateModel' // Import CertificateModel component
import {PublicationModel} from './PublicationModel' // Import PublicationModel component
import {ArticlesModel} from './ArticlesModel' // Import ArticlesModel component
import {AwardModel} from './AwardModel' // Import AwardModel component
import {Link, useSearchParams} from 'react-router-dom' // Get URL search parameters
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import Swal from 'sweetalert2'
import {useSelector} from 'react-redux'
const Faculty = Yup.object().shape({
  firstName: Yup.string().required('firstName is required'),
  lastName: Yup.string().required('lastName is required'),
  title: Yup.string().required('title is required'),
  highest_education: Yup.string().required('highest education is required'),
  years_of_experience: Yup.string().required('years_of_experience is required'),
  about: Yup.string().required('about is required'),
  facultyEnrollementId: Yup.string()
    .required('facultyEnrollementId is required')
    .matches(/^[a-zA-Z0-9]*$/, 'faculty Enrollement Id should only contain A-Z or 0-9 characters'),
})

export default function FacultyView() {
  const [details, setDetails]: any = useState([]) // State for faculty details
  const {updateData} = useSelector((state: any) => state.FacultySlice)

  const [selected, setSelected]: any = useState({
    // State for selected data
    publication: [],
    Articles: [],
    certificate: [],
    Awards: [],
  })
  const [loading, setLoading] = useState(false)
  const [flag, setFlag]: any = useState({
    // State for flags
    story: false,
    publication: false,
    Articles: false,
    certificate: false,
    Awards: false,
  })
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    title: '',
    highest_education: '',
    years_of_experience: '',
    about: '',
    facultyEnrollementId: '',
  })
  const [images, setImages]: any = useState({})

  const [searchparams] = useSearchParams() // Get URL search parameters
  const id = searchparams.get('id') // Get 'id' from URL

  // Faculty details API
  const [facultyDetailsTrigger, result] = useLazyGetFacultyDetailsQuery() // Fetch faculty details
  const {isSuccess, isFetching} = result // Get API query status
  // publisher api
  const [deletePublication, deleteResult] = useDeletePublicationMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete} = deleteResult
  // Articles api
  const [deleteArticles, deleA] = useDeleteArticlesMutation()
  const {isSuccess: isArticleD, isLoading: iosNotArticled} = deleA
  // certification api
  const [deleteCertificate, certificateD] = useDeleteCertificationFMutation()
  const {isSuccess: isCertificate, isLoading: isNotCertificate} = certificateD
  // award api
  const [deleteAwards, awardsD] = useDeleteAwardsMutation()
  const {isSuccess: isAwards, isLoading: isNotAward} = awardsD
  useEffect(() => {
    facultyDetailsTrigger(id) // Fetch faculty details with 'id'
  }, [facultyDetailsTrigger, id])

  // Update state when faculty details API call is successful
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setDetails(result?.data)
      setInitialValues({
        facultyEnrollementId: result?.data?.facultyEnrollementId,
        firstName: result?.data?.firstName,
        lastName: result?.data?.lastName,
        title: result?.data?.title,
        highest_education: result?.data?.highestEducation,
        years_of_experience: result?.data?.yearsOfExperience,
        about: result?.data?.about,
      })
    }
  }, [isSuccess, isFetching, result?.data])
  useEffect(() => {
    if (updateData) {
      setDetails((prevDetails) => ({...prevDetails, ...updateData}))
      setInitialValues({
        facultyEnrollementId: updateData?.facultyEnrollementId,
        firstName: updateData?.firstName,
        lastName: updateData?.lastName,
        title: updateData?.title,
        highest_education: updateData?.highestEducation,
        years_of_experience: updateData?.yearsOfExperience,
        about: updateData?.about,
      })
    }
  })
  // const updateDetails APi
  const [updateDetails] = useUpdateFacultyDetailsMutation()
  // const {isSuccess: isDetails, isLoading} = detailsResult
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Faculty,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          dbId: Number(id),
          firstName: values?.firstName,
          lastName: values?.lastName,
          facultyEnrollementId: values.facultyEnrollementId,
          title: values?.title,
          highestEducation: values?.highest_education,
          yearsOfExperience: values?.years_of_experience,
          facultyImg: images?.facultyimage,
          about: values?.about,
          facultyPublications: result?.data?.facultyPublications,
          facultyCertification: result?.data?.facultyCertification,
          facultyArticles: result?.data?.facultyArticles,
          facultyAwards: result?.data?.facultyAwards,
        }
        updateDetails(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // change image to base64
  const onHandleChangeFile: any = (e: any) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      const base64String = reader.result
      setImages({
        ...images,
        [e.target.name]: base64String,
      })
    }
    if (file) {
      reader.readAsDataURL(file)
      setImages({
        ...images,
        [e.target.name]: file,
      })
    }
  }
  useEffect(() => {
    if (
      (isDelete && !isNotDelete) ||
      (isArticleD && !iosNotArticled) ||
      (isCertificate && !isNotCertificate) ||
      (isAwards && !isNotAward)
    ) {
      facultyDetailsTrigger(id)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [
    isDelete,
    isNotDelete,
    id,
    isArticleD,
    facultyDetailsTrigger,
    iosNotArticled,
    isCertificate,
    isNotCertificate,
    isAwards,
    isNotAward,
  ])

  return (
    <>
      {isFetching ? ( // Loading spinner if data is being fetched
        <>
          <div className='px-5'>
            <div className='col-12 text-center card mb-5 p-5'>
              <i
                className='fa-solid fa-circle-notch fa-spin p-10 text-primary'
                style={{fontSize: '30px', color: 'unset'}}
              ></i>
            </div>
          </div>
        </>
      ) : (
        <div className='row'>
          <div className='col-12 mb-5'>
            <div className='card'>
              <div className='card-header pt-5 pb-5'>
                <h3 className='card-title '>
                  <Link to={'/faculty'}>
                    <button
                      className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                      data-bs-toggle='tooltip'
                      data-bs-placement='top'
                      aria-label='Back'
                      data-bs-original-title='Back'
                      data-kt-initialized='1'
                    >
                      <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </button>
                  </Link>
                  <div className='flex-column d-flex'>
                    <span className='card-label fw-bold fs-3 mb-1'>Faculty</span>
                    <span className='text-muted mt-1 fw-semibold fs-7'>Update Faculty Details</span>
                  </div>
                </h3>
              </div>
            </div>
          </div>
          {/* Story */}
          <div className='col-12 mb-5'>
            <div className='card'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-12 mb-7'>
                    <div className='fs-6 text-muted'>
                      <span className='text-danger'>*</span> indicates required{' '}
                    </div>
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label'>
                      First Name<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='firstName'
                      {...formik.getFieldProps('firstName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                        {
                          'is-valid': formik.touched.firstName && !formik.errors.firstName,
                        }
                      )}
                    />
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label'>
                      Last Name<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='lastName'
                      {...formik.getFieldProps('lastName')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                        {
                          'is-valid': formik.touched.lastName && !formik.errors.lastName,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Faculty Enrollment ID<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='facultyEnrollementId'
                      {...formik.getFieldProps('facultyEnrollementId')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.facultyEnrollementId &&
                            formik.errors.facultyEnrollementId,
                        },
                        {
                          'is-valid':
                            formik.touched.facultyEnrollementId &&
                            !formik.errors.facultyEnrollementId,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Faculty Image<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='file'
                      name='facultyimage'
                      onChange={onHandleChangeFile}
                      className={clsx('form-control bg-transparent', {
                        'is-valid': images?.facultyimage,
                      })}
                    />
                    {images?.facultyimage ? (
                      <img
                        src={images?.facultyimage}
                        className='w-50px h-50px mt-2 rounded'
                        alt='credential'
                      />
                    ) : null}
                  </div>
                  <div className='col-12 mb-5'>
                    <label className='form-label'>
                      Position<span className='text-danger'>*</span>{' '}
                    </label>
                    <input
                      type='text'
                      name='title'
                      {...formik.getFieldProps('title')}
                      className={clsx(
                        'form-control bg-transparent',
                        {'is-invalid': formik.touched.title && formik.errors.title},
                        {
                          'is-valid': formik.touched.title && !formik.errors.title,
                        }
                      )}
                    />
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label'>
                      Highest Qualification<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      name='highest_education'
                      {...formik.getFieldProps('highest_education')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.highest_education && formik.errors.highest_education,
                        },
                        {
                          'is-valid':
                            formik.touched.highest_education && !formik.errors.highest_education,
                        }
                      )}
                    />
                  </div>
                  <div className='col-md-6 mb-5'>
                    <label className='form-label'>
                      Experience <span className='text-danger'>*</span>
                    </label>
                    <input
                      name='years_of_experience'
                      type='text'
                      placeholder='5+ years'
                      {...formik.getFieldProps('years_of_experience')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid':
                            formik.touched.years_of_experience && formik.errors.years_of_experience,
                        },
                        {
                          'is-valid':
                            formik.touched.years_of_experience &&
                            !formik.errors.years_of_experience,
                        }
                      )}
                    />
                  </div>
                  <div className='col-12 mb-5'>
                    <label>
                      Faculty Information <span className='text-danger'>*</span>
                    </label>
                    <textarea
                      name='about'
                      {...formik.getFieldProps('about')}
                      className={clsx(
                        'form-control bg-transparent',
                        {
                          'is-invalid': formik.touched.about && formik.errors.about,
                        },
                        {
                          'is-valid': formik.touched.about && !formik.errors.about,
                        }
                      )}
                      id='exampleFormControlTextarea1'
                      rows={3}
                    ></textarea>
                  </div>
                  <div className='col-12 text-end'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={formik.cc || !formik.isValid}
                      onClick={formik?.handleSubmit}
                    >
                      {!loading && <span className='indicator-label'>Update Details</span>}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* publication */}
          {details?.facultyPublications?.length === 0 ? (
            <div className='col-12  mb-5'>
              <div className='card'>
                <div className='card-body'>
                  <button
                    className='btn  btn-color-gray-400 btn-active-color-primary'
                    type='button'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='modal'
                    data-bs-target='#Publication'
                    onClick={() =>
                      setSelected({
                        ...selected,
                        publication: {
                          dbId: null,
                          facultyId: id,
                          publicationDate: '',
                          publicationLink: '',
                          publisherDetails: '',
                          shortDescr: '',
                          title: '',
                        },
                      })
                    }
                  >
                    <i className='ki-duotone ki-message-add fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>{' '}
                    Add publication
                  </button>
                </div>
              </div>
              <div className='modal fade' id='Publication' aria-hidden='true'>
                <PublicationModel name='Publication' data={selected?.publication} />
              </div>
            </div>
          ) : (
            <div className='col-12 mb-5'>
              <div className='card card-custom'>
                <div className='card-header '>
                  <h2 className='card-title fs-2 fw-bold'>Publication</h2>
                  <div className='card-toolbar'>
                    <button
                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                      type='button'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      data-bs-toggle='modal'
                      data-bs-target='#Publication'
                      onClick={() =>
                        setSelected({
                          ...selected,
                          publication: {
                            dbId: null,
                            facultyId: id,
                            publicationDate: '',
                            publicationLink: '',
                            publisherDetails: '',
                            shortDescr: '',
                            title: '',
                          },
                        })
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </button>
                    <i
                      className={`${
                        flag?.publication ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.publication
                          ? setFlag({...flag, publication: false})
                          : setFlag({...flag, publication: true})
                      }}
                    ></i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='row'>
                    {details?.facultyPublications?.map((item) => {
                      return (
                        <div key={item._id} className='col-md-6 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=' mb-5'>
                                <img
                                  src={item?.publisherDetails?.mediumIcon}
                                  className='text-center rounded symbol-badge symbol-35px'
                                  alt=''
                                  style={{height: '50px', width: '50px', objectFit: 'cover'}}
                                />
                              </div>
                              <div className=''>
                                <h3 className='m-0 fs-5'>{item?.title}</h3>
                                <div className='fs-7'>{item?.publicationDate}</div>
                                <div className='fw-bold text-muted fs-8'>{item?.shortDescr}</div>
                                <a
                                  href={item?.publicationLink}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <button
                                    className='btn fs-8 mt-2 mb-5 px-2 py-2 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                    style={{borderRadius: '20px'}}
                                  >
                                    Show Credential
                                    <i className='fa-solid fa-arrow-up-right-from-square m-2'></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                            <div>
                              {flag?.publication ? (
                                <div className='d-flex align-items-center'>
                                  <button
                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#Publication    '
                                  >
                                    <i
                                      className='fa-solid fa-pen fs-5 me-3 '
                                      onClick={() => {
                                        setSelected({...selected, publication: item})
                                      }}
                                    ></i>
                                  </button>
                                  <i
                                    className='ki-duotone ki-trash-square fs-1'
                                    style={{cursor: 'pointer'}}
                                    onClick={() =>
                                      Swal.fire({
                                        title: 'Confirm deletion?',
                                        showDenyButton: false,
                                        showCancelButton: true,
                                        confirmButtonText:
                                          "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                        confirmButtonColor: '#009EF7',
                                        cancelButtonText:
                                          '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                          deletePublication(item?.dbId)
                                        } else if (result.isDenied) {
                                          Swal.fire('Record is not deleted', '', 'error')
                                        }
                                      })
                                    }
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='modal fade' id='Publication' aria-hidden='true'>
                  <PublicationModel name='Publication' data={selected?.publication} />
                </div>
              </div>
            </div>
          )}
          {/* Articles */}
          {details?.facultyArticles?.length === 0 ? (
            <div className='col-12 mb-5'>
              <div className='card '>
                <div className='card-body'>
                  <button
                    className='btn  btn-color-gray-400 btn-active-color-primary'
                    type='button'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='modal'
                    data-bs-target='#Articles'
                    onClick={() =>
                      setSelected({
                        ...selected,
                        Articles: {
                          facultyId: id,
                          title: '',
                          shortDescr: '',
                          articleLink: '',
                          articleDate: '',
                        },
                      })
                    }
                  >
                    <i className='ki-duotone ki-message-add fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>{' '}
                    Add Articles
                  </button>
                </div>
              </div>
              <div className='modal fade' id='Articles' aria-hidden='true'>
                <ArticlesModel name='Articles' data={selected?.Articles} />
              </div>
            </div>
          ) : (
            <div className='col-12 mb-5'>
              <div className='card card-custom'>
                <div className='card-header '>
                  <h2 className='card-title fs-2 fw-bold'>Articles</h2>
                  <div className='card-toolbar'>
                    <button
                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                      type='button'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      data-bs-toggle='modal'
                      data-bs-target='#Articles'
                      onClick={() =>
                        setSelected({
                          ...selected,
                          Articles: {
                            facultyId: id,
                            title: '',
                            shortDescr: '',
                            articleLink: '',
                            articleDate: '',
                          },
                        })
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </button>
                    <i
                      className={`${
                        flag?.Articles ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.Articles
                          ? setFlag({...flag, Articles: false})
                          : setFlag({...flag, Articles: true})
                      }}
                    ></i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='row'>
                    {details?.facultyArticles?.map((item) => {
                      return (
                        <div key={item._id} className='col-md-6 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=' mb-5'>
                                <img
                                  src={item?.articleImg}
                                  className='text-center rounded symbol-badge symbol-35px'
                                  alt=''
                                  style={{height: '100px', width: '100px', objectFit: 'cover'}}
                                />
                              </div>
                              <div className=''>
                                <h3 className='m-0 fs-5'>{item?.title}</h3>
                                <div className='fs-7'>{item?.articleDate}</div>
                                <div className='fw-bold text-muted fs-8'>{item?.shortDescr}</div>
                                <a
                                  href={item?.articleLink}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <button
                                    className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                    style={{borderRadius: '20px'}}
                                  >
                                    Show Credential
                                    <i className='fa-solid fa-arrow-up-right-from-square m-2'></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                            <div>
                              {flag?.Articles ? (
                                <div className='d-flex align-items-center'>
                                  <button
                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#Articles'
                                  >
                                    <i
                                      className='fa-solid fa-pen fs-5 me-3 '
                                      onClick={() => {
                                        setSelected({...selected, Articles: item})
                                      }}
                                    ></i>
                                  </button>
                                  <i
                                    className='ki-duotone ki-trash-square fs-1'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      Swal.fire({
                                        title: 'Confirm deletion?',
                                        showDenyButton: false,
                                        showCancelButton: true,
                                        confirmButtonText:
                                          "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                        confirmButtonColor: '#009EF7',
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                          deleteArticles(item?.dbId)
                                        } else if (result.isDenied) {
                                          Swal.fire('Record is not deleted', '', 'error')
                                        }
                                      })
                                    }}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='modal fade' id='Articles' aria-hidden='true'>
                  <ArticlesModel name='Articles' data={selected?.Articles} />
                </div>
              </div>
            </div>
          )}
          {/* certificates */}
          {details?.facultyCertification?.length === 0 ? (
            <div className='col-12 mb-5'>
              <div className='card '>
                <div className='card-body'>
                  <button
                    className='btn btn-color-gray-400 btn-active-color-primary'
                    type='button'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='modal'
                    data-bs-target='#Certificates'
                    onClick={() =>
                      setSelected({
                        ...selected,
                        certificate: {
                          facultyId: id,
                          certificationName: '',
                          issuedBy: '',
                          issueDate: '',
                          expirationDate: '',
                          credentialID: '',
                          credentialURL: '',
                        },
                      })
                    }
                  >
                    <i className='ki-duotone ki-message-add fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>{' '}
                    Add Certificates
                  </button>
                </div>
              </div>
              <div className='modal fade' id='Certificates' aria-hidden='true'>
                <CertificateModel name='New Article' data={selected?.certificate} />
              </div>
            </div>
          ) : (
            <div className='col-12 mb-5'>
              <div className='card card-custom'>
                <div className='card-header '>
                  <h2 className='card-title fs-2 fw-bold'>Certificates</h2>
                  <div className='card-toolbar'>
                    <button
                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                      type='button'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      data-bs-toggle='modal'
                      data-bs-target='#Certificates'
                      onClick={() =>
                        setSelected({
                          ...selected,
                          certificate: {
                            facultyId: id,
                            certificationName: '',
                            issuedBy: '',
                            issueDate: '',
                            shortDescr: '',
                            expirationDate: '',
                            credentialID: '',
                            credentialURL: '',
                          },
                        })
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </button>
                    <i
                      className={`${
                        flag?.certificate ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.certificate
                          ? setFlag({...flag, certificate: false})
                          : setFlag({...flag, certificate: true})
                      }}
                    ></i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='row'>
                    {details?.facultyCertification?.map((item) => {
                      return (
                        <div key={item._id} className='col-md-6 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=' mb-5'>
                                <img
                                  src={item?.credentialImg}
                                  className='text-center rounded symbol-badge symbol-35px'
                                  alt=''
                                  style={{height: '50px', width: '50px', objectFit: 'cover'}}
                                />
                              </div>
                              <div className=''>
                                <h3 className='m-0 fs-5'>{item?.name}</h3>
                                <div className='fs-7'>
                                  {item?.expirationDate
                                    ? `${item?.issueDate} - ${item?.expirationDate}`
                                    : `${item?.issueDate} `}
                                </div>
                                <div className='fw-bold text-muted fs-8'>{item?.issuedBy}</div>
                                <div className='text-muted fw-bold fs-8'>
                                  Credential ID {item?.credentialID}
                                </div>
                                <a
                                  href={item?.credentialURL}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  <button
                                    className='btn fs-8 mt-2 mb-5 btn-outline btn-outline-solid btn-outline-default text-muted fw-bold me-2 mb-2 py-1'
                                    style={{borderRadius: '20px'}}
                                  >
                                    Show Credential{' '}
                                    <i className='fa-solid fa-arrow-up-right-from-square m-2'></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                            <div>
                              {flag?.certificate ? (
                                <>
                                  <button
                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#Certificates'
                                  >
                                    <i
                                      className='fa-solid fa-pen fs-5 me-3 '
                                      onClick={() => {
                                        console.log(item)
                                        setSelected({...selected, certificate: item})
                                      }}
                                    ></i>
                                  </button>
                                  <i
                                    className='ki-duotone ki-trash-square fs-1'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      Swal.fire({
                                        title: 'Confirm deletion?',
                                        showDenyButton: false,
                                        showCancelButton: true,
                                        confirmButtonText:
                                          "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                        confirmButtonColor: '#009EF7',
                                        cancelButtonText:
                                          '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                          deleteCertificate(item?.dbId)
                                        } else if (result.isDenied) {
                                          Swal.fire('Record is not deleted', '', 'error')
                                        }
                                      })
                                    }}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='modal fade' id='Certificates' aria-hidden='true'>
                  <CertificateModel name='License or certification' data={selected?.certificate} />
                </div>
              </div>
            </div>
          )}

          {/* Awards */}
          {details?.facultyAwards?.length === 0 ? (
            <div className='col-12 mb-5'>
              <div className='card '>
                <div className='card-body'>
                  <button
                    className='btn  btn-color-gray-400 btn-active-color-primary'
                    type='button'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    data-bs-toggle='modal'
                    data-bs-target='#Awards'
                    onClick={() =>
                      setSelected({
                        ...selected,
                        Awards: {
                          facultyId: id,
                          title: '',
                          awardYear: '',
                          shortDescr: '',
                        },
                      })
                    }
                  >
                    <i className='ki-duotone ki-message-add fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>{' '}
                    Awards
                  </button>
                </div>
              </div>
              <div className='modal fade' id='Awards' aria-hidden='true'>
                <AwardModel name='Awards' data={selected?.Awards} />
              </div>
            </div>
          ) : (
            <div className='col-12 mb-5'>
              <div className='card card-custom'>
                <div className='card-header '>
                  <h2 className='card-title fs-2 fw-bold'>Awards</h2>
                  <div className='card-toolbar'>
                    <button
                      className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                      type='button'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      data-bs-toggle='modal'
                      data-bs-target='#Awards'
                      onClick={() =>
                        setSelected({
                          ...selected,
                          Awards: {
                            facultyId: id,
                            title: '',
                            awardYear: '',
                            shortDescr: '',
                          },
                        })
                      }
                    >
                      <i className='ki-duotone ki-message-add fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                        <span className='path3'></span>
                      </i>
                    </button>
                    <i
                      className={`${
                        flag?.Awards ? 'fa-solid fa-check text-success' : 'fa-solid fa-pen'
                      } fs-2`}
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        flag?.Awards
                          ? setFlag({...flag, Awards: false})
                          : setFlag({...flag, Awards: true})
                      }}
                    ></i>
                  </div>
                </div>
                <div className='card-body py-5'>
                  <div className='row'>
                    {details?.facultyAwards?.map((item) => {
                      return (
                        <div key={item._id} className='col-md-6 mb-5 border-bottom'>
                          <div className='d-flex align-items-baseline justify-content-between'>
                            <div className='d-md-flex' style={{gap: '20px'}}>
                              <div className=''>
                                <h3 className='m-0 fs-5'>{item?.title}</h3>
                                <div className='fs-7'>{item?.year}</div>
                                <div className='fw-bold text-muted fs-8'>{item?.shortDescr}</div>
                              </div>
                            </div>
                            <div>
                              {flag?.Awards ? (
                                <div className='d-flex align-items-center'>
                                  <button
                                    className='btn btn-icon btn-color-gray-400 btn-active-color-primary'
                                    type='button'
                                    data-kt-menu-trigger='click'
                                    data-kt-menu-placement='bottom-end'
                                    data-kt-menu-flip='top-end'
                                    data-bs-toggle='modal'
                                    data-bs-target='#Awards'
                                  >
                                    <i
                                      className='fa-solid fa-pen fs-5 me-3 '
                                      onClick={() => {
                                        setSelected({...selected, Awards: item})
                                      }}
                                    ></i>
                                  </button>
                                  <i
                                    className='ki-duotone ki-trash-square fs-1'
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                      Swal.fire({
                                        title: 'Confirm deletion?',
                                        showDenyButton: false,
                                        showCancelButton: true,
                                        confirmButtonText:
                                          "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                                        confirmButtonColor: '#009EF7',
                                      }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                          deleteAwards(item?.dbId)
                                        } else if (result.isDenied) {
                                          Swal.fire('Record is not deleted', '', 'error')
                                        }
                                      })
                                    }}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                    <span className='path3'></span>
                                  </i>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='modal fade' id='Awards' aria-hidden='true'>
                  <AwardModel name='Awards' data={selected?.Awards} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
