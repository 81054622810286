import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  author: [],
}

export const authorSlice = createSlice({
  name: 'authorSlice',
  initialState,
  reducers: {
    setAuthor: (state, action) => {
      state.author = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setAuthor} = authorSlice.actions
export default authorSlice.reducer
