import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const ImageModal = ({show, onHide, images, onImageSelect}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>About Images</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Display images here */}
        <div className='row'>
          {images &&
            images.map((image, index) => (
              <div className='col-4 mb-5 '>
                <img
                  key={index}
                  src={image}
                  alt={`About  ${index}`}
                  className='h-100 w-100 border border-2 border-dark'
                  onClick={() => onImageSelect(image)}
                  style={{cursor: 'pointer', borderRadius: '20px'}}
                />
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ImageModal
