/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useAssignmentListMutation, useDeleteAssignmentMutation} from '../../../../../Api/allApi'
import DataTables from '../../../../DataTable/DataTables'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setSubmitAssignment} from '../../../../../redux/AssignmentSlice'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'

type Props = {
  className?: string
}

const SubmittedAssignment: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  // data
  const columns: any = [
    {
      name: 'Assignment Name',
      selector: (row: any, index) => {
        return (
          <div className='  '>
            {row?.assignmentName}
            <button
              className='btn btn-secondary btn-sm px-3 py-1 rounded-circle'
              onClick={() => handleToggleDescription(index)}
            >
              <i className='fa-solid fa-info p-0 fs-7'></i>
            </button>
            <div
              className={`tooltip ${clickedIndex === index ? 'd-block' : 'd-none'}`}
              style={{visibility: `${clickedIndex === index ? 'visible' : 'hidden'}`}}
            >
              {row?.assignmentName} - {row?.description}
            </div>
          </div>
        )
      },
      center: true,
      sortable: true,
      width: '400px',
    },
    {
      name: 'Upload Date',
      selector: (row: any) => (row?.assignmentDate ? row?.assignmentDate : '-'),
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Due Date',
      selector: (row: any) => (row?.assignmentDueDate ? row?.assignmentDueDate : '-'),
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Maximum Grade ',
      selector: (row: any) => (row?.maximumGrade ? row?.maximumGrade : '-'),
      center: true,
      sortable: true,
      width: '200px',
    },
    {
      name: 'Status',
      center: true,
      sortable: true,
      selector: (row: any) => {
        return (
          <span
            className={`badge badge-light-${
              row?.assignmentStatus === 'active'
                ? 'success'
                : row?.assignmentStatus === 'closed'
                ? 'danger'
                : ''
            }`}
          >
            {row?.assignmentStatus}
          </span>
        )
      },
      width: '200px',
    },
    {
      name: '',
      style: {
        justifyContent: 'end',
      },
      selector: (row: any) => {
        return (
          <div className=''>
            <a
              href={row?.assignmentLink}
              target='_blank'
              className='me-5'
              rel='noopener noreferrer'
            >
              <button
                className='btn btn-sm btn-light-primary'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='fa-solid fa-eye'></i> View
              </button>
            </a>
            <Link to={`/assignment/AllAssignment/add?assignmentId=${row?.dbId}`} className='me-5'>
              <button
                className='btn btn-sm btn-light-warning'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Edit
              </button>
            </Link>
            <button
              className='btn btn-sm btn-light-danger '
              type='button'
              style={{height: 'unset', width: 'unset'}}
              onClick={() => {
                Swal.fire({
                  title: 'Confirm deletion?',
                  showDenyButton: false,
                  showCancelButton: true,
                  confirmButtonText:
                    '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
                  confirmButtonColor: '#009EF7',
                  cancelButtonText:
                    '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    deleteAssignment(row?.dbId)
                  } else if (result.isDenied) {
                    Swal.fire('Record is not deleted', '', 'error')
                  }
                })
              }}
            >
              <i className='ki-duotone ki-trash-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>{' '}
              Delete
            </button>
          </div>
        )
      },
      sortable: true,
    },
  ]
  const {submitAssignment} = useSelector((state: any) => state.assignmentSlice)
  const {filter} = useSelector((state: any) => state.assignmentSlice)
  const [clickedIndex, setClickedIndex] = useState(-1)

  const handleToggleDescription = (index: number) => {
    setClickedIndex(clickedIndex === index ? -1 : index)
  }
  // pagination
  const [totalRows, setTotalRows]: any = useState(0)
  const [perPage, setPerPage]: any = useState(20)
  const [page, setPage]: any = useState(1)
  const handlePageChange = (page: any) => {
    setPage(page)
  }
  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }

  // assignment api trigger
  const [assignmentTrigger, result] = useAssignmentListMutation()
  const {isSuccess, isLoading} = result
  // assignment Delete api
  const [deleteAssignment, deleteResult] = useDeleteAssignmentMutation()
  const {isSuccess: isDSuccess, isLoading: isDLoading, isError, error} = deleteResult
  useEffect(() => {
    if (Object.keys(filter).length !== 0) {
      if (filter?.streamId > 0 && filter?.classId > 0) {
        let payload = {
          ...filter,
          assignmentStatus: 'closed',
          itemsPerPage: perPage,
          page: page,
        }
        assignmentTrigger(payload)
      }
    }
  }, [assignmentTrigger, filter, perPage, page])

  // when api is calling successfully
  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(setSubmitAssignment(result?.data?.assignmentList))
      setTotalRows(result?.data?.payload?.pagination?.total)
    }
  }, [isSuccess, isLoading, result, dispatch])
  useEffect(() => {
    if (isDSuccess && !isDLoading) {
      let payload = {
        ...filter,
        assignmentStatus: 'closed',
        itemsPerPage: perPage,
        page: page,
      }
      assignmentTrigger(payload)
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDSuccess, isDLoading, deleteResult?.data?.detail, assignmentTrigger, filter, page, perPage])
  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <DataTables
            columns={columns}
            data={submitAssignment}
            loading={isLoading}
            totalRows={totalRows}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handlePerRowsChange}
            page={page}
            perPage={perPage}
          />

          {/* end::Table container */}
        </div>
      </div>
    </>
  )
}

export {SubmittedAssignment}
