import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {JobHeader} from './JobHeader'
import AddJob from './AddJob'
import AllJobs from './Component/AllJobs'
import ByClass from './Component/ByClass'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'All',
    path: '/jobs/all',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const JobPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <JobHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='all'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>All</PageTitle>
              <AllJobs />
            </>
          }
        />
        <Route
          path='applied'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>applied</PageTitle>
              <AllJobs />
            </>
          }
        />
        <Route
          path='offered'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>offered</PageTitle>
              <AllJobs />
            </>
          }
        />
        <Route
          path='rejected'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>rejected</PageTitle>
              <AllJobs />
            </>
          }
        />
        <Route
          path='pending'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>pending</PageTitle>
              <AllJobs />
            </>
          }
        />
        <Route
          path='closed'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>pending</PageTitle>
              <AllJobs />
            </>
          }
        />
        <Route
          path='class'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>class</PageTitle>
              <ByClass />
            </>
          }
        />
        <Route index element={<Navigate to='/jobs/all' />} />
      </Route>
      <Route path='/addJob' element={<AddJob />} />
    </Routes>
  )
}

export default JobPage
