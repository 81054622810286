import React, {useEffect, useRef, useState} from 'react'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
// import {setNotice} from '../../../redux/AcademicSlice'
import {useSelector} from 'react-redux'
import {Link, useSearchParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import {
  useAddNoticeMutation,
  useLazyAcademicFilterQuery,
  useLazyGetNoticeDetailsQuery,
} from '../../../Api/allApi'
import Select from 'react-select'
import {langs, noticeOption} from '../../SunEditorConfiguration/config'
const oneLiner = Yup.object().shape({
  notice: Yup.string().required('Notice is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date()
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
  startTime: Yup.string(),
  endTime: Yup.string().test({
    name: 'endTime',
    test: function (value) {
      const startTime = this.parent.startTime // Access the value of startTime

      // Check if endTime is required based on the presence of startTime
      if (startTime && startTime.trim() !== '') {
        return !!value && value.trim() !== ''
      }

      return true // No validation needed if startTime is not provided
    },
    message: 'End Time is required when Start Time is specified',
  }),
  streamId: Yup.number().required('Stream ID is required'),
  classId: Yup.array().required('Class ID is required'),
  noticeType: Yup.string().required('Notice Type is required'),
})
const Attachment = Yup.object().shape({
  noticeDate: Yup.date().required('Start Date is required'),
  streamId: Yup.number().required('Stream ID is required'),
  classId: Yup.array().required('Class ID is required'),
  noticeTitle: Yup.number().required('Notice Title is required'),
  noticeType: Yup.string().required('Notice Type is required'),
})
const Description = Yup.object().shape({
  notice: Yup.string().required('Notice is required'),
  startDate: Yup.date().required('Start Date is required'),
  endDate: Yup.date()
    .required('End Date is required')
    .min(Yup.ref('startDate'), 'End Date must be after Start Date'),
  startTime: Yup.string(),
  endTime: Yup.string().test({
    name: 'endTime',
    test: function (value) {
      const startTime = this.parent.startTime // Access the value of startTime

      // Check if endTime is required based on the presence of startTime
      if (startTime && startTime.trim() !== '') {
        return !!value && value.trim() !== ''
      }

      return true // No validation needed if startTime is not provided
    },
    message: 'End Time is required when Start Time is specified',
  }),
  streamId: Yup.number().required('Stream ID is required'),
  classId: Yup.array().required('Class ID is required'),
  noticeType: Yup.string().required('Notice Type is required'),
})
export default function AddNotice() {
  const editor: any = useRef()

  // Options for dropdown selects
  const [optionsData, setOptionData]: any = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: [],
    selectedClass: [],
    noticeType: [
      {label: 'One-liner notice', value: 'oneLiner'},
      {label: 'Attachment notice', value: 'attachment'},
      {label: 'Description notice', value: 'noticeHTML'},
    ],
    selectedNoticeType: {value: '', label: ''},
  })
  // get data from redux
  const {notice} = useSelector((state: any) => state.AcademicSlice)
  //   get id from params
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [disableSelection, setDisableSelection] = useState(false)

  const [loading, setLoading] = useState(false)
  const [initialValues, setInitialValues]: any = useState(
    optionsData?.selectedNoticeType?.value === 'oneLiner'
      ? {
          notice: '',
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          streamId: 0,
          classId: 0,
          noticeType: '',
        }
      : optionsData?.selectedNoticeType?.value === 'attachment'
      ? {
          noticeTitle: '',
          noticeDate: '',
          noticePDF: '',
          noticeType: '',
        }
      : {
          notice: '',
          startDate: '',
          startTime: '',
          endDate: '',
          endTime: '',
          noticeHTML: '',
          streamId: 0,
          classId: 0,
          noticeType: '',
        }
  )
  // Query data for filtering
  const [filterTrigger, fResult] = useLazyAcademicFilterQuery()
  const {isSuccess: isFSuccess, isFetching: isFFetching} = fResult
  // add notice api
  const [addNotice, result] = useAddNoticeMutation()
  const {isSuccess, isLoading, isError, error} = result
  // get notice details api
  const [noticeDetail, noticeDetailResult] = useLazyGetNoticeDetailsQuery()
  const {isSuccess: isDetails, isFetching: isNotDetails} = noticeDetailResult
  // Trigger filter and faculty data loading
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])
  // event details api
  useEffect(() => {
    if (id) {
      noticeDetail(Number(id))
    }
  }, [id, noticeDetail])

  // call when all data fill well
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema:
      optionsData?.selectedNoticeType?.value === 'oneLiner'
        ? oneLiner
        : optionsData?.selectedNoticeType?.value === 'attachment'
        ? Attachment
        : Description,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        setLoading(true)
        const startDate = moment(values.startDate).format('YYYY-MM-DD')
        const endDate = moment(values.endDate).format('YYYY-MM-DD')
        const noticeDate = moment(values.noticeDate).format('YYYY-MM-DD')

        let payload = {
          dbId: id ? Number(id) : 0,
          streamId: values?.streamId,
          classId: values?.classId,
          notice: values?.notice,
          noticeHTML: values?.noticeHTML,
          noticePDF: values?.noticePDF,
          noticeDate: noticeDate,
          startDate: startDate,
          endDate: endDate,
          startTime: values?.startTime,
          endTime: values?.endTime,
          showToAll: disableSelection,
        }
        addNotice(payload)
        setSubmitting(false)
        setLoading(false)
      } catch (error) {
        console.error('Error:', error)
        setStatus('Failed to perform the operation')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  // call when api returns status 200
  useEffect(() => {
    if (isSuccess && !isLoading) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Notice updated successfully!',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isSuccess, isLoading])
  useEffect(() => {
    let stream: any = []
    if (isFSuccess && !isFFetching) {
      fResult?.data?.stream?.map((item) => {
        stream.push({value: item?.dbId, label: item?.name})
        return item
      })
      setOptionData((prevData) => {
        return {...prevData, stream: stream}
      })
      setDisableSelection(false) // Enable selection after data is loaded
      if (id) {
        const findData = notice.find((item) => item?.dbId === Number(id))
        if (findData) {
          const selectedStream = fResult?.data?.stream.find(
            (item) => item?.dbId === findData?.streamId
          )
          const classArray = fResult?.data?.class[findData?.streamId]?.map((item) => {
            return {label: item?.name, value: item?.dbId}
          })
          const selectedClass = fResult?.data?.class[findData?.streamId]?.find(
            (item) => item?.dbId === findData?.classId
          )
          setOptionData((prevData) => {
            return {
              ...prevData,
              stream: stream,
              class: classArray,
              selectedStream: {label: selectedStream?.name, value: selectedStream?.dbId},
              streamId: selectedStream?.dbId,
              selectedClass: {label: selectedClass?.name, value: selectedClass?.dbId},
              classId: selectedClass?.dbId,
            }
          })
          formik.setFieldValue('classId', selectedClass?.dbId)
          formik.setFieldValue('streamId', selectedStream?.dbId)
        }
      }
    }
  }, [isFSuccess, isFFetching, fResult?.data, formik, id, notice])
  useEffect(() => {
    if (isDetails && !isNotDetails) {
      console.log(noticeDetailResult?.data)
    }
  }, [isDetails, isNotDetails, noticeDetailResult?.data])

  // call when API returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])

  const handleStreamSelect = (e) => {
    formik.setFieldValue('streamId', e.value)
    let classObj = []
    const classData = fResult.data.class[e.value]
    if (classData && classData.length > 0) {
      classObj = classData.map((item) => ({
        value: item.dbId,
        label: item.name,
      }))
      let data = {
        ...optionsData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: classObj,
        classId: [],
        selectedClass: [],
      }
      setOptionData(data)
    } else {
      let data = {
        ...optionsData,
        streamId: e.value,
        selectedStream: {label: e.label, value: e.value},
        class: [],
        classId: [],
        selectedClass: [],
      }
      setOptionData(data)
    }
  }
  const handleCLassSelect = (selectedClasses) => {
    let data = {
      ...optionsData,
      selectedClass: selectedClasses,
      classId: selectedClasses.map((classObj) => classObj.value),
    }

    setOptionData(data)
    // Assuming formik.values.classId is an array
    formik.setFieldValue('classId', data.classId)
  }
  const handleCheckboxChange = () => {
    setDisableSelection(!disableSelection)
    let stream: any = []
    fResult?.data?.stream?.map((item) => {
      stream.push({value: item?.dbId, label: item?.name})
      return item
    })
    setOptionData({
      stream: stream,
      streamId: 0,
      selectedStream: {value: '', label: ''},
      class: [],
      classId: [],
      selectedClass: [],
      noticeType: [
        {label: 'One-liner notice', value: 'oneLiner'},
        {label: 'Attachment notice', value: 'attachment'},
        {label: 'Description notice', value: 'noticeHTML'},
      ],
      selectedNoticeType: {
        value: optionsData?.selectedNoticeType?.value,
        label: optionsData?.selectedNoticeType?.label,
      },
    })
  }
  const handleNoticeTypeSelector = (e: any) => {
    formik.setFieldValue('noticeType', e.value)
    setInitialValues(
      e?.value === 'oneLiner'
        ? {
            notice: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            streamId: 0,
            classId: 0,
            noticeType: e.value,
          }
        : e?.value === 'attachment'
        ? {
            noticeTitle: '',
            noticeDate: '',
            noticePDF: '',
            noticeType: e.value,
          }
        : {
            notice: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            noticeHTML: '',
            streamId: 0,
            classId: 0,
            noticeType: e.value,
          }
    )
    setOptionData({
      ...optionsData,
      selectedNoticeType: {label: e.label, value: e.value},
    })
  }
  const handlePdfUpload = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        formik.setFieldValue('noticePDF', reader.result)
      }
      reader.readAsDataURL(file)
    }
  }
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor
  }
  const handelEditorCHange = (content: any) => {
    const escapedContent = content.replace(/"/g, '/"').replace(/'/g, "/'")
    formik.setFieldValue('noticeHTML', escapedContent)
  }
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <h3 className='card-title justify-content-between gap-2 pt-2 '>
            <Link to={'/academics/notices'}>
              <button
                className='btn btn-sm btn-icon btn-clear btn-active-light-primary '
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                aria-label='Back'
                data-bs-original-title='Back'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </Link>
            <div className='flex-column d-flex align-items-start'>
              <span className='card-label  fw-bold fs-3 mb-1'>Notice</span>
              <span className='text-muted mt-1 fw-semibold fs-7'>You can Add or edit Notice</span>
            </div>
          </h3>
        </div>
      </div>
      <div className='card mb-5'>
        <div className='card-body'>
          <label className='form-label'>Notice Type*</label>
          <Select
            placeholder='Select Notice Type'
            value={
              optionsData?.selectedNoticeType?.label !== '' ? optionsData?.selectedNoticeType : ''
            }
            options={optionsData?.noticeType}
            onChange={(e: any) => {
              handleNoticeTypeSelector(e)
            }}
          />
        </div>
      </div>
      {optionsData?.selectedNoticeType?.value === 'oneLiner' && (
        <div className='card'>
          <div className='card-body'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='notice'>
              <div className='row'>
                {/* notice */}
                <div className='col-12 mb-5'>
                  <label className='form-label'>Notice*</label>
                  <input
                    type='text'
                    placeholder='Add Notice'
                    {...formik.getFieldProps('notice')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.notice && formik.errors.notice},
                      {
                        'is-valid': formik.touched.notice && !formik.errors.notice,
                      }
                    )}
                  />
                </div>
                {/* startDate */}
                <div className='col-md-6 col-12 mb-5  position-relative'>
                  <label className='form-label'>Start Date*</label>
                  <input
                    type='date'
                    {...formik.getFieldProps('startDate')}
                    className={clsx(
                      'form-control  bg-secondary p-0',
                      {
                        'is-invalid': formik.touched.startDate && formik.errors.startDate,
                      },
                      {
                        'is-valid': formik.touched.startDate && !formik.errors.startDate,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik.values.startDate ? formik.values.startDate : 'Select Start Date..'}
                  </div>
                </div>
                {/* endDate */}
                <div className='col-md-6 col-12 mb-5  position-relative'>
                  <label className='form-label'>End Date*</label>
                  <input
                    type='date'
                    {...formik.getFieldProps('endDate')}
                    className={clsx(
                      'form-control  bg-secondary p-0',
                      {
                        'is-invalid': formik.touched.endDate && formik.errors.endDate,
                      },
                      {
                        'is-valid': formik.touched.endDate && !formik.errors.endDate,
                      }
                    )}
                    min={formik.values?.startDate}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik.values.endDate ? formik.values.endDate : 'Select End Date..'}
                  </div>
                </div>
                {/* startTime */}
                <div className='col-md-6 mb-5 position-relative'>
                  <label className='form-label'>Start Time</label>
                  <input
                    type='time'
                    name='startTime'
                    onChange={(e) => {
                      formik.setFieldValue('startTime', e.target.value)
                    }}
                    className={clsx(
                      'form-control bg-transparent p-0',
                      {
                        'is-invalid': formik.touched.startTime && formik.errors.startTime,
                      },
                      {
                        'is-valid': formik.touched.startTime && !formik.errors.startTime,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik?.values?.startTime ? formik?.values?.startTime : 'Select Start Time'}
                  </div>
                </div>
                {/* endTime */}
                <div className='col-md-6 mb-5 position-relative'>
                  <label className='form-label'>End Time</label>
                  <input
                    type='time'
                    name='endTime'
                    onChange={(e) => {
                      formik.setFieldValue('endTime', e.target.value)
                    }}
                    className={clsx(
                      'form-control bg-transparent p-0',
                      {
                        'is-invalid': formik.touched.endTime && formik.errors.endTime,
                      },
                      {
                        'is-valid': formik.touched.endTime && !formik.errors.endTime,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik?.values?.endTime ? formik?.values?.endTime : 'Select End Time'}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='mb-3 form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='disableSelectionCheckbox'
                      checked={disableSelection}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='disableSelectionCheckbox'>
                      Disable Selection
                    </label>
                  </div>
                </div>
                {/* stream */}
                <div className='mb-5 col-md-6 col-12'>
                  <Select
                    placeholder='select Stream'
                    isDisabled={disableSelection}
                    value={
                      optionsData?.selectedStream?.label !== '' &&
                      optionsData?.selectedStream?.value !== ''
                        ? optionsData?.selectedStream
                        : null
                    }
                    options={optionsData?.stream}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleStreamSelect(e)
                    }}
                  />
                  {/* end::Menu 2 */}
                </div>
                {/* class */}
                <div className=' mb-5 col-md-6 col-12'>
                  <Select
                    placeholder='select Class'
                    isMulti
                    value={optionsData?.selectedClass}
                    options={optionsData?.class}
                    isDisabled={optionsData?.streamId === 0 ? true : false}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleCLassSelect(e)
                    }}
                  />
                </div>
                {/* submit */}
                <div className='col-12 text-end mt-5 mb-5'>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label d-flex gap-2'>
                        <i className='ki-duotone ki-message-add fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>{' '}
                        {id ? 'Save' : 'Add'} Notice
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {optionsData?.selectedNoticeType?.value === 'attachment' && (
        <div className='card'>
          <div className='card-body'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='notice'>
              <div className='row'>
                {/* notice */}
                <div className='col-12 mb-5'>
                  <label className='form-label'>Notice*</label>
                  <input
                    type='text'
                    placeholder='Add Notice'
                    {...formik.getFieldProps('noticeTitle')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.noticeTitle && formik.errors.noticeTitle},
                      {
                        'is-valid': formik.touched.noticeTitle && !formik.errors.noticeTitle,
                      }
                    )}
                  />
                </div>
                {/* noticeDate */}
                <div className=' col-12 mb-5  position-relative'>
                  <label className='form-label'>Notice Date*</label>
                  <input
                    type='date'
                    {...formik.getFieldProps('noticeDate')}
                    className={clsx(
                      'form-control  bg-secondary p-0',
                      {
                        'is-invalid': formik.touched.noticeDate && formik.errors.noticeDate,
                      },
                      {
                        'is-valid': formik.touched.noticeDate && !formik.errors.noticeDate,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik.values.noticeDate ? formik.values.noticeDate : 'Select Notice Date..'}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='mb-3 form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='disableSelectionCheckbox'
                      checked={disableSelection}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='disableSelectionCheckbox'>
                      Disable Selection
                    </label>
                  </div>
                </div>
                {/* stream */}
                <div className='mb-5 col-md-6 col-12'>
                  <Select
                    placeholder='select Stream'
                    isDisabled={disableSelection}
                    value={
                      optionsData?.selectedStream?.label !== '' &&
                      optionsData?.selectedStream?.value !== ''
                        ? optionsData?.selectedStream
                        : null
                    }
                    options={optionsData?.stream}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleStreamSelect(e)
                    }}
                  />
                  {/* end::Menu 2 */}
                </div>
                {/* class */}
                <div className=' mb-5 col-md-6 col-12'>
                  <Select
                    placeholder='select Class'
                    isMulti
                    value={optionsData?.selectedClass}
                    options={optionsData?.class}
                    isDisabled={optionsData?.streamId === 0 ? true : false}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleCLassSelect(e)
                    }}
                  />
                </div>
                {/* noticePDF acceptor */}
                <div className='col-12 mb-5'>
                  <label className='form-label'>PDF File</label>
                  <input
                    type='file'
                    accept='.pdf,image/*'
                    onChange={(e) => handlePdfUpload(e)}
                    className='form-control'
                  />
                </div>
                {/* submit */}
                <div className='col-12 text-end mt-5 mb-5'>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label d-flex gap-2'>
                        <i className='ki-duotone ki-message-add fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>{' '}
                        {id ? 'Save' : 'Add'} Notice
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {optionsData?.selectedNoticeType?.value === 'noticeHTML' && (
        <div className='card'>
          <div className='card-body'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='notice'>
              <div className='row'>
                {/* notice */}
                <div className='col-12 mb-5'>
                  <label className='form-label'>Notice*</label>
                  <input
                    type='text'
                    placeholder='Add Notice'
                    {...formik.getFieldProps('notice')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.notice && formik.errors.notice},
                      {
                        'is-valid': formik.touched.notice && !formik.errors.notice,
                      }
                    )}
                  />
                </div>
                {/* startDate */}
                <div className='col-md-6 col-12 mb-5  position-relative'>
                  <label className='form-label'>Start Date*</label>
                  <input
                    type='date'
                    {...formik.getFieldProps('startDate')}
                    className={clsx(
                      'form-control  bg-secondary p-0',
                      {
                        'is-invalid': formik.touched.startDate && formik.errors.startDate,
                      },
                      {
                        'is-valid': formik.touched.startDate && !formik.errors.startDate,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik.values.startDate ? formik.values.startDate : 'Select Start Date..'}
                  </div>
                </div>
                {/* endDate */}
                <div className='col-md-6 col-12 mb-5  position-relative'>
                  <label className='form-label'>End Date*</label>
                  <input
                    type='date'
                    {...formik.getFieldProps('endDate')}
                    className={clsx(
                      'form-control  bg-secondary p-0',
                      {
                        'is-invalid': formik.touched.endDate && formik.errors.endDate,
                      },
                      {
                        'is-valid': formik.touched.endDate && !formik.errors.endDate,
                      }
                    )}
                    min={formik.values?.startDate}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik.values.endDate ? formik.values.endDate : 'Select End Date..'}
                  </div>
                </div>
                {/* startTime */}
                <div className='col-md-6 mb-5 position-relative'>
                  <label className='form-label'>Start Time</label>
                  <input
                    type='time'
                    name='startTime'
                    onChange={(e) => {
                      formik.setFieldValue('startTime', e.target.value)
                    }}
                    className={clsx(
                      'form-control bg-transparent p-0',
                      {
                        'is-invalid': formik.touched.startTime && formik.errors.startTime,
                      },
                      {
                        'is-valid': formik.touched.startTime && !formik.errors.startTime,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik?.values?.startTime ? formik?.values?.startTime : 'Select Start Time'}
                  </div>
                </div>
                {/* endTime */}
                <div className='col-md-6 mb-5 position-relative'>
                  <label className='form-label'>End Time</label>
                  <input
                    type='time'
                    name='endTime'
                    onChange={(e) => {
                      formik.setFieldValue('endTime', e.target.value)
                    }}
                    className={clsx(
                      'form-control bg-transparent p-0',
                      {
                        'is-invalid': formik.touched.endTime && formik.errors.endTime,
                      },
                      {
                        'is-valid': formik.touched.endTime && !formik.errors.endTime,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '55%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik?.values?.endTime ? formik?.values?.endTime : 'Select End Time'}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='mb-3 form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      id='disableSelectionCheckbox'
                      checked={disableSelection}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='disableSelectionCheckbox'>
                      Disable Selection
                    </label>
                  </div>
                </div>
                {/* stream */}
                <div className='mb-5 col-md-6 col-12'>
                  <Select
                    placeholder='select Stream'
                    isDisabled={disableSelection}
                    value={
                      optionsData?.selectedStream?.label !== '' &&
                      optionsData?.selectedStream?.value !== ''
                        ? optionsData?.selectedStream
                        : null
                    }
                    options={optionsData?.stream}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleStreamSelect(e)
                    }}
                  />
                  {/* end::Menu 2 */}
                </div>
                {/* class */}
                <div className=' mb-5 col-md-6 col-12'>
                  <Select
                    placeholder='select Class'
                    isMulti
                    value={optionsData?.selectedClass}
                    options={optionsData?.class}
                    isDisabled={optionsData?.streamId === 0 ? true : false}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleCLassSelect(e)
                    }}
                  />
                </div>
                {/* rich editor */}
                <div className='mb-5  col-12'>
                  <label htmlFor=''>Description:</label>
                  <SunEditor
                    setAllPlugins={true}
                    setOptions={noticeOption}
                    placeholder='Add Description here...'
                    lang={langs}
                    autoFocus={true}
                    height='100%'
                    width='100%'
                    getSunEditorInstance={getSunEditorInstance}
                    onChange={handelEditorCHange}
                  />
                </div>
                {/* submit */}
                <div className='col-12 text-end mt-5 mb-5'>
                  <button
                    type='submit'
                    className='btn btn-primary '
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label d-flex gap-2'>
                        <i className='ki-duotone ki-message-add fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>{' '}
                        {id ? 'Save' : 'Add'} Notice
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}
