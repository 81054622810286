/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link, useLocation} from 'react-router-dom'
import Filter from '../filter'

function ListAssignmentHeader() {
  const location = useLocation()
  return (
    <div className={`card  mb-6`}>
      <Filter type='Check assignment List status' />
      <div className='card-body pb-0 pt-0'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch justify-content-start p-2 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'></li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/assignment/AllAssignment/all' && 'active')
                }
                to='/assignment/AllAssignment/all'
              >
                All
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/assignment/AllAssignment/pending' && 'active ')
                }
                to='/assignment/AllAssignment/pending'
              >
                Active
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/assignment/AllAssignment/submitted' && 'active ')
                }
                to='/assignment/AllAssignment/submitted'
              >
                Closed
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ListAssignmentHeader}
