import {useEffect, useState} from 'react'
import '../event.css'
import {Link, useSearchParams} from 'react-router-dom'
import {useLazyGetEventDetailsQuery} from '../../../Api/allApi'
import Swal from 'sweetalert2'

const ViewEvents = () => {
  const [searchParams] = useSearchParams()
  var eventId: any = searchParams.get('eventId')
  const [eventDetails, setEventDetails]: any = useState([])
  // get event details api
  const [eventDetailsAPI, result] = useLazyGetEventDetailsQuery()
  const {isSuccess, isFetching, isError, error} = result
  useEffect(() => {
    if (eventId) {
      eventDetailsAPI(Number(eventId))
    }
  }, [eventDetailsAPI, eventId])
  // call when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setEventDetails(result?.data)
      setDeadline(result?.data?.endDate)
    }
  }, [isSuccess, isFetching, result?.data])
  // call when api returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  // countDown
  const [deadline, setDeadline] = useState('')
  const [timer, setTimer]: any = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })
  const getTime = (deadline: any) => {
    const time = Date.parse(deadline) - Date.now()
    setTimer({
      days: Math.floor(time / (1000 * 60 * 60 * 24)),
      hours: Math.floor((time / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((time / 1000 / 60) % 60),
      seconds: Math.floor((time / 1000) % 60),
    })
  }
  useEffect(() => {
    if (deadline) {
      const interval = setInterval(() => getTime(deadline), 1000)
      return () => clearInterval(interval)
    }
  }, [deadline])

  return (
    <>
      {eventDetails.length === 0 ? (
        <div className='card'>
          <div className='card-header align-items-center justify-content-center'>
            <h2 className='card-title fs-1'>
              <i className='ki-duotone ki-shield-cross'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
              No Event Found
            </h2>
          </div>
        </div>
      ) : !isSuccess ? (
        <div className='row'>
          <div className='col-12 text-center'>
            <i
              className='fa-solid fa-circle-notch fa-spin  text-primary '
              style={{fontSize: '30px', color: 'unset'}}
            ></i>
          </div>
        </div>
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-header'>
              <div className='card-title'>
                <Link to={'/events/Active'}>
                  <button
                    className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    aria-label='Back'
                    data-bs-original-title='Back'
                    data-kt-initialized='1'
                  >
                    <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </Link>
                view Event Details
              </div>
            </div>
          </div>
          <div className='card card-custom'>
            <div
              className='card-header p-0 text-center'
              style={{height: '300px', position: 'relative'}}
            >
              <img src={eventDetails?.coverImg} alt='' style={{height: '100%', width: '100%'}} />
              <div style={{position: 'absolute', height: '100%', width: '100%'}}>
                <h1 className=' fs-1 fw-600  mt-5'>{eventDetails?.title}</h1>
              </div>
            </div>
            <div className='card-body'>
              <div className='row my-20'>
                <h1 className='text-center'>Event WILL START IN</h1>
                <div className='row'>
                  <div className='col-3 mt-5'>
                    <div className='bg-danger mx-auto position-relative counter'>
                      <span
                        className='position-absolute text-white text-fixed-center text-center'
                        style={{top: '50%', left: '50%'}}
                      >
                        {timer?.days}
                      </span>
                      <p
                        className='position-absolute text-white sub-text-fixed-center text-center m-0 p-0'
                        style={{top: '70%', left: '50%'}}
                      >
                        Days
                      </p>
                    </div>
                  </div>
                  <div className='col-3 mt-5'>
                    <div className='bg-warning mx-auto position-relative counter'>
                      <span
                        className='position-absolute text-white text-fixed-center text-center'
                        style={{top: '50%', left: '50%'}}
                      >
                        {timer?.hours}
                      </span>
                      <p
                        className='position-absolute text-white sub-text-fixed-center text-center m-0 p-0'
                        style={{top: '70%', left: '50%'}}
                      >
                        Hours
                      </p>
                    </div>
                  </div>
                  <div className='col-3 mt-5'>
                    <div className='bg-success mx-auto position-relative counter'>
                      <span
                        className='position-absolute text-white text-fixed-center text-center'
                        style={{top: '50%', left: '50%'}}
                      >
                        {timer?.minutes}
                      </span>
                      <p
                        className='position-absolute text-white sub-text-fixed-center text-center m-0 p-0'
                        style={{top: '70%', left: '50%'}}
                      >
                        Mins
                      </p>
                    </div>
                  </div>
                  <div className='col-3 mt-5'>
                    <div className='bg-info mx-auto position-relative counter'>
                      <span
                        className='position-absolute text-white text-fixed-center text-center'
                        style={{top: '50%', left: '50%'}}
                      >
                        {timer?.seconds}
                      </span>
                      <p
                        className='position-absolute text-white sub-text-fixed-center text-center m-0 p-0'
                        style={{top: '70%', left: '50%'}}
                      >
                        Sec
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <h3 className='fw-bloder text-center fs-1'>About Workshop</h3>
              <div className='row align-items-center mb-5 justify-content-around'>
                <div className='col-12'>
                  <div className='fw-bold text-dark fs-4'>
                    Event Type : {eventDetails?.eventTypeDetails?.name}
                  </div>
                </div>
                <div className='col-md-12 ' style={{zIndex: '1'}}>
                  <p>
                    {eventDetails?.descr} Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Porro harum non laboriosam quasi quaerat, omnis dolor reiciendis eos commodi
                    voluptate dicta similique aliquid illo unde. Et, quae ullam. Quasi, quod? Lorem
                    ipsum dolor, sit amet consectetur adipisicing elit. A maxime eveniet corporis
                    enim est eius voluptatibus numquam ducimus, qui, amet quidem excepturi
                    asperiores nesciunt reprehenderit nostrum veniam, nisi id. Quam?
                  </p>
                </div>
              </div>
              <h1 className='fw-bloder text-center mb-5'>Our Organiser</h1>
              <div className='row'>
                <div className='col-12 text-center '>
                  <img
                    src={eventDetails?.organiserDetails?.imgLink}
                    alt='Organiser'
                    className='w-75px h-75px rounded-circle mb-5'
                  />
                  <h3 className='font-bold'>{eventDetails?.organiserDetails?.name}</h3>
                  <p className='text-muted'>{eventDetails?.organiserDetails?.about}</p>
                </div>
              </div>
            </div>
            <div className='card-footer border-0'>
              <div className='p-5'>
                <h1>Choose your slot</h1>
                <div className='my-10'>
                  <div className='row align-items-center'>
                    <div className='col-md-3 text-center'>
                      <div className='flex align-items-center fs-2 justify-content-center'>
                        <i className='bi bi-calendar-event-fill text-primary'></i>
                        <b className='mx-2 fs-2 fw-bold'>14th April,</b>Fri
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-danger'>2 seats left</div>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-warning'>10 seats left</div>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-success'>25 seats left</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row align-items-center my-10'>
                    <div className='col-md-3 text-center'>
                      <div className='flex align-items-center fs-2 justify-content-center'>
                        <i className='bi bi-calendar-event-fill text-primary'></i>
                        <b className='mx-2 fs-2 fw-bold'>15th April,</b>Fri
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-danger'>2 seats left</div>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-warning'>10 seats left</div>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-success'>25 seats left</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row align-items-center'>
                    <div className='col-md-3 text-center'>
                      <div className='flex align-items-center fs-2 justify-content-center'>
                        <i className='bi bi-calendar-event-fill text-primary'></i>
                        <b className='mx-2 fs-2 fw-bold'>16th April,</b>Fri
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='row'>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-danger'>2 seats left</div>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-warning'>10 seats left</div>
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='text-center'>
                            <div>
                              <p className='px-10 py-3 bg-secondary my-2 rounded-4'>
                                9:30 am -11:30 am
                              </p>
                            </div>
                            <div className='text-success'>25 seats left</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {eventDetails?.paid && (
                <div className='py-10'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div className='btn btn-primary'>Book my seat @ Rs. {eventDetails?.price}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ViewEvents
