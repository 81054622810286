import Swal from 'sweetalert2'
import Tooltip from 'rc-tooltip'
import DataTables from '../../DataTable/DataTables'
import {useEffect, useState} from 'react'
import {useDeleteCategoryMutation, useLazyGetCourseCategoryQuery} from '../../../Api/allApi'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {setCategory} from '../../../redux/CourseSlice'
import {CategoryModel} from './CategoryModel'
export default function CourseCategory() {
  // pagination
  const dispatch = useDispatch()
  const {category} = useSelector((state: any) => state.CourseSlice)
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)
  const [selected, setSelected] = useState({category: {}})
  // Category list api
  const [categoryList, result] = useLazyGetCourseCategoryQuery()
  const {isSuccess, isFetching} = result
  // delete Category api
  const [deleteCategory, deleteResult] = useDeleteCategoryMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete} = deleteResult
  useEffect(() => {
    categoryList({})
  }, [categoryList])

  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(setCategory(result?.data?.courseCategoryList))
      setTotalRows(result?.data?.courseCategoryList?.length)
    }
  }, [isSuccess, isFetching, result?.data?.courseCategoryList, dispatch])

  useEffect(() => {
    if (isDelete && !isNotDelete) {
      categoryList({})
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, deleteResult?.data?.detail, categoryList])

  const handlePageChange = (page: any) => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }

  // data
  const columns: any = [
    {
      name: 'Category Name',
      selector: (row: any) => row?.categoryName,
      center: true,
      sortable: true,
    },

    {
      style: {
        justifyContent: 'end',
      },
      name: '',
      selector: (row: any) => {
        return (
          <>
            <Tooltip placement='bottom' overlay={<span>Edit</span>}>
              <button
                className='btn btn-sm btn-light-warning me-2'
                type='button'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
                data-bs-toggle='modal'
                style={{height: 'unset', width: 'unset'}}
                data-bs-target='#Author'
                onClick={() =>
                  setSelected({
                    ...selected,
                    category: row,
                  })
                }
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Edit
              </button>
            </Tooltip>
            <Tooltip placement='bottom' overlay={<span>Delete</span>}>
              <button
                className='btn btn-sm btn-light-danger me-2'
                type='button'
                onClick={() => {
                  Swal.fire({
                    title: 'Confirm deletion?',
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText:
                      "<i className='ki-duotone ki-double-check  fs-1'> <span className='path1'></span><span className='path2'></span> <span className='path3'></span></i> Confirm",
                    confirmButtonColor: '#009EF7',
                    cancelButtonText:
                      '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      deleteCategory(row?.dbId)
                    } else if (result.isDenied) {
                      Swal.fire('Record is not deleted', '', 'error')
                    }
                  })
                }}
              >
                <i className='ki-duotone ki-trash-square fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Delete
              </button>
            </Tooltip>
          </>
        )
      },
      width: '300px',
    },
  ]

  return (
    <>
      {isFetching ? ( // Loading spinner if data is being fetched
        <>
          <div className='px-5'>
            <div className='col-12 text-center card mb-5 p-5'>
              <i
                className='fa-solid fa-circle-notch fa-spin p-10 text-primary'
                style={{fontSize: '30px', color: 'unset'}}
              ></i>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-header'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Category</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>
                  You can Add or edit Category
                </span>
              </h3>
              <div className='card-toolbar' style={{gap: '10px'}}>
                {/* add */}
                <button
                  className='btn btn-sm  m-0  btn-light-primary'
                  type='button'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  data-bs-toggle='modal'
                  style={{height: 'unset', width: 'unset'}}
                  data-bs-target='#Author'
                  onClick={() =>
                    setSelected({
                      ...selected,
                      category: {categoryName: ''},
                    })
                  }
                >
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>{' '}
                  Add Category
                </button>
              </div>
            </div>
          </div>
          <div className='col-12 mb-5'>
            <div className=' card card-custom '>
              <div className='card-body py-3'>
                {/* begin::Table container */}
                <DataTables
                  columns={columns}
                  data={category}
                  loading={isFetching}
                  totalRows={totalRows}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                  page={page}
                  perPage={perPage}
                />
                {/* end::Table container */}
              </div>
              <div className='modal fade' id='Author' aria-hidden='true'>
                <CategoryModel name='Category' type='addAuthor' data={selected?.category} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
