import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  blog: [],
}

export const BlogSlice = createSlice({
  name: 'BlogSlice',
  initialState,
  reducers: {
    setBlog: (state, action) => {
      console.log(action.payload)
      state.blog = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setBlog} = BlogSlice.actions
export default BlogSlice.reducer
