import React, {useEffect, useState} from 'react'
import DataTables from '../../DataTable/DataTables'
import {useSelector} from 'react-redux'
import Swal from 'sweetalert2'
import Tooltip from 'rc-tooltip'
import {useDispatch} from 'react-redux'
import {setNotice} from '../../../redux/AcademicSlice'
import {Link} from 'react-router-dom'
import {
  useDeleteNoticeMutation,
  useGetNoticeMutation,
  useLazyAcademicFilterQuery,
} from '../../../Api/allApi'
import ReactSelect from 'react-select'

export default function NoticePage() {
  // get data from redux
  const {notice} = useSelector((state: any) => state.AcademicSlice)
  const dispatch = useDispatch()
  // pagination
  const [totalRows, setTotalRows] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [page, setPage] = useState(1)

  // for filters
  const [filterData, setFilterData]: any = useState({
    stream: [],
    streamId: [],
    class: [],
    classId: [],
  })

  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  // notice api
  const [noticeTrigger, noticeResult] = useGetNoticeMutation()
  const {isSuccess: isNotice, isLoading: isNotNotice} = noticeResult
  // Delete Notice
  const [deleteNoticeTrigger, deleteResult]: any = useDeleteNoticeMutation()
  const {isSuccess: isDelete, isLoading: isNotDelete, isError, error} = deleteResult

  // filter trigger
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])
  useEffect(() => {
    if (filterData?.streamId?.length !== 0) {
      noticeTrigger({
        streamId: filterData?.streamId,
        classId: filterData?.classId,
      })
    }
  }, [filterData, noticeTrigger])
  // when api calling is done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = result?.data

      let classObj: any = []
      const classData = data.class[data?.stream[0]?.dbId]
      if (classData && classData.length > 0) {
        const classes = classData.map((item) => ({
          value: item.dbId,
          label: item.name,
        }))
        classObj = [...classObj, ...classes]
      }
      let streams = data.stream?.map((item) => {
        return {value: item?.dbId, label: item?.name}
      })
      setFilterData((prevData) => {
        return {
          ...prevData,
          stream: streams,
          streamId: [data?.stream[0]?.dbId],
          class: classObj,
        }
      })
    }
  }, [isSuccess, isFetching, result?.data])

  useEffect(() => {
    if (isNotice && !isNotNotice) {
      setTotalRows(noticeResult?.data?.noticeList?.length)
      dispatch(setNotice(noticeResult?.data?.noticeList))
    }
  }, [isNotice, isNotNotice, noticeResult?.data, dispatch])

  useEffect(() => {
    if (isDelete && !isNotDelete) {
      noticeTrigger({
        streamId: filterData?.streamId,
        classId: filterData?.classId,
      })
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: deleteResult?.data?.detail
          ? deleteResult?.data?.detail
          : 'Your record has been deleted',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isDelete, isNotDelete, filterData, noticeTrigger, deleteResult?.data])

  // call when api returns error
  useEffect(() => {
    if (isError) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        iconColor: '#DC3545',
        customClass: {
          popup: 'colored-toast',
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      })
      Toast.fire({
        icon: 'error',
        title: error?.error ? error?.error : 'Something went wrong',
      })
    }
  }, [isError, error])
  // data
  const columns: any = [
    {
      name: 'Notice',
      selector: (row: any) => {
        return (
          <Tooltip placement='bottom' overlay={row.notice}>
            <div> {row.notice}</div>
          </Tooltip>
        )
      },
      center: true,
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: (row: any) => row?.startDate,
      center: true,
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: (row: any) => row?.startTime,
      center: true,
      sortable: true,
    },
    {
      name: 'End Date',
      selector: (row: any) => row?.endDate,
      center: true,
      sortable: true,
    },
    {
      name: 'End Time',
      selector: (row: any) => row?.endTime,
      center: true,
      sortable: true,
    },

    {
      style: {
        justifyContent: 'end',
      },
      name: '',
      selector: (row: any) => {
        return (
          <>
            <Link to={`/academics/notices/notice?id=${row?.dbId}`}>
              <button
                className='btn btn-sm btn-light-warning me-2'
                type='button'
                style={{height: 'unset', width: 'unset'}}
              >
                <i className='ki-duotone ki-message-edit fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>{' '}
                Edit
              </button>
            </Link>
            <button
              className='btn btn-sm btn-light-danger me-2'
              type='button'
              onClick={() => {
                Swal.fire({
                  title: 'Confirm deletion?',
                  showDenyButton: false,
                  showCancelButton: true,
                  confirmButtonText:
                    '<i class="ki-duotone ki-double-check text-white fs-2"><span class="path1"></span> <span class="path2"></span></i> Confirm',
                  confirmButtonColor: '#009EF7',
                  cancelButtonText:
                    '<i class="ki-duotone ki-abstract-11  text-white fs-3"><span class="path1"></span><span class="path2"></span></i> Cancel',
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    deleteNotice(row?.dbId)
                  } else if (result.isDenied) {
                    Swal.fire('Record is not deleted', '', 'error')
                  }
                })
              }}
            >
              <i className='ki-duotone ki-trash-square fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>{' '}
              Delete
            </button>
          </>
        )
      },
      width: '250px',
    },
  ]
  //   for delete operation
  const deleteNotice = (dbId) => {
    deleteNoticeTrigger(dbId)
  }
  //pagination
  const handlePageChange = (page: any) => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setPerPage(newPerPage)
  }

  // handle stream
  const handleStreamSelect = (selectedOptions) => {
    let classObj: any = []
    let selectedStream: any = []
    selectedOptions.forEach((option) => {
      const classData = result.data.class[option.value]
      if (classData && classData.length > 0) {
        const classes = classData.map((item) => ({
          value: item.dbId,
          label: item.name,
        }))
        classObj = [...classObj, ...classes]
      }
      selectedStream.push({label: option.label, value: option.value})
    })

    let data = {
      ...filterData,
      selectedStream: selectedStream,
      streamId: selectedStream.map((stream) => stream.value), // Store selected stream IDs
      class: classObj,
    }
    setFilterData(data)
  }
  // handle class select
  const handleCLassSelect = (selectedOptions) => {
    let semesterOptions: any = []
    let selectedClasses: any = []
    selectedOptions.forEach((option) => {
      const semesterData = result.data.semester[option.value]
      if (semesterData && semesterData.length > 0) {
        const semesters = semesterData.map((item) => ({
          value: item.dbId,
          label: item.name,
        }))
        semesterOptions = [...semesterOptions, ...semesters]
      }
      selectedClasses.push({label: option.label, value: option.value})
    })

    let data = {
      ...filterData,
      selectedClass: selectedClasses,
      classId: selectedClasses.map((cls) => cls.value), // Store selected class IDs
      semester: semesterOptions,
    }
    setFilterData(data)
  }
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <h3 className={`card-title align-items-start flex-column `}>
            <span className='card-label fw-bold fs-3 mb-1'>Notice</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Check your Notice here</span>
          </h3>
          <div className='card-toolbar d-flex align-items-center gap-5'>
            {/* stream */}
            <div className='mb-5 '>
              <label className='from-label'>Select Stream:</label>
              <ReactSelect
                placeholder='Select Stream'
                value={filterData?.selectedStream || filterData?.stream[0]}
                options={filterData?.stream}
                isMulti={true}
                styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    margin: '0px',
                    color: '#7e8299',
                    fontWeight: 'bold',
                    ':hover': {
                      color: '#009ef7',
                      backgroundColor: '#f1faff',
                    },
                  }),
                }}
                onChange={(selectedOptions) => {
                  handleStreamSelect(selectedOptions)
                }}
              />
            </div>
            {/* class */}
            <div className=' mb-5'>
              <label className='from-label'>Select Class:</label>
              <ReactSelect
                placeholder='Select Class'
                value={filterData?.selectedClass}
                options={filterData?.class}
                // isDisabled={filterData?.streamId === 0 ? true : false}
                isMulti={true}
                styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer',
                    margin: '0px',
                    color: '#7e8299',
                    fontWeight: 'bold',
                    ':hover': {
                      color: '#009ef7',
                      backgroundColor: '#f1faff',
                    },
                  }),
                }}
                onChange={(selectedOptions) => {
                  handleCLassSelect(selectedOptions)
                }}
              />
            </div>

            <div className=''>
              <Link to='/academics/notices/notice'>
                <button className='btn btn-primary btn-sm me-6'>
                  <i className='ki-duotone ki-message-add fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                  </i>{' '}
                  Add notice
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 mb-5'>
        <div className=' card card-custom '>
          <div className='card-body py-3'>
            {/* begin::Table container */}
            <DataTables
              columns={columns}
              data={notice}
              loading={isNotNotice}
              totalRows={totalRows}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              page={page}
              perPage={perPage}
            />
            {/* end::Table container */}
          </div>
        </div>
      </div>
    </>
  )
}
