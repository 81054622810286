import React, {useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Segment, Item, Divider, Button, Icon, Message, Menu, Header} from 'semantic-ui-react'
import {getLetter} from '../../utils'
import {find, map, isEmpty, size, sum} from 'lodash'

const Quiz = ({data, endQuiz}) => {
  const quizHolder = map(data, function (o, index) {
    return {
      id: index,
      ...o,
    }
  })

  const [questionIndex, setQuestionIndex] = useState(0)

  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([])
  const handlePrevious = () => {
    setQuestionIndex(questionIndex - 1)
  }
  console.log(quizHolder[questionIndex])
  const handleNext = () => {
    console.log(quizHolder)
    if (questionIndex + 1 <= size(questionsAndAnswers)) {
      let point = 0
      const qna = questionsAndAnswers
      const isPreviouslyAnswered = find(qna, function (o) {
        return o.id === questionIndex
      })
      if (isEmpty(isPreviouslyAnswered)) {
        qna.push({
          id: quizHolder[questionIndex]?.id,
          question: quizHolder[questionIndex].question,
          correct_answer: quizHolder[questionIndex].correct_answer,
          point,
        })
        setQuestionsAndAnswers(qna)
      } else {
        const updateAnswer = map(qna, function (obj) {
          if (obj?.id === questionIndex) {
            return {
              ...obj,
              point,
            }
          } else {
            return obj
          }
        })
        setQuestionsAndAnswers(updateAnswer)
      }
      if (questionIndex === quizHolder.length - 1) {
        const correctAnswerCount = map(qna, 'point')
        return endQuiz({
          totalQuestions: quizHolder.length,
          correctAnswers: sum(correctAnswerCount),
          questionsAndAnswers: qna,
        })
      }
      setQuestionIndex(questionIndex + 1)
    } else {
      setQuestionIndex(questionIndex + 1)
    }
  }
  return (
    <Item.Header>
      <Fragment>
        <Segment>
          <Item.Group divided>
            <Item>
              <Item.Content>
                <Item.Extra>
                  {/* <div class='row'>
                    <div class='col-md-6 col-sm-12 d-flex  align-self-lg-center'> */}
                  <Header as='h1' block floated='left'>
                    <Icon name='info circle' />
                    <Header.Content>
                      {`Question No.${questionIndex + 1} of ${quizHolder.length}`}
                    </Header.Content>
                  </Header>
                </Item.Extra>
                <br />
                <Item.Meta>
                  <Message size='huge' floating>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `Q.${questionIndex + 1}  ${quizHolder[questionIndex]?.question}`,
                      }}
                    ></div>
                  </Message>
                  <br />
                  <Item.Description>
                    <h3> following answers:</h3>
                  </Item.Description>
                  <Divider />
                  {(quizHolder[questionIndex]?.optionType === 'single' ||
                    quizHolder[questionIndex]?.optionType === 'multiple') && (
                    <Menu vertical fluid size='massive'>
                      {quizHolder[questionIndex]?.options.map((option, i) => {
                        const letter = getLetter(i)
                        const decodedOption = option?.content
                        return (
                          <Menu.Item
                            key={decodedOption}
                            className={`${option?.correct ? 'text-success' : ''}`}
                            name={decodedOption}
                          >
                            <b style={{marginRight: '8px'}}>{letter}</b>
                            {decodedOption}
                          </Menu.Item>
                        )
                      })}
                    </Menu>
                  )}
                  {(quizHolder[questionIndex]?.optionType === 'short' ||
                    quizHolder[questionIndex]?.optionType === 'long') && (
                    <Menu vertical fluid size='massive'>
                      <Menu.Item
                        key={quizHolder[questionIndex]?.options}
                        name={quizHolder[questionIndex]?.options}
                      >
                        <b style={{marginRight: '8px'}}>Ans :</b>
                        {quizHolder[questionIndex]?.options}
                      </Menu.Item>
                    </Menu>
                  )}
                </Item.Meta>
                <Divider />
                <Item.Extra>
                  <Button
                    primary
                    content='Next'
                    onClick={handleNext}
                    floated='right'
                    size='big'
                    icon='right chevron'
                    labelPosition='right'
                    disabled={questionIndex + 1 === quizHolder.length ? true : false}
                  />
                  <Button
                    primary
                    content='Previous'
                    onClick={handlePrevious}
                    floated='right'
                    size='big'
                    icon='left chevron'
                    labelPosition='left'
                    disabled={questionIndex < 1}
                  />
                </Item.Extra>
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        <br />
      </Fragment>
    </Item.Header>
  )
}

Quiz.propTypes = {
  data: PropTypes.array.isRequired,
  countdownTime: PropTypes.number.isRequired,
  endQuiz: PropTypes.func.isRequired,
}

export default Quiz
