import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import ReactSelect from 'react-select'
import * as Yup from 'yup'
import {useLazyAcademicFilterQuery} from '../../../Api/allApi'
const StudentSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  dob: Yup.string().required('Date of birth is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  contactNo: Yup.string()
    .matches(/^[0-9]+$/, 'Contact number must contain only digits')
    .min(10, 'Contact number must be at least 10 characters')
    .max(10, 'Contact number must not exceed 15 characters')
    .required('Contact number is required'),
  gender: Yup.string().required('Gender is required'),
  bloodGroup: Yup.string().required('Blood group is required'),
  classId: Yup.string().required('class is required'),
  divisionId: Yup.string().required('division is required'),
  streamId: Yup.string().required('stream is required'),
})
export default function StudentLists() {
  const [initialValues] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    contactNo: '',
    gender: '',
    bloodGroup: '',
    classId: '',
    streamId: '',
    divisionId: '',
  })
  const [filterData, setFilterData] = useState({
    stream: [],
    streamId: 0,
    selectedStream: {value: '', label: ''},
    class: [],
    classId: 0,
    selectedClass: {value: '', label: ''},
    division: [],
    divisionId: 0,
    selectedDivision: {value: '', label: ''},
  })
  const [loading, setLoading] = useState(false)
  // filter api
  const [filterTrigger, result] = useLazyAcademicFilterQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    filterTrigger({})
  }, [filterTrigger])

  // when api calling is done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const data = result?.data
      let streams = data.stream?.map((item) => {
        return {value: item?.dbId, label: item?.name}
      })
      setFilterData((prevData) => {
        return {
          ...prevData,
          stream: streams,
        }
      })
    }
  }, [isSuccess, isFetching, result?.data])
  // call when all data fill well
  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: StudentSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        let payload = {
          firstName: values?.firstName,
          lastName: values?.lastName,
          dob: values?.lastName,
          email: values?.email,
          contactNo: values?.contactNo,
          gender: values?.gender,
          bloodGroup: values?.bloodGroup,
          classId: values?.classId,
          streamId: values?.streamId,
          divisionId: values?.divisionId,
        }
        console.log(payload)
      } catch (error) {
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const handleStreamSelect = (e) => {
    const classData = result.data.class[e.value]
    const classObj =
      classData && classData.length > 0
        ? classData.map((item) => ({value: item.dbId, label: item.name}))
        : []

    const data = {
      ...filterData,
      streamId: e.value,
      selectedStream: {label: e.label, value: e.value},
      class: classObj,
      classId: 0,
      selectedClass: {value: '', label: ''},
      division: [],
      divisionId: 0,
      selectedDivision: {value: '', label: ''},
    }
    setFilterData(data)
    formik.setFieldValue('streamId', e.value)
  }

  const handleClassSelect = (e) => {
    const divisionData = result.data.division[e.value]
    const division =
      divisionData && divisionData.length > 0
        ? divisionData.map((item) => ({value: item.dbId, label: item.division}))
        : []

    const data = {
      ...filterData,
      classId: e.value,
      selectedClass: {label: e.label, value: e.value},
      division: division,
      divisionId: 0,
      selectedDivision: {value: '', label: ''},
    }
    setFilterData(data)
    formik.setFieldValue('classId', e.value)
  }
  console.log(formik.errors)
  return (
    <>
      <div className='card mb-5'>
        <div className='card-header'>
          <h3 className={`card-title align-items-start flex-column `}>
            <span className='card-label fw-bold fs-3 mb-1'>Student</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Manage your Students here</span>
          </h3>
          <div className='card-toolbar d-flex align-items-center gap-5'>
            <button className='btn btn-primary btn-sm'>Download Sample Format</button>
            <button className='btn btn-primary btn-sm'>Upload Student Format</button>
          </div>
        </div>
      </div>
      <div className='col-12 mb-5'>
        <div className=' card card-custom '>
          <div className='card-body py-3'>
            <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
              <div className='row'>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    First Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    placeholder='First Name'
                    {...formik.getFieldProps('firstName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.firstName && formik.errors.firstName},
                      {
                        'is-valid': formik.touched.firstName && !formik.errors.firstName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4 col-12 mb-5'>
                  <label className='form-label'>
                    Last Name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    placeholder='Last Name'
                    {...formik.getFieldProps('lastName')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.lastName && formik.errors.lastName},
                      {
                        'is-valid': formik.touched.lastName && !formik.errors.lastName,
                      }
                    )}
                  />
                </div>
                <div className='col-md-4  mb-5  position-relative'>
                  <label className='form-label'>
                    Date Of Birth<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='date'
                    name='publicationDate'
                    id='publicationDate'
                    {...formik.getFieldProps('dob')}
                    className={clsx(
                      'form-control bg-transparent p-0',
                      {'is-invalid': formik.touched.dob && formik.errors.dob},
                      {
                        'is-valid': formik.touched.dob && !formik.errors.dob,
                      }
                    )}
                  />
                  <div
                    className='position-absolute form-label'
                    style={{top: '50%', left: '40%', pointerEvents: 'none'}}
                  >
                    <i className='fa-solid fa-calendar-days fs-5'></i>{' '}
                    {formik?.values?.dob ? formik?.values?.dob : 'Date Of Birth'}
                  </div>
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Gender<span className='text-danger'>*</span>
                  </label>
                  <ReactSelect
                    placeholder='Select Gender'
                    value={
                      formik.values.gender
                        ? {value: formik.values.gender, label: formik.values.gender}
                        : null
                    }
                    options={[
                      {value: 'male', label: 'Male'},
                      {value: 'female', label: 'Female'},
                    ]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('gender', selectedOption.value)
                    }
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Blood Group<span className='text-danger'>*</span>
                  </label>
                  <ReactSelect
                    placeholder='Select Blood Group'
                    value={
                      formik.values.bloodGroup
                        ? {value: formik.values.bloodGroup, label: formik.values.bloodGroup}
                        : null
                    }
                    options={[
                      {value: 'A+', label: 'A+'},
                      {value: 'A-', label: 'A-'},
                      {value: 'B+', label: 'B+'},
                      {value: 'B-', label: 'B-'},
                      {value: 'AB+', label: 'AB+'},
                      {value: 'AB-', label: 'AB-'},
                      {value: 'O+', label: 'O+'},
                      {value: 'O-', label: 'O-'},
                    ]}
                    onChange={(selectedOption: any) =>
                      formik.setFieldValue('bloodGroup', selectedOption.value)
                    }
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Email<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='email'
                    name='email'
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {'is-valid': formik.touched.email && !formik.errors.email}
                    )}
                  />
                </div>
                {/* stream */}
                <div className='mb-5 col-md-4 '>
                  <label className='form-label'>
                    Select Stream<span className='text-danger'>*</span>
                  </label>
                  <ReactSelect
                    placeholder='select Stream'
                    value={
                      filterData?.selectedStream?.label !== '' &&
                      filterData?.selectedStream?.value !== ''
                        ? filterData?.selectedStream
                        : null
                    }
                    options={filterData?.stream}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleStreamSelect(e)
                    }}
                  />

                  {/* end::Menu 2 */}
                </div>
                {/* class */}
                <div className='col-md-4 mb-5'>
                  <label className='form-label'>
                    Select Class<span className='text-danger'>*</span>
                  </label>
                  <ReactSelect
                    placeholder='select Class'
                    value={
                      filterData?.selectedClass?.label !== '' &&
                      filterData?.selectedClass?.value !== ''
                        ? filterData?.selectedClass
                        : null
                    }
                    options={filterData?.class}
                    isDisabled={filterData?.streamId === 0 ? true : false}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      handleClassSelect(e)
                    }}
                  />
                </div>
                {/* Division */}
                <div className='col-md-4 mb-5'>
                  <label className='form-label'>
                    Select Division<span className='text-danger'>*</span>
                  </label>
                  <ReactSelect
                    placeholder='select Division'
                    value={
                      filterData?.selectedDivision?.label !== '' &&
                      filterData?.selectedDivision?.value !== ''
                        ? filterData?.selectedDivision
                        : null
                    }
                    options={filterData?.division}
                    isDisabled={filterData?.classId === 0 ? true : false}
                    styles={{
                      option: (base) => ({
                        ...base,
                        cursor: 'pointer',
                        margin: '0px',
                        color: '#7e8299',
                        fontWeight: 'bold',
                        background: 'white', // this was the mistake (I needed to remove this)
                        ':hover': {
                          color: '#009ef7',
                          backgroundColor: '#f1faff',
                        },
                      }),
                    }}
                    onChange={(e: any) => {
                      setFilterData({
                        ...filterData,
                        selectedDivision: {label: e.label, value: e.value},
                        divisionId: e.value,
                      })
                      formik.setFieldValue('divisionId', e.value ? e.value : 0)
                    }}
                  />
                </div>
                <div className='col-md-4  mb-5 '>
                  <label className='form-label'>
                    Contact No<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    name='contactNo'
                    placeholder='Contact No'
                    {...formik.getFieldProps('contactNo')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.contactNo && formik.errors.contactNo},
                      {'is-valid': formik.touched.contactNo && !formik.errors.contactNo}
                    )}
                  />
                </div>

                <div className='col-12 border-top text-end'>
                  <button
                    type='submit'
                    className='btn btn-primary mt-2'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className='indicator-label d-flex align-items-center justify-content-center gap-2'>
                        <i className='ki-duotone ki-message-add fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                        Add Student
                      </span>
                    )}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
