import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  skills: [],
  learner: [],
  organization: [],
  publisher: [],
  certification: [],
}

export const achievementSlice = createSlice({
  name: 'achievementSlice',
  initialState,
  reducers: {
    setSkills: (state, action) => {
      state.skills = action.payload
    },
    setOrganization: (state, action) => {
      state.organization = action.payload
    },
    setPublisher: (state, action) => {
      state.publisher = action.payload
    },
    setLearner: (state, action) => {
      state.learner = action.payload
    },
    setCertification: (state, action) => {
      state.certification = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setSkills, setOrganization, setPublisher, setCertification, setLearner} =
  achievementSlice.actions
export default achievementSlice.reducer
