import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  faculty: [],
  updateData: [],
}

export const FacultySlice = createSlice({
  name: 'FacultySlice',
  initialState,
  reducers: {
    setFaculty: (state, action) => {
      state.faculty = action.payload
    },
    setUpdateData: (state, action) => {
      state.updateData = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const {setFaculty, setUpdateData} = FacultySlice.actions
export default FacultySlice.reducer
