import {useEffect, useState} from 'react'
import {useLazyGetSubjectDetailsQuery} from '../../../Api/allApi'
import './subject.css'
import {Link} from 'react-router-dom'
const ViewDetails = () => {
  const searchParams = new URLSearchParams(document.location.search)
  const subjectId = searchParams.get('subjectId')

  // subjectDetails api
  const [subjectDetailsTrigger, result] = useLazyGetSubjectDetailsQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    subjectDetailsTrigger(subjectId)
  }, [subjectDetailsTrigger, subjectId])

  const [subjectDetailsData, setSubjectDetailsData]: any = useState({})
  // when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setSubjectDetailsData(result?.data)
    }
  }, [isSuccess, isFetching, result])
  return (
    <>
      {isFetching ? (
        <div className='px-5'>
          <div className='col-12 text-center card mb-5 p-5'>
            <i
              className='fa-solid fa-circle-notch  fa-spin  p-10 text-primary'
              style={{fontSize: '30px', color: 'unset'}}
            ></i>
          </div>
        </div>
      ) : (
        <>
          <div className='card card-custom mb-5'>
            <div className='card-header d-flex justify-content-between'>
              <h2 className='card-title fs-2 fw-bold'>
                <Link to={'/Courses/Academic/View'}>
                  <button
                    className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    aria-label='Back'
                    data-bs-original-title='Back'
                    data-kt-initialized='1'
                  >
                    <i className='ki-duotone ki-arrow-left fs-1 m-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </Link>
                Subject Details
              </h2>
            </div>
          </div>
          <div className='card card-stretch mb-10'>
            <div className='card card-body p-8'>
              <div
                className='Subject-d-banner '
                style={{backgroundImage: `url(${subjectDetailsData?.coverImg})`}}
              >
                <div className='bg-image '>
                  <div
                    className='mx-auto d-flex align-items-center justify-content-center fw-bold text-white'
                    style={{width: '100%', height: '100%', fontSize: '35px'}}
                  >
                    {subjectDetailsData?.subjectName}
                  </div>
                </div>

                <div className='d-txt-btn  justify-content-between'>
                  <BannerBtn name='About Course' url='' />
                  <BannerBtn name='Learning Outcomes' url='' />
                  <BannerBtn name='Course Content' url='' />
                  <BannerBtn name='Faculties' url='' />
                  <BannerBtn name={`FAQ's`} url='' />
                </div>
              </div>

              <div className='row mt-2 '>
                <div className='col-md-6 col-sm-12 p-6  px-11'>
                  <h1 className='mb-6'>About Course</h1>
                  <p className='fs-6 font-Size '>{subjectDetailsData?.about}</p>
                </div>
                <div className='col-md-6 col-sm-12 sd-video-img mt-8 text-center'>
                  <img
                    src={subjectDetailsData?.aboutImg}
                    alt='video example'
                    style={{height: '80%', width: '80%'}}
                  />
                </div>
              </div>
            </div>

            <GetCourse data={subjectDetailsData?.outcomeList} />

            <FAQ
              title='Course Content'
              des=''
              type='content'
              border='border'
              data={subjectDetailsData}
            />

            <ProfileCard data={subjectDetailsData?.facultyDetails} />
            <FAQ
              title=' Frequesntly Asked Questions'
              des='First, a disclaimer – the entire process of writing a
                   blog post often takes more than a couple of hours,
                  even if you can type eighty words as per minute and 
                  your writing skills are sharp. '
              border='border-0'
              type='faqs'
              data={subjectDetailsData}
            />
          </div>
        </>
      )}
    </>
  )
}

const FAQ = ({title, des, border, data, type}) => {
  return (
    <>
      <div className='container pt-20 pb-10'>
        <div className='card card-stretch'>
          <div className='card-body'>
            <div className='mb-10'>
              <h4 className='fs-2x  w-bolder '>{title}</h4>
              <p className='fw-semibold fs-4  mb-2'>{des}</p>
            </div>
            <div className={`pb-5 ${border}  border-secondary`}>
              {type === 'content'
                ? data?.content?.map((item, index) => {
                    return <FaqList count={index + 1} item={item} type='content' />
                  })
                : data?.faqs?.map((item, index) => {
                    return <FaqList count={index + 1} item={item} type='faqs' />
                  })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const FaqList = (props) => {
  const {count, item, type} = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className='col-md-12  mb-10 mb-md-0 p-5 pr-0 pb-0'>
        {/* <h2 className='text-gray-800 fw-bold mb-4'>Buying Course</h2> */}
        <div className='m-0 p-3 border border-secondary shadow-sm' style={{borderRadius: '10px'}}>
          <div
            className='d-flex align-items-center justify-content-between cursor-pointer'
            onClick={() => {
              isOpen ? setIsOpen(false) : setIsOpen(true)
            }}
          >
            <h4 role='button' className=' fw-bold cursor-pointer mb-0'>
              <span className='m-2'>{count}</span>
              {type === 'content' ? item?.contentHead : item?.query}
            </h4>
            <div className='btn btn-sm btn-icon bg-dark btn-active-color-primary card  p-5'>
              <i
                className={`${
                  !isOpen ? 'fa-solid fa-plus text-primary' : 'fa-solid fa-minus text-white'
                }`}
                onClick={() => {
                  isOpen ? setIsOpen(false) : setIsOpen(true)
                }}
              ></i>
            </div>
          </div>

          <div id='kt_job_4_1' className='fs-6 ms-1 '>
            {isOpen && (
              <div className='mb-4  fw-semibold fs-6 ps-10 p-3'>
                {type === 'content' ? item?.contentBody : item?.reply}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const ProfileCard = ({data}) => {
  return (
    <>
      <div className='card card-stretch '>
        <div className='sd-get-outer bg-light-primary p-10'>
          <div className='container'>
            <h1 className='text-center my-10'>What You Get From This Course</h1>

            <div className='row '>
              {data?.map((item) => {
                return (
                  <div className='col-md-6 col-xxl-4 profile-card-outer'>
                    <ProfileView data={item} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const GetCourse = (props) => {
  const {data} = props
  return (
    <>
      <div className='sd-get-outer bg-light-primary p-10'>
        <div className='container'>
          <h1 className='text-center  my-10'>What You Get From This Course</h1>
          <div className='row mt-10'>
            {data?.map((item) => {
              return (
                <div className='col-md-6 col-lg-3 my-sm-5 my-lg-0'>
                  <GetCourseIcon icon='fa-solid fa-shield-halved' title={item?.details} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

const GetCourseIcon = ({icon, title}) => {
  return (
    <>
      <div className='sd-get-icon'>
        <div className=' text-center '>
          <i
            className={`${icon} bg-primary text-center p-7 text-white rounded-circle`}
            style={{fontSize: '50px'}}
          ></i>
        </div>
        <div className='sd-get-txt mt-3 '>
          <p className='text-primary fs-6'>{title}</p>
        </div>
      </div>
    </>
  )
}

const BannerBtn = ({name, url}) => {
  return (
    <>
      <div className='btn btn-primary fw-semibold  sd-btn d-md-flex justify-content-center align-items-center mx-auto  banner-btn-width '>
        {name}
      </div>
    </>
  )
}

function ProfileView({data}) {
  return (
    <>
      <div className='pv-container border border-dark rounded border-2 '>
        <div className='row p-5  text-center justify-content-center align-item-center'>
          <div className='col-sm-4 col-md-5 Profile-image'>
            <img src={data?.facultyImg} className='h-100 w-100' alt='profile_image...' />
          </div>

          <div className='col-sm-12 col-md-6 profile-title'>
            <h1>
              {' '}
              {data?.firstName} {data?.lastName}{' '}
            </h1>
            <h4 className='mt-2'>{data?.title}</h4>

            <div className='row P-edu align-items-center justify-content-center'>
              <div className='col-sm-12 text-center p-0'>
                <i className='bi bi-mortarboard fs-1 text-white'></i>
                <span className='mb-3 text-center'>Education </span>
                <br />
                <span className='eduction '>{data?.highestEducation}</span>
              </div>
              <div className='col-sm-12 text-center p-0'>
                <i className='bi bi-list fs-1 text-white'></i>
                <span> Experience </span>
                <br />
                <span className='experience'>{data?.yearsOfExperience}</span>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewDetails
